import { useEffect } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import dayjs from 'dayjs';

import { ScheduleMeetingModalView } from './view';
import { ScheduleMeetingModalMode, ScheduleMeetingModalProps, ScheduleMeetingFormData } from './model';

import {
  useOrdersControllerOrderCrmtMutation,
  useOrdersControllerOrderUmtMutation,
  useSalesOfficeControllerGetQuery
} from '@/api/web.client/webClientGeneratedApi';

export const scheduleMeetingSchema = Yup.object<ScheduleMeetingFormData>().shape({
  meetingDate: Yup.string().required().label('дата и время встречи'),
  salesOfficeId: Yup.number().required().label('место встречи')
});

export const ScheduleMeetingModal = ({
  meetingDate,
  onOk,
  orderId,
  onClickDate,
  mode,
  ...props
}: ScheduleMeetingModalProps) => {
  const formMethods = useForm<ScheduleMeetingFormData>({
    resolver: yupResolver<ScheduleMeetingFormData>(scheduleMeetingSchema),
    mode: 'onSubmit'
  });

  const salesOfficeId = formMethods.watch('salesOfficeId');

  const { handleSubmit, reset, setValue } = formMethods;

  const [scheduleMeeting, { isSuccess: isScheduleSuccess, reset: resetSchedule, isLoading: isScheduleLoading }] =
    useOrdersControllerOrderCrmtMutation();
  const [
    reScheduleMeeting,
    { isSuccess: isReScheduleSuccess, reset: resetReschedule, isLoading: isRescheduleLoading }
  ] = useOrdersControllerOrderUmtMutation();

  const { data: offices } = useSalesOfficeControllerGetQuery({});

  const onSubmit = handleSubmit(() => {
    if (!meetingDate) {
      return;
    }

    if (mode === ScheduleMeetingModalMode.update) {
      reScheduleMeeting({
        id: orderId,
        orderUmtRequest: {
          startDate: meetingDate.dateTimeStart,
          endDate: meetingDate.dateTimeEnd,
          salesOfficeId: salesOfficeId !== 0 ? salesOfficeId : undefined,
          isOnline: salesOfficeId === 0
        }
      });

      return;
    }

    scheduleMeeting({
      id: orderId,
      orderCrmtRequest: {
        startDate: meetingDate.dateTimeStart,
        endDate: meetingDate.dateTimeEnd,
        salesOfficeId: salesOfficeId !== 0 ? salesOfficeId : undefined,
        isOnline: salesOfficeId === 0
      }
    });
  });

  useEffect(() => {
    if (isScheduleSuccess || isReScheduleSuccess) {
      resetSchedule();
      resetReschedule();

      reset();
      onOk();
    }
  }, [isScheduleSuccess, isReScheduleSuccess, onOk, resetSchedule, resetReschedule, reset]);

  useEffect(() => {
    if (!meetingDate) {
      return;
    }

    setValue(
      'meetingDate',
      `${dayjs(meetingDate.dateTimeStart).format('DD.MM.YYYY HH:mm')} - ${dayjs(meetingDate.dateTimeEnd).format(
        'HH:mm'
      )}`
    );
  }, [meetingDate, setValue]);

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    reset();
    props.onCancel?.(event);
  };

  const handleDismiss = () => {
    reset();
    props.onDismiss?.();
  };

  const handleClickDate = () => {
    onClickDate();
  };

  return (
    <FormProvider {...formMethods}>
      <ScheduleMeetingModalView
        {...props}
        onSubmit={onSubmit}
        onCancel={handleCancel}
        onDismiss={handleDismiss}
        onClickDate={handleClickDate}
        isLoading={isScheduleLoading || isRescheduleLoading}
        offices={offices ?? []}
      />
    </FormProvider>
  );
};
