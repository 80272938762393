import { FC, JSX } from 'react';
import { useCurrentUser, UserRole } from '../../hooks/useCurrentUser';

export const UserRoleGuard: FC<{ children: JSX.Element; userRoles: UserRole[] }> = ({ children, userRoles }) => {
  const user = useCurrentUser();

  if (!userRoles.some((role) => user.hasRole(role))) {
    return null;
  }

  return children;
};
