import { SalesOfficeResponse } from '@/api/web.client/webClientGeneratedApi';
import { HookField } from '@/app/widgets/HookField';
import { AddIcon, ArrowRightSIcon, CloseIcon, SearchIcon } from '@/assets/icons';
import { Button, ButtonSize, ButtonVariant, Icon, Title } from '@/ui';
import { Col, Row, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { EmployeesViewProps } from './model';
import { SalesOfficeDetails } from './components/salesOfficeDetails';
import { SalesOfficeNew } from './components/salesOfficeNew';

export const SalesOfficesView: FC<EmployeesViewProps> = (props) => {
  const {
    onNewOffice,
    onDetailsOffice,
    onReset,
    form,
    salesOffices,
    citiesOptions,
    selectedOfficeId,
    isNewOfficeOpen,
    onCloseNewOffice
  } = props;

  const columns: ColumnsType<SalesOfficeResponse> = [
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      ellipsis: true,
      render: (name: string) => (
        <Tooltip placement="bottom" title={name}>
          <Typography.Text>{name}</Typography.Text>
        </Tooltip>
      )
    },
    {
      title: 'Город',
      dataIndex: 'city',
      key: 'cities',
      width: 200,
      ellipsis: true,
      render: (city: string) => {
        return (
          <Tooltip placement="bottom" title={city}>
            <Typography.Text>{city}</Typography.Text>
          </Tooltip>
        );
      }
    },
    {
      title: 'Адрес',
      dataIndex: 'address',
      key: 'address',
      width: 200,
      ellipsis: true,
      render: function (address: string) {
        return (
          <Tooltip placement="bottom" title={address}>
            <Typography.Text>{address}</Typography.Text>
          </Tooltip>
        );
      }
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 72,
      render: (_, row) => (
        <Button onClick={() => onDetailsOffice(row.id)} variant={ButtonVariant.iconText} size={ButtonSize.small}>
          <Icon icon={<ArrowRightSIcon />} />
        </Button>
      )
    }
  ];

  return (
    <>
      <Row justify="center" align="top">
        <Col span={24} className="content-centered-container">
          <Row align="top" justify="space-between" gutter={16}>
            <Col span={12}>
              <Title>Офисы продаж</Title>
            </Col>

            <Col span={6}>
              <Row justify="end" align="middle">
                <Col span={24}>
                  <Button onClick={onNewOffice} isWide size={ButtonSize.medium} prefixIcon={<AddIcon />}>
                    Добавить
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <FormProvider {...form}>
            <form name="SalesOfficeFilters">
              <Row align="bottom" gutter={16} style={{ marginBottom: 24 }}>
                <Col span={9}>
                  <HookField
                    isWhite
                    type="text"
                    name="search"
                    label="Поиск"
                    placeholder="Название, город или адрес"
                    suffix={<Icon icon={<SearchIcon />} />}
                  />
                </Col>

                <Col span={9}>
                  <HookField
                    name="cities"
                    type="select"
                    placeholder="Город"
                    allowClear={false}
                    options={citiesOptions}
                    mode="multiple"
                    isWhite
                    canSelectAll
                  />
                </Col>

                <Col span={6}>
                  <Button
                    isWide
                    type="button"
                    onClick={onReset}
                    prefixIcon={<CloseIcon />}
                    variant={ButtonVariant.white}
                  >
                    Сбросить фильтры
                  </Button>
                </Col>
              </Row>
            </form>
          </FormProvider>

          <Table
            pagination={false}
            showHeader={false}
            columns={columns}
            dataSource={salesOffices}
            locale={{
              emptyText: 'Нет данных'
            }}
            rowKey="id"
          />
        </Col>
      </Row>
      {selectedOfficeId && (
        <SalesOfficeDetails
          salesOffice={salesOffices.find((office) => office.id === selectedOfficeId)}
          salesOfficeId={selectedOfficeId}
          onClose={() => onDetailsOffice()}
        />
      )}
      {isNewOfficeOpen && <SalesOfficeNew onClose={onCloseNewOffice} />}
    </>
  );
};
