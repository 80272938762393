import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { webClientApi } from '../api/web.client/webClientApi';
import { rtkQueryErrorLogger } from './errorLoggerMiddleware';
import { createRootReducer } from './reducers';

export const store = configureStore({
  reducer: createRootReducer(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rtkQueryErrorLogger, webClientApi.middleware),
  devTools: process.env.NODE_ENV !== 'production'
});

export type StorageState = ReturnType<ReturnType<typeof createRootReducer>>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<R = void> = ThunkAction<R | Promise<R>, StorageState, unknown, AnyAction>;
if ((module as any)?.hot) {
  (module as any).hot.accept('./reducers/index', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const newRootReducer = require('./reducers/index').rootReducer;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    store.replaceReducer(newRootReducer);
  });
}
