import { Col, Row, Switch } from 'antd';
import { FC } from 'react';
import { Input, Label, LabelVariant } from '@/ui';

interface FormFieldGroupProps {
  label: string;
  value: string;
  isChecked: boolean;
  onChange: (val: boolean) => void;
}

export const FormFieldGroup: FC<FormFieldGroupProps> = (props) => {
  const { label, value, isChecked, onChange } = props;
  return (
    <div>
      <Label variant={LabelVariant.black24} value={label} />
      <Row gutter={16} align="middle">
        <Col style={{ flex: '1 1 auto' }}>
          <Input value={value} readOnly />
        </Col>
        <Col style={{ flex: '0 0 180px' }}>
          <Row gutter={12} align="middle">
            <Col>
              <Switch onChange={() => onChange(!isChecked)} size="small" checked={isChecked} />{' '}
            </Col>
            <Col>
              <Label level={2} value={isChecked ? 'Активен' : 'Заблокирован'} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
