import { Row, Col } from 'antd';

import { Flex, Title, LabelVariant, Badge, BadgeColors, Icon, IconSize, Label, Button, ButtonVariant } from '@/ui';

import { OrderFormMode } from '../../model';

import { Colors, Sizes } from '@/theme';

import { ArrowLeft } from '@/assets/icons';

import './styles.scss';

import { OrderFormHeaderViewProps } from './model';

export const OrderFormHeaderView = ({
  order,
  mode,
  orderStatusesMap,
  necessaryActionEnum,
  isScheduleMeetingAvailable,
  isCancelAvailable,
  onClickScheduleMeeting,
  onClickBack,
  onClickCancel
}: OrderFormHeaderViewProps) => {
  return (
    <Row justify="space-between">
      <Col span={24}>
        <Flex onClick={onClickBack} className="order-form-header-back-link">
          <Icon icon={<ArrowLeft />} color={Colors.black48} size={IconSize.md} />
          <Label value="Назад к списку заявок" variant={LabelVariant.black48} level={2} />
        </Flex>
      </Col>
      <Col>
        <Row gutter={10}>
          <Col>
            <Title level={1}>{mode === OrderFormMode.new ? 'Новая заявка' : `Заявка ${order?.number ?? ''}`}</Title>
          </Col>
          {order?.statusId && (
            <Col>
              <Badge text={orderStatusesMap?.[order.statusId] ?? ''} />
            </Col>
          )}

          {order?.necessaryAction && (
            <Col>
              <Badge
                text={necessaryActionEnum[order.necessaryAction] ?? order.necessaryAction}
                color={BadgeColors.yellow}
              />
            </Col>
          )}
        </Row>
      </Col>

      <Col>
        <Row gutter={Sizes.marginLG}>
          <Col>
            <Row gutter={Sizes.marginLG}>
              {order && isScheduleMeetingAvailable && (
                <Col>
                  <Button onClick={onClickScheduleMeeting}>Назначить встречу</Button>
                </Col>
              )}

              {order && isCancelAvailable && (
                <Col>
                  <Button variant={ButtonVariant.secondary} withBorder onClick={onClickCancel}>
                    Отменить заявку
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
