import { Row, Col, Space } from 'antd';

import { Title, TitleVariant, Badge, BadgeColors, Label, LabelVariant, Button, ButtonSize, ButtonVariant } from '@/ui';

import { ArrowLeftRight } from '@/assets/icons';

import { getApartmentForBuyText } from './helpers';

import { formatDateInTimeZone } from '@/utils/formatters';

import { OrderCardViewProps } from './model';

import './styles.scss';
import { useCurrentUser } from '../../hooks/useCurrentUser';

export const OrderCardCompactView = ({
  order,
  projectsMap,
  orderStatusesMap,
  orderStatusesEnum,
  necessaryActionEnum,
  isOrderTransferAvailable,
  onClick,
  onSendClick
}: OrderCardViewProps) => {
  const { projectId, totalArea, price } = order;
  const { timeZone } = useCurrentUser();

  const labelVariant = order.statusId === orderStatusesEnum?.ApplicationClosed ? LabelVariant.gray : LabelVariant.black;

  return (
    <>
      <Row className="order-card" justify="space-between" onClick={onClick}>
        <Col span={18} className="order-card-content">
          <Row>
            <Col span={24}>
              <Space direction="horizontal" align="baseline" style={{ marginBlockEnd: 16 }}>
                <div className="order-cart-title">
                  <Title
                    level={4}
                    variant={
                      order.statusId === orderStatusesEnum?.ApplicationClosed ? TitleVariant.gray : TitleVariant.normal
                    }
                  >
                    #{order.number}. {order.clientName}
                  </Title>
                </div>

                {order.statusId !== orderStatusesEnum?.ApplicationClosed ? (
                  <>
                    {order.necessaryAction && (
                      <Badge
                        text={necessaryActionEnum[order.necessaryAction] ?? order.necessaryAction}
                        color={BadgeColors.yellow}
                      />
                    )}

                    <Badge text={orderStatusesMap?.[order.statusId] || ''} color={BadgeColors.main} />
                  </>
                ) : null}
              </Space>
            </Col>

            <Col span={24}>
              <Space align="center">
                <Label
                  value={
                    projectId
                      ? getApartmentForBuyText({
                          project: projectsMap[projectId],
                          totalArea: totalArea ?? 0,
                          price: price ?? 0
                        })
                      : 'Нет проекта'
                  }
                  level={4}
                  variant={labelVariant}
                />

                <div className="divider" />

                <Label value="Заявка создана" level={4} variant={labelVariant} />
                <Label value={formatDateInTimeZone(order.createdAt, timeZone)} level={4} variant={labelVariant} />
              </Space>
            </Col>
          </Row>
        </Col>

        <Col span={6}>
          {order.statusId === orderStatusesEnum?.ApplicationClosed ? (
            <Row className="order-card-side" justify="start">
              <Col>
                <Badge text={orderStatusesMap?.[order.statusId] ?? ''} color={BadgeColors.grifel} />
              </Col>
            </Row>
          ) : (
            <>
              {isOrderTransferAvailable && (
                <Row className="order-card-side" justify="center">
                  <Col>
                    <Button
                      variant={ButtonVariant.secondary}
                      size={ButtonSize.small}
                      onClick={onSendClick}
                      prefixIcon={<ArrowLeftRight />}
                    >
                      Передать заявку
                    </Button>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
