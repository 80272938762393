import { Routes } from '@/app/routing';
import { CancelConfirmModal } from '@/app/widgets';
import { Layout, Space } from 'antd';
import { Content } from 'antd/es/layout/layout';

import { useState } from 'react';

import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { clearStore } from '../../../store/actions';
import { Colors } from '../../../theme';
import { Header, HeaderProps } from '../../../ui/Header';
import { LocalStorageKeys } from '../../../utils/localstorage/constants';
import { removeLocalStorageObject } from '../../../utils/localstorage/localStorage';
import { useAuthToken } from '../../hooks/useAuthToken';
import { useCurrentUser } from '../../hooks/useCurrentUser';

export const Main = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const token = useAuthToken();

  const showModal = () => {
    setOpen(true);
  };

  const { currentUser, hasRole } = useCurrentUser();

  if (!token) {
    return <Navigate to={Routes.login} />;
  }

  if (location.pathname === Routes.main) {
    if (hasRole('PartnerCommercialDirector') || hasRole('PartnerHeadOfDepartment')) {
      return <Navigate to={Routes.orders} />;
    }

    if (hasRole('PartnerManager')) {
      return <Navigate to={Routes.ordersList} />;
    }

    if (hasRole('ExchangeUser')) {
      return <Navigate to={Routes.partners} />;
    }

    if (hasRole('PartnerAdmin')) {
      return <Navigate to={Routes.projects} />;
    }
  }

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    removeLocalStorageObject(LocalStorageKeys.AuthToken);
    clearStore();
    navigate(Routes.login);
  };

  const handleDismiss = () => {
    setOpen(false);
  };

  const headerProps: HeaderProps = {
    menu: [],
    userName: currentUser?.name ?? '',
    onClickExit: showModal
  };

  if (hasRole('PartnerManager')) {
    headerProps.menu.push({
      label: 'Мои заявки',
      url: Routes.ordersList
    });
  }

  if (hasRole('PartnerCommercialDirector') || hasRole('PartnerHeadOfDepartment')) {
    headerProps.menu.push({
      label: 'Продажи',
      url: Routes.sales
    });

    headerProps.menu.push({
      label: 'График платежей',
      url: Routes.payments
    });

    headerProps.menu.push({
      label: 'Выплаты',
      url: Routes.statistics
    });

    headerProps.menu.push({
      label: 'Заявки',
      url: Routes.orders
    });

    headerProps.menu.push({
      label: 'Обязательства',
      url: Routes.bills
    });
  }

  if (hasRole('ExchangeUser')) {
    headerProps.menu.push({
      label: 'Партнеры',
      url: Routes.partners
    });
  }

  if (hasRole('PartnerAdmin')) {
    headerProps.menu.push({
      label: 'Проекты',
      url: Routes.projects
    });
    headerProps.menu.push({
      label: 'Сотрудники',
      url: Routes.employees
    });
    headerProps.menu.push({
      label: 'Отделы продаж',
      url: Routes.salesDepartments
    });
    headerProps.menu.push({
      label: 'Офисы продаж',
      url: Routes.salesOffices
    });
  }

  return (
    <>
      <Header {...headerProps} />
      <Space
        direction="vertical"
        style={{
          width: '100%',
          paddingTop: 48,
          paddingBottom: 64,
          backgroundColor: Colors.storm6,
          boxSizing: 'border-box',
          minHeight: 'calc(100vh - 100px)'
        }}
        size={[0, 48]}
      >
        <Layout style={{ backgroundColor: 'transparent' }}>
          <Content style={{ backgroundColor: 'transparent' }}>
            <Outlet />
          </Content>
        </Layout>
      </Space>
      <CancelConfirmModal
        onOk={handleOk}
        onCancel={handleCancel}
        onDismiss={handleDismiss}
        open={open}
        title="Вы действительно хотите выйти из системы?"
      />
    </>
  );
};
