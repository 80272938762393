import { UsersControllerProjectsApiArg } from '@/api/web.client/webClientGeneratedApi';

import { useUserProjects } from './useUserProjects';

export const useUserProjectsMap = (args: UsersControllerProjectsApiArg) => {
  const projects = useUserProjects(args);

  return projects.reduce((acc, curr) => {
    acc[curr.id] = curr.name;

    return acc;
  }, {} as Record<number, string>);
};
