import { forwardRef, useRef, useImperativeHandle, useId, useLayoutEffect } from 'react';

import { InfoCircleFilled } from '@ant-design/icons';

import { AutoComplete as AntdAutoComplete } from 'antd';
import type { AutoCompleteProps as AntdAutoCompleteProps } from 'antd';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';

import { useDropdownPosition } from '@/app/hooks/useDropdownPosition';

import { InputWrapper, InputWrapperProps } from '../InputWrapper/InputWrapper';

import './Autocomplete.scss';
import clsx from 'clsx';

export interface AutoCompleteProps extends AntdAutoCompleteProps, InputWrapperProps {
  isWhite?: boolean;
}

export const Autocomplete = forwardRef<BaseSelectRef, AutoCompleteProps>(
  ({ isWhite = false, label, description, readOnly, ...props }, ref) => {
    const selectRef = useRef<BaseSelectRef>();

    const wrapperRef = useRef<HTMLDivElement>(null);
    const dropdownMenuClassId = useId();

    useImperativeHandle(ref, () => selectRef.current as BaseSelectRef, [selectRef]);

    const [position, onVisibilityChange] = useDropdownPosition({
      wrapperRef,
      dropDownClass: dropdownMenuClassId
    });

    const popupClassName = clsx('select-popup', isWhite && 'white', dropdownMenuClassId, position ?? '');

    const selectClassName = clsx('ant-select-customize-input', isWhite && 'white', readOnly && 'read-only');

    const menuDivClassName = clsx('select-menu-additional-div', isWhite && 'white', position ?? '');

    useLayoutEffect(() => {
      onVisibilityChange(props.options?.length !== 0);
    }, [onVisibilityChange, props.options]);

    return (
      <InputWrapper label={label} description={description} status={props.status} readOnly={readOnly}>
        <div className="select-wrapper" ref={wrapperRef}>
          <AntdAutoComplete
            {...props}
            ref={(ref) => {
              if (ref) {
                selectRef.current = ref;
              }
            }}
            popupClassName={popupClassName}
            className={selectClassName}
            notFoundContent={null}
            tagRender={({ label }) => <span className="selected-tag">{label}</span>}
            onDropdownVisibleChange={onVisibilityChange}
          />

          {props.status === 'error' ? (
            <div className="select-error-icon">
              <InfoCircleFilled style={{ fontSize: '20px' }} />
            </div>
          ) : null}

          <div className={menuDivClassName} />
        </div>
      </InputWrapper>
    );
  }
);
