import { useOfferType } from '@/app/hooks/useOfferType';

import { CommercialOfferCardView } from './view';
import { CommercialOfferCardProps } from './model';
import { useCurrentUser } from '@/app/hooks/useCurrentUser';

export const CommercialOfferCard = ({ ...props }: Omit<CommercialOfferCardProps, 'offerTypes'>) => {
  const offerTypes = useOfferType();
  const { timeZone } = useCurrentUser();

  return <CommercialOfferCardView {...props} offerTypes={offerTypes} timeZone={timeZone} />;
};
