import { useEffect } from 'react';

import '@/utils/helpers/yup';

import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as Yup from 'yup';

import {
  useOrdersControllerUpdateDealConditionMutation,
  DealConditionRequest,
  ParticipantRequest,
  ParticipantJsonRequest
} from '@/api/web.client/webClientGeneratedApi';

import { DealConditionFormProps, DealConditionRequestForm } from './model';

import { DealConditionFormView } from './view';

import { useOrderStatusesEnum } from '@/app/hooks/useOrderStatusEnum';

import { omitBy } from 'lodash';
import {
  isDealDateTimeVisible,
  isDealParticipantsVisible,
  isHasEncumbrance,
  isMaternityCapitalUsedVisible,
  isMinorOwnersExistVisible,
  isNumberOfRoomsVisible,
  isPaymentConditionsVisible,
  isResidenceVisible
} from '@/app/content/orderForm/components/DealConditionForm/config';

const defaultValues: Partial<DealConditionRequest> = {
  hasEncumbrance: false,

  isResidence: false,

  maternityCapitalUsed: false,

  minorOwnersExist: false,
  minorNames: [''],

  isClientFunds: false,
  isInstallment: false,
  isCertificate: false,
  isMortgage: false,

  participants: []
};

export const DealConditionForm = ({ order }: DealConditionFormProps) => {
  const orderStatusesEnum = useOrderStatusesEnum();

  const readOnly = [orderStatusesEnum?.Deal, orderStatusesEnum?.DealContractSigned].includes(order.statusId);
  const isOrderLite = order.orderLite;

  const dealConditionSchema = Yup.object<DealConditionRequestForm>().shape({
    buyoutPrice: Yup.number().required().min(0, 'Отрицательные значения не допустимы').label('Стоимость квартиры'),
    paymentForDDU: Yup.number()
      .required()
      .label('ДС в счет оплаты ДДУ')
      .test(
        'paymentForDDUToBig',
        'Поле должно быть меньше суммы Цена выкупа и ДС на погашение ипотеки',
        (value, context) => readOnly || value + context.parent.paymentForEncumbrance <= context.parent.buyoutPrice
      ),
    paymentForParking: Yup.number()
      .optional()
      .min(0, 'Отрицательные значения не допустимы')
      .label('ДС в счет оплаты паркинга'),
    paymentForStorage: Yup.number()
      .optional()
      .min(0, 'Отрицательные значения не допустимы')
      .label('ДС в счет оплаты кладовки'),
    paymentToClientAccount: Yup.number()
      .optional()
      .min(0, 'Отрицательные значения не допустимы')
      .label('ДС на р/с клиента'),
    paymentForEncumbrance: Yup.number()
      .optional()
      .min(0, 'Отрицательные значения не допустимы')
      .label('ДС на погашение ипотеки')
      .test(
        'paymentForDDUToBig',
        'Поле должно быть меньше суммы Цена выкупа и ДС в счет оплаты ДДУ',
        (value, context) => readOnly || value + context.parent.paymentForDDU <= context.parent.buyoutPrice
      ),

    ...(isResidenceVisible
      ? {
          isResidence: Yup.boolean().optional().oneOf([true, false]).label('Проживание в сдаваемой квартире'),
          residenceDuration: Yup.string()
            .when('isResidence', (isResidence, field) =>
              isResidence[0] === true ? field.required() : field.notRequired()
            )
            .label('Длительность проживания'),
          vacatingDate: Yup.string()
            .when('isResidence', (isResidence, field) =>
              isResidence[0] === true ? field.required() : field.notRequired()
            )
            .label('Дата освобождения'),
          remainingProperty: Yup.string().optional().label('Оставляемое имущество')
        }
      : {}),

    ...(isHasEncumbrance
      ? {
          hasEncumbrance: Yup.boolean().optional().oneOf([true, false]).label('Наличие ипотеки'),
          remainingDebt: Yup.number()
            .when('hasEncumbrance', (hasEncumbrance, field) =>
              hasEncumbrance[0] === true ? field.required() : field.notRequired()
            )
            .min(0, 'Отрицательные значения не допустимы')
            .label('Остаток ипотеки'),
          encumbranceBankId: Yup.string()
            .when('hasEncumbrance', (hasEncumbrance, field) =>
              hasEncumbrance[0] === true ? field.required() : field.notRequired()
            )
            .label('Банк ипотеки')
        }
      : {}),

    ...(isMaternityCapitalUsedVisible
      ? {
          maternityCapitalUsed: Yup.boolean()
            .required()
            .oneOf([true, false])
            .label('Был использован материнский капитал')
        }
      : {}),
    ...(isMinorOwnersExistVisible
      ? {
          minorOwnersExist: Yup.boolean().required().oneOf([true, false]).label('Есть несовершеннолетние собственники'),
          minorNames: Yup.mixed<string[]>()
            .when('minorOwnersExist', (minorOwnersExist, field) =>
              minorOwnersExist[0] === true ? field.required() : field.optional()
            )
            .label('ФИО ребенка')
        }
      : {}),

    comment: Yup.string().optional().label('Комментарий'),

    projectId: Yup.number().optional().label('Проект'),
    house: Yup.string().optional().label('Дом'),
    ...(isNumberOfRoomsVisible
      ? {
          numberOfRooms: Yup.string().optional().label('Количество комнат')
        }
      : {}),
    totalArea: Yup.number().optional().min(0, 'Отрицательные значения не допустимы').label('Общая площадь квартиры'),

    ...(isPaymentConditionsVisible
      ? {
          isClientFunds: Yup.boolean().oneOf([true, false]).label('ДС клиента'),
          isInstallment: Yup.boolean().oneOf([true, false]).label('Рассрочка'),
          isCertificate: Yup.boolean().oneOf([true, false]).label('Сертификат'),
          isMortgage: Yup.boolean().oneOf([true, false]).label('Ипотека'),
          approvalStatus: Yup.number()
            .when('isMortgage', (isMortgage, field) =>
              isMortgage[0] === true ? field.required() : field.notRequired()
            )
            .label('Решение банка по ипотеке'),
          mortgageBankId: Yup.string()
            .when('isMortgage', (isMortgage, field) =>
              isMortgage[0] === true ? field.required() : field.notRequired()
            )
            .label('Банк ипотеки')
        }
      : {}),

    ...(isDealParticipantsVisible
      ? {
          participants: Yup.array<ParticipantRequest>()
            .of(
              Yup.object<ParticipantRequest>().shape({
                participant: Yup.object<ParticipantJsonRequest>().shape({
                  lastName: Yup.string()
                    .required()
                    .max(50, 'Поле Фамилия должно быть максимум 50 символов')
                    .label('Фамилия'),
                  firstName: Yup.string().required().max(50, 'Поле Имя должно быть максимум 50 символов').label('Имя'),
                  middleName: Yup.string()
                    .required()
                    .max(50, 'Поле Отчество должно быть максимум 50 символов')
                    .label('Отчество'),

                  passportSeries: Yup.string()
                    .required()
                    .test(
                      'isForeign',
                      'Серия паспорта должна содержать 4 цифры',
                      (value, context) => context.parent.isForeign || value.length === 4
                    )
                    .label('Серия паспорта'),

                  passportNumber: Yup.string()
                    .required()
                    .test(
                      'isForeign',
                      'Номер паспорта должна содержать 6 цифр',
                      (value, context) => context.parent.isForeign || value.length === 6
                    )
                    .label('Номер паспорта'),

                  isForeign: Yup.boolean().required().oneOf([true, false]).label('Иностранный гражданин'),

                  issuedBy: Yup.string()
                    .when('isForeign', (isForeign, field) =>
                      isForeign[0] === true ? field.notRequired() : field.required()
                    )
                    .label('Кем выдан'),

                  issueDate: Yup.string().optional().label('Когда выдан')
                })
              })
            )
            .required()
        }
      : {}),

    agreementNumber: Yup.string().optional().label('Номер договора ДДУ'),
    agreementDate: Yup.string().optional().label('Дата договора ДДУ'),
    ...(isDealDateTimeVisible
      ? {
          dealDateTime: Yup.string().optional().label('Дата и время сделки')
        }
      : {})
  });

  const formMethods = useForm<DealConditionRequestForm>({
    resolver: yupResolver<DealConditionRequestForm>(dealConditionSchema as any),
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues
  });

  const isResidence = order.dealCondition?.isResidence ?? false;

  const { handleSubmit, reset } = formMethods;

  const [updateDealCondition, { isLoading }] = useOrdersControllerUpdateDealConditionMutation();

  const handleSubmitForm = handleSubmit(async (data: DealConditionRequestForm) => {
    try {
      await updateDealCondition({
        id: order.id,
        dealConditionRequest: {
          ...data,
          orderLite: isOrderLite
        }
      }).unwrap();
    } catch (e) {
      console.log(e);
      // DO NOTHING
    }
  });

  useEffect(() => {
    if (!order) {
      return;
    }

    if (!order.dealCondition) {
      return;
    }

    reset(omitBy(order.dealCondition, (value) => value === null));
  }, [order, reset]);

  return (
    <FormProvider {...formMethods}>
      <DealConditionFormView
        onSubmit={handleSubmitForm}
        isLoading={isLoading}
        isResidence={isResidence}
        readOnly={readOnly}
      />
    </FormProvider>
  );
};
