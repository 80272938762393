import { Title, Label, Flex, LabelWeight, WithLoader } from '@/ui';

import { CurrentCommitmentsViewProps } from './model';
import { formatMoney } from '@/utils/formatters';

import './styles.scss';
import { Sizes } from '@/theme';

export const CurrentCommitmentsView = ({ isLoading, items, amountSum, projectsMap }: CurrentCommitmentsViewProps) => (
  <WithLoader isLoading={isLoading} className="current-commitments-loader">
    <Flex direction="column" align="flex-start" fullWidth gap={Sizes.marginLG} className="current-commitments">
      <Flex justify="space-between" align="flex-start" fullWidth>
        <div>
          <Title level={3}>Действующие обязательства</Title>
        </div>

        <div>
          <Title level={4}>{formatMoney(amountSum)} ₽</Title>
        </div>
      </Flex>

      {items?.map((item) => (
        <Flex
          key={`${item.projectId}-${item.house}`}
          justify="space-between"
          align="flex-start"
          fullWidth
          className="current-commitments-list"
        >
          <Label level={2} value={`${projectsMap[item.projectId]}  |  Дом ${item.house}`} />

          <Label level={2} value={`${formatMoney(item.amount)} ₽`} weight={LabelWeight.bold} />
        </Flex>
      ))}
    </Flex>
  </WithLoader>
);
