import { useFormContext } from 'react-hook-form';

import { Col, Row } from 'antd';
import { Button, ButtonSize, ButtonVariant, Icon, IconSize, Title } from '@/ui';

import { Colors } from '@/theme/themeVariables';

import { AddIcon, CheckboxCircleFilled } from '@/assets/icons';
import { HookField } from '@/app/widgets/HookField';

import { ApartmentsForSellViewProps } from './model';

import { yesNoRadioOptions } from '@/constants';
import { DealConditionRequest } from '@/api/web.client/webClientGeneratedApi';
import { useEffect } from 'react';
import { addMonths } from 'date-fns';
import {
  isHasEncumbrance,
  isMaternityCapitalUsedVisible,
  isMinorOwnersExistVisible,
  isResidenceVisible
} from '@/app/content/orderForm/components/DealConditionForm/config';

const isNumberFieldFilled = (num: string | undefined | number): boolean => {
  return !!num && !Number.isNaN(+num) && +num > 0;
};

export const ApartmentsForSellView = ({ banksOptions, readOnly, isResidence }: ApartmentsForSellViewProps) => {
  const { watch, setValue } = useFormContext<DealConditionRequest>();

  const watchHasEncumbranceCheck = watch('hasEncumbrance');
  const watchIsResidenceCheck = watch('isResidence');
  const watchMinorOwnersExist = watch('minorOwnersExist');
  const watchMinorNames = watch('minorNames');

  const isResidenceReadOnly = !isResidence && watchIsResidenceCheck;

  const handleAddChild = () => {
    setValue(`minorNames`, watchMinorNames ? [...watchMinorNames, ''] : ['']);
  };

  useEffect(() => {
    if (isResidenceReadOnly) {
      setValue(`residenceDuration`, 'До трех месяцев');
      setValue(`vacatingDate`, addMonths(new Date(), 3).toISOString());
    }
  }, [isResidenceReadOnly, setValue]);

  const paymentForParking = watch('paymentForParking');
  const paymentForStorage = watch('paymentForStorage');
  const paymentToClientAccount = watch('paymentToClientAccount');
  const paymentForEncumbrance = watch('paymentForEncumbrance');

  const isPaymentForParkingFilled = isNumberFieldFilled(paymentForParking);
  const isPaymentForStorageFilled = isNumberFieldFilled(paymentForStorage);
  const isPaymentToClientAccountFilled = isNumberFieldFilled(paymentToClientAccount);
  const isPaymentForEncumbranceFilled = isNumberFieldFilled(paymentForEncumbrance);

  return (
    <>
      <Title level={3}>
        <Icon icon={<CheckboxCircleFilled />} size={IconSize.xl} color={Colors.lime} />
        <>Сдаваемая в обмен.рф квартира</>
      </Title>

      <Row gutter={16}>
        <Col span={12} style={{ marginBottom: 32 }}>
          <HookField
            name="buyoutPrice"
            type="number"
            label="Цена выкупа"
            placeholder="0"
            decimalScale={2}
            min={0}
            valueSuffix="₽"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12} style={{ marginBottom: 32 }}>
          <HookField
            name="paymentForDDU"
            type="number"
            label="ДС в счет оплаты ДДУ"
            placeholder="0"
            decimalScale={2}
            min={0}
            valueSuffix="₽"
            readOnly={readOnly}
          />
        </Col>

        {isPaymentForParkingFilled && (
          <Col span={12} style={{ marginBottom: 32 }}>
            <HookField
              name="paymentForParking"
              type="number"
              label="ДС в счет оплаты паркинга"
              placeholder="0"
              decimalScale={2}
              min={0}
              valueSuffix="₽"
              readOnly={readOnly}
            />
          </Col>
        )}

        {isPaymentForStorageFilled && (
          <Col span={12} style={{ marginBottom: 32 }}>
            <HookField
              name="paymentForStorage"
              type="number"
              label="ДС в счет оплаты кладовки"
              placeholder="0"
              decimalScale={2}
              min={0}
              valueSuffix="₽"
              readOnly={readOnly}
            />
          </Col>
        )}

        {isPaymentToClientAccountFilled && (
          <Col span={12} style={{ marginBottom: 32 }}>
            <HookField
              name="paymentToClientAccount"
              type="number"
              label="ДС на р/с клиента"
              placeholder="0"
              decimalScale={2}
              min={0}
              valueSuffix="₽"
            />
          </Col>
        )}

        {isPaymentForEncumbranceFilled && (
          <Col span={12} style={{ marginBottom: 32 }}>
            <HookField
              name="paymentForEncumbrance"
              type="number"
              label="ДС на погашение ипотеки"
              placeholder="0"
              decimalScale={2}
              min={0}
              valueSuffix="₽"
              readOnly={readOnly}
            />
          </Col>
        )}
      </Row>

      {isResidenceVisible && (
        <Row style={{ marginBottom: 32 }} gutter={16}>
          <Col span={24} style={{ marginBottom: 32 }}>
            <HookField
              name="isResidence"
              type="radio"
              options={yesNoRadioOptions}
              label="Проживание в сдаваемой квартире"
              readOnly={readOnly}
            />
          </Col>

          {watchIsResidenceCheck ? (
            <>
              <Col span={12}>
                {isResidenceReadOnly ? (
                  <HookField name="residenceDuration" type="text" label="Длительность проживания" readOnly={true} />
                ) : (
                  <HookField
                    name="residenceDuration"
                    type="number"
                    label="Длительность проживания"
                    placeholder="0"
                    valueSuffix="месяцев"
                    readOnly={readOnly}
                  />
                )}
              </Col>

              <Col span={12}>
                <HookField
                  name="vacatingDate"
                  type="datePicker"
                  label="Дата освобождения"
                  placeholder="Дата освобождения"
                  readOnly={isResidenceReadOnly || readOnly}
                />
              </Col>

              <Col span={24}>
                <HookField
                  name="remainingProperty"
                  type="textArea"
                  label="Оставляемое имущество"
                  placeholder="Оставляемое имущество"
                  readOnly={readOnly}
                />
              </Col>
            </>
          ) : null}
        </Row>
      )}

      {isHasEncumbrance && (
        <Row style={{ marginBottom: 32 }} gutter={16}>
          <Col span={24} style={{ marginBottom: 32 }}>
            <HookField
              name="hasEncumbrance"
              type="radio"
              options={yesNoRadioOptions}
              label="Наличие ипотеки"
              readOnly={readOnly}
            />
          </Col>

          {watchHasEncumbranceCheck ? (
            <>
              <Col span={12}>
                <HookField
                  name="remainingDebt"
                  type="number"
                  label="Остаток ипотеки"
                  placeholder="0"
                  valueSuffix="₽"
                  decimalScale={1}
                  min={0}
                  readOnly={readOnly}
                />
              </Col>
              <Col span={12}>
                <HookField
                  name="encumbranceBankId"
                  type="select"
                  label="Банк ипотеки"
                  placeholder="Банк ипотеки"
                  options={banksOptions}
                  readOnly={readOnly}
                />
              </Col>
            </>
          ) : null}
        </Row>
      )}

      {isMaternityCapitalUsedVisible && (
        <Row gutter={16}>
          <Col span={24} style={{ marginBottom: 32 }}>
            <HookField
              name="maternityCapitalUsed"
              type="radio"
              options={yesNoRadioOptions}
              label="Был использован материнский капитал"
              readOnly={readOnly}
            />
          </Col>
        </Row>
      )}

      {isMinorOwnersExistVisible && (
        <>
          <Row gutter={16}>
            <Col span={24} style={{ marginBottom: 32 }}>
              <HookField
                name="minorOwnersExist"
                type="radio"
                options={yesNoRadioOptions}
                label="Есть несовершеннолетние собственники"
                readOnly={readOnly}
              />
            </Col>
          </Row>

          {watchMinorOwnersExist && (
            <>
              {watchMinorNames?.map((name, index) => (
                <Row gutter={16} key={`${name}-${index}`}>
                  <Col span={12} style={{ marginBottom: 16 }}>
                    <HookField
                      name={`minorNames[${index}]`}
                      type="text"
                      label="ФИО ребенка"
                      placeholder="ФИО ребенка"
                      readOnly={readOnly}
                    />
                  </Col>
                </Row>
              ))}

              {!readOnly && (
                <Row gutter={16} style={{ marginBottom: 32 }}>
                  <Col span={12}>
                    <Button
                      role="button"
                      type="button"
                      variant={ButtonVariant.secondary}
                      size={ButtonSize.small}
                      isWhite
                      prefixIcon={<AddIcon />}
                      onClick={handleAddChild}
                    >
                      Добавить ребенка
                    </Button>
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      )}

      <Row gutter={16}>
        <Col span={24}>
          <HookField name="comment" type="textArea" label="Комментарий" placeholder="" readOnly={readOnly} />
        </Col>
      </Row>
    </>
  );
};
