import { Row, Col, Space } from 'antd';

import { Title, Badge, BadgeColors, Button, ButtonSize, ButtonVariant } from '@/ui';
import { ArrowLeftRight } from '@/assets/icons';

import { usePhoneFormat } from '@/app/hooks/usePhoneFormat';

import { OrderCardRow } from './components/OrderCardRow';

import './styles.scss';

import { getApartmentForBuyText } from './helpers';
import { formatDateInTimeZone, formatMoney } from '@/utils/formatters';

import { OrderCardViewProps } from './model';
import { useCurrentUser } from '@/app/hooks/useCurrentUser';
import clsx from 'clsx';

export const OrderCardView = ({
  order,
  orderStatusesMap,
  orderStatusesEnum,
  necessaryActionEnum,
  projectsMap,
  isOrderTransferAvailable,
  isOpenAllowed,
  onClick,
  onSendClick
}: OrderCardViewProps) => {
  const { projectId, totalArea, price, orderLite, comment } = order;
  const { timeZone } = useCurrentUser();

  const apartmentForSaleText = order.isReady
    ? `г.${order.city}, ${order.street}, д.${order.building}, ${order.exchangeApartmentTotalArea} кв.м., ${formatMoney(
        order.clientPrice ?? 0
      )} ₽`
    : `${order.developerName}, ${order.projectName}${order.queue ? `, очередь ${order.queue}` : ''}`;

  const formatPhone = usePhoneFormat();

  return (
    <Row className={clsx(['order-card', { disabled: !isOpenAllowed }])} justify="space-between" onClick={onClick}>
      <Col span={16} className="order-card-content">
        <Title level={3}>
          #{order.number}. {order.clientName}
        </Title>
        {!orderLite && (
          <>
            <OrderCardRow
              title="Приобретаемая квартира"
              text={getApartmentForBuyText({
                project: projectId ? projectsMap[projectId] : '',
                totalArea: totalArea ?? 0,
                price: price ?? 0
              })}
              titleSpan={5}
            />

            <OrderCardRow title="Сдаваемая квартира" text={apartmentForSaleText} titleSpan={5} />
          </>
        )}

        {orderLite && comment && <OrderCardRow title="Комментарий" text={comment} titleSpan={5} />}

        <div className="order-info">
          <OrderCardRow
            title="Заявка создана"
            text={formatDateInTimeZone(order.createdAt, timeZone)}
            titleSpan={5}
            titlePadding="medium"
          />

          <OrderCardRow
            title="Телефон клиента"
            text={formatPhone(order.clientPhone)}
            titleSpan={5}
            titlePadding="medium"
          />
        </div>
      </Col>

      <Col>
        <Row className="order-card-side" justify="space-between">
          <Col>
            <Space direction="vertical" align="end">
              <Badge
                text={orderStatusesMap?.[order.statusId] ?? ''}
                color={order.statusId === orderStatusesEnum?.ApplicationClosed ? BadgeColors.grifel : BadgeColors.main}
              />
              {order.necessaryAction && (
                <Badge
                  text={necessaryActionEnum[order.necessaryAction] ?? order.necessaryAction}
                  color={BadgeColors.yellow}
                />
              )}
            </Space>
          </Col>

          <Col>
            {isOrderTransferAvailable && (
              <Button
                variant={ButtonVariant.secondary}
                size={ButtonSize.small}
                onClick={onSendClick}
                prefixIcon={<ArrowLeftRight />}
              >
                Передать заявку
              </Button>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
