import { PointerEvent, useRef } from 'react';

import { Row, Col } from 'antd';
import { FormProvider } from 'react-hook-form';

import {
  Title,
  Button,
  ButtonVariant,
  Label,
  LabelWeight,
  LabelVariant,
  Flex,
  WithLoader,
  Loader,
  TextLoader
} from '@/ui';

import { OrderCard, TransferOrderModal } from '@/app/widgets';

import { useModal } from '@/app/hooks/useModal';

import { CloseIcon } from '@/assets/icons';
import { Sizes } from '@/theme/themeVariables';

import { HookField } from '../../widgets/HookField';

import type { OrdersSalesListProps } from './model';

import './styles.scss';

export const OrdersSalesView = ({
  form,
  statusOptions,
  orders,
  necessaryActionsOptions,
  salesDepartmentsOptions,
  count,
  isLoading,
  onReset,
  onCardClick
}: OrdersSalesListProps) => {
  const orderIdRef = useRef<string>('');

  const [sendOrderModalOpened, openSendOrderModal, closeSendOrderModal] = useModal();

  const hanSendOrderClick = (id: string) => (event: PointerEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    orderIdRef.current = id;
    openSendOrderModal();
  };

  const handleCancelTransferOrderModal = () => {
    closeSendOrderModal();
    orderIdRef.current = '';
  };

  return (
    <Row justify="center" align="top">
      <Col span={24} className="content-centered-container">
        <Row justify="start" align="middle" gutter={24}>
          <Col>
            <Title>Заявки</Title>
          </Col>

          {isLoading ? (
            <Col span={24} style={{ marginBottom: Sizes.marginXL }}>
              <TextLoader />
            </Col>
          ) : (
            <>
              <Col>
                <Flex gap={4}>
                  <Label value="Активных:" level={3} variant={LabelVariant.black64} inline />
                  <Label
                    value={count.active}
                    level={3}
                    weight={LabelWeight.semibold}
                    variant={LabelVariant.lime}
                    inline
                  />
                </Flex>
              </Col>

              <Col>
                <Flex gap={4}>
                  <Label value="Требуют действия: " level={3} variant={LabelVariant.black64} inline />
                  <Label
                    value={count.necessaryAction}
                    level={3}
                    weight={LabelWeight.semibold}
                    variant={LabelVariant.mainError}
                    inline
                  />
                </Flex>
              </Col>
            </>
          )}
        </Row>

        <WithLoader isLoading={isLoading} className="order-sales-list-actions-loader ">
          <FormProvider {...form}>
            <form name="OrderFilters">
              <Row align="bottom" gutter={16} style={{ marginBottom: 24 }}>
                <>
                  <Col span={8}>
                    <HookField name="dates" type="datePickerRange" label="Дата создания" isWhite />
                  </Col>

                  <Col span={5}>
                    <HookField
                      name="statuses"
                      type="select"
                      label="Статус"
                      placeholder="Не выбран"
                      options={statusOptions}
                      mode="multiple"
                      isWhite
                      canSelectAll
                    />
                  </Col>

                  <Col span={5}>
                    <HookField
                      name="salesDepartments"
                      type="select"
                      label="Отдел продаж"
                      placeholder="Не выбран"
                      options={salesDepartmentsOptions}
                      mode="multiple"
                      isWhite
                      canSelectAll={salesDepartmentsOptions?.length > 1}
                      disabled={salesDepartmentsOptions?.length === 1}
                    />
                  </Col>

                  <Col span={5}>
                    <HookField
                      name="necessaryActions"
                      type="select"
                      label="Необходимое действие"
                      placeholder="Не выбран"
                      options={necessaryActionsOptions}
                      mode="multiple"
                      isWhite
                      canSelectAll
                    />
                  </Col>

                  <Col span={1}>
                    <Button
                      variant={ButtonVariant.secondary}
                      isWide
                      isWhite
                      onClick={onReset}
                      type="button"
                      prefixIcon={<CloseIcon />}
                      icon
                    />
                  </Col>
                </>
              </Row>
            </form>
          </FormProvider>
        </WithLoader>

        <Row>
          {isLoading ? (
            <>
              <Col span={24} style={{ marginBlock: 8 }}>
                <Loader className="order-sales-list-loader" />
              </Col>
              <Col span={24} style={{ marginBlock: 8 }}>
                <Loader className="order-sales-list-loader" />
              </Col>
              <Col span={24} style={{ marginBlock: 8 }}>
                <Loader className="order-sales-list-loader" />
              </Col>
              <Col span={24} style={{ marginBlock: 8 }}>
                <Loader className="order-sales-list-loader" />
              </Col>
            </>
          ) : (
            <>
              {orders.map((order) => (
                <Col span={24} style={{ marginBlock: 8 }} key={order.id}>
                  <OrderCard order={order} onSendClick={hanSendOrderClick(order.id)} onClick={onCardClick(order.id)} />
                </Col>
              ))}
            </>
          )}
        </Row>

        <TransferOrderModal
          orderId={orderIdRef.current}
          onOk={handleCancelTransferOrderModal}
          onCancel={handleCancelTransferOrderModal}
          onDismiss={handleCancelTransferOrderModal}
          open={sendOrderModalOpened}
        />
      </Col>
    </Row>
  );
};
