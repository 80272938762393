import { useOrderStatusesMap } from '@/app/hooks/useOrderStatusesMap';

import { OrderFormHeaderView } from './view';
import { OrderFormHeaderProps } from './model';
import { useNecessaryActionsEnum } from '@/app/hooks/useNecessaryActionsEnum';

export const OrderFormHeader = ({ ...props }: OrderFormHeaderProps) => {
  const orderStatusesMap = useOrderStatusesMap();
  const necessaryActionEnum = useNecessaryActionsEnum();

  return (
    <OrderFormHeaderView {...props} orderStatusesMap={orderStatusesMap} necessaryActionEnum={necessaryActionEnum} />
  );
};
