import { Label, Flex, LabelWeight, LabelVariant } from '@/ui';

import { PaymentRowViewProps, PaymentRowVariant } from './model';

import './styles.scss';
import clsx from 'clsx';

export const PaymentViewRow = ({ title, amount, variant }: PaymentRowViewProps) => (
  <Flex justify="space-between" align="flex-start" fullWidth className={clsx('payment-row', variant)}>
    <Label
      level={variant === PaymentRowVariant.header ? 2 : 3}
      value={title}
      variant={variant === PaymentRowVariant.header ? LabelVariant.black : LabelVariant.black48}
    />
    <Label
      level={variant === PaymentRowVariant.header ? 2 : 3}
      value={amount}
      weight={variant === PaymentRowVariant.header ? LabelWeight.bold : LabelWeight.normal}
      variant={variant === PaymentRowVariant.header ? LabelVariant.black : LabelVariant.black84}
    />
  </Flex>
);
