import { ProjectRequest, ProjectResponse } from '@/api/web.client/webClientGeneratedApi';

export const mapProjectResponseToRequest = (project: ProjectResponse): ProjectRequest => {
  return {
    name: project.name,
    city: project.city,
    isActive: project.isActive,
    salesDepartmentIds: project.salesDepartments.map((sd) => sd.id)
  };
};
