import { useEmployeeControllerGetQuery, EmployeeControllerGetApiArg } from '@/api/web.client/webClientGeneratedApi';
import { useMemo } from 'react';

export const useFreeEmployeesMap = (args: EmployeeControllerGetApiArg) => {
  const { data: employees } = useEmployeeControllerGetQuery(args);

  return useMemo(
    () =>
      employees?.reduce((acc, current) => {
        acc[current.id] = current.name;

        return acc;
      }, {} as Record<number, string>),
    [employees]
  );
};
