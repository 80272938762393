import { FC } from 'react';
import { ProjectFormViewProps } from './model';
import { Col, Row, Space } from 'antd';
import { Button, ButtonSize, ButtonVariant, Icon, Label, LabelVariant, LabelWeight } from '@/ui';
import { HookField } from '@/app/widgets/HookField';
import { Drawer } from '@/ui/Drawer';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { DrawerForm } from '@/ui/Drawer/DrawerForm/DrawerForm';
import { useDadata } from '@/app/hooks/useDadata';
import { AddIcon, CloseIcon } from '@/assets/icons';
import { BuildingObjectCard } from '@/app/widgets';
import { BuildingObjectResponse } from '@/api/web.client/webClientGeneratedApi';
import { useSalesDepartmentOptions } from '@/app/hooks/useSalesDepartmentOptions';

export const ProjectFormView: FC<ProjectFormViewProps> = (props) => {
  const { form, onClose, onSubmit, isNewProject, openAddBuildingObject, buildingObjects, setCurrentBuildingObject } =
    props;

  const formData = form.watch();

  const { data: cityOptions, onSearch: onSearchCity } = useDadata({
    from_bound: { value: 'city' },
    to_bound: { value: 'city' }
  });

  const { fields, append, remove, update } = useFieldArray({
    control: form.control,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    name: 'salesDepartmentIds'
  });

  const onClickAppendSalesDepartment = () => {
    append({ name: '' });
  };

  const onClickRemoveSalesDepartment = (index: number) => {
    if (fields.length > 0) {
      remove(index);
    } else {
      update(index, { name: '' });
    }
  };

  const openBuildingObjectDetails = (buildingObject: BuildingObjectResponse) => {
    setCurrentBuildingObject && setCurrentBuildingObject(buildingObject);
    openAddBuildingObject();
  };

  const salesDepartmentsOptions = useSalesDepartmentOptions();

  const drawerFormBody = (
    <Space direction="vertical" size={36} style={{ width: '100%' }}>
      <Space direction="vertical" size={20} style={{ width: '100%' }}>
        <div>
          <Label variant={LabelVariant.black24} value="Название" />
          <HookField name="name" type="text" />
        </div>
        <div>
          <Label variant={LabelVariant.black24} value="Город" />
          <HookField
            name="city"
            type="autocomplete"
            placeholder="Город"
            onSearch={onSearchCity}
            allowClear={false}
            options={cityOptions?.map((item) => ({ value: item.data.city, label: item.data.city })) ?? []}
            readOnly={!isNewProject}
          />
        </div>
      </Space>
      <div>
        <HookField name="isActive" type="switch" label={formData.isActive ? 'Активен' : 'Не активен'} />
      </div>
      <Space direction="vertical" size={20} style={{ width: '100%' }}>
        <Label level={2} weight={LabelWeight.bold} value="Отделы продаж" />
        {fields.map((field, index) => {
          const anotherSelectedOptions = salesDepartmentsOptions.filter((option) => {
            return !formData.salesDepartmentIds?.includes(option.value as number);
          });
          const availableOptions = salesDepartmentsOptions.filter((option) => {
            return (
              option.value === formData.salesDepartmentIds?.[index] ||
              anotherSelectedOptions.find((item) => item.value === (option.value as number))
            );
          });
          return (
            <div key={field.id}>
              <Label variant={LabelVariant.black24} value="Отдел продаж" />
              <Row gutter={16} align="middle">
                <Col style={{ flex: '1 1 auto' }}>
                  <HookField
                    name={`salesDepartmentIds.${index}`}
                    placeholder="Выберите отдел продаж"
                    options={availableOptions}
                    type="select"
                  />
                </Col>
                {fields.length > 0 && (
                  <Button
                    type="button"
                    variant={ButtonVariant.icon}
                    onClick={() => onClickRemoveSalesDepartment(index)}
                  >
                    <Icon icon={<CloseIcon />} />
                  </Button>
                )}
              </Row>
            </div>
          );
        })}
        <div>
          <Button
            role="button"
            type="button"
            size={ButtonSize.small}
            prefixIcon={<AddIcon />}
            variant={ButtonVariant.secondary}
            onClick={onClickAppendSalesDepartment}
          >
            Добавить отдел продаж
          </Button>
        </div>
      </Space>
      {!isNewProject && (
        <>
          <div style={{ display: 'flex', gap: 16, flexDirection: 'column' }}>
            <Label level={2} weight={LabelWeight.bold} value="Дома/Очереди" />

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 16 }}>
              {buildingObjects?.map((item) => (
                <BuildingObjectCard
                  key={item.name}
                  name={item.name}
                  completionDatePlan={item.completionDatePlan}
                  companyName={item.companyName}
                  onOpenBuildingDetails={() => openBuildingObjectDetails(item)}
                />
              ))}
            </div>
            <div>
              <Button
                onClick={openAddBuildingObject}
                variant={ButtonVariant.secondary}
                size={ButtonSize.small}
                prefixIcon={<AddIcon />}
                type="button"
              >
                Добавить дом/очередь
              </Button>
            </div>
          </div>
        </>
      )}
    </Space>
  );

  const drawerFormFooter = (
    <Row gutter={16}>
      <Col span={12}>
        <Button type="submit" role="button" isWide variant={ButtonVariant.primary}>
          Сохранить
        </Button>
      </Col>
      <Col span={12}>
        <Button type="button" role="button" onClick={onClose} isWide variant={ButtonVariant.secondary}>
          Отмена
        </Button>
      </Col>
    </Row>
  );

  return (
    <Drawer open onClose={onClose} size="large">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} style={{ display: 'contents' }}>
          <DrawerForm title={isNewProject ? 'Новый проект' : 'Данные проекта'} footer={drawerFormFooter}>
            {drawerFormBody}
          </DrawerForm>
        </form>
      </FormProvider>
    </Drawer>
  );
};
