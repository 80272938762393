import { Col } from 'antd';
import { SalesGraphRow } from './components/SalesGraphRow';
import { FunnelReportItem } from '@/api/web.client/webClientGeneratedApi';
import { WithLoader } from '@/ui';
import './styles.scss';

export type SalesGraphProps = {
  sales?: FunnelReportItem[];
  isLoading: boolean;
  lengths: number[];
};

export const SalesGraphView = ({ sales, isLoading, lengths }: SalesGraphProps) => {
  const salesNotFound = sales?.[0].count === 0;

  return (
    <Col className="graph-container">
      <WithLoader isLoading={isLoading} className="graph-loader">
        {salesNotFound ? (
          <span>Заявки не найдены</span>
        ) : (
          sales &&
          sales.map((sale, i) => (
            <SalesGraphRow
              percentage={sale.percentage}
              length={lengths[i]}
              opacity={i}
              key={i}
              title={sale.status}
              count={sale.count}
              highlighted={sale.highlighted}
            />
          ))
        )}
      </WithLoader>
    </Col>
  );
};
