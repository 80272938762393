import { Row, Col } from 'antd';

import { Title } from '@/ui';

import { Sizes } from '@/theme/themeVariables';

import { CurrentCommitments } from './components/CurrentCommitments';
import { DebtRepaymentSchedule } from './components/DebtRepaymentSchedule';

export const PaymentsView = () => (
  <Row justify="center" align="top">
    <Col span={24} style={{ maxWidth: Sizes.maxLayoutWidth }}>
      <Row align="top" justify="start" gutter={Sizes.marginLG} style={{ marginBottom: Sizes.marginXL }}>
        <Col span={24} style={{ marginBottom: Sizes.marginXL }}>
          <Title>График платежей</Title>
        </Col>

        <Col span={18}>
          <Row>
            <Col span={24} style={{ marginBottom: Sizes.marginXL }}>
              <CurrentCommitments />
            </Col>

            <Col span={24} style={{ marginBottom: Sizes.marginXL }}>
              <DebtRepaymentSchedule />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  </Row>
);
