import { FC } from 'react';
import { EmployeeForm } from '@/app/content/employees/components/employeeForm';
import { EmployeeDetailsProps } from '@/app/content/employees/components/employeeDetails/model';
import {
  useEmployeeControllerGetByIdQuery,
  useEmployeeControllerResetPasswordMutation,
  useEmployeeControllerUpdateMutation
} from '@/api/web.client/webClientGeneratedApi';
import { EmployeeFormData } from '@/app/content/employees/components/employeeForm/model';
import { mapEmployeeFormDataToResponse } from '@/app/content/employees/components/employeeForm/mapper';
import toast from 'react-hot-toast';

export const EmployeeDetails: FC<EmployeeDetailsProps> = (props) => {
  const { employeeId, onClose } = props;

  const { data: employee } = useEmployeeControllerGetByIdQuery({
    userId: employeeId
  });

  const [updateEmployee] = useEmployeeControllerUpdateMutation();
  const [resetPassword] = useEmployeeControllerResetPasswordMutation();

  if (!employee) {
    return null;
  }

  const onSubmit = async (data: EmployeeFormData) => {
    try {
      const employeeRequest = mapEmployeeFormDataToResponse(data);
      await updateEmployee({
        userId: employee.id,
        employeeRequest
      }).unwrap();
      onClose();
      toast.success('Сотрудник успешно изменен');
    } catch {
      // do nothing
    }
  };

  const onResetPassword = async () => {
    try {
      await resetPassword({
        userId: employee.id
      }).unwrap();
      toast.success('Пароль успешно сброшен, дальнейшие инструкции отправлены на почту');
    } catch {
      // do nothing
    }
  };

  return <EmployeeForm employee={employee} onClose={onClose} onSubmit={onSubmit} onResetPassword={onResetPassword} />;
};
