import { Row, Col } from 'antd';

import { ButtonVariant, Title, Button, WithLoader, TextLoader } from '@/ui';

import { BillsViewProps } from './model';

import { CloseIcon } from '@/assets/icons';

import { HookField } from '@/app/widgets/HookField';

import { Sizes } from '@/theme/themeVariables';

import './styles.scss';
import { BillsTable } from './components';

export const BillsView = ({ bills, projectsOptions, isLoading, onReset }: BillsViewProps) => (
  <Row justify="center" align="top">
    <Col span={24} style={{ maxWidth: Sizes.maxLayoutWidth }}>
      <Row align="top" justify="space-between" gutter={16}>
        <Col span={12}>
          <Title>Обязательства</Title>
        </Col>
      </Row>

      {projectsOptions.length === 0 && (
        <Row style={{ marginBottom: Sizes.marginXL }}>
          <Col span={24}>
            <TextLoader />
          </Col>
        </Row>
      )}

      <WithLoader isLoading={projectsOptions.length === 0} className="bills-list-actions-loader">
        <form name="Filters">
          <Row align="bottom" gutter={16} style={{ marginBottom: 24 }}>
            <Col span={8}>
              <HookField name="dates" type="datePickerRange" label="Период по дате гашения" isWhite />
            </Col>

            <Col span={11}>
              <HookField
                name="projectIds"
                type="select"
                label="Проекты"
                placeholder="Не выбран"
                options={projectsOptions}
                mode="multiple"
                isWhite
                canSelectAll
                loading={projectsOptions.length === 0}
              />
            </Col>

            <Col span={5}>
              <Button
                variant={ButtonVariant.secondary}
                isWide
                isWhite
                onClick={onReset}
                type="button"
                loading={projectsOptions.length === 0 || isLoading}
                prefixIcon={<CloseIcon />}
              >
                Сбросить фильтры
              </Button>
            </Col>
          </Row>
        </form>
      </WithLoader>

      <WithLoader isLoading={isLoading} className="bills-list-loader">
        <BillsTable bills={bills} />
      </WithLoader>
    </Col>
  </Row>
);
