import { FC } from 'react';
import toast from 'react-hot-toast';
import { useSalesOfficeControllerCreateSalesOfficeMutation } from '@/api/web.client/webClientGeneratedApi';
import { SalesOfficeFormData } from '../officeForm/model';
import { mapSalesOfficeFormDataToCreateRequest } from '../officeForm/mapper';
import { SalesOfficeNewProps } from './model';
import { OfficeForm } from '../officeForm';
import { useCurrentUser } from '@/app/hooks/useCurrentUser';

export const SalesOfficeNew: FC<SalesOfficeNewProps> = (props) => {
  const { onClose } = props;
  const { currentUser } = useCurrentUser();

  const corporateGroupId = currentUser?.corporateGroupId;
  const [createSalesOffice] = useSalesOfficeControllerCreateSalesOfficeMutation();

  const onSubmit = async (data: SalesOfficeFormData) => {
    if (!corporateGroupId) {
      return;
    }
    try {
      const salesOfficeCreateRequest = mapSalesOfficeFormDataToCreateRequest(data);
      await createSalesOffice({
        corporateGroupId,
        salesOfficeCreateRequest
      }).unwrap();
      onClose();
      toast.success('Офис продаж успешно создан');
    } catch {
      // do nothing
    }
  };

  return <OfficeForm onClose={onClose} onSubmit={onSubmit} />;
};
