import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/browser';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { applyThemeToCssVariables } from './theme';

applyThemeToCssVariables();
if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

if (process.env.WEB_VITALS === 'true') {
  reportWebVitals(console.log);
}
