import { Drawer as AntDrawer, DrawerProps as AntDrawerProps } from 'antd';
import clsx from 'clsx';
import { FC } from 'react';
import { CloseIcon } from '@/assets/icons';
import { Button, ButtonSize, ButtonVariant } from '../Buttons';
import { Icon } from '../Icon';
import './Drawer.scss';

export interface DrawerProps extends Omit<AntDrawerProps, 'onClose' | 'open'> {
  open: boolean;
  onClose: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Drawer: FC<DrawerProps> = ({ open, onClose, children, className, width, ...props }) => {
  return (
    <AntDrawer
      {...props}
      width={width ?? '50%'}
      headerStyle={{ display: 'none' }}
      open={open}
      className={clsx('custom-drawer', className)}
      onClose={onClose}
    >
      <div className="custom-drawer__button-wrapper">
        <Button onClick={onClose} size={ButtonSize.medium} variant={ButtonVariant.icon}>
          <Icon icon={<CloseIcon />} />
        </Button>
      </div>
      {children}
    </AntDrawer>
  );
};
