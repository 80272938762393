import * as Yup from 'yup';
import { FC } from 'react';
import { SalesOfficeFormData, SalesOfficeFormProps } from './model';
import { SalesOfficeFormView } from './view';
import { useForm } from 'react-hook-form';
import { mapSalesOfficeResponseToFormData } from './mapper';
import { yupResolver } from '@hookform/resolvers/yup';

export const officeFormSchema = Yup.object<SalesOfficeFormData>().shape({
  name: Yup.string()
    .required('Введите название')
    .max(150, 'Название не должно превышать 150 символов')
    .label('Название'),
  city: Yup.string().required('Введите город').label('Город'),
  address: Yup.string().required('Введите адрес').label('Адрес')
});

const defaultValues: SalesOfficeFormData = {
  name: '',
  city: '',
  address: ''
};

export const OfficeForm: FC<SalesOfficeFormProps> = (props) => {
  const { salesOffice } = props;

  const form = useForm<SalesOfficeFormData>({
    defaultValues: salesOffice ? mapSalesOfficeResponseToFormData(salesOffice) : defaultValues,
    resolver: yupResolver<SalesOfficeFormData>(officeFormSchema)
  });

  const isNewOffice = !salesOffice;

  return SalesOfficeFormView({
    ...props,
    form,
    isNewOffice
  });
};
