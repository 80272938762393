import { useEffect } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { useOrdersControllerOrderTransferMutation, OrderTransferRequest } from '@/api/web.client/webClientGeneratedApi';

import { useOrderManagersOptions } from '@/app/hooks/useOrderManagersOptions';

import { TransferOrderModalProps } from './model';

import { TransferOrderModalView } from './view';

export const orderTransferSchema = Yup.object<OrderTransferRequest>().shape({
  managerId: Yup.number().required().label('менеджер')
});

export const TransferOrderModal = ({ orderId, onOk, ...props }: TransferOrderModalProps) => {
  const formMethods = useForm<OrderTransferRequest>({
    resolver: yupResolver<OrderTransferRequest>(orderTransferSchema),
    mode: 'onSubmit'
  });

  const { handleSubmit, setValue } = formMethods;

  const [sendOrder, { isSuccess, reset }] = useOrdersControllerOrderTransferMutation();

  useEffect(() => {
    if (isSuccess) {
      onOk();
      reset();
    }
  }, [isSuccess, onOk, reset]);

  const onSubmit = handleSubmit((data: OrderTransferRequest) => {
    sendOrder({
      id: orderId,
      orderTransferRequest: data
    });
  });

  const managersOptions = useOrderManagersOptions(orderId);

  useEffect(() => {
    if (managersOptions.length === 1) {
      setValue('managerId', Number(managersOptions[0].value));
    }
  }, [managersOptions, setValue]);

  return (
    <FormProvider {...formMethods}>
      <TransferOrderModalView {...props} onSubmit={onSubmit} managersOptions={managersOptions} />
    </FormProvider>
  );
};
