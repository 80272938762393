import { useMemo } from 'react';

import { CurrentCommitmentsView } from './view';

import { useReportsControllerGetCurrentDebtReportQuery } from '@/api/web.client/webClientGeneratedApi';

import { useUserProjectsMap } from '@/app/hooks/useUserProjectsMap';

export const CurrentCommitments = () => {
  const { currentData, isLoading } = useReportsControllerGetCurrentDebtReportQuery();
  const projectsMap = useUserProjectsMap({});

  const amountSum =
    useMemo(() => currentData?.items.reduce((acc, curr) => acc + curr.amount, 0), [currentData?.items]) ?? 0;

  return (
    <CurrentCommitmentsView
      isLoading={isLoading}
      items={currentData?.items}
      amountSum={amountSum}
      projectsMap={projectsMap}
    />
  );
};
