import { FunnelReportItem } from '@/api/web.client/webClientGeneratedApi';
import { SalesGraphView } from './view';
import { calcLengthOfRow } from './utils/calcLengthOfRow';
import './styles.scss';

export type SalesGraphProps = {
  sales?: FunnelReportItem[];
  isLoading: boolean;
};

export const SalesGraph = ({ sales, isLoading }: SalesGraphProps) => {
  const counts = sales ? sales.map((item) => Number(item.count)) : [];
  const lengths = calcLengthOfRow(counts);

  return <SalesGraphView lengths={lengths} sales={sales} isLoading={isLoading} />;
};
