import { ReactNode, ButtonHTMLAttributes } from 'react';

import clsx from 'clsx';

import './Button.scss';
import { ButtonLoader } from './ButtonLoader';

export enum ButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  white = 'white',
  icon = 'icon',
  iconText = 'icon-text'
}
export enum ButtonSize {
  large = 'large',
  medium = 'medium',
  small = 'small'
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  loading?: boolean;
  children?: ReactNode;
  isWide?: boolean;
  isWhite?: boolean;
  withBorder?: boolean;
  prefixIcon?: ReactNode;
  icon?: boolean;
}

export const Button = ({
  variant = ButtonVariant.primary,
  size = ButtonSize.large,
  loading = false,
  children,
  isWide = false,
  isWhite = false,
  prefixIcon,
  withBorder = false,
  icon = false,
  className,
  ...props
}: ButtonProps) => {
  const buttonClassName = clsx(
    'button',
    `${size}`,
    `${variant}`,
    loading && 'loading',
    isWide && 'wide',
    isWhite && 'white',
    icon && 'icon',
    withBorder && 'withBorder',
    className
  );

  return (
    <button className={buttonClassName} {...props}>
      {loading ? (
        <ButtonLoader variant={variant} />
      ) : (
        <>
          {prefixIcon && <span className="button-prefix-icon">{prefixIcon}</span>}
          {!icon && children}
        </>
      )}
    </button>
  );
};
