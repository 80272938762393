const padZero = (number: number): string => {
  return number.toString().padStart(2, '0');
};

export const timeZoneMinutesToTimezoneString = (timesZoneMinutes: number) => {
  const sign = timesZoneMinutes >= 0 ? '+' : '-';
  const hours = Math.abs(Math.floor(timesZoneMinutes / 60));
  const minutes = Math.abs(timesZoneMinutes) % 60;

  return `${sign}${padZero(hours)}:${padZero(minutes)}`;
};
