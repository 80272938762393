import { forwardRef, useRef, useImperativeHandle, useId } from 'react';

import { Select as AntdSelect } from 'antd';
import type { SelectProps as AntdSelectProps } from 'antd';
import { Option } from 'rc-select';

import { DefaultOptionType } from 'rc-select/lib/Select';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';

import { Checkbox } from '@/ui/CheckBox/Checkbox';

import { ArrowDown } from '@/assets/icons';

import { InputWrapper, InputWrapperProps } from '@/ui/Inputs/InputWrapper/InputWrapper';

import { useDropdownPosition } from '@/app/hooks/useDropdownPosition';

import './Select.scss';
import clsx from 'clsx';

export interface SelectProps extends AntdSelectProps, InputWrapperProps {
  isWhite?: boolean;
  canSelectAll?: boolean;
  isSmall?: boolean;
  isGrey?: boolean;
}

const selectAll = {
  value: 'selectAll',
  label: 'Все'
};

export const Select = forwardRef<BaseSelectRef, SelectProps>(
  (
    {
      isWhite = false,
      canSelectAll = false,
      isSmall = false,
      isGrey = false,
      label,
      description,
      options,
      readOnly,
      ...props
    },
    ref
  ) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    const dropdownMenuClassId = useId();

    const [position, onVisibilityChange] = useDropdownPosition({
      wrapperRef,
      dropDownClass: dropdownMenuClassId
    });

    const selectRef = useRef<BaseSelectRef>();
    useImperativeHandle(ref, () => selectRef.current as BaseSelectRef, [selectRef]);

    const isMultiSelect = props.mode === 'multiple';

    const menuDivClassName = clsx(
      'select-menu-additional-div',
      isMultiSelect && 'multiple',
      isWhite && 'white',
      position ?? ''
    );

    const popupClassName = clsx(
      'select-popup',
      isMultiSelect && 'multiple',
      isWhite && 'white',
      isSmall && 'small',
      isGrey && 'grey',
      dropdownMenuClassId,
      position ?? ''
    );

    const selectClassName = clsx(
      'ant-select-customize-input',
      isWhite && 'white',
      isSmall && 'small',
      isGrey && 'grey',
      readOnly && 'read-only'
    );

    const menuItemSelectedIcon = (props: { isSelected: boolean }) => (
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      <Checkbox checked={props.isSelected} onChange={() => {}} />
    );

    const handleSelect: AntdSelectProps['onSelect'] = (option) => {
      if (props.mode !== 'multiple' || option !== selectAll.value) {
        return;
      }

      props.onChange?.(
        options?.map((option) => option.value),
        options as DefaultOptionType[]
      );
    };

    const handleDeselect: AntdSelectProps['onDeselect'] = (option) => {
      if (props.mode !== 'multiple' || option !== selectAll.value) {
        return;
      }

      props.onChange?.([], options as DefaultOptionType[]);
    };

    const handleChange = (value: any, option: DefaultOptionType | DefaultOptionType[]) => {
      if (props.mode !== 'multiple') {
        props.onChange?.(value, option);

        return;
      }

      props.onChange?.(
        value.filter((item: string) => item !== selectAll.value),
        option
      );
    };

    const value =
      props.mode === 'multiple' && props.value?.length === options?.length && canSelectAll
        ? [...props.value, selectAll.value]
        : props.value;

    return (
      <InputWrapper label={label} description={description} status={props.status} readOnly={readOnly}>
        <div className="select-wrapper" ref={wrapperRef}>
          <AntdSelect
            suffixIcon={<ArrowDown />}
            {...props}
            ref={(ref) => {
              if (ref) {
                selectRef.current = ref;
              }
            }}
            value={value}
            onChange={handleChange}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
            popupClassName={popupClassName}
            className={selectClassName}
            notFoundContent={null}
            tagRender={({ label, value }) => (
              <span className="selected-tag">
                {label}
                {props.value?.at(-1) === value ? '' : ','}
              </span>
            )}
            onDropdownVisibleChange={onVisibilityChange}
            maxTagCount={canSelectAll && props.value?.length === options?.length ? 0 : undefined}
            maxTagPlaceholder={canSelectAll && props.value?.length === options?.length ? selectAll.label : null}
            menuItemSelectedIcon={isMultiSelect ? menuItemSelectedIcon : undefined}
            optionLabelProp="children"
            placeholder={
              canSelectAll && isMultiSelect && (props.value?.length === 0 || options?.length === props.value?.length)
                ? selectAll.label
                : props.placeholder
            }
            popupMatchSelectWidth={true}
          >
            {isMultiSelect && canSelectAll ? <Option value={selectAll.value}>{selectAll.label}</Option> : null}
            {options?.map((option) => (
              <Option value={option.value} key={option.value}>
                {option.label}
              </Option>
            ))}
          </AntdSelect>

          {options?.length !== 0 ? <div className={menuDivClassName} /> : null}
        </div>
      </InputWrapper>
    );
  }
);
