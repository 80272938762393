import { ReactNode } from 'react';

import clsx from 'clsx';

import './Icon.scss';

export enum IconSize {
  xxl = 'xxl',
  xl = 'xl',
  lg = 'lg',
  md = 'md',
  sm = 'sm',
  xs = 'xs'
}

export interface IconProps {
  icon: ReactNode;
  size?: IconSize;
  color?: string;
}

export const Icon = ({ icon, size = IconSize.md, color = 'currentColor' }: IconProps) => {
  const iconClassName = clsx('icon', size);

  return (
    <span className={iconClassName} style={{ color }}>
      {icon}
    </span>
  );
};
