import * as Yup from 'yup';
import { FC } from 'react';
import { EmployeeFormData, EmployeeFormProps } from './model';
import { EmployeeFormView } from './view';
import { useForm } from 'react-hook-form';
import { mapEmployeeResponseToFormData } from './mapper';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUserRoles } from '@/app/hooks/useUserRoles';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const employeeFormSchema = Yup.object<EmployeeFormData>().shape({
  name: Yup.string().required('Введите ФИО').max(150, 'Длина ФИО не должна быть больше 150 символов').label('ФИО'),
  role: Yup.string().required('Выберите роль').label('Роль'),
  phone: Yup.string()
    .required('Введите номер телефона')
    .test('phone', 'Ведите правильный номер телефона', (value) => isValidPhoneNumber(value))
    .label('Телефон'),
  email: Yup.string().required('Введите email').email('Введите валидный email').label('Email'),
  isActive: Yup.boolean().required('Введите активность').oneOf([true, false]).label('Активность')
});

export const EmployeeForm: FC<EmployeeFormProps> = (props) => {
  const { employee } = props;

  const defaultValues: EmployeeFormData = {
    email: '',
    name: '',
    isActive: true,
    role: ''
  };

  const form = useForm<EmployeeFormData>({
    defaultValues: employee ? mapEmployeeResponseToFormData(employee) : defaultValues,
    resolver: yupResolver<EmployeeFormData>(employeeFormSchema)
  });

  const { rolesOptions } = useUserRoles();

  const isNewEmployee = !employee;

  return EmployeeFormView({
    ...props,
    form,
    rolesOptions,
    isNewEmployee
  });
};
