import { ReactNode, useState } from 'react';

import { Title, Icon, IconSize, Flex } from '@/ui';

import { ArrowDown } from '@/assets/icons';

import clsx from 'clsx';
import './ExpandableCard.scss';

export enum ExpandableCardVariant {
  default = 'default',
  wide = 'wide'
}
export interface ExpandableCardProps {
  children: ReactNode;
  title?: string;
  expandable?: boolean;
  variant?: ExpandableCardVariant;
  initialExpanded?: boolean;

  onExpand?: (open: boolean) => void;
}

export const ExpandableCard = ({
  onExpand,
  children,
  title,
  expandable = false,
  variant = ExpandableCardVariant.default,
  initialExpanded = false
}: ExpandableCardProps) => {
  const [cardExpanded, setExpanded] = useState(initialExpanded);

  const handleClick = () => {
    if (!expandable) {
      return;
    }

    onExpand?.(!cardExpanded);
    setExpanded((value) => !value);
  };

  const iconClassName = clsx('card-icon', cardExpanded && 'expanded');
  const cardClassName = clsx('card-wrapper', variant);

  return (
    <div className={cardClassName} onClick={handleClick}>
      <Flex fullWidth justify="space-between">
        <Title level={4}>{title}</Title>

        {expandable && (
          <div className={iconClassName}>
            <Icon icon={<ArrowDown />} size={variant === ExpandableCardVariant.default ? IconSize.md : IconSize.xxl} />
          </div>
        )}
      </Flex>

      <>{children}</>
    </div>
  );
};
