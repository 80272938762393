import { Sizes } from '@/theme';
import { Row, Col } from 'antd';
import { Title, WithLoader } from '@/ui';
import { HookField } from '@/app/widgets/HookField';
import { FormProvider } from 'react-hook-form';
import { FC } from 'react';
import { StatsProps } from './model';
import { formatMoney } from '@/utils/formatters';
import './styles.scss';

export const StatisticsView: FC<StatsProps> = ({
  form,
  data,
  projectById,
  startDateValidation,
  endDateValidation,
  projectsIsLoading,
  reportIsLoading
}) => {
  const finalPrice = data ? data.reduce((prev, curr) => curr.amount + prev, 0) : 0;

  const getContent = () => {
    if (!data) {
      return <p>Выберите период, чтобы сформировать статистику по выплатам</p>;
    }

    if (data.length === 0) {
      return <p>Выплаты за выбранный период не найдены</p>;
    } else {
      return (
        <>
          <Row justify="space-between">
            <Title level={3}>Выплачено</Title>
            <Title level={4}>{formatMoney(finalPrice)} ₽</Title>
          </Row>
          {data.map((item, i) => (
            <Row key={i} justify="space-between" className="info">
              <span className="name">
                {projectById[item.projectId]?.name ?? 'Проект не найден'} | Дом {item.house}
              </span>
              <strong className="price">{formatMoney(item.amount)} ₽</strong>
            </Row>
          ))}
        </>
      );
    }
  };

  return (
    <Row justify="center" align="top">
      <Col span={24} style={{ maxWidth: Sizes.maxLayoutWidth }}>
        <Row align="top" justify="space-between" gutter={16}>
          <Col>
            <Title>Статистика по выплатам</Title>
          </Col>
        </Row>

        <FormProvider {...form}>
          <form name="StatsFilters">
            <Row align="bottom" gutter={16} style={{ marginBottom: 24 }}>
              <Col span={8}>
                <HookField
                  disabledDateStart={startDateValidation}
                  disabledDateEnd={endDateValidation}
                  label="Период"
                  name="dates"
                  type="datePickerRange"
                  isWhite
                />
              </Col>
            </Row>
          </form>
        </FormProvider>

        <Row>
          <Col span={18} className="statistics-container">
            <WithLoader isLoading={projectsIsLoading || reportIsLoading}>{getContent()}</WithLoader>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
