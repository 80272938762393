import { FC, ReactNode } from 'react';
import { Label, LabelVariant, Title } from '../../Typography';
import './DrawerForm.scss';

export interface DrawerFormProps {
  title: string;
  subTitle?: string;
  children?: ReactNode;
  footer?: ReactNode;
}

export const DrawerForm: FC<DrawerFormProps> = (props) => {
  const { title, subTitle, footer, children } = props;
  return (
    <div className="drawer-form">
      <div className="drawer-form__header">
        <Title level={3}>{title}</Title>
        {subTitle && <Label variant={LabelVariant.gray}>{subTitle}</Label>}
      </div>
      <div className="drawer-form__body">{children}</div>
      {footer && <div className="drawer-form__footer">{footer}</div>}
    </div>
  );
};
