import { Col, Row } from 'antd';
import { RejectionReportItem } from '@/api/web.client/webClientGeneratedApi';
import './styles.scss';
import { WithLoader } from '@/ui';

type SalesInfoRejectedProps = {
  rejections?: RejectionReportItem[];
  isLoading: boolean;
};

export const SalesInfoRejected = ({ rejections, isLoading }: SalesInfoRejectedProps) => {
  return (
    <Col className="info-container">
      <WithLoader isLoading={isLoading} className="reject-loader">
        <h4 className="heading">Причины отказа по заявкам</h4>
        {rejections &&
          rejections.map((item, i) => (
            <Row className="line" key={i} style={{ marginBlock: 16 }} align="middle">
              <span className="amount">{item.count}</span>
              <span className="title">{item.reason}</span>
            </Row>
          ))}
      </WithLoader>
    </Col>
  );
};
