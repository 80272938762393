export const phoneMask = (phone: string, mask: string): string => {
  const result: string[] = [];
  let j = 0;
  for (let i = 0; i < mask.length; i++) {
    const maskSymbol = mask[i];
    const phoneSymbol = phone[j];
    if (maskSymbol === phoneSymbol) {
      result.push(phoneSymbol);
      j++;
    } else if (maskSymbol === '#') {
      j++;
      result.push(phoneSymbol || '_');
    } else {
      result.push(maskSymbol);
    }
  }
  return result.join('');
};
