import { forwardRef } from 'react';

import { Input as AntdInput } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

import type { InputProps as AntdInputProps, InputRef } from 'antd/es/input';

import clsx from 'clsx';

import './InputBase.scss';

export interface InputBaseProps extends AntdInputProps {
  isWhite?: boolean;
}

export const InputBase = forwardRef<InputRef, InputBaseProps>(({ isWhite = false, ...props }, ref) => {
  const inputClassName = clsx(
    'input',
    props.status && `${props.status}`,
    isWhite && 'white',
    props.value && 'has-value'
  );

  return (
    <AntdInput
      {...props}
      ref={ref}
      rootClassName={inputClassName}
      suffix={props.status === 'error' ? <InfoCircleFilled style={{ fontSize: '20px' }} /> : props.suffix}
    />
  );
});
