import * as Yup from 'yup';
import { FC } from 'react';
import { ProjectFormProps } from './model';
import { ProjectFormView } from './view';
import { useForm } from 'react-hook-form';
import { ProjectRequest } from '@/api/web.client/webClientGeneratedApi';
import { yupResolver } from '@hookform/resolvers/yup';
import { mapProjectResponseToRequest } from './mapper';

const defaultValues: ProjectRequest = {
  name: '',
  city: '',
  isActive: true
};

export const projectSchema = Yup.object<ProjectRequest>().shape({
  name: Yup.string()
    .required('Введите название')
    .max(100, 'Длина названия не должна быть больше 100 символов')
    .label('Название'),
  city: Yup.string().required('Введите город').label('Город'),
  isActive: Yup.boolean().required('Введите активность').oneOf([true, false]).label('Активность'),
  salesDepartmentIds: Yup.array().of(Yup.number().required()).label('Отделы продаж')
});

export const ProjectForm: FC<ProjectFormProps> = (props) => {
  const { project } = props;

  const form = useForm<ProjectRequest>({
    defaultValues: project ? mapProjectResponseToRequest(project) : defaultValues,
    resolver: yupResolver<ProjectRequest>(projectSchema)
  });

  return ProjectFormView({
    ...props,
    form,
    isNewProject: !project
  });
};
