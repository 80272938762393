import { ProjectsView } from './view';
import { BuildingObjectResponse, useProjectsControllerGetProjectsQuery } from '@/api/web.client/webClientGeneratedApi';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { skipToken } from '@reduxjs/toolkit/query';
import { useState } from 'react';

export const Projects = () => {
  const { currentUser } = useCurrentUser();

  const [isActive, setIsActive] = useState<boolean | undefined>(undefined);

  const [isNewProjectOpen, setIsNewProjectOpen] = useState<boolean>(false);

  const [editedProjectId, setEditedProjectId] = useState<number | undefined>(undefined);

  const [isAddBuildingObjectOpen, setIsAddBuildingObjectOpen] = useState(false);

  const [currentBuildingObject, setCurrentBuildingObject] = useState<BuildingObjectResponse | undefined>(undefined);

  const [activeProjectId, setActiveProjectId] = useState<number | undefined>(undefined);

  const { data: projects, isLoading } = useProjectsControllerGetProjectsQuery(
    currentUser?.corporateGroupId
      ? {
          isActive,
          corporateGroupId: currentUser?.corporateGroupId
        }
      : skipToken
  );

  const buildingObjects = projects
    ?.map((project) => project.buildingObjects)
    .flat()
    .filter((flat) => flat.projectId === editedProjectId);

  return ProjectsView({
    projects: projects ?? [],
    isLoading,
    isActive,
    setIsActive,
    setEditedProjectId,
    editedProjectId,
    setIsNewProjectOpen,
    isNewProjectOpen,
    isAddBuildingObjectOpen,
    setIsAddBuildingObjectOpen,
    buildingObjects: buildingObjects ?? [],
    currentBuildingObject,
    setCurrentBuildingObject,
    activeProjectId,
    setActiveProjectId
  });
};
