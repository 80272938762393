import { useFormContext } from 'react-hook-form';

import { Row, Col } from 'antd';
import { Title, Icon, IconSize, Label, LabelWeight, Flex } from '@/ui';

import { Colors, Sizes } from '@/theme/themeVariables';

import { CheckboxCircleFilled } from '@/assets/icons';
import { HookField } from '@/app/widgets/HookField';

import { DealConditionRequest } from '@/api/web.client/webClientGeneratedApi';

import { useUserProjectsOptions } from '@/app/hooks/useUserProjectsOptions';
import { useMortgageApprovalStatusOptions } from '@/app/hooks/useMortgageApprovalStatusOptions';
import { useBanksOptions } from '@/app/hooks/useBanksOptions';

import { roomsCountOptions } from '@/constants';

import { ApartmentsForBuyViewProps } from './model';
import {
  isNumberOfRoomsVisible,
  isPaymentConditionsVisible
} from '@/app/content/orderForm/components/DealConditionForm/config';

export const ApartmentsForBuyView = ({ readOnly }: ApartmentsForBuyViewProps) => {
  const { watch } = useFormContext<DealConditionRequest>();
  const watchIsMortgageCheck = watch('isMortgage');

  const projectsOptions = useUserProjectsOptions({});
  const approvalStatusOptions = useMortgageApprovalStatusOptions();
  const banksOptions = useBanksOptions();

  return (
    <>
      <Title level={3}>
        <Icon icon={<CheckboxCircleFilled />} size={IconSize.xl} color={Colors.lime} />
        <>Приобретаемая квартира</>
      </Title>

      <Row style={{ marginBottom: 32 }} gutter={16}>
        <Col span={12}>
          <HookField
            name="projectId"
            type="select"
            label="Проект"
            placeholder="Не выбран"
            options={projectsOptions}
            disabled={projectsOptions.length === 0}
            showSearch
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) => {
              return (option?.children?.toString() ?? '')?.toLowerCase()?.includes(input.toLowerCase());
            }}
            readOnly={readOnly}
          />
        </Col>

        <Col span={12}>
          <HookField name="house" type="text" label="Дом" placeholder="Дом" readOnly={readOnly} />
        </Col>
      </Row>

      <Row style={{ marginBottom: 32 }} gutter={16}>
        {isNumberOfRoomsVisible && (
          <Col span={12}>
            <HookField
              name="numberOfRooms"
              type="select"
              label="Количество комнат"
              placeholder="Количество комнат"
              options={roomsCountOptions}
              readOnly={readOnly}
            />
          </Col>
        )}

        <Col span={12}>
          <HookField
            name="totalArea"
            type="number"
            label="Общая площадь квартиры"
            placeholder="0"
            min={0}
            decimalScale={1}
            valueSuffix={`м\u00B2`}
            readOnly={readOnly}
          />
        </Col>

        {isPaymentConditionsVisible && (
          <>
            <Col span={24} style={{ marginTop: 36 }}>
              <Label value="Условия оплаты" level={3} weight={LabelWeight.semibold}>
                <Flex direction="row" justify="start" style={{ marginTop: Sizes.marginSM }} gap={Sizes.marginXXL}>
                  <HookField name="isClientFunds" type="checkbox" label="ДС клиента" readOnly={readOnly} />
                  <HookField name="isInstallment" type="checkbox" label="Рассрочка" readOnly={readOnly} />
                  <HookField name="isCertificate" type="checkbox" label="Сертификат" readOnly={readOnly} />
                  <HookField name="isMortgage" type="checkbox" label="Ипотека" readOnly={readOnly} />
                </Flex>
              </Label>
            </Col>
            {watchIsMortgageCheck === true && (
              <Row style={{ marginBottom: 32 }} gutter={16}>
                <Col span={12}>
                  <HookField
                    name="approvalStatus"
                    type="select"
                    label="Решение банка по ипотеке"
                    placeholder="Решение банка по ипотеке"
                    options={approvalStatusOptions}
                    readOnly={readOnly}
                  />
                </Col>

                <Col span={12}>
                  <HookField
                    name="mortgageBankId"
                    type="select"
                    label="Банк ипотеки"
                    placeholder="Банк ипотеки"
                    options={banksOptions}
                    readOnly={readOnly}
                  />
                </Col>
              </Row>
            )}
          </>
        )}
      </Row>
    </>
  );
};
