import { useState } from 'react';

import { SalesDepartmentCardProps } from './model';
import { Button, ButtonSize, ButtonVariant, Icon, IconSize, Title, TitleVariant, WithLoader } from '@/ui';

import { ArrowDown, EditIcon } from '@/assets/icons';

import { Row, Col } from 'antd';

import './styles.scss';
import clsx from 'clsx';

export const SalesDepartmentCardView = ({ salesDepartment, onClickEdit, expanded }: SalesDepartmentCardProps) => {
  const [cardExpanded, setExpanded] = useState(expanded);

  const onClickCollapse = () => {
    setExpanded(!cardExpanded);
  };

  const salesDepartmentHeadOfDepartment = salesDepartment.users.find((user) =>
    user.roles.includes('PartnerHeadOfDepartment')
  );

  const salesDepartmentManagers = salesDepartment.users.filter((user) => user.roles.includes('PartnerManager'));

  return (
    <WithLoader isLoading={false} className="sales-department-card-loader">
      <div className="sales-department-card">
        <Row className="sales-department-card__header" justify="space-between">
          <Col>
            <Row gutter={24} align="middle">
              <Col>
                <div className="sales-department-card__title">
                  <Title level={3} variant={TitleVariant.normal}>
                    {salesDepartment.name}
                  </Title>
                </div>
              </Col>
              <Col>
                <Button onClick={onClickEdit} variant={ButtonVariant.icon} size={ButtonSize.small}>
                  <Icon size={IconSize.md} icon={<EditIcon />} />
                </Button>
              </Col>
            </Row>
          </Col>
          <Col>
            <Button
              size={ButtonSize.small}
              variant={ButtonVariant.icon}
              onClick={onClickCollapse}
              className={clsx('sales-department-card__collapse-button', { active: cardExpanded })}
            >
              <Icon size={IconSize.xl} icon={<ArrowDown />} />
            </Button>
          </Col>
        </Row>
        {cardExpanded && (
          <>
            <Row>
              <Col span={24}>
                <div className="sales-department-card__info">
                  <span>{salesDepartment.city}</span>
                </div>
                <div className="sales-department-card__body">
                  <Row>
                    <Col span={8}>
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <div className="sales-department-card__employees-title">Руководитель ОП:</div>
                        </Col>
                        <Col span={24}>
                          <Row gutter={[16, 16]}>
                            <Col span={24}>
                              <div className="sales-department-card__employee-name">
                                {salesDepartmentHeadOfDepartment ? salesDepartmentHeadOfDepartment.name : '—'}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={16}>
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <div className="sales-department-card__employees-title">Менеджеры:</div>
                        </Col>
                        <Col span={24}>
                          <Row gutter={[16, 16]}>
                            {!salesDepartmentManagers.length && '—'}
                            {salesDepartmentManagers.map((manager) => (
                              <Col key={manager.id} span={12}>
                                <div className="sales-department-card__employee-name">{manager.name}</div>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </WithLoader>
  );
};
