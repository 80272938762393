import { ReactNode } from 'react';
import { Typography } from 'antd';

import './Label.scss';
import clsx from 'clsx';

export enum LabelVariant {
  gray = 'gray',
  black = 'black',
  black24 = 'black24',
  black36 = 'black36',
  black64 = 'black64',
  black72 = 'black72',
  black84 = 'black84',
  mainError = 'mainError',
  lime = 'lime',
  black48 = 'black48'
}

export enum LabelWeight {
  normal = 'normal',
  semibold = 'semibold',
  bold = 'bold'
}

export interface LabelProps {
  level?: 1 | 2 | 3 | 4 | 5;
  variant?: LabelVariant;
  children?: ReactNode;
  value?: ReactNode;
  weight?: LabelWeight;
  inline?: boolean;
  ellipsis?: boolean;
}

export const Label = ({
  value,
  level = 4,
  variant = LabelVariant.black,
  weight = LabelWeight.normal,
  children,
  ellipsis = false,
  inline = false
}: LabelProps) => {
  const labelClassName = clsx('text', `level-${level}`, `variant-${variant}`, `weight-${weight}`, inline && 'inline');

  return (
    <Typography.Text className={labelClassName} ellipsis={ellipsis ? { tooltip: value } : undefined}>
      {value && <span className="label">{value}</span>}
      {children}
    </Typography.Text>
  );
};
