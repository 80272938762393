import { forwardRef, InputHTMLAttributes, ReactNode, useId } from 'react';

import { CheckboxCheck, CheckboxIndetermine } from '@/assets/icons';

import './Checkbox.scss';
import clsx from 'clsx';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  status?: 'error' | 'warning' | '';
  indeterminate?: boolean;
  label?: ReactNode;
  readOnly?: boolean;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({ label, readOnly = false, ...props }, ref) => {
  const checkboxWrapperClassName = clsx('checkbox', props.status && `${props.status}`);
  const checkboxIconClassName = clsx('checkbox-check-icon', props.checked && 'checked');

  const id = useId();

  return (
    <div className="checkbox-wrapper">
      <div className={checkboxWrapperClassName}>
        <input
          {...props}
          id={id}
          type="checkbox"
          ref={ref}
          className="checkbox-input"
          disabled={readOnly || props.disabled}
        />
        <div className={checkboxIconClassName}>{props.indeterminate ? <CheckboxIndetermine /> : <CheckboxCheck />}</div>
      </div>

      {label && (
        <label htmlFor={id} className="checkbox-label">
          {label}
        </label>
      )}
    </div>
  );
});
