import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import {
  BuildingObjectCreateRequest,
  useBuildingObjectControllerCreateBuildingObjectMutation,
  useBuildingObjectControllerDeleteBuildingObjectMutation,
  useBuildingObjectControllerUpdateBuildingObjectMutation
} from '@/api/web.client/webClientGeneratedApi';
import { AddBuildingObjectProps } from './model';
import { AddBuildingObjectView } from './view';
import { salesDepartmentOptionTransformer } from './mapper';
import toast from 'react-hot-toast';
import { useCompaniesOptions } from '@/app/hooks/useSalesCompaniesOptions';
import { useCurrentUser } from '@/app/hooks/useCurrentUser';

export const addBuildingObjectSchema = Yup.object<BuildingObjectCreateRequest>().shape({
  name: Yup.string().max(150, 'Название не должно превышать 150 символов').required('Введите название'),
  completionDatePlan: Yup.string().required('Введите планируемый срок сдачи'),
  companyId: Yup.number().required('Выберите продающую организацию')
});

export const AddBuildingObject: FC<AddBuildingObjectProps> = (props) => {
  const { onClose, projectId, currentBuildingObject } = props;
  const form = useForm<BuildingObjectCreateRequest>({
    resolver: yupResolver<BuildingObjectCreateRequest>(addBuildingObjectSchema),
    defaultValues: {
      name: currentBuildingObject?.name ?? '',
      completionDatePlan: currentBuildingObject?.completionDatePlan ?? '',
      companyId: currentBuildingObject?.companyId ?? undefined
    }
  });

  const salesDepartmentOptions = salesDepartmentOptionTransformer(useCompaniesOptions());
  const [createBuildingObject] = useBuildingObjectControllerCreateBuildingObjectMutation();
  const [updateBuildingObject] = useBuildingObjectControllerUpdateBuildingObjectMutation();
  const [deleteBuildingObject] = useBuildingObjectControllerDeleteBuildingObjectMutation();

  const { currentUser } = useCurrentUser();

  const corporateGroupId = currentUser?.corporateGroupId;

  if (!corporateGroupId) {
    return null;
  }

  const onDeleteBuildingObject = async (buildingObjectId: number) => {
    try {
      await deleteBuildingObject({ buildingObjectId, corporateGroupId, projectId }).unwrap();
      toast.success('Дом/очередь успешно удалена');
      onClose();
    } catch (e) {
      // do nothing
    }
  };

  const onSubmit: SubmitHandler<BuildingObjectCreateRequest> = async (buildingObjectCreateRequest) => {
    if (currentBuildingObject) {
      try {
        await updateBuildingObject({
          buildingObjectUpdateRequest: buildingObjectCreateRequest,
          buildingObjectId: currentBuildingObject.id,
          corporateGroupId,
          projectId
        }).unwrap();
        toast.success('Дом/очередь успешно обновлена');
        onClose();
      } catch (e) {
        // do nothing
      }
    } else {
      try {
        await createBuildingObject({
          buildingObjectCreateRequest,
          corporateGroupId,
          projectId
        }).unwrap();
        toast.success('Дом/очередь успешно создана');
        onClose();
      } catch (e) {
        // do nothing
      }
    }
  };

  return AddBuildingObjectView({
    ...props,
    onSubmit,
    salesDepartmentOptions,
    isNewBulidingObject: !currentBuildingObject,
    form,
    onDeleteBuildingObject
  });
};
