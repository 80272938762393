import { ReactNode } from 'react';
import { Label, Description, LabelVariant } from '@/ui/Typography';

import clsx from 'clsx';

import './InputWrapper.scss';

export interface InputWrapperProps {
  label?: ReactNode;
  description?: string;
  readOnly?: boolean;
}

interface InputWrapperPropsInner extends InputWrapperProps {
  children: ReactNode;
  status?: 'error' | 'warning' | '';
}

export const InputWrapper = ({ children, label, description, status, readOnly = false }: InputWrapperPropsInner) => {
  const inputWrapperClassName = clsx('input-wrapper', readOnly && 'read-only');

  return (
    <div className={inputWrapperClassName}>
      <Label value={label || ''} variant={LabelVariant.gray}>
        <div className="input-container">{children}</div>
      </Label>

      {description && <Description status={status}>{description}</Description>}
    </div>
  );
};
