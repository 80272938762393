import { forwardRef } from 'react';

import { Input } from 'antd';
import type { InputProps, InputRef } from 'antd/es/input';
import { InputWrapper, InputWrapperProps } from '../InputWrapper/InputWrapper';

import clsx from 'clsx';

import './InputPassword.scss';

export interface InputPasswordProps extends InputProps, InputWrapperProps {}

export const InputPassword = forwardRef<InputRef, InputPasswordProps>(({ label, description, ...props }, ref) => {
  const inputClassName = clsx('password-input', `${props.status}`);

  return (
    <InputWrapper label={label} description={description} status={props.status}>
      <Input.Password {...props} rootClassName={inputClassName} ref={ref} />
    </InputWrapper>
  );
});
