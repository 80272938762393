import {
  ReportsControllerGetFunnelReportApiArg,
  useReportsControllerGetFunnelReportQuery
} from '@/api/web.client/webClientGeneratedApi';

export const useFunnelReport = (args: ReportsControllerGetFunnelReportApiArg) => {
  const response = useReportsControllerGetFunnelReportQuery(args);

  const { isLoading } = response;
  const sales = response?.data?.items;
  return { sales, isLoading };
};
