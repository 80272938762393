import { ExpandableCard, Flex } from '@/ui';

import { FilesCardViewProps } from './model';

import { FileLink } from '../FileLink';

export const FilesCardView = ({ offersFiles }: FilesCardViewProps) => (
  <ExpandableCard title="Файлы">
    <Flex direction="column" align="flex-start" gap={16}>
      {offersFiles.map((item) => (
        <FileLink key={item.fileId} orderId={item.orderId} offerId={item.offerId} fileId={item.fileId} />
      ))}
    </Flex>
  </ExpandableCard>
);
