import { Colors, Sizes, IconSizes, BreakPoints, Fonts, FontSizes } from './themeVariables';

export function applyThemeToCssVariables() {
  const styles = [Colors, Sizes, BreakPoints, Fonts, FontSizes, IconSizes];

  const root = document.querySelector<HTMLElement>(':root');

  if (!root) {
    return;
  }

  styles.forEach((element) => {
    for (const [key, value] of Object.entries(element)) {
      root.style.setProperty(`--${key}`, typeof value === 'number' ? `${value}px` : value);
    }
  });
}
