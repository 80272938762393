import React, { useState, useRef, useEffect } from 'react';
import { ActionMenuProps } from './model';
import styles from './styles.module.scss';
import cn from 'classnames';
import { useClickOutside } from '@/app/hooks/useClickOutside';

export const ActionMenuView = ({ children, options }: ActionMenuProps) => {
  const [open, setOpen] = useState(false);
  const [listHeight, setListHeight] = useState(0);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (listRef.current) {
      const height = listRef.current.scrollHeight;
      setListHeight(height);
    }
  }, [open]);

  useClickOutside(listRef, () => {
    if (open) {
      setOpen(false);
    }
  });

  return (
    <div onClick={() => setOpen(!open)} className={styles.container}>
      {children}
      <div className={styles.listContainer}>
        <div
          ref={listRef}
          style={{ maxHeight: open ? `${listHeight}px` : '0' }}
          className={cn(styles.list, open ? '' : styles.list_hidden)}
        >
          {options.map((option, index) => (
            <span key={index} onClick={option.onClick} className={styles.item}>
              {option.label}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
