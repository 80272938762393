import { Table } from 'antd';

import { BillsTableViewProps } from './model';

import './styles.scss';

export const BillsTableView = ({ billsTableData, columns }: BillsTableViewProps) => (
  <Table
    dataSource={billsTableData}
    columns={columns}
    pagination={false}
    className="table"
    locale={{ emptyText: 'Нет данных' }}
  />
);
