import { webClientBaseApi as api } from './webClientBaseApi';

import { OffersControllerGetFileApiArg } from './webClientGeneratedApi';

export const openFile = async (data: string, filename: string): Promise<void> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const href = URL.createObjectURL(data as any);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

const parseFileName = (contentDisposition: string | null): string | null => {
  try {
    if (!contentDisposition) {
      return null;
    }
    // https://regex101.com/library/P31cKV
    const regExp = new RegExp(/^.*filename[^;=\n]*=(([\'"]).*?\2|[^;\n]*)[\n;]?$/);
    return regExp.exec(contentDisposition)?.[1] ?? null;
  } catch {
    return null;
  }
};

const injectedCustomApi = api.injectEndpoints({
  endpoints: (build) => ({
    offersCustomControllerGetFile: build.query<{ body: string; fileName: string; }, OffersControllerGetFileApiArg>({
      query: (queryArg) => {
        return {
          url: `/api/orders/${queryArg.orderId}/offers/${queryArg.offerId}/file`,
          responseHandler: async (response) => {
            if (response.ok) {
              const file = await response.blob();

              const contentDisposition = response.headers.get('Content-Disposition');

              const fileNameParsed = parseFileName(contentDisposition);

              const fileName = fileNameParsed ? decodeURI(fileNameParsed) : 'file';

              return {
                fileName,
                body: file,
              };
            }

            return null;
          }
        };
      }
    })
  })
});

export const { useOffersCustomControllerGetFileQuery } = injectedCustomApi;
