import { Col, Row, Space } from 'antd';
import { FC } from 'react';
import { AddIcon } from '@/assets/icons';
import { Button, ButtonSize, ButtonVariant, Label, Switch, Title } from '@/ui';
import { Drawer } from '@/ui/Drawer';
import { DrawerForm } from '@/ui/Drawer/DrawerForm/DrawerForm';
import { CompanyForm } from '../CompanyForm/CompanyForm';
import { FormFieldGroup } from './components/FormFieldGroup';
import { CorporateGroupDetailsViewProps } from './model';

export const CorporateGroupDetailsView: FC<CorporateGroupDetailsViewProps> = (props) => {
  const {
    onSave,
    onClose,
    corporateGroup,
    onClickAddCompany,
    isActiveChanged,
    orderLiteOption,
    onChangeCompanyActive,
    isCorporateGroupActive,
    onChangeCorporateGroupActive,
    isCompanyFormOpen,
    onCancelCompanyForm,
    onSaveCompanyForm,
    onChangeOrderLiteOption
  } = props;

  const drawerFormBody = (
    <Space direction="vertical" size={36} style={{ width: '100%' }}>
      <FormFieldGroup
        label="Название партнера"
        value={corporateGroup.name}
        isChecked={isCorporateGroupActive}
        onChange={onChangeCorporateGroupActive}
      />
      <Space direction="vertical" size={20} style={{ width: '100%' }}>
        <Title level={4}>Список юр. лиц</Title>
        {corporateGroup.companies.map((company) => (
          <FormFieldGroup
            key={company.id}
            label="Название Юр. лица"
            value={company.name}
            isChecked={company.isActive}
            onChange={(val) => onChangeCompanyActive(company.id, val)}
          />
        ))}
        <Button
          isWhite
          role="button"
          size={ButtonSize.small}
          prefixIcon={<AddIcon />}
          onClick={onClickAddCompany}
          variant={ButtonVariant.secondary}
        >
          Добавить юр. лицо
        </Button>
        <Row gutter={12} align="middle">
          <Col>
            <Switch
              onChange={() => {
                onChangeOrderLiteOption(!orderLiteOption);
              }}
              size="small"
              checked={orderLiteOption}
            />{' '}
          </Col>
          <Col>
            <Label level={2} value={'Разрешен сокращенный формат заявки'} />
          </Col>
        </Row>
      </Space>
    </Space>
  );

  const drawerFormFooter = (
    <Row gutter={16}>
      <Col span={12}>
        <Button role="button" onClick={onSave} disabled={!isActiveChanged} isWide variant={ButtonVariant.primary}>
          Сохранить
        </Button>
      </Col>
      <Col span={12}>
        <Button role="button" onClick={onClose} isWide variant={ButtonVariant.secondary}>
          Отмена
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <Drawer open onClose={onClose} size="large">
        <DrawerForm title="Данные партнера" footer={drawerFormFooter}>
          {drawerFormBody}
        </DrawerForm>
      </Drawer>
      {isCompanyFormOpen && (
        <CompanyForm
          onClose={onCancelCompanyForm}
          onSubmit={onSaveCompanyForm}
          corporateGroupName={corporateGroup.name}
        />
      )}
    </>
  );
};
