import { Row, Col } from 'antd';
import { HookField } from '@/app/widgets/HookField';
import { Icon, IconSize, Title } from '@/ui';
import { CheckboxCircleFilled } from '@/assets/icons';
import { Colors } from '@/theme';
import { isDealDateTimeVisible } from '@/app/content/orderForm/components/DealConditionForm/config';

export const AgreementDataFormView = () => (
  <>
    <Title level={3}>
      <Icon icon={<CheckboxCircleFilled />} size={IconSize.xl} color={Colors.lime} />
      <>Информация по ДДУ</>
    </Title>
    <Row gutter={16} style={{ marginBottom: 32 }}>
      <Col span={12}>
        <HookField name="agreementNumber" type="text" label="Номер договора ДДУ" placeholder="Номер договора ДДУ" />
      </Col>
      <Col span={12}>
        <HookField name="agreementDate" type="datePicker" label="Дата договора ДДУ" placeholder="Дата договора ДДУ" />
      </Col>
    </Row>

    {isDealDateTimeVisible && (
      <Row gutter={16} style={{ marginBottom: 32 }}>
        <Col span={12}>
          <HookField
            name="dealDateTime"
            type="datePicker"
            showTime
            format="DD/MM/YYYY HH:mm"
            label="Дата и время сделки"
            placeholder="Дата и время сделки"
          />
        </Col>
      </Row>
    )}
  </>
);
