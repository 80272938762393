import { Col, Row, Space, Tooltip } from 'antd';
import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { Button, ButtonVariant, Label, LabelVariant } from '@/ui';
import { Drawer } from '@/ui/Drawer';
import { DrawerForm } from '@/ui/Drawer/DrawerForm/DrawerForm';
import { HookField } from '@/app/widgets/HookField';
import { AddBuildingObjectViewProps } from './model';
import { DeleteIcon } from '@/assets/icons';
import { COMPANY_NOT_FOUND_VALUE } from './mapper';
import { Colors } from '@/theme';

export const AddBuildingObjectView: FC<AddBuildingObjectViewProps> = (props) => {
  const {
    form,
    onSubmit,
    onClose,
    salesDepartmentOptions,
    isNewBulidingObject,
    currentBuildingObject,
    onDeleteBuildingObject
  } = props;

  const companyId = form.watch('companyId');
  const companyNotFound = companyId === COMPANY_NOT_FOUND_VALUE;
  const COMPANY_NOT_FOUND_MESSAGE = 'Для добавления организации свяжитесь со своим куратором со стороны Обмен РФ';

  const drawerFormBody = (
    <Space direction="vertical" size={36} style={{ width: '100%' }}>
      <Space direction="vertical" size={20} style={{ width: '100%' }}>
        <div>
          <Label variant={LabelVariant.black24} value="Название" />
          <HookField name="name" type="text" placeholder="Название" />
        </div>
        <div>
          <Label variant={LabelVariant.black24} value="Планируемый срок сдачи" />
          <HookField name="completionDatePlan" type="datePicker" placeholder="Планируемый срок сдачи" />
        </div>
        <div>
          <Label variant={LabelVariant.black24} value="Продающая организация" />
          <Tooltip
            color={Colors.lightFog}
            overlayInnerStyle={{ color: Colors.black72, padding: 10, borderRadius: 0, textAlign: 'center' }}
            open={companyNotFound}
            placement="bottomLeft"
            title={COMPANY_NOT_FOUND_MESSAGE}
          >
            <HookField
              name="companyId"
              options={salesDepartmentOptions}
              type="select"
              placeholder="Выберите юридическое лицо"
            />
          </Tooltip>
        </div>
        <Col span={12}>
          {!isNewBulidingObject && currentBuildingObject && (
            <Button
              onClick={() => onDeleteBuildingObject(currentBuildingObject.id)}
              prefixIcon={<DeleteIcon />}
              role="button"
              variant={ButtonVariant.secondary}
              type="button"
            >
              Удалить
            </Button>
          )}
        </Col>
      </Space>
    </Space>
  );

  const drawerFormFooter = (
    <Row gutter={16}>
      <Col span={12}>
        <Button disabled={companyNotFound} type="submit" role="button" isWide variant={ButtonVariant.primary}>
          Сохранить
        </Button>
      </Col>
      <Col span={12}>
        <Button type="button" role="button" onClick={onClose} isWide variant={ButtonVariant.secondary}>
          Отмена
        </Button>
      </Col>
    </Row>
  );

  return (
    <Drawer open onClose={onClose} size="large">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} style={{ display: 'contents' }}>
          <DrawerForm
            title={isNewBulidingObject ? 'Новый дом/очередь' : 'Данные дома/очереди'}
            footer={drawerFormFooter}
          >
            {drawerFormBody}
          </DrawerForm>
        </form>
      </FormProvider>
    </Drawer>
  );
};
