import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import {
  CorporateGroupCreateRequest,
  useCorporateGroupControllerCreateMutation
} from '@/api/web.client/webClientGeneratedApi';
import { CorporateGroupFormProps } from './model';
import { CorporateGroupFormView } from './view';
import toast from 'react-hot-toast';

export const corporateGroupCreateSchema = Yup.object<CorporateGroupCreateRequest>().shape({
  name: Yup.string().required('Введите название партнера'),
  email: Yup.string().email('Введите валидный email').required('Введите Email'),
  companies: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Введите название компании')
      })
    )
    .required('Необходимо добавить хотя бы одну компанию'),
  orderLiteOption: Yup.boolean().default(false)
});

export const CorporateGroupForm: FC<CorporateGroupFormProps> = (props) => {
  const { onClose } = props;

  const form = useForm<CorporateGroupCreateRequest>({
    resolver: yupResolver<CorporateGroupCreateRequest>(corporateGroupCreateSchema),
    defaultValues: {
      companies: [
        {
          name: ''
        }
      ],
      orderLiteOption: false
    }
  });

  const [createCorporateGroup] = useCorporateGroupControllerCreateMutation();

  return CorporateGroupFormView({
    ...props,
    form,
    onSubmit: async (formData) => {
      try {
        await createCorporateGroup({
          corporateGroupCreateRequest: formData
        }).unwrap();
        onClose();
        toast.success('Группа компаний успешно создана');
      } catch {
        // do nothing
      }
    }
  });
};
