import { FC } from 'react';
import { EmployeeFormViewProps } from './model';
import { Button, ButtonSize, ButtonVariant, Icon, IconSize, Label, LabelVariant } from '@/ui';
import { HookField } from '@/app/widgets/HookField';
import { Alert, Col, Popconfirm, Row, Space } from 'antd';
import { Drawer } from '@/ui/Drawer';
import { FormProvider } from 'react-hook-form';
import { DrawerForm } from '@/ui/Drawer/DrawerForm/DrawerForm';
import { LockIcon } from '@/assets/icons';

export const EmployeeFormView: FC<EmployeeFormViewProps> = (props) => {
  const { form, onClose, onSubmit, onResetPassword, isNewEmployee, rolesOptions, employee } = props;

  const formData = form.watch();

  const onResetEmail = (oldEmail: string) => {
    form.setValue('email', oldEmail);
  };

  const drawerFormBody = (
    <Space direction="vertical" size={36} style={{ width: '100%' }}>
      <Space direction="vertical" size={20} style={{ width: '100%' }}>
        <div>
          <Label variant={LabelVariant.black24} value="ФИО" />
          <HookField name="name" type="text" />
        </div>
        <div>
          <Label variant={LabelVariant.black24} value="Роль" />
          <HookField name="role" type="select" placeholder="Не выбрана" options={rolesOptions} />
        </div>
        <div>
          <Label variant={LabelVariant.black24} value="Телефон" />
          <HookField name="phone" type="phone" />
        </div>
        <Space direction="vertical" size={10} style={{ width: '100%' }}>
          <div>
            <Label variant={LabelVariant.black24} value="Email" />
            <HookField name="email" type="text" />
          </div>
          {employee && employee.email !== formData.email && (
            <Alert
              message="Почта сотрудника изменена, после сохранения на новую почту будет отправлено письмо с подтверждением, старый пароль будет сброшен."
              type="error"
              action={
                <Button
                  onClick={() => onResetEmail(employee?.email ?? '')}
                  type="button"
                  role="button"
                  size={ButtonSize.small}
                  variant={ButtonVariant.primary}
                >
                  Отменить
                </Button>
              }
            />
          )}
        </Space>
      </Space>
      <div>
        <HookField name="isActive" type="switch" label={formData.isActive ? 'Активен' : 'Не активен'} />
      </div>
      {onResetPassword && (
        <Popconfirm
          okText="Сбросить"
          cancelText="Отмена"
          onConfirm={onResetPassword}
          title="Сбросить пароль сотрудника?"
          description="После сброса пароля сотрудник не сможет авторизоваться со старым паролем"
        >
          <Button
            type="button"
            role="button"
            prefixIcon={<Icon size={IconSize.xl} icon={<LockIcon />} />}
            variant={ButtonVariant.secondary}
          >
            Сбросить пароль
          </Button>
        </Popconfirm>
      )}
    </Space>
  );

  const drawerFormFooter = (
    <Row gutter={16}>
      <Col span={12}>
        <Button type="submit" role="button" isWide variant={ButtonVariant.primary}>
          Сохранить
        </Button>
      </Col>
      <Col span={12}>
        <Button type="button" role="button" onClick={onClose} isWide variant={ButtonVariant.secondary}>
          Отмена
        </Button>
      </Col>
    </Row>
  );

  return (
    <Drawer open onClose={onClose} size="large">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} style={{ display: 'contents' }}>
          <DrawerForm title={isNewEmployee ? 'Новый сотрудник' : 'Данные сотрудника'} footer={drawerFormFooter}>
            {drawerFormBody}
          </DrawerForm>
        </form>
      </FormProvider>
    </Drawer>
  );
};
