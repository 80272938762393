import { PointerEvent, forwardRef } from 'react';

import { DefaultOptionType } from 'antd/es/select';

import clsx from 'clsx';
import './Chip.scss';
import { Flex } from '../Utility/Flex/Flex';
import { Sizes } from '@/theme';

export interface ChipProps {
  text: React.ReactNode;
  selected: boolean;
  onClick: (event: PointerEvent<HTMLDivElement>) => void;
}

export const Chip = ({ text, selected = false, onClick }: ChipProps) => {
  const chipClassName = clsx('chip', selected && 'selected');

  return (
    <div className={chipClassName} onClick={onClick}>
      {text}
    </div>
  );
};

export interface ChipGroupProps {
  value?: DefaultOptionType['value'];
  options?: DefaultOptionType[];

  onChange?: (value: DefaultOptionType['value']) => void;
}

export const ChipGroup = forwardRef<HTMLDivElement, ChipGroupProps>(({ value, options, onChange }, ref) => {
  const handleClickChip = (value: DefaultOptionType['value']) => () => {
    onChange?.(value);
  };

  return (
    <Flex gap={Sizes.marginXS} ref={ref}>
      {options?.map((option) => (
        <Chip
          key={option.value}
          text={option.label}
          selected={option.value === value}
          onClick={handleClickChip(option.value)}
        />
      ))}
    </Flex>
  );
});
