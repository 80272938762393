import { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';

import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Routes } from '@/app/routing';

import { OrdersListView } from './view';

import type { OrdersMyListProps, OrdersMyListData } from './model';

import { OrderResponse, useOrdersControllerGetMyOrdersQuery } from '@/api/web.client/webClientGeneratedApi';

import { useOrderStatuses } from '@/app/hooks/useOrderStatuses';

import { getZonedStartOfDay, getZonedEndOfDay } from '@/utils/formatters';
import { useCurrentUser } from '../../hooks/useCurrentUser';

export const OrdersList = () => {
  const navigate = useNavigate();

  const { timeZone } = useCurrentUser();

  const statusOptions = useOrderStatuses();

  const form = useForm<OrdersMyListData>({
    defaultValues: {
      statuses: [],
      dates: {}
    }
  });

  const { watch, reset, setValue } = form;

  useEffect(() => {
    if (statusOptions.length === 0) {
      return;
    }

    setValue(
      'statuses',
      statusOptions.map((option) => Number(option.value))
    );
  }, [setValue, statusOptions]);

  const currentStatuses = watch('statuses');
  const currentDates = watch('dates');

  const myOrdersQuery = useOrdersControllerGetMyOrdersQuery(
    {
      dateFrom: currentDates.start ? getZonedStartOfDay(currentDates.start, timeZone).toISOString() : undefined,
      dateTo: currentDates.end ? getZonedEndOfDay(currentDates.end, timeZone).toISOString() : undefined,
      statuses: currentStatuses.length === statusOptions?.length ? [] : currentStatuses
    },
    {
      refetchOnMountOrArgChange: true
    }
  );

  const [orders, setOrders] = useState<OrderResponse[]>([]);

  useEffect(() => {
    if (document.startViewTransition) {
      document.startViewTransition(() => {
        flushSync(() => {
          setOrders(myOrdersQuery.data ?? []);
        });
      });

      return;
    }

    setOrders(myOrdersQuery.data ?? []);
  }, [myOrdersQuery.data]);

  const handleReset = () => {
    reset({
      statuses: statusOptions.map((option) => Number(option.value)),
      dates: {}
    });
  };

  const handleNewOrderClick = (orderLite: boolean) => {
    const query = orderLite ? `?orderLite=${orderLite}` : '';
    navigate(Routes.newOrder + query);
  };

  const handleMoreClick = () => {
    console.log('more');
  };

  const handleCardClick = (id: string, orderLite: boolean) => () => {
    const query = orderLite ? `?orderLite=${orderLite}` : '';
    navigate(`${Routes.orders}/${id}` + query);
  };

  const props: OrdersMyListProps = {
    form,
    orders,
    statusOptions,
    hasMore: false,
    isLoading: myOrdersQuery.isLoading,

    onReset: handleReset,
    onNewOrderClick: handleNewOrderClick,
    onMoreClick: handleMoreClick,
    onCardClick: handleCardClick
  };

  return <OrdersListView {...props} />;
};
