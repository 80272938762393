import { FC } from 'react';
import toast from 'react-hot-toast';
import { useEmployeeControllerCreateMutation } from '@/api/web.client/webClientGeneratedApi';
import { EmployeeFormData } from '../employeeForm/model';
import { mapEmployeeFormDataToResponse } from '../employeeForm/mapper';
import { EmployeeNewProps } from './model';
import { EmployeeForm } from '../employeeForm';

export const EmployeeNew: FC<EmployeeNewProps> = (props) => {
  const { onClose } = props;

  const [createEmployee] = useEmployeeControllerCreateMutation();

  const onSubmit = async (data: EmployeeFormData) => {
    try {
      const employeeRequest = mapEmployeeFormDataToResponse(data);
      await createEmployee({
        employeeRequest
      }).unwrap();
      onClose();
      toast.success('Сотрудник успешно создан');
    } catch {
      // do nothing
    }
  };

  return <EmployeeForm onClose={onClose} onSubmit={onSubmit} />;
};
