import { FC } from 'react';
import { ProjectsViewProps } from './model';
import { Col, Row, Space } from 'antd';
import { Button, ButtonSize, ButtonVariant, TextLoader, Title, WithLoader } from '@/ui';
import { AddIcon } from '@/assets/icons';
import { ProjectCard } from '../../widgets/ProjectCard';
import { ProjectNew } from './components/ProjectNew';
import { ProjectDetails } from './components/ProjectDetails';
import { AddBuildingObject } from './components/AddBuildingObject';

export const ProjectsView: FC<ProjectsViewProps> = (props) => {
  const {
    setIsNewProjectOpen,
    projects,
    isLoading,
    isActive,
    setIsActive,
    setEditedProjectId,
    editedProjectId,
    isNewProjectOpen,
    isAddBuildingObjectOpen,
    setIsAddBuildingObjectOpen,
    buildingObjects,
    setCurrentBuildingObject,
    currentBuildingObject,
    activeProjectId,
    setActiveProjectId
  } = props;
  return (
    <>
      <Row justify="center" align="top">
        <Col span={24} className="content-centered-container">
          <Row align="top" justify="space-between" gutter={16}>
            <Col span={12}>
              <Title>Проекты</Title>
            </Col>

            <Col span={5}>
              <Row justify="end" align="middle">
                <Col span={24}>
                  <Button
                    isWide
                    prefixIcon={<AddIcon />}
                    size={ButtonSize.medium}
                    onClick={() => setIsNewProjectOpen(true)}
                  >
                    Добавить
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          {isLoading && <TextLoader />}

          <WithLoader isLoading={isLoading}>
            <Space direction="vertical" style={{ width: '100%' }} size={24}>
              <Row gutter={4}>
                <Col>
                  <Button
                    type="button"
                    size={ButtonSize.small}
                    onClick={() => setIsActive(undefined)}
                    variant={isActive === undefined ? ButtonVariant.primary : ButtonVariant.white}
                  >
                    Все
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="button"
                    size={ButtonSize.small}
                    onClick={() => setIsActive(true)}
                    variant={isActive === true ? ButtonVariant.primary : ButtonVariant.white}
                  >
                    Активен
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="button"
                    size={ButtonSize.small}
                    onClick={() => setIsActive(false)}
                    variant={isActive === false ? ButtonVariant.primary : ButtonVariant.white}
                  >
                    Не активен
                  </Button>
                </Col>
              </Row>

              <Space style={{ width: '100%' }} direction="vertical" size={16}>
                {projects.map((project) => (
                  <ProjectCard
                    isCollapsed={activeProjectId === project.id}
                    onClickCollapse={() => {
                      if (activeProjectId === project.id) {
                        setActiveProjectId(undefined);
                      } else {
                        setActiveProjectId(project.id);
                      }
                    }}
                    key={project.id}
                    project={project}
                    onClickEdit={() => setEditedProjectId(project.id)}
                  />
                ))}
              </Space>
            </Space>
          </WithLoader>
        </Col>
      </Row>
      {isNewProjectOpen && (
        <ProjectNew
          buildingObjects={buildingObjects}
          openAddBuildingObject={() => setIsAddBuildingObjectOpen(true)}
          onClose={() => setIsNewProjectOpen(false)}
        />
      )}
      {editedProjectId && (
        <ProjectDetails
          setCurrentBuildingObject={setCurrentBuildingObject}
          openAddBuildingObject={() => setIsAddBuildingObjectOpen(true)}
          projectId={editedProjectId}
          onClose={() => setEditedProjectId()}
        />
      )}
      {isAddBuildingObjectOpen && editedProjectId && (
        <AddBuildingObject
          currentBuildingObject={currentBuildingObject}
          projectId={editedProjectId}
          onClose={() => {
            setIsAddBuildingObjectOpen(false), setCurrentBuildingObject(undefined);
          }}
        />
      )}
    </>
  );
};
