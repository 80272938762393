import { useFormContext } from 'react-hook-form';

import { Row, Col } from 'antd';
import { Title, Icon, IconSize } from '@/ui';

import { CheckboxCircleFilled } from '@/assets/icons';

import { Colors } from '@/theme/themeVariables';
import { HookField } from '@/app/widgets/HookField';

import { OrderFormMode } from '../../../../model';

import { OrderDetailsResponse } from '@/api/web.client/webClientGeneratedApi';

import { yesNoRadioOptions } from '@/constants';

import { CustomerDataProps } from './model';

export const CustomerDataView = ({ mode, shortForm }: CustomerDataProps) => {
  const { watch } = useFormContext<OrderDetailsResponse>();

  const watchHaveAgentCheck = watch('hasAgent');

  return (
    <>
      {!shortForm && (
        <Title level={3}>
          <Icon
            icon={<CheckboxCircleFilled />}
            size={IconSize.xl}
            color={mode === OrderFormMode.readonly ? Colors.lime : Colors.storm6}
          />
          <>Данные клиента</>
        </Title>
      )}{' '}
      <HookField name="orderLite" type="text" style={{ display: 'none' }} />
      <Row style={{ marginBottom: 32 }} gutter={16}>
        <Col span={12}>
          <HookField
            name="clientName"
            type="text"
            label="ФИО"
            placeholder="ФИО"
            readOnly={mode === OrderFormMode.readonly}
          />
        </Col>

        <Col span={12}>
          <HookField name="clientPhone" type="phone" label="Телефон" readOnly={mode === OrderFormMode.readonly} />
        </Col>
      </Row>
      <Row style={{ marginBottom: 32 }} gutter={16}>
        {!shortForm && (
          <Col span={24} style={{ marginBottom: 32 }}>
            <HookField
              name="isForeigner"
              type="radio"
              options={yesNoRadioOptions}
              label="Гражданин иностранного государства"
              readOnly={mode === OrderFormMode.readonly}
            />
          </Col>
        )}

        <Col span={24}>
          <HookField
            name="hasAgent"
            type="radio"
            options={yesNoRadioOptions}
            label="Клиент с агентом?"
            readOnly={mode === OrderFormMode.readonly}
          />
        </Col>
      </Row>
      {watchHaveAgentCheck ? (
        <>
          {!shortForm && (
            <Row style={{ marginBottom: 32, marginTop: 32 }} gutter={16}>
              <Col span={12}>
                <HookField
                  name="realEstateAgency"
                  type="text"
                  label="Агентство недвижимости"
                  placeholder="Агентство недвижимости"
                  readOnly={mode === OrderFormMode.readonly}
                />
              </Col>
            </Row>
          )}

          <Row style={{ marginBottom: 32 }} gutter={16}>
            <Col span={12}>
              <HookField
                name="agent"
                type="text"
                label="ФИО"
                placeholder="ФИО агента"
                readOnly={mode === OrderFormMode.readonly}
              />
            </Col>

            <Col span={12}>
              <HookField name="agentPhone" type="phone" label="Телефон" readOnly={mode === OrderFormMode.readonly} />
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
};
