import { forwardRef } from 'react';

import { InfoCircleFilled } from '@ant-design/icons';

import { InputWrapper, InputWrapperProps } from '../InputWrapper/InputWrapper';

import { NumericFormat, NumericFormatProps, NumberFormatValues } from 'react-number-format';

import clsx from 'clsx';

import './InputNumber.scss';

export interface InputNumberProps extends Omit<NumericFormatProps, 'type' | 'onChange'>, InputWrapperProps {
  valueSuffix?: string;
  status?: 'error' | '';
  onChange?: (value: number | string) => void;
}

export const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(
  ({ valueSuffix, label, description, readOnly, onChange, ...props }, ref) => {
    const inputClassName = clsx('input-number', props.status && `${props.status}`);

    const handleValueChange = (values: NumberFormatValues) => {
      onChange?.(values.floatValue || values.value);
    };

    return (
      <InputWrapper
        label={
          valueSuffix ? (
            <>
              <>{label}</>, <>{valueSuffix}</>
            </>
          ) : (
            label
          )
        }
        description={description}
        status={props.status}
        readOnly={readOnly}
      >
        <div className="input-number-container">
          <NumericFormat
            {...props}
            onValueChange={handleValueChange}
            allowedDecimalSeparators={[',']}
            valueIsNumericString
            getInputRef={ref}
            thousandSeparator=" "
            type="text"
            suffix={valueSuffix && `  ${valueSuffix}`}
            fixedDecimalScale={Boolean(props.decimalScale)}
            className={inputClassName}
          />

          {props.status === 'error' ? (
            <div className="input-number-icon">
              <InfoCircleFilled style={{ fontSize: '20px' }} />
            </div>
          ) : null}
        </div>
      </InputWrapper>
    );
  }
);
