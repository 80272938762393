import { forwardRef, ReactNode, useId } from 'react';

import { SwitchProps as AntdSwitchProps, Switch as AntdSwitch } from 'antd';

import './Switch.scss';
import clsx from 'clsx';

export interface SwitchProps extends AntdSwitchProps {
  status?: 'error' | 'warning' | '';
  indeterminate?: boolean;
  label?: ReactNode;
  readOnly?: boolean;
  value?: boolean;
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(({ label, readOnly = false, ...props }, ref) => {
  const switchWrapperClassName = clsx('switch', props.status && `${props.status}`);

  const id = useId();

  return (
    <div className="switch-wrapper">
      <div className={switchWrapperClassName}>
        <AntdSwitch
          {...props}
          checked={props.checked ?? props.value}
          id={id}
          ref={ref}
          className="switch-input"
          disabled={readOnly || props.disabled}
        />
      </div>

      {label && (
        <label htmlFor={id} className="switch-label">
          {label}
        </label>
      )}
    </div>
  );
});
