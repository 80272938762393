import { useEmployeeControllerGetQuery, EmployeeControllerGetApiArg } from '@/api/web.client/webClientGeneratedApi';
import { useMemo } from 'react';

export const useFreeEmployeesOptions = (args: EmployeeControllerGetApiArg) => {
  const { data: employees } = useEmployeeControllerGetQuery(args);

  return useMemo(
    () =>
      employees
        ?.filter((employee) => !employee.salesDepartments?.length)
        .map((employee) => ({
          label: employee.name,
          value: employee.id
        })),
    [employees]
  );
};
