import { ReactNode, HTMLAttributes, DetailedHTMLProps } from 'react';

import { Sizes } from '@/theme';

export interface FlexProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode;
  justify?: 'start' | 'end' | 'center' | 'space-around' | 'space-between' | 'space-evenly';
  align?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  direction?: 'row' | 'column';
  gap?: number;
  fullWidth?: boolean;
}

export const Flex = ({
  fullWidth = false,
  justify = 'start',
  align = 'center',
  direction = 'row',
  gap,
  children,
  style,
  ...props
}: FlexProps) => (
  <div
    style={{
      display: 'flex',
      flexDirection: direction,
      justifyContent: justify,
      alignItems: align,
      gap: `${gap ?? Sizes.gap}px`,
      width: fullWidth ? '100%' : 'auto',
      ...style
    }}
    {...props}
  >
    {children}
  </div>
);
