import '@/utils/helpers/yup';

import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as Yup from 'yup';

import { isValidPhoneNumber } from 'libphonenumber-js';

import { OrderDataFormView } from './view';

import { useOrdersControllerCreateOrderMutation, OrderCreateRequest } from '@/api/web.client/webClientGeneratedApi';

import { OrderDataFormViewProps, OrderDataFormProps } from './model';

import { OrderFormMode } from '../../model';

import { ApartmentNumber } from '@/types';
import { useEffect } from 'react';
import { useOrderLite } from '@/app/hooks/useOrderLite';
import { useUnload } from '@/app/hooks/useUnload';

const phoneValidator = Yup.string()
  .required('Введите номер телефона')
  .test('phone', 'Ведите правильный номер телефона', (value) => isValidPhoneNumber(value));

export const orderFormSchema = Yup.object<OrderCreateRequest>().shape({
  projectId: Yup.number()
    .when('orderLite', (orderLite, field) => (orderLite[0] === false ? field.required() : field.optional()))
    .label('Проект'),
  house: Yup.string().max(20).label('Дом'),
  totalArea: Yup.number()
    .when('orderLite', (orderLite, field) => (orderLite[0] === false ? field.required() : field.optional()))
    .min(0, 'Отрицательные значения не допустимы')
    .label('Общая площадь квартиры'),
  price: Yup.number()
    .when('orderLite', (orderLite, field) => (orderLite[0] === false ? field.required() : field.optional()))
    .min(0, 'Отрицательные значения не допустимы')
    .label('Стоимость квартиры'),

  clientName: Yup.string().required().label('ФИО'),
  clientPhone: phoneValidator.label('Телефон'),
  isForeigner: Yup.boolean().required().oneOf([true, false]).label('Гражданин иностранного государства'),

  hasAgent: Yup.boolean().required().oneOf([true, false]).label('Клиент с агентом'),
  realEstateAgency: Yup.string()
    .max(250)
    .when(['hasAgent', 'orderLite'], ([hasAgent, orderLite], field) => {
      if (hasAgent && !orderLite) {
        field.required();
      }
      return field.optional();
    })
    .label('Агентство недвижимости'),
  agent: Yup.string()
    .when('hasAgent', (hasAgent, field) => (hasAgent[0] === true ? field.max(150).required() : field.optional()))
    .label('ФИО'),
  agentPhone: Yup.string()
    .when('hasAgent', (hasAgent, field) => (hasAgent[0] === true ? phoneValidator : field.optional()))
    .label('Телефон'),

  isReady: Yup.boolean().required().oneOf([true, false]).label('На каком этапе квартира'),

  orderLite: Yup.boolean().required(),

  city: Yup.string()
    .when('orderLite', (orderLite, field) => (orderLite[0] === false ? field.required() : field.optional()))
    .max(50)
    .label('Город'),
  street: Yup.string()
    .max(50)
    .when(['isReady', 'orderLite'], ([isReady, orderLite], field) => {
      if (isReady && !orderLite) {
        return field.required();
      }
      return field.optional();
    })
    .label('Улица'),
  building: Yup.string()
    .when(['isReady', 'orderLite'], ([isReady, orderLite], field) => {
      if (isReady && !orderLite) {
        return field.required();
      }
      return field.optional();
    })
    .label('Дом'),
  apartmentNumber: Yup.string()
    .when(['isReady', 'orderLite'], ([isReady, orderLite], field) => {
      if (isReady && !orderLite) {
        return field.required();
      }
      return field.optional();
    })
    .label('Квартира'),

  developerName: Yup.string()
    .when('isReady', (isReady, field) => (isReady[0] ? field.optional() : field.max(250).required()))
    .label('Застройщик'),
  projectName: Yup.string()
    .when('isReady', (isReady, field) => (isReady[0] ? field.optional() : field.max(100).required()))
    .label('Название ЖК'),
  queue: Yup.number().optional().min(0, 'Отрицательные значения не допустимы').label('Очередь'),

  buildingCompletionDate: Yup.string()
    .when('isReady', (isReady, field) => (isReady[0] ? field.optional() : field.required()))
    .label('Срок сдачи'),

  floor: Yup.number()
    .when('orderLite', (orderLite, field) => (orderLite[0] === false ? field.required() : field.optional()))
    .min(0, 'Отрицательные значения не допустимы')
    .label('Этаж'),
  numberOfFloors: Yup.number()
    .when('orderLite', (orderLite, field) => (orderLite[0] === false ? field.required() : field.optional()))
    .min(0, 'Отрицательные значения не допустимы')
    .label('Этажность дома'),
  numberOfRooms: Yup.mixed<ApartmentNumber>()
    .when('orderLite', (orderLite, field) => (orderLite[0] === false ? field.required() : field.optional()))
    .label('Количество комнат'),
  exchangeApartmentTotalArea: Yup.number()
    .when('orderLite', (orderLite, field) => (orderLite[0] === false ? field.required() : field.optional()))
    .min(0, 'Отрицательные значения не допустимы')
    .label('Общая площадь квартиры'),

  hasEncumbrance: Yup.boolean().required().oneOf([true, false]).label('Наличие ипотеки'),

  encumbranceBankId: Yup.string()
    .when('hasEncumbrance', (haveMortgage, field) => (haveMortgage[0] === true ? field.required() : field.optional()))
    .label('Банк ипотеки'),
  remainingDebt: Yup.number()
    .when('hasEncumbrance', (haveMortgage, field) => (haveMortgage[0] === true ? field.required() : field.optional()))
    .min(0, 'Отрицательные значения не допустимы')
    .label('Остаток ипотеки'),

  minorOwnersExist: Yup.boolean().required().oneOf([true, false]).label('Есть несовершеннолетние собственники'),
  maternityCapitalUsed: Yup.boolean().required().oneOf([true, false]).label('Был использован материнский капитал'),

  clientPrice: Yup.number().min(0, 'Отрицательные значения не допустимы').label('Ожидание по цене выкупа клиента'),
  agentPrice: Yup.number()
    .when(['hasAgent', 'orderLite'], ([hasAgent, orderLite], field) => {
      if (hasAgent && !orderLite) {
        return field.required();
      }
      return field.optional();
    })
    .min(0, 'Отрицательные значения не допустимы')
    .label('Ожидание по цене выкупа клиента'),
  comment: Yup.string().optional().max(2000).label('Комментарий'),

  corpus: Yup.string().optional().label('Корпус'),
  necessaryAction: Yup.string().optional().label('Необходимые действия')
});

export const OrderDataForm = ({ order, onSubmit }: OrderDataFormProps) => {
  const orderLite = useOrderLite(order);

  const defaultValues: Partial<OrderCreateRequest> = {
    isReady: true,
    hasEncumbrance: false,
    minorOwnersExist: false,
    maternityCapitalUsed: false,
    isForeigner: false,
    hasAgent: false,
    orderLite
  };

  const formMethods = useForm<OrderCreateRequest>({
    resolver: yupResolver<OrderCreateRequest>(orderFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues
  });

  useUnload(formMethods.formState.isDirty);

  const { handleSubmit, reset } = formMethods;

  const [createOrder, { isLoading }] = useOrdersControllerCreateOrderMutation();

  const handleFormSubmit = handleSubmit(async (orderCreateRequest: OrderCreateRequest) => {
    try {
      await createOrder({ orderCreateRequest }).unwrap();

      onSubmit();
    } catch {
      // DO NOTHING
    }
  });

  useEffect(() => {
    if (order !== undefined) {
      reset(order);
    }
  }, [order, reset]);

  const props: OrderDataFormViewProps = {
    mode: order ? OrderFormMode.readonly : OrderFormMode.new,
    shortForm: orderLite,
    isLoading,
    onSubmit: handleFormSubmit
  };

  return (
    <FormProvider {...formMethods}>
      <OrderDataFormView {...props} />
    </FormProvider>
  );
};
