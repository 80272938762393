import { useEffect, useState } from 'react';
import { SalesDepartmentsView } from './view';

import {
  useStructureControllerStructureQuery,
  CorporateGroupStructureShortResponse
} from '@/api/web.client/webClientGeneratedApi';

export const SalesDepartments = () => {
  const [structure, setStructure] = useState<CorporateGroupStructureShortResponse>();

  const queryStructure = useStructureControllerStructureQuery();

  useEffect(() => {
    if (!queryStructure.isSuccess) {
      return;
    }

    setStructure(queryStructure.currentData);
  }, [queryStructure.currentData, queryStructure.isSuccess]);

  return <SalesDepartmentsView structure={structure} />;
};
