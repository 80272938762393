import { useEffect, useState } from 'react';
import { useMeetingResults } from './useMeetingResults';

export const useMeetingResultsEnum = () => {
  const meetingResult = useMeetingResults();
  const [statusEnum, setStatusEnum] = useState<Record<string, number>>({});

  useEffect(() => {
    if (meetingResult.length === 0) {
      return;
    }

    setStatusEnum(
      meetingResult?.reduce((acc, current) => {
        acc[current.key] = Number(current.value);

        return acc;
      }, {} as Record<string, number>)
    );
  }, [meetingResult]);

  return statusEnum;
};
