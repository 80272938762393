import { LocalStorageKeys } from './constants';

export const getLocalStorageObject = <T = any>(key: LocalStorageKeys): T | undefined => {
  const json = localStorage.getItem(key);
  if (!json || json === 'undefined') {
    return undefined;
  }

  return JSON.parse(json) as T;
};

export const setLocalStorageObject = (key: LocalStorageKeys, object: unknown): void => {
  const json = JSON.stringify(object);
  localStorage.setItem(key, json);
};

export const removeLocalStorageObject = (key: LocalStorageKeys): void => {
  localStorage.removeItem(key);
};
