import { webClientBaseApi as api } from "./webClientBaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    usersControllerMe: build.query<
      UsersControllerMeApiResponse,
      UsersControllerMeApiArg
    >({
      query: () => ({ url: `/api/users/me` }),
    }),
    usersControllerProjects: build.query<
      UsersControllerProjectsApiResponse,
      UsersControllerProjectsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/projects`,
        params: { search: queryArg.search, isActive: queryArg.isActive },
      }),
    }),
    usersControllerSetTimezone: build.mutation<
      UsersControllerSetTimezoneApiResponse,
      UsersControllerSetTimezoneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/timezone`,
        method: "PUT",
        body: queryArg.userTimezoneRequest,
      }),
    }),
    usersControllerFind: build.query<
      UsersControllerFindApiResponse,
      UsersControllerFindApiArg
    >({
      query: () => ({ url: `/api/users` }),
    }),
    employeeControllerGet: build.query<
      EmployeeControllerGetApiResponse,
      EmployeeControllerGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/employee`,
        params: { search: queryArg.search, roles: queryArg.roles },
      }),
    }),
    employeeControllerCreate: build.mutation<
      EmployeeControllerCreateApiResponse,
      EmployeeControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/employee`,
        method: "POST",
        body: queryArg.employeeRequest,
      }),
    }),
    employeeControllerGetById: build.query<
      EmployeeControllerGetByIdApiResponse,
      EmployeeControllerGetByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/employee/${queryArg.userId}` }),
    }),
    employeeControllerUpdate: build.mutation<
      EmployeeControllerUpdateApiResponse,
      EmployeeControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/employee/${queryArg.userId}`,
        method: "PUT",
        body: queryArg.employeeRequest,
      }),
    }),
    employeeControllerResetPassword: build.mutation<
      EmployeeControllerResetPasswordApiResponse,
      EmployeeControllerResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/employee/${queryArg.userId}/reset-password`,
        method: "POST",
      }),
    }),
    companyControllerCreateCompany: build.mutation<
      CompanyControllerCreateCompanyApiResponse,
      CompanyControllerCreateCompanyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group/${queryArg.corporateGroupId}/company`,
        method: "POST",
        body: queryArg.corporateGroupCompanyCreateRequest,
      }),
    }),
    companyControllerCompanySetIsActive: build.mutation<
      CompanyControllerCompanySetIsActiveApiResponse,
      CompanyControllerCompanySetIsActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group/${queryArg.corporateGroupId}/company/${queryArg.companyId}/is-active`,
        method: "PUT",
        body: queryArg.companySetIsActiveRequest,
      }),
    }),
    structureControllerStructure: build.query<
      StructureControllerStructureApiResponse,
      StructureControllerStructureApiArg
    >({
      query: () => ({ url: `/api/corporate-group/structure` }),
    }),
    structureControllerStructureCompany: build.query<
      StructureControllerStructureCompanyApiResponse,
      StructureControllerStructureCompanyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group/structure/company/${queryArg.companyId}`,
      }),
    }),
    structureControllerAssignUserSalesDepartment: build.mutation<
      StructureControllerAssignUserSalesDepartmentApiResponse,
      StructureControllerAssignUserSalesDepartmentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group/${queryArg.corporateGroupId}/sales-department/${queryArg.salesDepartmentId}/users`,
        method: "POST",
        body: queryArg.salesDepartmentUserRequest,
      }),
    }),
    structureControllerUnAssignUserSalesDepartment: build.mutation<
      StructureControllerUnAssignUserSalesDepartmentApiResponse,
      StructureControllerUnAssignUserSalesDepartmentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group/${queryArg.corporateGroupId}/sales-department/${queryArg.salesDepartmentId}/users/${queryArg.userId}`,
        method: "DELETE",
      }),
    }),
    projectsControllerUpdateProject: build.mutation<
      ProjectsControllerUpdateProjectApiResponse,
      ProjectsControllerUpdateProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group/${queryArg.corporateGroupId}/projects/${queryArg.projectId}`,
        method: "PUT",
        body: queryArg.projectRequest,
      }),
    }),
    projectsControllerGetProject: build.query<
      ProjectsControllerGetProjectApiResponse,
      ProjectsControllerGetProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group/${queryArg.corporateGroupId}/projects/${queryArg.projectId}`,
      }),
    }),
    projectsControllerGetProjects: build.query<
      ProjectsControllerGetProjectsApiResponse,
      ProjectsControllerGetProjectsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group/${queryArg.corporateGroupId}/projects`,
        params: { isActive: queryArg.isActive },
      }),
    }),
    projectsControllerCreateProject: build.mutation<
      ProjectsControllerCreateProjectApiResponse,
      ProjectsControllerCreateProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group/${queryArg.corporateGroupId}/projects`,
        method: "POST",
        body: queryArg.projectRequest,
      }),
    }),
    salesDepartmentControllerCreateSalesDepartment: build.mutation<
      SalesDepartmentControllerCreateSalesDepartmentApiResponse,
      SalesDepartmentControllerCreateSalesDepartmentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group/${queryArg.corporateGroupId}/sales-department`,
        method: "POST",
        body: queryArg.salesDepartmentCreateRequest,
      }),
    }),
    salesDepartmentControllerUpdateSalesDepartment: build.mutation<
      SalesDepartmentControllerUpdateSalesDepartmentApiResponse,
      SalesDepartmentControllerUpdateSalesDepartmentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group/${queryArg.corporateGroupId}/sales-department/${queryArg.salesDepartmentId}`,
        method: "PUT",
        body: queryArg.salesDepartmentUpdateRequest,
      }),
    }),
    salesDepartmentControllerGetSalesDepartment: build.query<
      SalesDepartmentControllerGetSalesDepartmentApiResponse,
      SalesDepartmentControllerGetSalesDepartmentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group/${queryArg.corporateGroupId}/sales-department/${queryArg.salesDepartmentId}`,
      }),
    }),
    salesOfficeControllerCreateSalesOffice: build.mutation<
      SalesOfficeControllerCreateSalesOfficeApiResponse,
      SalesOfficeControllerCreateSalesOfficeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group/${queryArg.corporateGroupId}/sales-office`,
        method: "POST",
        body: queryArg.salesOfficeCreateRequest,
      }),
    }),
    salesOfficeControllerUpdateSalesOffice: build.mutation<
      SalesOfficeControllerUpdateSalesOfficeApiResponse,
      SalesOfficeControllerUpdateSalesOfficeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group/${queryArg.corporateGroupId}/sales-office/${queryArg.salesOfficeId}`,
        method: "PUT",
        body: queryArg.salesOfficeUpdateRequest,
      }),
    }),
    salesOfficeControllerGet: build.query<
      SalesOfficeControllerGetApiResponse,
      SalesOfficeControllerGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group/sales-office`,
        params: { search: queryArg.search, cities: queryArg.cities },
      }),
    }),
    corporateGroupControllerGetMy: build.query<
      CorporateGroupControllerGetMyApiResponse,
      CorporateGroupControllerGetMyApiArg
    >({
      query: () => ({ url: `/api/corporate-group/my` }),
    }),
    corporateGroupControllerFilter: build.query<
      CorporateGroupControllerFilterApiResponse,
      CorporateGroupControllerFilterApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group/filter`,
        params: { search: queryArg.search },
      }),
    }),
    corporateGroupControllerCreate: build.mutation<
      CorporateGroupControllerCreateApiResponse,
      CorporateGroupControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group`,
        method: "POST",
        body: queryArg.corporateGroupCreateRequest,
      }),
    }),
    corporateGroupControllerGet: build.query<
      CorporateGroupControllerGetApiResponse,
      CorporateGroupControllerGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/corporate-group/${queryArg.id}` }),
    }),
    corporateGroupControllerSetIsActive: build.mutation<
      CorporateGroupControllerSetIsActiveApiResponse,
      CorporateGroupControllerSetIsActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group/${queryArg.id}/is-active`,
        method: "PUT",
        body: queryArg.corporateGroupSetIsActiveRequest,
      }),
    }),
    corporateGroupControllerSetOrderLiteOption: build.mutation<
      CorporateGroupControllerSetOrderLiteOptionApiResponse,
      CorporateGroupControllerSetOrderLiteOptionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group/${queryArg.id}/order-lite-option`,
        method: "PUT",
        body: queryArg.corporateGroupSetOrderLiteOptionRequest,
      }),
    }),
    buildingObjectControllerCreateBuildingObject: build.mutation<
      BuildingObjectControllerCreateBuildingObjectApiResponse,
      BuildingObjectControllerCreateBuildingObjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group/${queryArg.corporateGroupId}/projects/${queryArg.projectId}/building-objects`,
        method: "POST",
        body: queryArg.buildingObjectCreateRequest,
      }),
    }),
    buildingObjectControllerUpdateBuildingObject: build.mutation<
      BuildingObjectControllerUpdateBuildingObjectApiResponse,
      BuildingObjectControllerUpdateBuildingObjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group/${queryArg.corporateGroupId}/projects/${queryArg.projectId}/building-objects/${queryArg.buildingObjectId}`,
        method: "PUT",
        body: queryArg.buildingObjectUpdateRequest,
      }),
    }),
    buildingObjectControllerDeleteBuildingObject: build.mutation<
      BuildingObjectControllerDeleteBuildingObjectApiResponse,
      BuildingObjectControllerDeleteBuildingObjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/corporate-group/${queryArg.corporateGroupId}/projects/${queryArg.projectId}/building-objects/${queryArg.buildingObjectId}`,
        method: "DELETE",
      }),
    }),
    authControllerLogin: build.mutation<
      AuthControllerLoginApiResponse,
      AuthControllerLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/login`,
        method: "POST",
        body: queryArg.loginDto,
      }),
    }),
    authControllerRefresh: build.mutation<
      AuthControllerRefreshApiResponse,
      AuthControllerRefreshApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/refresh`,
        method: "POST",
        body: queryArg.refreshDto,
      }),
    }),
    authControllerChangePassword: build.mutation<
      AuthControllerChangePasswordApiResponse,
      AuthControllerChangePasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/change-password`,
        method: "POST",
        body: queryArg.changePasswordDto,
        params: { token: queryArg.token },
      }),
    }),
    offersControllerGetFile: build.query<
      OffersControllerGetFileApiResponse,
      OffersControllerGetFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/orders/${queryArg.orderId}/offers/${queryArg.offerId}/file`,
      }),
    }),
    offersControllerGetFileDemo: build.query<
      OffersControllerGetFileDemoApiResponse,
      OffersControllerGetFileDemoApiArg
    >({
      query: () => ({ url: `/api/orders/file` }),
    }),
    ordersControllerGetMyOrders: build.query<
      OrdersControllerGetMyOrdersApiResponse,
      OrdersControllerGetMyOrdersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/orders/my`,
        params: {
          dateFrom: queryArg.dateFrom,
          dateTo: queryArg.dateTo,
          statuses: queryArg.statuses,
        },
      }),
    }),
    ordersControllerGetOrders: build.query<
      OrdersControllerGetOrdersApiResponse,
      OrdersControllerGetOrdersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/orders`,
        params: {
          dateFrom: queryArg.dateFrom,
          dateTo: queryArg.dateTo,
          statuses: queryArg.statuses,
          salesDepartmentIds: queryArg.salesDepartmentIds,
          necessaryActions: queryArg.necessaryActions,
        },
      }),
    }),
    ordersControllerCreateOrder: build.mutation<
      OrdersControllerCreateOrderApiResponse,
      OrdersControllerCreateOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/orders`,
        method: "POST",
        body: queryArg.orderCreateRequest,
      }),
    }),
    ordersControllerGetOrder: build.query<
      OrdersControllerGetOrderApiResponse,
      OrdersControllerGetOrderApiArg
    >({
      query: (queryArg) => ({ url: `/api/orders/${queryArg.id}` }),
    }),
    ordersControllerUpdateDealCondition: build.mutation<
      OrdersControllerUpdateDealConditionApiResponse,
      OrdersControllerUpdateDealConditionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/orders/${queryArg.id}/deal-condition`,
        method: "PUT",
        body: queryArg.dealConditionRequest,
      }),
    }),
    ordersControllerOrderReject: build.mutation<
      OrdersControllerOrderRejectApiResponse,
      OrdersControllerOrderRejectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/orders/${queryArg.id}/reject`,
        method: "PUT",
        body: queryArg.orderRejectionRequest,
      }),
    }),
    ordersControllerOrderTransfer: build.mutation<
      OrdersControllerOrderTransferApiResponse,
      OrdersControllerOrderTransferApiArg
    >({
      query: (queryArg) => ({
        url: `/api/orders/${queryArg.id}/transfer`,
        method: "PUT",
        body: queryArg.orderTransferRequest,
      }),
    }),
    ordersControllerOrderCrmt: build.mutation<
      OrdersControllerOrderCrmtApiResponse,
      OrdersControllerOrderCrmtApiArg
    >({
      query: (queryArg) => ({
        url: `/api/orders/${queryArg.id}/crmt`,
        method: "POST",
        body: queryArg.orderCrmtRequest,
      }),
    }),
    ordersControllerOrderUmt: build.mutation<
      OrdersControllerOrderUmtApiResponse,
      OrdersControllerOrderUmtApiArg
    >({
      query: (queryArg) => ({
        url: `/api/orders/${queryArg.id}/umt`,
        method: "POST",
        body: queryArg.orderUmtRequest,
      }),
    }),
    ordersControllerOrderApply: build.mutation<
      OrdersControllerOrderApplyApiResponse,
      OrdersControllerOrderApplyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/orders/${queryArg.id}/apply`,
        method: "POST",
      }),
    }),
    ordersControllerOrderReassessment: build.mutation<
      OrdersControllerOrderReassessmentApiResponse,
      OrdersControllerOrderReassessmentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/orders/${queryArg.id}/reassessment`,
        method: "POST",
      }),
    }),
    ordersControllerOrderWaitingDecision: build.mutation<
      OrdersControllerOrderWaitingDecisionApiResponse,
      OrdersControllerOrderWaitingDecisionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/orders/${queryArg.id}/waiting-decision`,
        method: "POST",
        body: queryArg.orderWaitingDecisionRequest,
      }),
    }),
    ordersControllerGetOrderManagers: build.query<
      OrdersControllerGetOrderManagersApiResponse,
      OrdersControllerGetOrderManagersApiArg
    >({
      query: (queryArg) => ({ url: `/api/orders/${queryArg.id}/managers` }),
    }),
    ordersControllerGetOrderExchangeManagerCalendar: build.query<
      OrdersControllerGetOrderExchangeManagerCalendarApiResponse,
      OrdersControllerGetOrderExchangeManagerCalendarApiArg
    >({
      query: (queryArg) => ({
        url: `/api/orders/${queryArg.id}/exchange-manager-calendar`,
        params: { start: queryArg.start, end: queryArg.end },
      }),
    }),
    mockCrmControllerCreateUpdate: build.mutation<
      MockCrmControllerCreateUpdateApiResponse,
      MockCrmControllerCreateUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/mock-crm/create-update`,
        method: "POST",
        body: queryArg.mockCrmCreateUpdateRequest,
      }),
    }),
    calendarControllerCallback: build.query<
      CalendarControllerCallbackApiResponse,
      CalendarControllerCallbackApiArg
    >({
      query: (queryArg) => ({
        url: `/api/calendar/callback`,
        params: {
          secret: queryArg.secret,
          code: queryArg.code,
          scope: queryArg.scope,
        },
      }),
    }),
    calendarControllerAuth: build.query<
      CalendarControllerAuthApiResponse,
      CalendarControllerAuthApiArg
    >({
      query: (queryArg) => ({
        url: `/api/calendar/auth`,
        params: { secret: queryArg.secret },
      }),
    }),
    calendarControllerRevoke: build.query<
      CalendarControllerRevokeApiResponse,
      CalendarControllerRevokeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/calendar/revoke`,
        params: { secret: queryArg.secret },
      }),
    }),
    resourcesControllerGetResources: build.query<
      ResourcesControllerGetResourcesApiResponse,
      ResourcesControllerGetResourcesApiArg
    >({
      query: () => ({ url: `/api/resources` }),
    }),
    resourcesControllerGetEnums: build.query<
      ResourcesControllerGetEnumsApiResponse,
      ResourcesControllerGetEnumsApiArg
    >({
      query: () => ({ url: `/api/resources/enums` }),
    }),
    settingsControllerGet: build.query<
      SettingsControllerGetApiResponse,
      SettingsControllerGetApiArg
    >({
      query: () => ({ url: `/api/resources/settings` }),
    }),
    settingsControllerUpdate: build.mutation<
      SettingsControllerUpdateApiResponse,
      SettingsControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/resources/settings/${queryArg.id}`,
        method: "PUT",
        body: queryArg.settingRequest,
      }),
    }),
    dadataControllerAddressSuggestions: build.mutation<
      DadataControllerAddressSuggestionsApiResponse,
      DadataControllerAddressSuggestionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/dadata/address/suggestions`,
        method: "POST",
        body: queryArg.dadataSuggestAddressRequest,
      }),
    }),
    reportsControllerGetFunnelReport: build.query<
      ReportsControllerGetFunnelReportApiResponse,
      ReportsControllerGetFunnelReportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reports/funnel`,
        params: {
          dateFrom: queryArg.dateFrom,
          dateTo: queryArg.dateTo,
          projectIds: queryArg.projectIds,
        },
      }),
    }),
    reportsControllerGetRejectionReport: build.query<
      ReportsControllerGetRejectionReportApiResponse,
      ReportsControllerGetRejectionReportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reports/rejection`,
        params: {
          dateFrom: queryArg.dateFrom,
          dateTo: queryArg.dateTo,
          projectIds: queryArg.projectIds,
        },
      }),
    }),
    reportsControllerGetCurrentDebtReport: build.query<
      ReportsControllerGetCurrentDebtReportApiResponse,
      ReportsControllerGetCurrentDebtReportApiArg
    >({
      query: () => ({ url: `/api/reports/current-debt` }),
    }),
    reportsControllerGetRepaymentScheduleReport: build.query<
      ReportsControllerGetRepaymentScheduleReportApiResponse,
      ReportsControllerGetRepaymentScheduleReportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reports/repayment-schedule`,
        params: { monthStartDate: queryArg.monthStartDate },
      }),
    }),
    reportsControllerGetPaymentReport: build.query<
      ReportsControllerGetPaymentReportApiResponse,
      ReportsControllerGetPaymentReportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reports/payment`,
        params: { dateFrom: queryArg.dateFrom, dateTo: queryArg.dateTo },
      }),
    }),
    reportsControllerGetBillsReport: build.query<
      ReportsControllerGetBillsReportApiResponse,
      ReportsControllerGetBillsReportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reports/bills`,
        params: {
          dateFrom: queryArg.dateFrom,
          dateTo: queryArg.dateTo,
          projectIds: queryArg.projectIds,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as webClientGeneratedApi };
export type UsersControllerMeApiResponse = /** status 200  */ MeResponse;
export type UsersControllerMeApiArg = void;
export type UsersControllerProjectsApiResponse =
  /** status 200  */ ProjectShortResponse[];
export type UsersControllerProjectsApiArg = {
  search?: string;
  isActive?: boolean;
};
export type UsersControllerSetTimezoneApiResponse = unknown;
export type UsersControllerSetTimezoneApiArg = {
  userTimezoneRequest: UserTimezoneRequest;
};
export type UsersControllerFindApiResponse = unknown;
export type UsersControllerFindApiArg = void;
export type EmployeeControllerGetApiResponse =
  /** status 200  */ EmployeeResponse[];
export type EmployeeControllerGetApiArg = {
  search?: string;
  roles?: string[];
};
export type EmployeeControllerCreateApiResponse =
  /** status 200  */ EmployeeResponse;
export type EmployeeControllerCreateApiArg = {
  employeeRequest: EmployeeRequest;
};
export type EmployeeControllerGetByIdApiResponse =
  /** status 200  */ EmployeeResponse;
export type EmployeeControllerGetByIdApiArg = {
  userId: number;
};
export type EmployeeControllerUpdateApiResponse =
  /** status 200  */ EmployeeResponse;
export type EmployeeControllerUpdateApiArg = {
  userId: number;
  employeeRequest: EmployeeRequest;
};
export type EmployeeControllerResetPasswordApiResponse = unknown;
export type EmployeeControllerResetPasswordApiArg = {
  userId: number;
};
export type CompanyControllerCreateCompanyApiResponse =
  /** status 201  */ CorporateGroupShortResponse;
export type CompanyControllerCreateCompanyApiArg = {
  corporateGroupId: number;
  corporateGroupCompanyCreateRequest: CorporateGroupCompanyCreateRequest;
};
export type CompanyControllerCompanySetIsActiveApiResponse = unknown;
export type CompanyControllerCompanySetIsActiveApiArg = {
  corporateGroupId: number;
  companyId: number;
  companySetIsActiveRequest: CompanySetIsActiveRequest;
};
export type StructureControllerStructureApiResponse =
  /** status 200  */ CorporateGroupStructureShortResponse;
export type StructureControllerStructureApiArg = void;
export type StructureControllerStructureCompanyApiResponse =
  /** status 200  */ CompanyStructureResponse;
export type StructureControllerStructureCompanyApiArg = {
  companyId: number;
};
export type StructureControllerAssignUserSalesDepartmentApiResponse =
  /** status 201  */ UserStructureResponse;
export type StructureControllerAssignUserSalesDepartmentApiArg = {
  corporateGroupId: number;
  salesDepartmentId: number;
  salesDepartmentUserRequest: SalesDepartmentUserRequest;
};
export type StructureControllerUnAssignUserSalesDepartmentApiResponse = unknown;
export type StructureControllerUnAssignUserSalesDepartmentApiArg = {
  corporateGroupId: number;
  salesDepartmentId: number;
  userId: number;
};
export type ProjectsControllerUpdateProjectApiResponse =
  /** status 202  */ ProjectResponse;
export type ProjectsControllerUpdateProjectApiArg = {
  corporateGroupId: number;
  projectId: number;
  projectRequest: ProjectRequest;
};
export type ProjectsControllerGetProjectApiResponse =
  /** status 200  */ ProjectResponse;
export type ProjectsControllerGetProjectApiArg = {
  corporateGroupId: number;
  projectId: number;
};
export type ProjectsControllerGetProjectsApiResponse =
  /** status 200  */ ProjectResponse[];
export type ProjectsControllerGetProjectsApiArg = {
  corporateGroupId: number;
  isActive?: boolean;
};
export type ProjectsControllerCreateProjectApiResponse =
  /** status 201  */ ProjectResponse;
export type ProjectsControllerCreateProjectApiArg = {
  corporateGroupId: number;
  projectRequest: ProjectRequest;
};
export type SalesDepartmentControllerCreateSalesDepartmentApiResponse =
  /** status 201  */ SalesDepartmentStructureResponse;
export type SalesDepartmentControllerCreateSalesDepartmentApiArg = {
  corporateGroupId: number;
  salesDepartmentCreateRequest: SalesDepartmentCreateRequest;
};
export type SalesDepartmentControllerUpdateSalesDepartmentApiResponse = unknown;
export type SalesDepartmentControllerUpdateSalesDepartmentApiArg = {
  corporateGroupId: number;
  salesDepartmentId: number;
  salesDepartmentUpdateRequest: SalesDepartmentUpdateRequest;
};
export type SalesDepartmentControllerGetSalesDepartmentApiResponse =
  /** status 200  */ SalesDepartmentStructureResponse;
export type SalesDepartmentControllerGetSalesDepartmentApiArg = {
  corporateGroupId: number;
  salesDepartmentId: number;
};
export type SalesOfficeControllerCreateSalesOfficeApiResponse =
  /** status 201  */ SalesOfficeResponse;
export type SalesOfficeControllerCreateSalesOfficeApiArg = {
  corporateGroupId: number;
  salesOfficeCreateRequest: SalesOfficeCreateRequest;
};
export type SalesOfficeControllerUpdateSalesOfficeApiResponse = unknown;
export type SalesOfficeControllerUpdateSalesOfficeApiArg = {
  corporateGroupId: number;
  salesOfficeId: number;
  salesOfficeUpdateRequest: SalesOfficeUpdateRequest;
};
export type SalesOfficeControllerGetApiResponse =
  /** status 200  */ SalesOfficeResponse[];
export type SalesOfficeControllerGetApiArg = {
  search?: string;
  cities?: string[];
};
export type CorporateGroupControllerGetMyApiResponse =
  /** status 200  */ CorporateGroupResponse;
export type CorporateGroupControllerGetMyApiArg = void;
export type CorporateGroupControllerFilterApiResponse =
  /** status 200  */ CorporateGroupShortResponse[];
export type CorporateGroupControllerFilterApiArg = {
  search?: string;
};
export type CorporateGroupControllerCreateApiResponse =
  /** status 201  */ CorporateGroupShortResponse;
export type CorporateGroupControllerCreateApiArg = {
  corporateGroupCreateRequest: CorporateGroupCreateRequest;
};
export type CorporateGroupControllerGetApiResponse =
  /** status 200  */ CorporateGroupShortResponse;
export type CorporateGroupControllerGetApiArg = {
  id: number;
};
export type CorporateGroupControllerSetIsActiveApiResponse = unknown;
export type CorporateGroupControllerSetIsActiveApiArg = {
  id: number;
  corporateGroupSetIsActiveRequest: CorporateGroupSetIsActiveRequest;
};
export type CorporateGroupControllerSetOrderLiteOptionApiResponse = unknown;
export type CorporateGroupControllerSetOrderLiteOptionApiArg = {
  id: number;
  corporateGroupSetOrderLiteOptionRequest: CorporateGroupSetOrderLiteOptionRequest;
};
export type BuildingObjectControllerCreateBuildingObjectApiResponse =
  /** status 201  */ BuildingObjectResponse;
export type BuildingObjectControllerCreateBuildingObjectApiArg = {
  corporateGroupId: number;
  projectId: number;
  buildingObjectCreateRequest: BuildingObjectCreateRequest;
};
export type BuildingObjectControllerUpdateBuildingObjectApiResponse = unknown;
export type BuildingObjectControllerUpdateBuildingObjectApiArg = {
  corporateGroupId: number;
  projectId: number;
  buildingObjectId: number;
  buildingObjectUpdateRequest: BuildingObjectUpdateRequest;
};
export type BuildingObjectControllerDeleteBuildingObjectApiResponse = unknown;
export type BuildingObjectControllerDeleteBuildingObjectApiArg = {
  corporateGroupId: number;
  projectId: number;
  buildingObjectId: number;
};
export type AuthControllerLoginApiResponse =
  /** status 200  */ LoginResponseDto;
export type AuthControllerLoginApiArg = {
  loginDto: LoginDto;
};
export type AuthControllerRefreshApiResponse =
  /** status 200  */ RefreshResponse;
export type AuthControllerRefreshApiArg = {
  refreshDto: RefreshDto;
};
export type AuthControllerChangePasswordApiResponse =
  /** status 200  */ ChangePasswordResponse;
export type AuthControllerChangePasswordApiArg = {
  token: string;
  changePasswordDto: ChangePasswordDto;
};
export type OffersControllerGetFileApiResponse = /** status 200  */ Blob;
export type OffersControllerGetFileApiArg = {
  orderId: string;
  offerId: number;
};
export type OffersControllerGetFileDemoApiResponse = /** status 200  */ Blob;
export type OffersControllerGetFileDemoApiArg = void;
export type OrdersControllerGetMyOrdersApiResponse =
  /** status 200  */ OrderResponse[];
export type OrdersControllerGetMyOrdersApiArg = {
  dateFrom?: string;
  dateTo?: string;
  statuses?: number[];
};
export type OrdersControllerGetOrdersApiResponse =
  /** status 200  */ OrderResponse[];
export type OrdersControllerGetOrdersApiArg = {
  dateFrom?: string;
  dateTo?: string;
  statuses?: number[];
  salesDepartmentIds?: number[];
  necessaryActions?: string[];
};
export type OrdersControllerCreateOrderApiResponse =
  /** status 201  */ OrderResponse;
export type OrdersControllerCreateOrderApiArg = {
  orderCreateRequest: OrderCreateRequest;
};
export type OrdersControllerGetOrderApiResponse =
  /** status 200  */ OrderDetailsResponse;
export type OrdersControllerGetOrderApiArg = {
  id: string;
};
export type OrdersControllerUpdateDealConditionApiResponse = unknown;
export type OrdersControllerUpdateDealConditionApiArg = {
  id: string;
  dealConditionRequest: DealConditionRequest;
};
export type OrdersControllerOrderRejectApiResponse = unknown;
export type OrdersControllerOrderRejectApiArg = {
  id: string;
  orderRejectionRequest: OrderRejectionRequest;
};
export type OrdersControllerOrderTransferApiResponse = unknown;
export type OrdersControllerOrderTransferApiArg = {
  id: string;
  orderTransferRequest: OrderTransferRequest;
};
export type OrdersControllerOrderCrmtApiResponse = unknown;
export type OrdersControllerOrderCrmtApiArg = {
  id: string;
  orderCrmtRequest: OrderCrmtRequest;
};
export type OrdersControllerOrderUmtApiResponse = unknown;
export type OrdersControllerOrderUmtApiArg = {
  id: string;
  orderUmtRequest: OrderUmtRequest;
};
export type OrdersControllerOrderApplyApiResponse = unknown;
export type OrdersControllerOrderApplyApiArg = {
  id: string;
};
export type OrdersControllerOrderReassessmentApiResponse = unknown;
export type OrdersControllerOrderReassessmentApiArg = {
  id: string;
};
export type OrdersControllerOrderWaitingDecisionApiResponse = unknown;
export type OrdersControllerOrderWaitingDecisionApiArg = {
  id: string;
  orderWaitingDecisionRequest: OrderWaitingDecisionRequest;
};
export type OrdersControllerGetOrderManagersApiResponse =
  /** status 200  */ UserShortResponse[];
export type OrdersControllerGetOrderManagersApiArg = {
  id: string;
};
export type OrdersControllerGetOrderExchangeManagerCalendarApiResponse =
  /** status 200  */ OrderExchangeManagerCalendarResponse;
export type OrdersControllerGetOrderExchangeManagerCalendarApiArg = {
  id: string;
  start: string;
  end: string;
};
export type MockCrmControllerCreateUpdateApiResponse = unknown;
export type MockCrmControllerCreateUpdateApiArg = {
  mockCrmCreateUpdateRequest: MockCrmCreateUpdateRequest;
};
export type CalendarControllerCallbackApiResponse = unknown;
export type CalendarControllerCallbackApiArg = {
  secret: string;
  code: string;
  scope: string;
};
export type CalendarControllerAuthApiResponse = unknown;
export type CalendarControllerAuthApiArg = {
  secret: string;
};
export type CalendarControllerRevokeApiResponse = unknown;
export type CalendarControllerRevokeApiArg = {
  secret: string;
};
export type ResourcesControllerGetResourcesApiResponse =
  /** status 200  */ ResourcesResponse;
export type ResourcesControllerGetResourcesApiArg = void;
export type ResourcesControllerGetEnumsApiResponse =
  /** status 200  */ EnumDictionariesResponse;
export type ResourcesControllerGetEnumsApiArg = void;
export type SettingsControllerGetApiResponse =
  /** status 200  */ SettingResponse[];
export type SettingsControllerGetApiArg = void;
export type SettingsControllerUpdateApiResponse =
  /** status 202  */ SettingResponse;
export type SettingsControllerUpdateApiArg = {
  id: number;
  settingRequest: SettingRequest;
};
export type DadataControllerAddressSuggestionsApiResponse =
  /** status 200  */ DadataSuggestAddressResponse[];
export type DadataControllerAddressSuggestionsApiArg = {
  dadataSuggestAddressRequest: DadataSuggestAddressRequest;
};
export type ReportsControllerGetFunnelReportApiResponse =
  /** status 200  */ FunnelReportResponse;
export type ReportsControllerGetFunnelReportApiArg = {
  dateFrom?: string;
  dateTo?: string;
  projectIds?: number[];
};
export type ReportsControllerGetRejectionReportApiResponse =
  /** status 200  */ RejectionReportResponse;
export type ReportsControllerGetRejectionReportApiArg = {
  dateFrom?: string;
  dateTo?: string;
  projectIds?: number[];
};
export type ReportsControllerGetCurrentDebtReportApiResponse =
  /** status 200  */ CurrentDebtReportResponse;
export type ReportsControllerGetCurrentDebtReportApiArg = void;
export type ReportsControllerGetRepaymentScheduleReportApiResponse =
  /** status 200  */ RepaymentScheduleReportResponse;
export type ReportsControllerGetRepaymentScheduleReportApiArg = {
  monthStartDate: string;
};
export type ReportsControllerGetPaymentReportApiResponse =
  /** status 200  */ PaymentReportResponse;
export type ReportsControllerGetPaymentReportApiArg = {
  dateFrom: string;
  dateTo: string;
};
export type ReportsControllerGetBillsReportApiResponse =
  /** status 200  */ BillsReportResponse;
export type ReportsControllerGetBillsReportApiArg = {
  dateFrom?: string;
  dateTo?: string;
  projectIds?: number[];
};
export type SalesDepartmentResponse = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  id: number;
  corporateGroupId: number;
  name: string;
  city: string;
};
export type MeResponse = {
  userId: number;
  email: string;
  name: string;
  phone: string;
  corporateGroupId: number;
  salesDepartments: SalesDepartmentResponse[];
  isActive: boolean;
  timeZone: number;
  roles: (
    | "ExchangeUser"
    | "PartnerAdmin"
    | "PartnerCommercialDirector"
    | "PartnerHeadOfDepartment"
    | "PartnerManager"
  )[];
};
export type ProjectShortResponse = {
  id: number;
  name: string;
  city: string;
  salesDepartments: SalesDepartmentResponse[];
};
export type UserTimezoneRequest = {
  timeZone: number;
};
export type EmployeeResponse = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  id: number;
  email: string;
  name: string;
  phone?: string;
  corporateGroupId?: number;
  salesDepartments: SalesDepartmentResponse[];
  isActive: boolean;
  timeZone?: number;
  roles: string[];
};
export type EmployeeRequest = {
  email: string;
  name: string;
  phone?: string;
  isActive: boolean;
  roles: string[];
};
export type CompanyShortResponse = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  id: number;
  corporateGroupId: number;
  name: string;
  isActive: boolean;
};
export type CorporateGroupShortResponse = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  id: number;
  name: string;
  isActive: boolean;
  companies: CompanyShortResponse[];
  orderLiteOption: boolean;
};
export type CorporateGroupCompanyCreateRequest = {
  name: string;
};
export type CompanySetIsActiveRequest = {
  isActive: boolean;
};
export type CompanyStructureShortResponse = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  id: number;
  corporateGroupId: number;
  name: string;
  isActive: boolean;
};
export type UserStructureResponse = {
  id: number;
  name: string;
  roles: (
    | "ExchangeUser"
    | "PartnerAdmin"
    | "PartnerCommercialDirector"
    | "PartnerHeadOfDepartment"
    | "PartnerManager"
  )[];
};
export type SalesDepartmentStructureResponse = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  id: number;
  corporateGroupId: number;
  name: string;
  city: string;
  users: UserStructureResponse[];
};
export type CorporateGroupStructureShortResponse = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  id: number;
  name: string;
  isActive: boolean;
  companies: CompanyStructureShortResponse[];
  orderLiteOption: boolean;
  salesDepartments: SalesDepartmentStructureResponse[];
};
export type CompanyStructureResponse = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  id: number;
  corporateGroupId: number;
  name: string;
  isActive: boolean;
};
export type SalesDepartmentUserRequest = {};
export type BuildingObjectResponse = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  id: number;
  name: string;
  completionDatePlan: string;
  companyId: number;
  projectId: number;
  companyName: string;
};
export type ProjectResponse = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  id: number;
  name: string;
  city: string;
  corporateGroupId: number;
  isActive: boolean;
  buildingObjects: BuildingObjectResponse[];
  salesDepartments: SalesDepartmentResponse[];
};
export type ProjectRequest = {
  name: string;
  city: string;
  isActive: boolean;
  salesDepartmentIds?: number[];
};
export type SalesDepartmentCreateRequest = {
  name: string;
  city: string;
  headOfDepartmentUserId?: number;
  managerIds?: number[];
};
export type SalesDepartmentUpdateRequest = {
  name: string;
  headOfDepartmentUserId?: number;
};
export type SalesOfficeResponse = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  id: number;
  corporateGroupId: number;
  name: string;
  city: string;
  address: string;
};
export type SalesOfficeCreateRequest = {
  name: string;
  city: string;
  address: string;
};
export type SalesOfficeUpdateRequest = {
  name: string;
  city: string;
  address: string;
};
export type CompanyResponse = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  id: number;
  corporateGroupId: number;
  name: string;
  isActive: boolean;
};
export type CorporateGroupResponse = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  id: number;
  name: string;
  isActive: boolean;
  companies: CompanyResponse[];
  projects: ProjectResponse[];
  salesDepartments: SalesDepartmentResponse[];
  orderLiteOption: boolean;
};
export type CorporateGroupCreateRequest = {
  name: string;
  email: string;
  companies: CorporateGroupCompanyCreateRequest[];
  orderLiteOption: boolean;
};
export type CorporateGroupSetIsActiveRequest = {
  isActive: boolean;
};
export type CorporateGroupSetOrderLiteOptionRequest = {
  orderLiteOption: boolean;
};
export type BuildingObjectCreateRequest = {
  name: string;
  completionDatePlan: string;
  companyId: number;
};
export type BuildingObjectUpdateRequest = {
  name: string;
  completionDatePlan: string;
  companyId: number;
};
export type LoginResponseDto = {
  accessToken: string;
  refreshToken: string;
};
export type LoginDto = {
  email: string;
  password: string;
};
export type RefreshResponse = {
  accessToken: string;
  refreshToken: string;
};
export type RefreshDto = {
  refreshToken: string;
};
export type ChangePasswordResponse = {
  accessToken: string;
  refreshToken: string;
};
export type ChangePasswordDto = {
  password: string;
  repeatPassword: string;
};
export type OrderResponse = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  id: string;
  version?: number;
  orderLite: boolean;
  number: string;
  statusId: number;
  statusChangedAt: string;
  necessaryAction?: string;
  corporateGroupId?: number;
  salesDepartmentId?: number;
  projectId?: number;
  house?: string;
  totalArea?: number;
  price?: number;
  managerId?: number;
  clientName: string;
  clientPhone: string;
  isForeigner?: boolean;
  hasAgent: boolean;
  realEstateAgency?: string;
  agent?: string;
  agentPhone?: string;
  isReady: boolean;
  city?: string;
  street?: string;
  building?: string;
  corpus?: string;
  apartmentNumber?: string;
  developerName?: string;
  projectName?: string;
  queue?: number;
  buildingCompletionDate?: string;
  floor?: number;
  numberOfFloors?: number;
  numberOfRooms?: string;
  exchangeApartmentTotalArea?: number;
  hasEncumbrance?: boolean;
  encumbranceBankId?: string;
  remainingDebt?: number;
  minorOwnersExist?: boolean;
  maternityCapitalUsed?: boolean;
  clientPrice?: number;
  agentPrice?: number;
  comment?: string;
  rejectionReasonId?: string;
  readyForSecondaryMarket?: boolean;
  exchangeManagerName?: string;
  exchangeManagerPhone?: string;
  exchangeManagerEmail?: string;
};
export type OrderCreateRequest = {
  necessaryAction?: string;
  orderLite?: boolean;
  projectId?: number;
  house?: string;
  totalArea?: number;
  price?: number;
  clientName: string;
  clientPhone: string;
  isForeigner?: boolean;
  hasAgent: boolean;
  realEstateAgency?: string;
  agent?: string;
  agentPhone?: string;
  isReady?: boolean;
  city?: string;
  street?: string;
  building?: string;
  corpus?: string;
  apartmentNumber?: string;
  developerName?: string;
  projectName?: string;
  queue?: number;
  buildingCompletionDate?: string;
  floor?: number;
  numberOfFloors?: number;
  numberOfRooms?: string;
  exchangeApartmentTotalArea?: number;
  hasEncumbrance?: boolean;
  encumbranceBankId?: string;
  remainingDebt?: number;
  minorOwnersExist?: boolean;
  maternityCapitalUsed?: boolean;
  clientPrice?: number;
  agentPrice?: number;
  comment?: string;
};
export type ParticipantJsonResponse = {
  lastName: string;
  firstName: string;
  middleName: string;
  passportSeries: string;
  passportNumber: string;
  issuedBy?: string;
  isForeign: boolean;
  issueDate?: string;
};
export type ParticipantResponse = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  id: number;
  dealConditionId: number;
  participant: ParticipantJsonResponse;
};
export type DealConditionResponse = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  id: number;
  orderId: string;
  dealDateTime?: string;
  buyoutPrice: number;
  paymentForDDU: number;
  paymentForParking?: number;
  paymentForStorage?: number;
  paymentToClientAccount?: number;
  paymentForEncumbrance?: number;
  isResidence: boolean;
  residenceDuration?: string;
  vacatingDate?: string;
  remainingProperty?: string;
  hasEncumbrance: boolean;
  remainingDebt?: number;
  encumbranceBankId?: string;
  maternityCapitalUsed: boolean;
  minorOwnersExist: boolean;
  minorNames?: string[];
  comment?: string;
  projectId: number;
  house?: string;
  numberOfRooms?: string;
  totalArea?: number;
  isClientFunds: boolean;
  isInstallment: boolean;
  isCertificate: boolean;
  isMortgage: boolean;
  approvalStatus?: number;
  mortgageBankId?: string;
  agreementNumber?: string;
  agreementDate?: string;
  participants?: ParticipantResponse[];
};
export type MeetingResponse = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  id: number;
  orderId: string;
  dateTimeStart: string;
  dateTimeEnd: string;
  waiting: boolean;
  waitingDate?: string;
  result?: number;
  salesOfficeId?: number;
  isOnline?: boolean;
};
export type UserResponse = {
  userId: number;
  email: string;
  name: string;
  phone: string;
};
export type OfferResponse = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  id: number;
  orderId: string;
  offerType: number;
  buyoutPrice: number;
  isResidence: boolean;
  residenceDuration?: string;
  vacatingDate?: string;
  paymentForEncumbrance: number;
  paymentToClientAccount?: number;
  paymentForDDU: number;
  paymentForParking?: number;
  paymentForStorage?: number;
  fileId?: string;
};
export type EventResponse = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  id: number;
  title: string;
  description: string;
  params: object;
  eventDate: string;
};
export type OrderDetailsResponse = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  id: string;
  version?: number;
  orderLite: boolean;
  number: string;
  statusId: number;
  statusChangedAt: string;
  necessaryAction?: string;
  corporateGroupId: number;
  salesDepartmentId: number;
  projectId: number;
  house: string;
  totalArea: number;
  price: number;
  managerId: number;
  clientName: string;
  clientPhone: string;
  isForeigner: boolean;
  hasAgent: boolean;
  realEstateAgency?: string;
  agent?: string;
  agentPhone?: string;
  isReady: boolean;
  city: string;
  street?: string;
  building?: string;
  corpus?: string;
  apartmentNumber?: string;
  developerName?: string;
  projectName?: string;
  queue?: number;
  buildingCompletionDate?: string;
  floor: number;
  numberOfFloors: number;
  numberOfRooms: string;
  exchangeApartmentTotalArea: number;
  hasEncumbrance: boolean;
  encumbranceBankId?: string;
  remainingDebt?: number;
  minorOwnersExist: boolean;
  maternityCapitalUsed: boolean;
  clientPrice: number;
  agentPrice: number;
  comment?: string;
  rejectionReasonId?: string;
  readyForSecondaryMarket?: boolean;
  exchangeManagerName?: string;
  exchangeManagerPhone?: string;
  exchangeManagerEmail?: string;
  dealCondition?: DealConditionResponse;
  meetings?: MeetingResponse[];
  meeting?: MeetingResponse;
  manager?: UserResponse;
  offers?: OfferResponse[];
  events?: EventResponse[];
  isScheduleMeetingAvailable: boolean;
  isCancelAvailable: boolean;
  isManagerCardAvailable: boolean;
  isDealConditionActive: boolean;
};
export type ParticipantJsonRequest = {
  lastName: string;
  firstName: string;
  middleName: string;
  passportSeries: string;
  passportNumber: string;
  issuedBy?: string;
  isForeign: boolean;
  issueDate?: string;
};
export type ParticipantRequest = {
  participant: ParticipantJsonRequest;
};
export type DealConditionRequest = {
  orderLite: boolean;
  dealDateTime?: string;
  buyoutPrice: number;
  paymentForDDU: number;
  paymentForParking?: number;
  paymentForStorage?: number;
  paymentToClientAccount?: number;
  paymentForEncumbrance?: number;
  isResidence?: boolean;
  residenceDuration?: string;
  vacatingDate?: string;
  remainingProperty?: string;
  hasEncumbrance?: boolean;
  remainingDebt?: number;
  encumbranceBankId?: string;
  maternityCapitalUsed?: boolean;
  minorOwnersExist?: boolean;
  minorNames?: string[];
  comment?: string;
  projectId?: number;
  house?: string;
  numberOfRooms?: string;
  totalArea?: number;
  isClientFunds?: boolean;
  isInstallment?: boolean;
  isCertificate?: boolean;
  isMortgage?: boolean;
  approvalStatus?: number;
  mortgageBankId?: string;
  agreementNumber?: string;
  agreementDate?: string;
  participants?: ParticipantRequest[];
};
export type OrderRejectionRequest = {
  rejectionReasonId: string;
  readyForSecondaryMarket: boolean;
};
export type OrderTransferRequest = {
  managerId: number;
};
export type OrderCrmtRequest = {
  startDate: string;
  endDate: string;
  salesOfficeId?: number;
  isOnline?: boolean;
};
export type OrderUmtRequest = {
  startDate: string;
  endDate: string;
  salesOfficeId?: number;
  isOnline?: boolean;
};
export type OrderWaitingDecisionRequest = {
  waitingDate: string;
};
export type UserShortResponse = {
  id: number;
  name: string;
};
export type OrderExchangeManagerEvent = {
  startDate: string;
  endDate: string;
  name: string;
};
export type OrderExchangeManagerBusy = {
  startDate: string;
  endDate: string;
};
export type OrderExchangeManagerCalendarResponse = {
  from: string;
  to: string;
  startHour: number;
  endHour: number;
  timeZone: number;
  minEventTimeMinutes: number;
  maxEventTimeMinutes: number;
  events: OrderExchangeManagerEvent[];
  busy: OrderExchangeManagerBusy[];
};
export type MockCrmCreateUpdateRequest = {
  updateType:
    | "ManagerChanged"
    | "ClientRejected"
    | "MeetingScheduled"
    | "MeetingRescheduled"
    | "WaitingDecision"
    | "ReassessmentRequired"
    | "DeveloperApartment"
    | "DealCondition"
    | "ClientAgreed"
    | "OrderCreated"
    | "OrderRejected"
    | "OrderInProgress"
    | "ExchangeManagerChanged"
    | "InspectionScheduled"
    | "InspectionRescheduled"
    | "InspectionConducted"
    | "SubmittedForAssessment"
    | "AssessmentDone"
    | "ContractConcluded"
    | "ContractRegistered";
  orderId: string;
};
export type BankResponse = {
  id: string;
  name: string;
};
export type RejectionReasonResponse = {
  id: string;
  reason: string;
};
export type CountryResponse = {
  key: string;
  name: string;
  mask: string;
  prefix: string;
};
export type DictionariesResponse = {
  banks: BankResponse[];
  rejectionReasons: RejectionReasonResponse[];
  countries: CountryResponse[];
};
export type SettingsResponse = {
  foo: string;
};
export type ResourcesResponse = {
  dictionaries: DictionariesResponse;
  settings: SettingsResponse;
};
export type EnumDictionaryValue = {
  key: string;
  value: string | number;
  label: string;
};
export type EnumDictionariesResponse = {
  meetingResult: EnumDictionaryValue[];
  mortgageApprovalStatus: EnumDictionaryValue[];
  offerType: EnumDictionaryValue[];
  orderStatus: EnumDictionaryValue[];
  necessaryAction: EnumDictionaryValue[];
  userRoles: EnumDictionaryValue[];
};
export type SettingResponse = {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  id: number;
  name: string;
  description: string;
  value: string;
  type: "duration" | "number" | "date" | "string";
  group: string;
};
export type SettingRequest = {
  value: string;
};
export type DadataSuggestAddressResponseData = {
  postal_code: string;
  country: string;
  country_iso_code: string;
  federal_district: string;
  region_fias_id: string;
  region_kladr_id: string;
  region_iso_code: string;
  region_with_type: string;
  region_type: string;
  region_type_full: string;
  region: string;
  area_fias_id: string;
  area_kladr_id: string;
  area_with_type: string;
  area_type: string;
  area_type_full: string;
  area: string;
  city_fias_id: string;
  city_kladr_id: string;
  city_with_type: string;
  city_type: string;
  city_type_full: string;
  city: string;
  city_area: string;
  city_district_fias_id: string;
  city_district_kladr_id: string;
  city_district_with_type: string;
  city_district_type: string;
  city_district_type_full: string;
  city_district: string;
  settlement_fias_id: string;
  settlement_kladr_id: string;
  settlement_with_type: string;
  settlement_type: string;
  settlement_type_full: string;
  settlement: string;
  street_fias_id: string;
  street_kladr_id: string;
  street_with_type: string;
  street_type: string;
  street_type_full: string;
  street: string;
  stead_fias_id: string;
  stead_cadnum: string;
  stead_type: string;
  stead_type_full: string;
  stead: string;
  house_fias_id: string;
  house_kladr_id: string;
  house_cadnum: string;
  house_type: string;
  house_type_full: string;
  house: string;
  block_type: string;
  block_type_full: string;
  block: string;
  entrance: string;
  floor: string;
  flat_fias_id: string;
  flat_cadnum: string;
  flat_type: string;
  flat_type_full: string;
  flat: string;
  flat_area: string;
  square_meter_price: string;
  flat_price: string;
  postal_box: string;
  fias_id: string;
  fias_code: string;
  fias_level: string;
  fias_actuality_state: string;
  kladr_id: string;
  geoname_id: string;
  capital_marker: string;
  okato: string;
  oktmo: string;
  tax_office: string;
  tax_office_legal: string;
  timezone: string;
  geo_lat: string;
  geo_lon: string;
  beltway_hit: string;
  beltway_distance: string;
  metro: string;
  divisions: string;
  qc_geo: string;
  qc_complete: string;
  qc_house: string;
  history_values: any[];
  unparsed_parts: string;
  source: string;
  qc: string;
};
export type DadataSuggestAddressResponse = {
  value: string;
  unrestricted_value: string;
  data: DadataSuggestAddressResponseData;
};
export type DadataLocationBound = {
  value:
    | "country"
    | "region"
    | "area"
    | "city"
    | "settlement"
    | "street"
    | "house"
    | "flat";
};
export type DadataLocationQuery = {
  region?: string;
  area?: string;
  city?: string;
  settlement?: string;
  street?: string;
};
export type DadataSuggestAddressRequest = {
  query: string;
  count?: number;
  from_bound?: DadataLocationBound;
  locations?: DadataLocationQuery[];
  to_bound?: DadataLocationBound;
};
export type FunnelReportItem = {
  status: string;
  count: number;
  percentage?: number;
  highlighted: boolean;
};
export type FunnelReportResponse = {
  items: FunnelReportItem[];
};
export type RejectionReportItem = {
  reason: string;
  count: number;
};
export type RejectionReportResponse = {
  items: RejectionReportItem[];
};
export type CurrentDebtReportItem = {
  projectId: number;
  house: string;
  amount: number;
};
export type CurrentDebtReportResponse = {
  items: CurrentDebtReportItem[];
};
export type RepaymentScheduleReportItem = {
  projectId: number;
  house: string;
  monthYear: string;
  amount: number;
  repaidAmount: number;
  repaymentBalanceAmount: number;
  overdueAmount: number;
};
export type RepaymentScheduleReportResponse = {
  items: RepaymentScheduleReportItem[];
};
export type PaymentReportItem = {
  projectId: number;
  house: string;
  amount: number;
};
export type PaymentReportResponse = {
  items: PaymentReportItem[];
};
export type BillsReportItem = {
  id: string;
  projectId: number;
  amount: number;
  client: string;
  creationDate: string;
  plannedRedemptionDate: string;
  remain: number;
  factRedemptionDate: string;
};
export type BillsReportResponse = {
  items: BillsReportItem[];
};
export const {
  useUsersControllerMeQuery,
  useUsersControllerProjectsQuery,
  useUsersControllerSetTimezoneMutation,
  useUsersControllerFindQuery,
  useEmployeeControllerGetQuery,
  useEmployeeControllerCreateMutation,
  useEmployeeControllerGetByIdQuery,
  useEmployeeControllerUpdateMutation,
  useEmployeeControllerResetPasswordMutation,
  useCompanyControllerCreateCompanyMutation,
  useCompanyControllerCompanySetIsActiveMutation,
  useStructureControllerStructureQuery,
  useStructureControllerStructureCompanyQuery,
  useStructureControllerAssignUserSalesDepartmentMutation,
  useStructureControllerUnAssignUserSalesDepartmentMutation,
  useProjectsControllerUpdateProjectMutation,
  useProjectsControllerGetProjectQuery,
  useProjectsControllerGetProjectsQuery,
  useProjectsControllerCreateProjectMutation,
  useSalesDepartmentControllerCreateSalesDepartmentMutation,
  useSalesDepartmentControllerUpdateSalesDepartmentMutation,
  useSalesDepartmentControllerGetSalesDepartmentQuery,
  useSalesOfficeControllerCreateSalesOfficeMutation,
  useSalesOfficeControllerUpdateSalesOfficeMutation,
  useSalesOfficeControllerGetQuery,
  useCorporateGroupControllerGetMyQuery,
  useCorporateGroupControllerFilterQuery,
  useCorporateGroupControllerCreateMutation,
  useCorporateGroupControllerGetQuery,
  useCorporateGroupControllerSetIsActiveMutation,
  useCorporateGroupControllerSetOrderLiteOptionMutation,
  useBuildingObjectControllerCreateBuildingObjectMutation,
  useBuildingObjectControllerUpdateBuildingObjectMutation,
  useBuildingObjectControllerDeleteBuildingObjectMutation,
  useAuthControllerLoginMutation,
  useAuthControllerRefreshMutation,
  useAuthControllerChangePasswordMutation,
  useOffersControllerGetFileQuery,
  useOffersControllerGetFileDemoQuery,
  useOrdersControllerGetMyOrdersQuery,
  useOrdersControllerGetOrdersQuery,
  useOrdersControllerCreateOrderMutation,
  useOrdersControllerGetOrderQuery,
  useOrdersControllerUpdateDealConditionMutation,
  useOrdersControllerOrderRejectMutation,
  useOrdersControllerOrderTransferMutation,
  useOrdersControllerOrderCrmtMutation,
  useOrdersControllerOrderUmtMutation,
  useOrdersControllerOrderApplyMutation,
  useOrdersControllerOrderReassessmentMutation,
  useOrdersControllerOrderWaitingDecisionMutation,
  useOrdersControllerGetOrderManagersQuery,
  useOrdersControllerGetOrderExchangeManagerCalendarQuery,
  useMockCrmControllerCreateUpdateMutation,
  useCalendarControllerCallbackQuery,
  useCalendarControllerAuthQuery,
  useCalendarControllerRevokeQuery,
  useResourcesControllerGetResourcesQuery,
  useResourcesControllerGetEnumsQuery,
  useSettingsControllerGetQuery,
  useSettingsControllerUpdateMutation,
  useDadataControllerAddressSuggestionsMutation,
  useReportsControllerGetFunnelReportQuery,
  useReportsControllerGetRejectionReportQuery,
  useReportsControllerGetCurrentDebtReportQuery,
  useReportsControllerGetRepaymentScheduleReportQuery,
  useReportsControllerGetPaymentReportQuery,
  useReportsControllerGetBillsReportQuery,
} = injectedRtkApi;
