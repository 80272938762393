import { BuildingObjectResponse } from '@/api/web.client/webClientGeneratedApi';

export const buildingObjectSort = (buildingObjects: BuildingObjectResponse[]) => {
  return buildingObjects.slice().sort((a, b) => {
    if (a.completionDatePlan < b.completionDatePlan) {
      return -1;
    } else if (a.completionDatePlan > b.completionDatePlan) {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  });
};
