import { Row, Col, Divider } from 'antd';
import { Button } from '@/ui';

import { ApartmentsForBuy, CustomerData, ApartmentsForSell } from './components';

import { Colors } from '@/theme/themeVariables';

import { OrderFormMode } from '../../model';

import { OrderDataFormViewProps } from './model';

export const OrderDataFormView = ({ mode, onSubmit, isLoading, shortForm }: OrderDataFormViewProps) => {
  return (
    <form name="Order" onSubmit={onSubmit} style={{ backgroundColor: Colors.white100, padding: 64 }}>
      {!shortForm && (
        <>
          <ApartmentsForBuy mode={mode} />

          <Divider />
        </>
      )}
      <CustomerData mode={mode} shortForm={shortForm} />

      {!shortForm && <Divider />}

      <ApartmentsForSell mode={mode} shortForm={shortForm} />

      {mode === OrderFormMode.new && (
        <Row style={{ marginTop: 24 }}>
          <Col span={12}>
            <Button type="submit" isWide loading={isLoading}>
              Сохранить
            </Button>
          </Col>
        </Row>
      )}
    </form>
  );
};
