import { forwardRef } from 'react';

import { InfoCircleFilled } from '@ant-design/icons';

import { InputWrapper, InputWrapperProps } from '../InputWrapper/InputWrapper';

import { PatternFormat, PatternFormatProps, NumberFormatValues } from 'react-number-format';

import clsx from 'clsx';

import './InputNumber.scss';

export interface InputPatternProps extends Omit<PatternFormatProps, 'type' | 'onChange'>, InputWrapperProps {
  status?: 'error' | '';
  onChange?: (value: number | string) => void;
}

export const InputPattern = forwardRef<HTMLInputElement, InputPatternProps>(
  ({ label, description, readOnly, onChange, ...props }, ref) => {
    const inputClassName = clsx('input-number', props.status && `${props.status}`);

    const handleValueChange = (values: NumberFormatValues) => {
      onChange?.(values.floatValue || values.value);
    };

    return (
      <InputWrapper label={label} description={description} status={props.status} readOnly={readOnly}>
        <div className="input-number-container">
          <PatternFormat
            {...props}
            onValueChange={handleValueChange}
            valueIsNumericString
            getInputRef={ref}
            type="text"
            className={inputClassName}
          />

          {props.status === 'error' ? (
            <div className="input-number-icon">
              <InfoCircleFilled style={{ fontSize: '20px' }} />
            </div>
          ) : null}
        </div>
      </InputWrapper>
    );
  }
);
