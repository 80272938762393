import {
  SalesOfficeCreateRequest,
  SalesOfficeResponse,
  SalesOfficeUpdateRequest
} from '@/api/web.client/webClientGeneratedApi';
import { SalesOfficeFormData } from './model';

export const mapSalesOfficeResponseToFormData = (salesOffice: SalesOfficeResponse): SalesOfficeFormData => {
  return {
    name: salesOffice.name,
    city: salesOffice.city,
    address: salesOffice.address
  };
};

export const mapSalesOfficeFormDataToCreateRequest = (
  salesOfficeFormData: SalesOfficeFormData
): SalesOfficeCreateRequest => {
  return {
    address: salesOfficeFormData.address,
    name: salesOfficeFormData.name,
    city: salesOfficeFormData.city
  };
};

export const mapSalesOfficeFormDataToUpdateRequest = (
  salesOfficeFormData: SalesOfficeFormData
): SalesOfficeUpdateRequest => {
  return {
    address: salesOfficeFormData.address,
    name: salesOfficeFormData.name,
    city: salesOfficeFormData.city
  };
};
