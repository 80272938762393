import { useCorporateGroupControllerGetMyQuery } from '../../api/web.client/webClientGeneratedApi';

export const useUserCorporateGroupProjects = () => {
  const corporateGroupQuery = useCorporateGroupControllerGetMyQuery();

  const { data, isLoading, isError } = corporateGroupQuery;
  const projects = data?.projects ?? [];

  return { projects, isLoading, isError };
};
