import { useState } from 'react';
import { useModal } from '@/app/hooks/useModal';

import { skipToken } from '@reduxjs/toolkit/dist/query';

import { DateTimeRangeType } from '@/ui';

import { addDays, startOfWeek, addWeeks, addHours, addMinutes, startOfDay } from 'date-fns';

import { CalendarModalProps } from './model';

import { CalendarModalView } from './view';

import { useOrdersControllerGetOrderExchangeManagerCalendarQuery } from '@/api/web.client/webClientGeneratedApi';

export const CalendarModal = ({ onClickAdd, orderId, ...props }: CalendarModalProps) => {
  const [timeRangeModalOpened, openTimeRangeModal, closeTimeRangeModal] = useModal();
  const [calendarDate, setCalendarDate] = useState<Date | undefined>(undefined);

  const [startDay, setStartDay] = useState(
    startOfWeek(Date.now(), {
      weekStartsOn: 1
    })
  );

  const { currentData: calendar } = useOrdersControllerGetOrderExchangeManagerCalendarQuery(
    props.open
      ? {
          id: orderId,
          start: startDay.toISOString(),
          end: addWeeks(startDay, 1).toISOString()
        }
      : skipToken
  );

  const handleClickPrev = () => {
    if (!calendar) {
      return;
    }

    setStartDay(addDays(startDay, -7));
  };

  const handleClickNext = () => {
    if (!calendar) {
      return;
    }

    setStartDay(addDays(startDay, 7));
  };

  const handleClickToday = () => {
    setStartDay(
      startOfWeek(Date.now(), {
        weekStartsOn: 1
      })
    );
  };

  const handleClickAdd = (date: Date) => {
    setCalendarDate(date);

    openTimeRangeModal();
  };

  const handleAddDate = (range: DateTimeRangeType) => {
    if (!calendarDate) {
      return;
    }

    const startTime = range.start?.split(':').map(Number);
    const endTime = range.end?.split(':').map(Number);

    if (startTime?.length !== 2 && endTime?.length !== 2) {
      return;
    }

    closeTimeRangeModal();

    onClickAdd({
      dateTimeStart: addMinutes(
        addHours(startOfDay(calendarDate), startTime?.[0] ?? 0),
        startTime?.[1] ?? 0
      ).toISOString(),
      dateTimeEnd: addMinutes(addHours(startOfDay(calendarDate), endTime?.[0] ?? 0), endTime?.[1] ?? 0).toISOString()
    });
  };

  return (
    <CalendarModalView
      {...props}
      calendar={calendar}
      startDay={startDay}
      timeRangeModalOpened={timeRangeModalOpened}
      timeRange={{
        start: `${calendar?.startHour}:00`,
        end: `${calendar?.endHour}:00`
      }}
      calendarDate={calendarDate}
      onAddDate={handleAddDate}
      closeTimeRangeModal={closeTimeRangeModal}
      onClickAdd={handleClickAdd}
      onClickPrev={handleClickPrev}
      onClickNext={handleClickNext}
      onClickToday={handleClickToday}
    />
  );
};
