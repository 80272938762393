import { Row, Col } from 'antd';
import { Label, LabelVariant } from '@/ui';
import clsx from 'clsx';

import './OrderCardRow.scss';

const spanCount = 24;

export interface OrderCardRowProps {
  title?: string;
  text: string;
  titleSpan?: number;
  titlePadding?: 'none' | 'medium';
  level?: 1 | 2 | 3 | 4 | 5;
}

export const OrderCardRow = ({ title, text, titlePadding = 'none', titleSpan = 6, level = 3 }: OrderCardRowProps) => {
  const titleClassName = clsx(`title-padding-${titlePadding}`);

  return (
    <Row className="order-card-row">
      {title && (
        <Col span={titleSpan}>
          <div className={titleClassName}>
            <Label level={level} variant={LabelVariant.gray} value={title} />
          </div>
        </Col>
      )}
      <Col span={spanCount - titleSpan}>
        <Label level={level} variant={LabelVariant.black} value={text} />
      </Col>
    </Row>
  );
};
