import {
  ReportsControllerGetRejectionReportApiArg,
  useReportsControllerGetRejectionReportQuery
} from '@/api/web.client/webClientGeneratedApi';

export const useFunnelRejections = (args: ReportsControllerGetRejectionReportApiArg) => {
  const response = useReportsControllerGetRejectionReportQuery(args);

  const { isLoading } = response;
  const rejections = response.data?.items;
  return { rejections, isLoading };
};
