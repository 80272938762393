import { Colors } from './themeVariables/colors';
import { Fonts, FontSizes } from './themeVariables/fonts';
import { Sizes } from './themeVariables/sizes';

import { ThemeConfig } from 'antd';

export const theme: ThemeConfig = {
  token: {
    colorBgBase: Colors.storm6,
    colorBorder: Colors.storm6,
    colorPrimaryHover: Colors.storm6,
    colorTextPlaceholder: Colors.black48,
    colorLink: Colors.lime,
    colorLinkHover: Colors.darkLime,

    colorError: Colors.mainError,
    colorErrorBg: Colors.bgError,
    colorWarning: Colors.warning,
    colorBgLayout: Colors.white100,

    fontFamily: Fonts.primaryFont
  },
  components: {
    Typography: {
      fontSizeHeading1: FontSizes.fontSizeH1,
      lineHeightHeading1: FontSizes.lineHeightH1,
      fontSizeHeading2: FontSizes.fontSizeH2,
      lineHeightHeading2: FontSizes.lineHeightH2,
      fontSizeHeading3: FontSizes.fontSizeH3,
      lineHeightHeading3: FontSizes.lineHeightH3,
      fontSizeHeading4: FontSizes.fontSizeH4,
      lineHeightHeading4: FontSizes.lineHeightH4,

      fontWeightStrong: FontSizes.boldNumber,

      colorTextDescription: Colors.black64
    },

    Form: {
      colorTextHeading: Colors.black36,
      marginLG: Sizes.marginLG
    },
    Checkbox: {
      colorPrimary: Colors.lime,
      colorPrimaryActive: Colors.lime,
      colorBgContainer: Colors.white100,
      colorPrimaryHover: Colors.black12,

      colorBorder: Colors.black12,
      controlInteractiveSize: 20,

      borderRadiusSM: Sizes.noBorder
    },
    Switch: {
      colorPrimary: Colors.lime,
      colorPrimaryActive: Colors.lime,
      colorBgContainer: Colors.white100,
      colorPrimaryHover: Colors.black12,

      colorBorder: Colors.black12,
      controlInteractiveSize: 20,

      borderRadiusSM: Sizes.noBorder
    },
    DatePicker: {
      colorPrimary: Colors.lime
    },
    Table: {
      colorPrimary: Colors.lime,
      colorBgLayout: Colors.white100,
      colorBgBase: Colors.white100,
      colorBgContainer: Colors.white100,
      borderRadius: 0,
      borderRadiusOuter: 0,
      borderRadiusSM: 0,
      borderRadiusLG: 0,
      borderRadiusXS: 0
    },
    Button: {
      colorPrimary: Colors.lime,
      colorPrimaryHover: Colors.pistachio
    },
    Alert: {
      borderRadius: 24,
      borderRadiusXS: 24,
      borderRadiusSM: 24,
      borderRadiusLG: 24,
      borderRadiusOuter: 24
    }
  }
};
