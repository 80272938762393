import { skipToken } from '@reduxjs/toolkit/query';

import { OrderFormView } from './view';

import { useParams, useNavigate } from 'react-router-dom';

import { useEffect, useState } from 'react';

import { OrderFormProps, OrderFormMode } from './model';

import {
  useOrdersControllerGetOrderQuery,
  OrderDetailsResponse,
  OfferResponse
} from '@/api/web.client/webClientGeneratedApi';

import { useCurrentUser } from '@/app/hooks/useCurrentUser';

import { Routes } from '@/app/routing';

export const OrderForm = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();

  const user = useCurrentUser();

  const [order, setOrder] = useState<OrderDetailsResponse>();
  const [sortedOffers, setSortedOffers] = useState<OfferResponse[]>();

  const orderQuery = useOrdersControllerGetOrderQuery(orderId ? { id: orderId } : skipToken);

  useEffect(() => {
    if (!orderQuery.isSuccess) {
      return;
    }

    setOrder(orderQuery.data);

    if (orderQuery.data.offers) {
      const offers = [...orderQuery.data.offers];

      offers.sort((current, next) => new Date(current.createdAt).getTime() - new Date(next.createdAt).getTime());

      setSortedOffers(offers);
    }
  }, [orderQuery]);

  const onSubmitDataForm = () => {
    navigate(Routes.ordersList);
  };

  const handleClickBack = () => {
    navigate(user.hasRole('PartnerManager') ? Routes.ordersList : Routes.orders);
  };

  const props: OrderFormProps = {
    mode: orderId ? OrderFormMode.readonly : OrderFormMode.new,
    order,
    offers: sortedOffers,

    isScheduleMeetingAvailable: order?.isScheduleMeetingAvailable ?? false,
    isCancelAvailable: order?.isCancelAvailable ?? false,
    isManagerCardAvailable: order?.isManagerCardAvailable ?? false,
    isDealConditionActive: order?.isDealConditionActive ?? false,

    onSubmitDataForm,

    onClickBack: handleClickBack
  };

  return <OrderFormView {...props} />;
};
