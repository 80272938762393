import { useResourcesControllerGetResourcesQuery } from '@/api/web.client/webClientGeneratedApi';

export const useRejectionReasonsOptions = () => {
  const resourcesQuery = useResourcesControllerGetResourcesQuery();

  return resourcesQuery?.currentData?.dictionaries.rejectionReasons.map((item) => ({
    label: item.reason,
    value: item.id
  }));
};
