import { Col, Row } from 'antd';
import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { Button, ButtonVariant, Label, LabelVariant } from '@/ui';
import { Drawer } from '@/ui/Drawer';
import { DrawerForm } from '@/ui/Drawer/DrawerForm/DrawerForm';
import { HookField } from '@/app/widgets/HookField';
import { CompanyFormViewProps } from './model';

export const CompanyFormView: FC<CompanyFormViewProps> = (props) => {
  const { form, onSubmit, onClose, corporateGroupName } = props;

  const drawerFormBody = (
    <div>
      <Label variant={LabelVariant.black24} value="Название Юр. лица" />
      <HookField name="name" type="text" />
    </div>
  );

  const drawerFormFooter = (
    <Row gutter={16}>
      <Col span={12}>
        <Button type="submit" role="button" isWide variant={ButtonVariant.primary}>
          Сохранить
        </Button>
      </Col>
      <Col span={12}>
        <Button type="button" role="button" onClick={onClose} isWide variant={ButtonVariant.secondary}>
          Отмена
        </Button>
      </Col>
    </Row>
  );

  return (
    <Drawer open onClose={onClose} size="large">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} style={{ display: 'contents' }}>
          <DrawerForm title="Новое юр. лицо" subTitle={corporateGroupName} footer={drawerFormFooter}>
            {drawerFormBody}
          </DrawerForm>
        </form>
      </FormProvider>
    </Drawer>
  );
};
