export const weekDays: string[] = ['Пон', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
export const weekEnd: string[] = ['Сб', 'Вс'];

export const getActiveHoursArray = (startHour: number, closeHour: number) => {
  const result = [];
  for (let index = startHour; index < closeHour; index++) {
    result.push(index);
  }

  return result;
};

export const monthFormatter = new Intl.DateTimeFormat('ru-Ru', { month: 'long' });
