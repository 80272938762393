import { PointerEvent } from 'react';

import { useOrderStatusesMap } from '@/app/hooks/useOrderStatusesMap';
import { useUserProjectsMap } from '@/app/hooks/useUserProjectsMap';
import { useOrderStatusesEnum } from '@/app/hooks/useOrderStatusEnum';
import { useCurrentUser } from '@/app/hooks/useCurrentUser';
import { useNecessaryActionsEnum } from '@/app/hooks/useNecessaryActionsEnum';

import { canOpenClosedOrders } from './helpers';

import { OrderCardView } from './OrderCardView';
import { OrderCardCompactView } from './OrderCardCompactView';
import { OrderCardProps } from './model';

export const OrderCard = ({ ...props }: OrderCardProps) => {
  const user = useCurrentUser();

  const orderStatusesMap = useOrderStatusesMap();
  const orderStatusesEnum = useOrderStatusesEnum();
  const necessaryActionEnum = useNecessaryActionsEnum();
  const projectsMap = useUserProjectsMap({});
  const isOpenAllowed =
    canOpenClosedOrders(user.currentUser?.roles ?? []) || props.order.statusId !== orderStatusesEnum?.ApplicationClosed;

  const handleClickCard = (event: PointerEvent<HTMLDivElement>) => {
    if (isOpenAllowed) {
      props.onClick(event);
    }
  };

  if (!user) {
    return null;
  }

  const cardProps = {
    orderStatusesMap,
    orderStatusesEnum,
    necessaryActionEnum,
    projectsMap,
    onClick: handleClickCard
  };

  if (user.hasRole('PartnerManager')) {
    return (
      <OrderCardView
        {...props}
        {...cardProps}
        isOpenAllowed={isOpenAllowed}
        isOrderTransferAvailable={
          user.currentUser !== undefined &&
          user.currentUser.userId !== props.order.managerId &&
          props.order.statusId !== orderStatusesEnum?.ApplicationClosed
        }
      />
    );
  }

  return <OrderCardCompactView {...props} {...cardProps} isOrderTransferAvailable={true} isOpenAllowed />;
};
