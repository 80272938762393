import { CssPropertyNumber, CssProperty } from '../types';

export const Sizes: CssPropertyNumber = {
  gap: 8,

  controlBorderRadius: 32,

  controlPaddingHorizontal: 32,
  controlPaddingVertical: 8,

  controlSizeLG: 52,
  controlSizeMD: 48,
  controlSizeSM: 40,
  controlSizeXS: 32,

  controlHeightLGmobile: 44,
  controlHeightMDmobile: 36,
  controlHeightSMmobile: 32,

  languageSelectWidth: 60,

  checkboxControlInteractiveSize: 20,
  radioControlSize: 20,

  inputPaddingHorizontal: 24,
  inputPaddingVertical: 14,

  marginXS: 4,
  marginSM: 8,
  marginLG: 16,
  marginXL: 24,
  marginXXL: 32,

  paddingXS: 4,
  paddingXS6: 6,
  paddingSM: 8,
  paddingMD: 12,
  paddingLG: 16,
  paddingXL: 24,
  paddingXXL: 32,
  paddingXXXL: 36,

  paddingCard: 20,

  noBorder: 0,
  borderSM: 12,

  iconSizeXXL: 32,
  iconSizeXL: 28,
  iconSizeLG: 24,
  iconSizeMD: 20,
  iconSizeSM: 16,
  iconSizeXS: 12,

  negativeMarginXS: -4,
  negativeMarginSM: -12,

  paddingModal: 40,
  marginBlockModal: 40,

  maxLayoutWidth: 1248,

  cardActionLoaderHeight: 84,

  cardLoaderHeightSM: 100,
  cardLoaderHeightMD: 128,
  cardLoaderHeightXL: 310,

  orderFormSpaceSize: 384, // 100vh - 384px = высота форма заявки

  salesDepartmentCardMinHeight: 64
};

export const IconSizes: CssProperty = {
  iconScaleXXL: String(Sizes.iconSizeXXL / Sizes.iconSizeLG),
  iconScaleXL: String(Sizes.iconSizeXL / Sizes.iconSizeLG),
  iconScaleLG: String(Sizes.iconSizeLG / Sizes.iconSizeLG),
  iconScaleMD: String(Sizes.iconSizeMD / Sizes.iconSizeLG),
  iconScaleSM: String(Sizes.iconSizeSM / Sizes.iconSizeLG),
  iconScaleXS: String(Sizes.iconSizeXS / Sizes.iconSizeLG)
};
