import { SalesGraphRowType } from '../../model';
import { SalesGraphRowView } from './view';

export const SalesGraphRow = ({ count, title, opacity, percentage, length, highlighted }: SalesGraphRowType) => {
  let currentOpacity = 1;

  switch (opacity) {
    case 5:
      currentOpacity = 0.84;
      break;
    case 4:
      currentOpacity = 0.72;
      break;

    case 3:
      currentOpacity = 0.64;
      break;

    case 2:
      currentOpacity = 0.48;
      break;

    case 1:
      currentOpacity = 0.24;
      break;

    case 0:
      currentOpacity = 0.12;
      break;
    default:
      currentOpacity = 1;
      break;
  }

  return (
    <SalesGraphRowView
      length={length}
      percentage={percentage}
      opacity={currentOpacity}
      count={count}
      title={title}
      highlighted={highlighted}
    />
  );
};
