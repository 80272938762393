import { forwardRef } from 'react';

import { TimePicker } from './TimePicker';
import { Flex } from '../Utility';

import { DateTimeRangeType } from '../DatePicker';

import { InfoCircleFilled } from '@ant-design/icons';

import { Description } from '@/ui/Typography';

import dayjs from 'dayjs';

import './TimePickerRange.scss';

export interface TimePickerRangeProps {
  value: DateTimeRangeType;
  status?: 'error' | '';
  isWhite?: boolean;
  timeRange?: DateTimeRangeType;
  description?: string;
  onChange: (value: DateTimeRangeType) => void;
}

export const TimePickerRange = forwardRef<HTMLInputElement, TimePickerRangeProps>(
  ({ value, onChange, timeRange, isWhite, status, description }) => {
    const handleChangeStart = (newValue?: string) => {
      onChange({
        start: newValue,
        end: value.end
      });
    };

    const handleChangeEnd = (newValue?: string) => {
      onChange({
        start: value.start,
        end: newValue
      });
    };

    return (
      <div className="time-picker-range">
        <div className="time-picker-range-pickers">
          <TimePicker
            value={value.start}
            onChange={handleChangeStart}
            placeholder="Начало"
            label="Начало"
            isWhite={isWhite}
            timeRange={{
              start: timeRange?.start,
              end: value.end ? dayjs(value.end, 'HH:mm').subtract(1, 'hour').format('HH:mm') : timeRange?.end
            }}
          />

          <TimePicker
            value={value.end}
            onChange={handleChangeEnd}
            placeholder="Конец"
            label="Конец"
            isWhite={isWhite}
            timeRange={{
              start: value.start ? dayjs(value.start, 'HH:mm').add(1, 'hour').format('HH:mm') : timeRange?.start,
              end: timeRange?.end
            }}
          />
        </div>

        {description && (
          <Description status={status}>
            <Flex>
              {status === 'error' && <InfoCircleFilled style={{ fontSize: '20px' }} />}
              {description}
            </Flex>
          </Description>
        )}
      </div>
    );
  }
);
