import type { FormEvent, MouseEvent } from 'react';

import { ModalProps } from '@/ui';
import { SalesOfficeResponse } from '@/api/web.client/webClientGeneratedApi';

export interface MeetingDateRange {
  dateTimeStart: string;
  dateTimeEnd: string;
}

export interface ScheduleMeetingFormData {
  meetingDate: string;
  salesOfficeId: number;
}

export enum ScheduleMeetingModalMode {
  create = 'create',
  update = 'update'
}

export interface ScheduleMeetingModalProps extends ModalProps {
  orderId: string;
  meetingDate?: MeetingDateRange;
  mode: ScheduleMeetingModalMode;

  onOk: () => void;
  onClickDate: () => void;
}

export interface ScheduleMeetingModalViewProps extends Omit<ModalProps, 'onOk'> {
  isLoading: boolean;

  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  onClickDate: (event: MouseEvent<HTMLInputElement> | MouseEvent<SVGSVGElement> | FormEvent<HTMLInputElement>) => void;

  offices: SalesOfficeResponse[];
}
