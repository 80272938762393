import { useEffect, useState } from 'react';

import type { DefaultOptionType } from 'rc-select/es/Select';
import { useStructureControllerStructureQuery } from '@/api/web.client/webClientGeneratedApi';

export const useCompaniesOptions = () => {
  const { data } = useStructureControllerStructureQuery();

  const [companiesOptions, setCompaniesOptions] = useState<DefaultOptionType[]>([]);

  useEffect(() => {
    if (companiesOptions.length !== 0 || !data?.companies) {
      return;
    }

    setCompaniesOptions(
      data.companies.map((item) => ({
        value: item.id,
        label: item.name
      }))
    );
  }, [data?.companies, companiesOptions]);

  return companiesOptions;
};
