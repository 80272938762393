import { useEmployeeControllerGetQuery, EmployeeControllerGetApiArg } from '@/api/web.client/webClientGeneratedApi';
import { useMemo } from 'react';

export const useEmployeesOptions = (args: EmployeeControllerGetApiArg) => {
  const { data: employees } = useEmployeeControllerGetQuery(args);

  return useMemo(
    () =>
      employees?.map((employee) => ({
        label: employee.name,
        value: employee.id
      })),
    [employees]
  );
};
