import { AddIcon, CloseIcon } from '@/assets/icons';
import { Button, ButtonSize, ButtonVariant, Icon, Label, LabelVariant } from '@/ui';
import { Drawer } from '@/ui/Drawer';
import { DrawerForm } from '@/ui/Drawer/DrawerForm/DrawerForm';
import { Col, Row, Space } from 'antd';
import { FC } from 'react';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { HookField } from '../../../../widgets/HookField';
import { CorporateGroupFormViewProps } from './model';

export const CorporateGroupFormView: FC<CorporateGroupFormViewProps> = (props) => {
  const { form, onClose, onSubmit } = props;

  const { fields, append, remove, update } = useFieldArray({
    control: form.control,
    name: 'companies'
  });

  const onClickAppendCompany = () => {
    append({ name: '' });
  };

  const onClickRemove = (index: number) => {
    if (fields.length > 1) {
      remove(index);
    } else {
      update(index, { name: '' });
    }
  };

  const drawerFormBody = (
    <Space direction="vertical" size={36} style={{ width: '100%' }}>
      <div>
        <Label variant={LabelVariant.black24} value="Название партнера" />
        <HookField name="name" type="text" />
      </div>
      <div>
        <Label variant={LabelVariant.black24} value="Email администратора" />
        <HookField name="email" type="text" />
      </div>
      <Space direction="vertical" size={20} style={{ width: '100%' }}>
        {fields.map((field, index) => (
          <div key={field.id}>
            <Label variant={LabelVariant.black24} value="Название Юр. лица" />
            <Row gutter={16} align="middle">
              <Col style={{ flex: '1 1 auto' }}>
                <HookField name={`companies.${index}.name`} type="text" />
              </Col>
              {fields.length > 1 && (
                <Button type="button" variant={ButtonVariant.icon} onClick={() => onClickRemove(index)}>
                  <Icon icon={<CloseIcon />} />
                </Button>
              )}
            </Row>
          </div>
        ))}
        <div>
          <Button
            isWhite
            role="button"
            type="button"
            size={ButtonSize.small}
            prefixIcon={<AddIcon />}
            variant={ButtonVariant.secondary}
            onClick={onClickAppendCompany}
          >
            Добавить юр. лицо
          </Button>
        </div>
        <Row gutter={12} align="middle">
          <Col>
            <HookField size="small" type="switch" name="orderLiteOption" />
          </Col>
          <Col>
            <Label level={2} value="Разрешен сокращенный формат заявки" />
          </Col>
        </Row>
      </Space>
    </Space>
  );

  const drawerFormFooter = (
    <Row gutter={16}>
      <Col span={12}>
        <Button type="submit" role="button" isWide variant={ButtonVariant.primary}>
          Сохранить
        </Button>
      </Col>
      <Col span={12}>
        <Button type="button" role="button" onClick={onClose} isWide variant={ButtonVariant.secondary}>
          Отмена
        </Button>
      </Col>
    </Row>
  );

  return (
    <Drawer open onClose={onClose} size="large">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} style={{ display: 'contents' }}>
          <DrawerForm title="Новый партнер" footer={drawerFormFooter}>
            {drawerFormBody}
          </DrawerForm>
        </form>
      </FormProvider>
    </Drawer>
  );
};
