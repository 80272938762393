import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/query';
import { HttpStatusCode } from 'axios';
import { FC, useEffect, JSX } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from '@/ui';
import { useOrdersControllerGetOrderQuery } from '@/api/web.client/webClientGeneratedApi';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { Routes } from '../index';

export const OrderResolver: FC<{ children: JSX.Element }> = ({ children }) => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const user = useCurrentUser();
  const { isLoading, error } = useOrdersControllerGetOrderQuery(orderId ? { id: orderId } : skipToken);

  useEffect(() => {
    const err = error as FetchBaseQueryError;
    if (err) {
      navigate(user.hasRole('PartnerManager') ? Routes.ordersList : Routes.orders);
    }
  }, [error, navigate, user]);

  if (isLoading || (error && (error as FetchBaseQueryError).status !== HttpStatusCode.Unauthorized)) {
    return (
      <div className="loader__wrapper">
        <Loader />
      </div>
    );
  }

  return children;
};
