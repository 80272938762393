import { useMemo } from 'react';
import { CountryResponse, useResourcesControllerGetResourcesQuery } from '../../api/web.client/webClientGeneratedApi';

const baseCountryKey = 'RU';

export const useCountries = () => {
  const { data } = useResourcesControllerGetResourcesQuery();
  const countries = useMemo(() => data?.dictionaries?.countries ?? [], [data?.dictionaries?.countries]);
  const countryKeys = useMemo(() => countries.map<string>((item) => item.key), [countries]);
  const countryByKey = useMemo(
    () =>
      countries.reduce<Record<string, CountryResponse>>((prev, current) => ({ ...prev, [current.key]: current }), {}),
    [countries]
  );
  return {
    countryKeys,
    countryByKey,
    baseCountryKey
  };
};
