import { useState } from 'react';

import { AddEmployeeBlockProps } from './model';
import { AddEmployeeBlockView } from './view';

export const AddEmployeeBlock = ({ employeesOptions, onAddUser }: AddEmployeeBlockProps) => {
  const [isShowAddSelect, setIsShowAddSelect] = useState(false);
  const [employeeSelectValue, setEmployeeSelectValue] = useState<number>();

  const handleClickAddButton = () => {
    setIsShowAddSelect(true);
  };

  const handleAddUser = (userId: number) => {
    setEmployeeSelectValue(undefined);
    setIsShowAddSelect(false);

    onAddUser(userId);
  };

  return (
    <AddEmployeeBlockView
      isShowAddSelect={isShowAddSelect}
      employeesOptions={employeesOptions}
      employeeSelectValue={employeeSelectValue}
      onAddUser={handleAddUser}
      onClickAddButton={handleClickAddButton}
    />
  );
};
