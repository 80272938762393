import '@/utils/helpers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthControllerChangePasswordMutation } from '@/api/web.client/webClientGeneratedApi';
import { LocalStorageKeys } from '@/utils/localstorage/constants';
import { setLocalStorageObject } from '@/utils/localstorage/localStorage';
import { Routes } from '@/app/routing';

import { LoginFormView } from './view';

import { ChangePasswordForm, ChangePasswordFormProps } from './model';

import * as Yup from 'yup';

const passwordValidator = Yup.string().required('Введите пароль').label('Пароль');
const repeatPasswordValidator = Yup.string()
  .required('Повторите пароль')
  .oneOf([Yup.ref('password')], 'Пароли должны совпадать')
  .label('Пароль');
const acceptValidator = Yup.boolean().isTrue('Подтвердите согласие на обработку персональных данных').label('Согласие');

export const resetPasswordFormSchema = Yup.object<ChangePasswordForm>().shape({
  password: passwordValidator,
  repeatPassword: repeatPasswordValidator,
  accept: acceptValidator
});

export const ChangePassword = () => {
  const [onChangePassword, { isLoading }] = useAuthControllerChangePasswordMutation();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const token = queryParams.get('token');

  const form = useForm<ChangePasswordForm>({
    resolver: yupResolver<ChangePasswordForm>(resetPasswordFormSchema),
    defaultValues: {
      accept: false
    }
  });

  useEffect(() => {
    if (!token) {
      navigate(Routes.main);
    }
  }, [navigate, token]);

  const onSubmit = async (data: ChangePasswordForm) => {
    try {
      if (!token) {
        toast.error('Токен не указан');
      }
      const res = await onChangePassword({
        token: token ?? '',
        changePasswordDto: {
          password: data.password,
          repeatPassword: data.repeatPassword
        }
      }).unwrap();
      setLocalStorageObject(LocalStorageKeys.AuthToken, res);
      navigate(Routes.main);
    } catch {
      // DO NOTHING
    }
  };

  const props: ChangePasswordFormProps = {
    form,
    onSubmit,
    isLoading
  };

  return <LoginFormView {...props} />;
};
