import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  CorporateGroupControllerFilterApiArg,
  useCorporateGroupControllerFilterQuery
} from '@/api/web.client/webClientGeneratedApi';
import { PartnersView } from './view';

const formDefaultValues: CorporateGroupControllerFilterApiArg = {
  search: undefined
};

export const Partners: FC = () => {
  const [selectedCorporateGroupId, setSelectedCorporateGroupId] = useState<number | undefined>(undefined);

  const [editCorporateGroupId, setEditCorporateGroupId] = useState<number | undefined>(undefined);

  const [isCreateFormOpen, setIsCreateFormOpen] = useState<boolean>(false);

  const form = useForm<CorporateGroupControllerFilterApiArg>({
    defaultValues: formDefaultValues
  });

  const formData = form.watch();

  const { data: corporateGroups, isLoading } = useCorporateGroupControllerFilterQuery(formData);

  const handleReset = () => {
    form.reset(formDefaultValues);
  };

  return PartnersView({
    form,
    isCreateFormOpen,
    editCorporateGroupId,
    selectedCorporateGroupId,
    corporateGroups: corporateGroups ?? [],
    isLoading,
    onReset: handleReset,
    onCloseCreateForm: () => {
      setIsCreateFormOpen((val) => !val);
    },
    onCloseCorporateGroupForm(): void {
      setEditCorporateGroupId(undefined);
    },
    onSelectCorporateGroup: (id: number) => {
      setSelectedCorporateGroupId(id !== selectedCorporateGroupId ? id : undefined);
    },
    onEditCorporateGroup(id: number): void {
      setEditCorporateGroupId(id);
    },
    onNewCorporateGroup(): void {
      setIsCreateFormOpen(true);
    }
  });
};
