export enum ApiTags {
  GetMe = 'GetMe',
  GetOrder = 'GetOrder',
  GetOrders = 'GetOrders',
  GetMyOrders = 'GetMyOrders',
  GetOrderManagers = 'GetOrderManagers',
  GetCorporateGroup = 'GetCorporateGroup',
  GetCorporateGroups = 'GetCorporateGroups',
  GetEmployees = 'GetEmployees',
  GetEmployee = 'GetEmployee',
  GetStructure = 'GetStructure',
  GetCorporateGroupStructure = 'GetCorporateGroupStructure',
  GetSalesDepartment = 'GetSalesDepartment',
  GetProjects = 'GetProjects',
  GetProject = 'GetProject',
  GetSalesOffices = 'GetSalesOffices'
}
