import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { phoneMask } from '../../utils/formatters/phoneMask';
import { useCountries } from './useCountries';

export const usePhoneFormat = (): ((phoneString?: string) => string) => {
  const { countryByKey } = useCountries();

  return (phoneString?: string): string => {
    if (!phoneString) {
      return '';
    }
    const phone = parsePhoneNumberFromString(String(phoneString));
    const country = countryByKey?.[phone?.country ?? 'RU'];
    if (phone && country) {
      return phoneMask(phoneString, country.mask);
    }
    return phoneString;
  };
};
