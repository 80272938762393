import { useResourcesControllerGetResourcesQuery } from '@/api/web.client/webClientGeneratedApi';

export const useBanksOptions = () => {
  const resourceQuery = useResourcesControllerGetResourcesQuery();

  const banksOptions =
    resourceQuery.currentData?.dictionaries?.banks.map((bank) => ({
      label: bank.name,
      value: bank.id
    })) || [];

  return banksOptions;
};
