import { forwardRef } from 'react';

import { InputWrapper, InputWrapperProps } from '@/ui/Inputs/InputWrapper/InputWrapper';

import './DatePickerRange.scss';
import { DatePickerSolo } from './DatePicker';
import { DatePickerProps as BaseDatePickerProps } from 'antd';

export interface DateTimeRangeType {
  start?: string;
  end?: string;
}

export interface DatePickerRangeProps extends InputWrapperProps {
  value: DateTimeRangeType;
  onChange: (value: DateTimeRangeType) => void;
  status?: 'error';
  isWhite?: boolean;
  disabledDateStart?: BaseDatePickerProps['disabledDate'];
  disabledDateEnd?: BaseDatePickerProps['disabledDate'];
}

export const DatePickerRange = forwardRef<HTMLInputElement, DatePickerRangeProps>(
  ({ status, label, description, value, onChange, isWhite, disabledDateStart, disabledDateEnd }) => {
    const handleChangeStart = (newValue?: string) => {
      onChange({
        start: newValue,
        end: value.end
      });
    };

    const handleChangeEnd = (newValue?: string) => {
      onChange({
        start: value.start,
        end: newValue
      });
    };

    return (
      <InputWrapper label={label} description={description} status={status}>
        <div className="date-picker-range">
          <DatePickerSolo
            disabledDate={disabledDateStart}
            value={value.start}
            onChange={handleChangeStart}
            placeholder="От"
            isWhite={isWhite}
          />
          <span className="divider" />
          <DatePickerSolo
            disabledDate={disabledDateEnd}
            value={value.end}
            onChange={handleChangeEnd}
            placeholder="До"
            isWhite={isWhite}
          />
        </div>
      </InputWrapper>
    );
  }
);
