import { Modal } from '@/ui';

import { HookField } from '@/app/widgets/HookField';

import { TransferOrderModalViewProps } from './model';

const formName = 'OrderTransfer';

export const TransferOrderModalView = ({ onSubmit, managersOptions, ...props }: TransferOrderModalViewProps) => (
  <Modal
    {...props}
    title="Передача заявки"
    okText="Сохранить"
    cancelText="Отмена"
    titleLevel={3}
    submitButtonAttrs={{ form: formName, type: 'submit' }}
  >
    <form id={formName} name={formName} onSubmit={onSubmit}>
      <HookField
        name="managerId"
        type="select"
        label="ФИО Менеджера"
        placeholder="Выберите менеджера"
        options={managersOptions}
      />
    </form>
  </Modal>
);
