import { ReactNode } from 'react';
import { Loader, LoaderProps } from './Loader';

export interface WithLoaderProps extends LoaderProps {
  isLoading: boolean;
  children: ReactNode;
}

export const WithLoader = ({ children, isLoading = true, ...props }: WithLoaderProps) =>
  isLoading ? <Loader {...props} /> : <>{children}</>;
