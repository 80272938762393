import { ProjectResponse } from '@/api/web.client/webClientGeneratedApi';

export const toProjectOptions = (projects: ProjectResponse[]) => {
  if (projects.length === 0) {
    return [];
  }
  const result = projects.map((project) => ({
    value: project.id,
    label: `${project.name} ${project.city}`,
    key: project.id
  }));

  return result;
};
