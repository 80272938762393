import { ButtonHTMLAttributes } from 'react';

import { Modal as AntdModal, ModalProps as AntdModalProps, Row, Col } from 'antd';
import { Button, ButtonVariant, ButtonSize, Title } from '@/ui';

import { CloseIcon } from '@/assets/icons';

import { Colors } from '@/theme';

import clsx from 'clsx';
import './Modal.scss';

export interface ModalProps extends AntdModalProps {
  onDismiss: () => void;
  submitButtonAttrs?: ButtonHTMLAttributes<HTMLButtonElement> & { loading?: boolean };
  titleLevel?: 1 | 2 | 3 | 4;
}

export const Modal = ({ submitButtonAttrs, titleLevel = 4, title, footer, ...props }: ModalProps) => (
  <AntdModal
    {...props}
    maskStyle={{ backgroundColor: Colors.modalMask }}
    className={clsx('modal', props.className ?? '')}
    centered
    closeIcon={<CloseIcon />}
    onCancel={props.onDismiss}
    title={<Title level={titleLevel}>{title}</Title>}
    footer={
      footer ?? (
        <Row gutter={16}>
          <Col span={12}>
            <Button key="submit" onClick={props.onOk} size={ButtonSize.medium} isWide {...submitButtonAttrs}>
              {props.okText}
            </Button>
          </Col>

          <Col span={12}>
            <Button
              key="back"
              onClick={props.onCancel}
              size={ButtonSize.medium}
              variant={ButtonVariant.secondary}
              isWide
            >
              {props.cancelText}
            </Button>
          </Col>
        </Row>
      )
    }
  />
);
