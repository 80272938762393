import type { PointerEvent } from 'react';

import { OrderDetailsResponse, OfferResponse } from '@/api/web.client/webClientGeneratedApi';

export enum OrderFormMode {
  new = 'new',
  readonly = 'readonly'
}

interface Model {
  mode: OrderFormMode;
  order?: OrderDetailsResponse;
  offers?: OfferResponse[];

  isScheduleMeetingAvailable: boolean;
  isCancelAvailable: boolean;
  isManagerCardAvailable: boolean;
  isDealConditionActive: boolean;
}

interface Actions {
  onSubmitDataForm: () => void;
  onClickBack: (event: PointerEvent<HTMLDivElement>) => void;
}

export interface OrderFormProps extends Model, Actions {}
