import { useEffect, useMemo } from 'react';

import { useForm, FormProvider } from 'react-hook-form';

import { skipToken } from '@reduxjs/toolkit/dist/query';

import { DebtRepaymentScheduleForm } from './model';

import { useReportsControllerGetRepaymentScheduleReportQuery } from '@/api/web.client/webClientGeneratedApi';

import { useUserProjectsMap } from '@/app/hooks/useUserProjectsMap';

import { DebtRepaymentScheduleView } from './view';

import { formatDateToMonthYear } from '@/utils/formatters';
import { DefaultOptionType } from 'antd/es/select';

const today = new Date(Date.now()).toDateString();

export const DebtRepaymentSchedule = () => {
  const projectsMap = useUserProjectsMap({});

  const formMethods = useForm<DebtRepaymentScheduleForm>({
    defaultValues: {
      monthStartDate: today
    }
  });

  const { watch, setValue } = formMethods;

  const monthStartDate = watch('monthStartDate');
  const currentMonth = watch('currentMonth');

  const { currentData, isLoading, isSuccess } = useReportsControllerGetRepaymentScheduleReportQuery(
    monthStartDate ? { monthStartDate } : skipToken
  );

  useEffect(() => {
    if (!isSuccess || !currentData?.items[0]) {
      return;
    }

    setValue('currentMonth', currentData?.items[0]?.monthYear);
  }, [currentData, isSuccess, setValue]);

  const monthOptions = useMemo(() => {
    const result: DefaultOptionType[] = [];

    if (!currentData?.items) {
      return result;
    }

    const existItems: Record<string, boolean> = {};

    for (const item of currentData.items) {
      if (existItems[item.monthYear]) {
        continue;
      }

      existItems[item.monthYear] = true;

      result.push({
        label: formatDateToMonthYear(item.monthYear),
        value: item.monthYear
      });
    }

    return result;
  }, [currentData?.items]);

  const filteredItems =
    currentMonth && currentData ? currentData?.items.filter((item) => item.monthYear === currentMonth) : [];

  const amountValues = filteredItems.reduce(
    (acc, curr) => {
      acc.amount += curr.amount;
      acc.repaidAmount += curr.repaidAmount;
      acc.repaymentBalanceAmount += curr.repaymentBalanceAmount;

      return acc;
    },
    {
      amount: 0,
      repaidAmount: 0,
      repaymentBalanceAmount: 0
    }
  );

  return (
    <FormProvider {...formMethods}>
      <DebtRepaymentScheduleView
        isLoading={isLoading}
        items={filteredItems}
        monthOptions={monthOptions}
        projectsMap={projectsMap}
        amountValues={amountValues}
      />
    </FormProvider>
  );
};
