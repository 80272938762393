import { MouseEvent, useEffect } from 'react';

import { TimeRangeModalView } from './view';

import dayjs from 'dayjs';
import { addHours, addMinutes, startOfDay } from 'date-fns';

import { TimeRangeModalProps, TimeRangeForm } from './model';

import { DateTimeRangeType } from '@/ui';

import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export const timeRangeSchema = Yup.object<TimeRangeForm>().shape({
  meetingRangeTime: Yup.object<DateTimeRangeType>()
    .shape({
      start: Yup.string().required(),
      end: Yup.string().required()
    })
    .required()
});

export const TimeRangeModal = ({ onAddDate, calendarDate, calendar, ...props }: TimeRangeModalProps) => {
  const formMethods = useForm<TimeRangeForm>({
    resolver: yupResolver<TimeRangeForm>(timeRangeSchema),
    mode: 'onSubmit',
    defaultValues: {
      meetingRangeTime: {}
    }
  });

  const { handleSubmit, reset, watch, setValue, setError } = formMethods;

  const meetingRangeTime = watch('meetingRangeTime');

  const handleSubmitForm = handleSubmit(({ meetingRangeTime }: TimeRangeForm) => {
    if (!calendar || !calendarDate) {
      return;
    }

    const startDate = dayjs(meetingRangeTime.start, 'HH:mm');
    const endDate = dayjs(meetingRangeTime.end, 'HH:mm');

    const startOfWorkDay = addHours(startOfDay(startDate.valueOf()), calendar.startHour).valueOf();
    const endOfWorkDay = addHours(startOfDay(endDate.valueOf()), calendar.endHour).valueOf();

    if (startDate.valueOf() < startOfWorkDay) {
      setError('meetingRangeTime', {
        message: `Время начала встречи должно быть больше чем ${calendar.startHour}:00`
      });

      return;
    }

    if (endDate.valueOf() > endOfWorkDay) {
      setError('meetingRangeTime', {
        message: `Время завершения встречи должно быть больше чем ${calendar.endHour}:00`
      });

      return;
    }

    const duration = endDate.hour() * 60 + endDate.minute() - startDate.hour() * 60 - startDate.minute();

    if (duration < calendar.minEventTimeMinutes) {
      setError('meetingRangeTime', {
        message: `Длительность встречи должна быть больше чем ${calendar.minEventTimeMinutes} минут`
      });

      return;
    }

    if (duration > calendar.maxEventTimeMinutes) {
      setError('meetingRangeTime', {
        message: `Длительность встречи должна быть меньше чем ${calendar.maxEventTimeMinutes} минут`
      });

      return;
    }

    if (duration > calendar.maxEventTimeMinutes) {
      setError('meetingRangeTime', {
        message: `Длительность встречи должна быть меньше чем ${calendar.maxEventTimeMinutes} минут`
      });

      return;
    }

    if (calendar?.events.length !== 0 || calendar?.busy.length !== 0) {
      for (const event of [...calendar?.events, ...calendar?.busy]) {
        const eventStart = new Date(event.startDate).valueOf();
        const eventEnd = Date.parse(event.endDate).valueOf();

        const calendarDateStart = addMinutes(
          addHours(startOfDay(calendarDate), startDate.hour()),
          startDate.minute()
        ).valueOf();

        const calendarDateEnd = addMinutes(
          addHours(startOfDay(calendarDate), endDate.hour()),
          endDate.minute()
        ).valueOf();

        if (
          (calendarDateStart > eventStart && calendarDateStart < eventEnd) ||
          (calendarDateEnd > eventStart && calendarDateEnd < eventEnd)
        ) {
          setError('meetingRangeTime', {
            message: 'Это время занято'
          });

          return;
        }
      }
    }

    onAddDate(meetingRangeTime);

    reset({
      meetingRangeTime: {}
    });
  });

  const handleCancel = (event: MouseEvent<HTMLButtonElement>) => {
    reset({
      meetingRangeTime: {}
    });

    props.onCancel?.(event);
  };

  useEffect(() => {
    if (!calendarDate) {
      return;
    }
    const startHour = calendarDate.getHours();

    setValue('meetingRangeTime', {
      start: `${startHour}:00`,
      end: `${startHour + 1}:00`
    });
  }, [calendarDate, setValue]);

  return (
    <FormProvider {...formMethods}>
      <TimeRangeModalView
        {...props}
        onSubmit={handleSubmitForm}
        onCancel={handleCancel}
        isSubmitDisabled={meetingRangeTime.end === undefined || meetingRangeTime.start === undefined}
      />
    </FormProvider>
  );
};
