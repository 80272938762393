import { PointerEvent, useRef } from 'react';

import { Row, Col } from 'antd';
import { Title, Button, ButtonSize, ButtonVariant, WithLoader, Loader, TextLoader, ActionMenu } from '@/ui';

import { OrderCard, TransferOrderModal } from '@/app/widgets';
import { useModal } from '@/app/hooks/useModal';

import { AddIcon, CloseIcon } from '@/assets/icons';
import { Sizes } from '@/theme/themeVariables';
import { FormProvider } from 'react-hook-form';

import { HookField } from '@/app/widgets/HookField';

import type { OrdersMyListProps } from './model';

import './styles.scss';
import { useUserCorporateGroup } from '@/app/hooks/useUserCorporateGroup';

export const OrdersListView = ({
  form,
  statusOptions,
  orders,
  hasMore,
  isLoading,

  onReset,
  onNewOrderClick,
  onMoreClick,
  onCardClick
}: OrdersMyListProps) => {
  const orderIdRef = useRef<string>('');

  const corporateGroup = useUserCorporateGroup();

  const [sendOrderModalOpened, openSendOrderModal, closeSendOrderModal] = useModal();

  const hanSendOrderClick = (id: string) => (event: PointerEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    orderIdRef.current = id;
    openSendOrderModal();
  };

  const handleCancelTransferOrderModal = () => {
    closeSendOrderModal();
    orderIdRef.current = '';
  };

  const actionMenuOptions = [
    {
      label: 'Стандартная',
      onClick: () => {
        onNewOrderClick(false);
      }
    },
    {
      label: 'Сокращенная',
      onClick: () => {
        onNewOrderClick(true);
      }
    }
  ];

  const newOrderButton = (
    <Button isWide size={ButtonSize.medium} prefixIcon={<AddIcon />} loading={isLoading}>
      Новая заявка
    </Button>
  );

  const newOrderButtonWithMenu = corporateGroup?.orderLiteOption ? (
    <ActionMenu options={actionMenuOptions}>{newOrderButton}</ActionMenu>
  ) : (
    <Button
      isWide
      size={ButtonSize.medium}
      prefixIcon={<AddIcon />}
      loading={isLoading}
      onClick={() => {
        onNewOrderClick(false);
      }}
    >
      Новая заявка
    </Button>
  );

  return (
    <Row justify="center" align="top">
      <Col span={24} className="content-centered-container">
        <Row align="top" justify="space-between" gutter={16}>
          <Col span={12}>
            <Title>Мои заявки</Title>
          </Col>

          <Col span={5}>
            <Row justify="end" align="middle">
              <Col span={24}>{newOrderButtonWithMenu}</Col>
            </Row>
          </Col>
        </Row>

        {isLoading && (
          <Row style={{ marginBottom: Sizes.marginXL }}>
            <Col span={24}>
              <TextLoader />
            </Col>
          </Row>
        )}

        <WithLoader isLoading={statusOptions === undefined || isLoading} className="order-list-actions-loader">
          <FormProvider {...form}>
            <form name="OrderFilters">
              <Row align="bottom" gutter={16} style={{ marginBottom: 24 }}>
                <Col span={8}>
                  <HookField label="Дата создания заявки" name="dates" type="datePickerRange" isWhite />
                </Col>

                <Col span={11}>
                  <HookField
                    name="statuses"
                    type="select"
                    label="Статус"
                    placeholder="Не выбран"
                    options={statusOptions}
                    mode="multiple"
                    isWhite
                    canSelectAll
                  />
                </Col>

                <Col span={5}>
                  <Button
                    variant={ButtonVariant.secondary}
                    isWide
                    isWhite
                    onClick={onReset}
                    type="button"
                    prefixIcon={<CloseIcon />}
                  >
                    Сбросить фильтры
                  </Button>
                </Col>
              </Row>
            </form>
          </FormProvider>
        </WithLoader>

        <Row>
          {isLoading ? (
            <>
              <Col span={24} style={{ marginBlock: Sizes.gap }}>
                <Loader className="order-list-loader" />
              </Col>
              <Col span={24} style={{ marginBlock: Sizes.gap }}>
                <Loader className="order-list-loader" />
              </Col>
            </>
          ) : (
            <>
              {orders.map((order) => (
                <Col span={24} key={order.id} style={{ marginBlock: Sizes.gap }}>
                  <OrderCard
                    order={order}
                    onSendClick={hanSendOrderClick(order.id)}
                    onClick={onCardClick(order.id, order.orderLite)}
                  />
                </Col>
              ))}
            </>
          )}
        </Row>

        {hasMore && (
          <Row justify="end" style={{ marginTop: 32 }}>
            <Button onClick={onMoreClick}>Загрузить еще</Button>
          </Row>
        )}

        <TransferOrderModal
          orderId={orderIdRef.current}
          onOk={handleCancelTransferOrderModal}
          onCancel={handleCancelTransferOrderModal}
          onDismiss={handleCancelTransferOrderModal}
          open={sendOrderModalOpened}
        />
      </Col>
    </Row>
  );
};
