import { Label, ExpandableCard, Flex, Button, ButtonSize, ButtonVariant, LabelVariant } from '@/ui';

import { ThreePartiesMeetingCardViewProps } from './model';

export const ThreePartiesMeetingCardView = ({
  meetingDateTime,
  meetingWaitingDate,
  showScheduleMeetingButton,
  showResultButton,
  showRescheduleMeetingButton,
  onClickSchedule,
  onClickReschedule,
  onShowMeetingResults
}: ThreePartiesMeetingCardViewProps) => (
  <ExpandableCard title="Встреча трёх сторон">
    <Flex direction="column" align="flex-start" gap={16}>
      {meetingDateTime && <Label value={meetingDateTime} variant={LabelVariant.black72} />}

      {meetingWaitingDate && <Label value={meetingWaitingDate} variant={LabelVariant.black72} />}

      <Flex direction="column" align="flex-start" gap={8} fullWidth>
        {showScheduleMeetingButton && (
          <Button size={ButtonSize.small} onClick={onClickSchedule} isWide>
            Назначить встречу
          </Button>
        )}

        {showResultButton && (
          <Button size={ButtonSize.small} onClick={onShowMeetingResults} isWide>
            Результат встречи
          </Button>
        )}

        {showRescheduleMeetingButton && (
          <Button size={ButtonSize.small} onClick={onClickReschedule} variant={ButtonVariant.secondary} isWide>
            Перенести встречу
          </Button>
        )}
      </Flex>
    </Flex>
  </ExpandableCard>
);
