import { useFormContext } from 'react-hook-form';

import { Row, Col } from 'antd';
import { Title, Icon, IconSize } from '@/ui';

import { Colors } from '@/theme/themeVariables';

import { CheckboxCircleFilled } from '@/assets/icons';
import { HookField } from '@/app/widgets/HookField';
import { useDadata } from '@/app/hooks/useDadata';

import { OrderFormMode } from '../../../../model';
import { OrderDetailsResponse } from '@/api/web.client/webClientGeneratedApi';

import { ApartmentsForSellProps } from './model';

import { roomsCountOptions, yesNoRadioOptions } from '@/constants';

export const ApartmentsForSellView = ({
  mode,
  apartmentRadioOptions,
  banksOptions,
  shortForm
}: ApartmentsForSellProps) => {
  const { watch } = useFormContext<OrderDetailsResponse>();
  const watchApartmentStateReadyCheck = watch('isReady');
  const watchHasEncumbranceCheck = watch('hasEncumbrance');
  const city = watch('city');
  const street = watch('street');
  const hasAgent = watch('hasAgent');

  const { data: cityOptions, onSearch: onSearchCity } = useDadata({
    from_bound: { value: 'city' },
    to_bound: { value: 'city' }
  });

  const { data: streetOptions, onSearch: onSearchStreet } = useDadata({
    from_bound: { value: 'street' },
    to_bound: { value: 'street' },
    locations: [
      {
        city
      }
    ]
  });

  const { data: buildingOptions, onSearch: onSearchBuilding } = useDadata({
    from_bound: { value: 'house' },
    to_bound: { value: 'house' },
    locations: [
      {
        city,
        street
      }
    ]
  });

  return (
    <>
      {!shortForm && (
        <>
          <Title level={3}>
            <Icon
              icon={<CheckboxCircleFilled />}
              size={IconSize.xl}
              color={mode === OrderFormMode.readonly ? Colors.lime : Colors.storm6}
            />
            <>Сдаваемая в обмен.рф квартира</>
          </Title>

          <Row style={{ marginBottom: 32 }} gutter={16}>
            <Col span={24} style={{ marginBottom: 32 }}>
              <HookField
                name="isReady"
                type="radio"
                options={apartmentRadioOptions}
                label="На каком этапе квартира?"
                readOnly={mode === OrderFormMode.readonly}
              />
            </Col>

            <Col span={24} style={{ marginBottom: 32 }}>
              <HookField
                name="city"
                type="autocomplete"
                placeholder="Город"
                label="Город"
                readOnly={mode === OrderFormMode.readonly}
                onSearch={onSearchCity}
                allowClear={false}
                options={cityOptions?.map((item) => ({ value: item.data.city, label: item.data.city })) ?? []}
              />
            </Col>
          </Row>

          {watchApartmentStateReadyCheck ? (
            <Row style={{ marginBottom: 32 }} gutter={16}>
              <Col span={12}>
                <HookField
                  name="street"
                  type="autocomplete"
                  label="Улица"
                  placeholder="Улица"
                  readOnly={mode === OrderFormMode.readonly}
                  onSearch={onSearchStreet}
                  allowClear={false}
                  options={streetOptions?.map((item) => ({ value: item.data.street, label: item.data.street })) ?? []}
                />
              </Col>

              <Col span={6}>
                <HookField
                  name="building"
                  type="autocomplete"
                  label="Дом"
                  placeholder="Номер дома"
                  readOnly={mode === OrderFormMode.readonly}
                  onSearch={onSearchBuilding}
                  allowClear={false}
                  options={buildingOptions?.map((item) => ({ value: item.data.house, label: item.data.house })) ?? []}
                />
              </Col>

              <Col span={6}>
                <HookField
                  name="apartmentNumber"
                  type="text"
                  label="Квартира"
                  placeholder="Номер квартиры"
                  readOnly={mode === OrderFormMode.readonly}
                />
              </Col>
            </Row>
          ) : (
            <>
              <Row style={{ marginBottom: 32 }} gutter={16}>
                <Col span={12}>
                  <HookField
                    name="developerName"
                    type="text"
                    label="Застройщик"
                    placeholder="Застройщик"
                    readOnly={mode === OrderFormMode.readonly}
                  />
                </Col>

                <Col span={12}>
                  <HookField
                    name="projectName"
                    type="text"
                    label="Название ЖК"
                    placeholder="Название жилого комплекса"
                    readOnly={mode === OrderFormMode.readonly}
                  />
                </Col>
              </Row>

              <Row style={{ marginBottom: 32 }} gutter={16}>
                <Col span={12}>
                  <HookField
                    name="queue"
                    type="number"
                    label="Очередь (не обязательно)"
                    placeholder="Номер очереди"
                    decimalScale={0}
                    min={0}
                    readOnly={mode === OrderFormMode.readonly}
                  />
                </Col>

                <Col span={12}>
                  <HookField
                    name="buildingCompletionDate"
                    type="datePicker"
                    label="Срок сдачи"
                    placeholder="Срок сдачи дома"
                    readOnly={mode === OrderFormMode.readonly}
                  />
                </Col>
              </Row>
            </>
          )}
          <Row style={{ marginBottom: 32 }} gutter={16}>
            <Col span={12}>
              <HookField
                name="floor"
                type="number"
                decimalScale={0}
                label="Этаж"
                min={0}
                placeholder="Этаж квартиры"
                readOnly={mode === OrderFormMode.readonly}
              />
            </Col>

            <Col span={12}>
              <HookField
                name="numberOfFloors"
                type="number"
                decimalScale={0}
                min={0}
                label="Этажность дома"
                placeholder="Этажность дома"
                readOnly={mode === OrderFormMode.readonly}
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 32 }} gutter={16}>
            <Col span={12}>
              <HookField
                name="numberOfRooms"
                type="select"
                options={roomsCountOptions}
                label="Количество комнат"
                placeholder="Не выбрано"
                readOnly={mode === OrderFormMode.readonly}
              />
            </Col>

            <Col span={12}>
              <HookField
                name="exchangeApartmentTotalArea"
                type="number"
                label="Общая площадь квартиры"
                placeholder="0"
                min={0}
                decimalScale={1}
                valueSuffix={`м\u00B2`}
                readOnly={mode === OrderFormMode.readonly}
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 32 }} gutter={16}>
            <Col span={24} style={{ marginBottom: 32 }}>
              <HookField
                name="hasEncumbrance"
                type="radio"
                options={yesNoRadioOptions}
                label="Наличие ипотеки"
                readOnly={mode === OrderFormMode.readonly}
              />
            </Col>

            {watchHasEncumbranceCheck ? (
              <>
                <Col span={12}>
                  <HookField
                    name="encumbranceBankId"
                    type="select"
                    label="Банк ипотеки"
                    placeholder="Банк ипотеки"
                    options={banksOptions}
                    readOnly={mode === OrderFormMode.readonly}
                  />
                </Col>
                <Col span={12}>
                  <HookField
                    name="remainingDebt"
                    type="number"
                    label="Остаток ипотеки"
                    placeholder="0"
                    valueSuffix="₽"
                    decimalScale={1}
                    min={0}
                    readOnly={mode === OrderFormMode.readonly}
                  />
                </Col>
              </>
            ) : null}
          </Row>

          <Row style={{ marginBottom: 32 }} gutter={16}>
            <Col span={24} style={{ marginBottom: 32 }}>
              <HookField
                name="minorOwnersExist"
                type="radio"
                options={yesNoRadioOptions}
                label="Есть несовершеннолетние собственники"
                readOnly={mode === OrderFormMode.readonly}
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 32 }} gutter={16}>
            <Col span={24} style={{ marginBottom: 32 }}>
              <HookField
                name="maternityCapitalUsed"
                type="radio"
                options={yesNoRadioOptions}
                label="Был использован материнский капитал"
                readOnly={mode === OrderFormMode.readonly}
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 32 }} gutter={16}>
            <Col span={12}>
              <HookField
                name="clientPrice"
                type="number"
                label="Ожидание по цене выкупа клиента"
                placeholder="0"
                valueSuffix="₽"
                decimalScale={1}
                min={0}
                readOnly={mode === OrderFormMode.readonly}
              />
            </Col>
            {hasAgent && (
              <Col span={12}>
                <HookField
                  name="agentPrice"
                  type="number"
                  label="Ожидание по цене выкупа агента"
                  placeholder="0"
                  valueSuffix="₽"
                  decimalScale={1}
                  min={0}
                  readOnly={mode === OrderFormMode.readonly}
                />
              </Col>
            )}
          </Row>
        </>
      )}

      <Row style={{ marginBottom: 32 }} gutter={16}>
        <Col span={24}>
          <HookField
            name="comment"
            type="textArea"
            label="Комментарий"
            placeholder="Комментарий"
            readOnly={mode === OrderFormMode.readonly}
            style={{ borderRadius: 16 }}
          />
        </Col>
      </Row>
    </>
  );
};
