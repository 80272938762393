import { FC, useState } from 'react';
import { HeaderTimezoneWidgetViewProps } from './model';
import { Select } from '@/ui';
import './styles.scss';
import { Popconfirm } from 'antd';
import { keyBy } from 'lodash';

const options: { value: number; label: string }[] = [
  { value: -720, label: '(UTC-12:00) Линия перемены дат' },
  { value: -660, label: '(UTC-11:00) Ниуэ, Самоа' },
  { value: -600, label: '(UTC-10:00) Гавайи, Таити' },
  { value: -540, label: '(UTC-9:00) Аляска, Острова Гамбье' },
  { value: -480, label: '(UTC-8:00) Тихоокеанское время (США и Канада)' },
  { value: -420, label: '(UTC-7:00) Горное время (США и Канада)' },
  { value: -360, label: '(UTC-6:00) Центральное время (США и Канада), Мехико' },
  { value: -300, label: '(UTC-5:00) Восточное время (США и Канада), Богота, Лима' },
  { value: -240, label: '(UTC-4:00) Атлантическое время (Канада), Каракас, Ла-Пас' },
  { value: -210, label: '(UTC-3:30) Ньюфаундленд' },
  { value: -180, label: '(UTC-3:00) Буэнос-Айрес' },
  { value: -120, label: '(UTC-2:00) Среднеатлантическое время' },
  { value: -60, label: '(UTC-1:00) Кабо-Верде' },
  { value: 0, label: '(UTC) Лондон' },
  { value: 60, label: '(UTC+1:00) Берлин, Рим, Париж, Мадрид' },
  { value: 120, label: '(UTC+2:00) Калининград' },
  { value: 180, label: '(UTC+3:00) Москва' },
  { value: 240, label: '(UTC+4:00) Самара' },
  { value: 300, label: '(UTC+5:00) Екатеринбург' },
  { value: 360, label: '(UTC+6:00) Омск' },
  { value: 420, label: '(UTC+7:00) Красноярск' },
  { value: 480, label: '(UTC+8:00) Иркутск' },
  { value: 540, label: '(UTC+9:00) Якутск' },
  { value: 600, label: '(UTC+10:00) Владивосток' },
  { value: 660, label: '(UTC+11:00) Магадан' },
  { value: 720, label: '(UTC+12:00) Анадырь' }
];

const optionByValue = keyBy(options, (item) => item.value);

export const HeaderTimezoneWidgetView: FC<HeaderTimezoneWidgetViewProps> = (props) => {
  const { value, onChange, isLoading, isSuggestionActive } = props;

  const [isPopconfirmVisible, setIsPopconfirmVisible] = useState(isSuggestionActive);

  const select = <Select loading={isLoading} value={value} options={options} onChange={onChange} isSmall isGrey />;

  const selectedValue = optionByValue[value];

  const selectRender = isSuggestionActive ? (
    <Popconfirm
      open={isPopconfirmVisible}
      title="Часовой пояс"
      okText="Да"
      icon={null}
      disabled={isLoading}
      cancelText="Отмена"
      onConfirm={() => onChange(value)}
      onCancel={() => setIsPopconfirmVisible(false)}
      description={
        selectedValue?.label
          ? `Ваш часовой определен как ${selectedValue?.label}, сохранить?`
          : 'Ваш часовой пояс не определен, выберите пожалуйста из списка'
      }
    >
      {select}
    </Popconfirm>
  ) : (
    select
  );

  return <div className="HeaderTimezoneWidget">{selectRender}</div>;
};
