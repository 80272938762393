import { useResourcesControllerGetEnumsQuery } from '@/api/web.client/webClientGeneratedApi';
import { useMemo } from 'react';
import { useCurrentUser } from '@/app/hooks/useCurrentUser';

export const useUserRoles = () => {
  const { data: enumResponse } = useResourcesControllerGetEnumsQuery();

  const { hasRole } = useCurrentUser();

  const filteredRoles = useMemo(() => {
    return (
      enumResponse?.userRoles?.filter((item) => (hasRole('ExchangeUser') ? true : item.key !== 'ExchangeUser')) ?? []
    );
  }, [enumResponse?.userRoles, hasRole]);

  const rolesOptions = useMemo(
    () =>
      filteredRoles.map((userRole) => ({
        label: userRole.label,
        value: userRole.value
      })) ?? [],
    [filteredRoles]
  );

  const roleByName = useMemo(
    () =>
      filteredRoles.reduce(
        (prev, current) => ({
          ...prev,
          [current.key]: current.label
        }),
        {}
      ) ?? {},
    [filteredRoles]
  );

  return { roleByName, rolesOptions };
};
