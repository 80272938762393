import { useUserProjects } from './useUserProjects';

import { UsersControllerProjectsApiArg } from '@/api/web.client/webClientGeneratedApi';

export const useUserProjectsOptions = (args: UsersControllerProjectsApiArg) => {
  const projectsQuery = useUserProjects(args);

  const projectsOptions = projectsQuery.map((project) => ({
    label: `${project.city}, ${project.name}`,
    value: project.id
  }));

  return projectsOptions;
};
