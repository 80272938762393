import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { HttpStatusCode } from 'axios';
import { FC, useEffect, JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { Routes } from '../index';
import { Loader } from '@/ui';

export const AuthGuard: FC<{ children: JSX.Element }> = ({ children }) => {
  const navigate = useNavigate();
  const { isCurrentUserLoading, currentUserError: error } = useCurrentUser();

  useEffect(() => {
    const err = error as FetchBaseQueryError;
    if (err?.status === HttpStatusCode.Unauthorized) {
      navigate(Routes.login);
    }
  }, [error, navigate]);

  if (isCurrentUserLoading || (error && (error as FetchBaseQueryError).status !== HttpStatusCode.Unauthorized)) {
    return (
      <div className="loader__wrapper">
        <Loader className="loader__wrapper" />
      </div>
    );
  }

  return children;
};
