import { skipToken } from '@reduxjs/toolkit/query';

import { useOrdersControllerGetOrderManagersQuery } from '@/api/web.client/webClientGeneratedApi';
import { useCurrentUser } from './useCurrentUser';
import { useMemo } from 'react';

export const useOrderManagersOptions = (orderId?: string) => {
  const managersQuery = useOrdersControllerGetOrderManagersQuery(orderId ? { id: orderId } : skipToken, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true
  });

  const { currentUser } = useCurrentUser();

  return useMemo(() => {
    if (!managersQuery.data || !currentUser) {
      return [];
    }

    return managersQuery.data
      .filter((manager) => manager.id !== currentUser.userId)
      .map((manager) => ({
        value: manager.id,
        label: manager.name
      }));
  }, [managersQuery.data, currentUser]);
};
