import { ReactNode, forwardRef } from 'react';

import { Label, LabelWeight } from '@/ui/Typography';
import {
  Radio as AntdRadio,
  RadioGroupProps as AntdRadioGroupProps,
  RadioProps as AntdRadioProps,
  CheckboxOptionType
} from 'antd';

import clsx from 'clsx';

import './Radio.scss';

export interface RadioProps extends AntdRadioProps {
  readOnly?: boolean;
}

export const Radio = ({ readOnly = false, ...props }: RadioProps) => {
  const radioButtonClassName = clsx('radio', readOnly && 'read-only');

  return <AntdRadio {...props} className={radioButtonClassName} />;
};

export interface RadioGroupProps extends AntdRadioGroupProps {
  options?: CheckboxOptionType[];
  label: ReactNode;
  readOnly?: boolean;
  vertical?: boolean;
}

export const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  ({ label, options, readOnly = false, vertical = false, ...props }, ref) => {
    const radioGroupClassName = clsx('radio-group', readOnly && 'read-only', vertical && 'vertical');

    return (
      <Label value={label ?? ''} level={3} weight={LabelWeight.semibold}>
        <div className={radioGroupClassName}>
          <AntdRadio.Group {...props} ref={ref}>
            {options ? (
              options?.map((item) => (
                <Radio value={item.value} key={String(item.value)} readOnly={readOnly}>
                  {item.label}
                </Radio>
              ))
            ) : (
              <>{props.children}</>
            )}
          </AntdRadio.Group>
        </div>
      </Label>
    );
  }
);
