import { forwardRef } from 'react';
import { InputBase, InputBaseProps } from './InputBase';

import type { InputRef } from 'antd/es/input';
import { InputWrapper, InputWrapperProps } from '../InputWrapper/InputWrapper';

export interface InputProps extends InputBaseProps, InputWrapperProps {}

export const Input = forwardRef<InputRef, InputProps>(({ label, description, readOnly, ...props }, ref) => (
  <InputWrapper label={label} description={description} status={props.status} readOnly={readOnly}>
    <InputBase {...props} ref={ref} />
  </InputWrapper>
));
