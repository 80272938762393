import { Row, Col } from 'antd';
import { ButtonVariant, Title, Button, WithLoader, TextLoader } from '@/ui';
import { Sizes } from '@/theme/themeVariables';
import { SalesProps } from './model';
import { FormProvider } from 'react-hook-form';
import { HookField } from '@/app/widgets/HookField';
import { CloseIcon } from '@/assets/icons';
import { SalesGraph, SalesInfoRejected } from '@/app/widgets';
import './styles.scss';

export const SalesView = ({
  form,
  projectsOptions,
  sales,
  rejections,
  salesIsLoading,
  rejectionsIsLoading,
  projectsIsLoading,
  onReset
}: SalesProps) => {
  return (
    <Row justify="center" align="top">
      <Col span={24} style={{ maxWidth: Sizes.maxLayoutWidth }}>
        <Row align="top" justify="space-between" gutter={16}>
          <Col span={12}>
            <Title>Воронка продаж</Title>
          </Col>
        </Row>

        {projectsIsLoading && (
          <Row style={{ marginBottom: Sizes.marginXL }}>
            <Col span={24}>
              <TextLoader />
            </Col>
          </Row>
        )}

        <WithLoader
          isLoading={projectsIsLoading || projectsOptions === undefined}
          className="project-list-actions-loader"
        >
          <FormProvider {...form}>
            <form name="SalesFilters">
              <Row align="bottom" gutter={16} style={{ marginBottom: 24 }}>
                <Col span={8}>
                  <HookField name="dates" type="datePickerRange" isWhite />
                </Col>

                <Col span={11}>
                  <HookField
                    name="projects"
                    type="select"
                    label="Проекты"
                    placeholder="Не выбран"
                    options={projectsOptions}
                    mode="multiple"
                    isWhite
                    canSelectAll
                    loading={projectsIsLoading}
                  />
                </Col>

                <Col span={5}>
                  <Button
                    variant={ButtonVariant.secondary}
                    isWide
                    isWhite
                    onClick={onReset}
                    type="button"
                    loading={projectsIsLoading || salesIsLoading || rejectionsIsLoading}
                    prefixIcon={<CloseIcon />}
                  >
                    Сбросить фильтры
                  </Button>
                </Col>
              </Row>
            </form>
          </FormProvider>
        </WithLoader>

        <Row justify="space-between" wrap={false} style={{ gap: Sizes.marginLG }}>
          <Col span={16}>
            <SalesGraph isLoading={salesIsLoading} sales={sales} />
          </Col>
          <Col span={8}>
            <SalesInfoRejected isLoading={rejectionsIsLoading} rejections={rejections} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
