import { useRoutes } from 'react-router';
import {
  Login,
  Main,
  OrderForm,
  OrdersList,
  OrdersSalesList,
  SalesDepartments,
  Partners,
  Sales,
  Payments,
  Statistics,
  Bills
} from '@/app/content';
import { ChangePassword } from '../content/changePassword';
import { Employees } from '../content/employees';

import { AuthGuard } from './resolvers/authGuard';
import { OrderResolver } from './resolvers/order.resolver';
import { UserRoleGuard } from './resolvers/user-role.guard';
import { Projects } from '../content/projects';
import { SalesOffices } from '../content/salesOffices';

export const Routes = {
  main: '/',
  changePassword: '/change-password',
  login: '/login',
  orders: '/orders',
  ordersList: '/orders/my',
  newOrder: '/orders/new',
  order: '/orders/:orderId',
  partners: '/partners',
  salesDepartments: '/sales-departments',
  employees: '/employees',
  projects: '/projects',
  payments: '/payments',
  sales: '/sales',
  statistics: '/statistics',
  bills: '/bills',
  salesOffices: '/sales-offices'
};

export const Content = () => {
  return useRoutes([
    {
      path: Routes.main,
      element: (
        <AuthGuard>
          <Main />
        </AuthGuard>
      ),
      children: [
        {
          path: Routes.order,
          element: (
            <OrderResolver>
              <OrderForm />
            </OrderResolver>
          )
        },
        {
          path: Routes.newOrder,
          element: (
            <UserRoleGuard userRoles={['PartnerManager']}>
              <OrderForm />
            </UserRoleGuard>
          )
        },
        {
          path: Routes.sales,
          element: (
            <UserRoleGuard userRoles={['PartnerCommercialDirector', 'PartnerHeadOfDepartment']}>
              <Sales />
            </UserRoleGuard>
          )
        },
        {
          path: Routes.statistics,
          element: (
            <UserRoleGuard userRoles={['PartnerCommercialDirector', 'PartnerHeadOfDepartment']}>
              <Statistics />
            </UserRoleGuard>
          )
        },
        {
          path: Routes.ordersList,
          element: (
            <UserRoleGuard userRoles={['PartnerManager']}>
              <OrdersList />
            </UserRoleGuard>
          )
        },
        {
          path: Routes.orders,
          element: (
            <UserRoleGuard userRoles={['PartnerCommercialDirector', 'PartnerHeadOfDepartment']}>
              <OrdersSalesList />
            </UserRoleGuard>
          )
        },
        {
          path: Routes.payments,
          element: (
            <UserRoleGuard userRoles={['PartnerCommercialDirector', 'PartnerHeadOfDepartment']}>
              <Payments />
            </UserRoleGuard>
          )
        },
        {
          path: Routes.bills,
          element: (
            <UserRoleGuard userRoles={['PartnerCommercialDirector', 'PartnerHeadOfDepartment']}>
              <Bills />
            </UserRoleGuard>
          )
        },
        {
          path: Routes.partners,
          element: (
            <UserRoleGuard userRoles={['ExchangeUser']}>
              <Partners />
            </UserRoleGuard>
          )
        },
        {
          path: Routes.salesDepartments,
          element: (
            <UserRoleGuard userRoles={['PartnerAdmin']}>
              <SalesDepartments />
            </UserRoleGuard>
          )
        },
        {
          path: Routes.employees,
          element: (
            <UserRoleGuard userRoles={['PartnerAdmin']}>
              <Employees />
            </UserRoleGuard>
          )
        },
        {
          path: Routes.projects,
          element: (
            <UserRoleGuard userRoles={['PartnerAdmin']}>
              <Projects />
            </UserRoleGuard>
          )
        },
        {
          path: Routes.salesOffices,
          element: (
            <UserRoleGuard userRoles={['PartnerAdmin']}>
              <SalesOffices />
            </UserRoleGuard>
          )
        }
      ]
    },
    {
      path: Routes.login,
      element: <Login />
    },
    {
      path: Routes.changePassword,
      element: <ChangePassword />
    }
  ]);
};
