import { FC } from 'react';
import { ProjectCardProps } from './model';
import {
  Badge,
  BadgeColors,
  Button,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconSize,
  Title,
  TitleVariant,
  WithLoader
} from '@/ui';
import { ArrowDown, EditIcon } from '@/assets/icons';
import './styles.scss';
import { Row, Col } from 'antd';
import clsx from 'clsx';
import { BuildingObjectCard } from '@/app/widgets';
import { buildingObjectSort } from '@/utils/formatters';

export const ProjectCardView: FC<ProjectCardProps> = (props) => {
  const { onClickEdit, isCollapsed, onClickCollapse, project } = props;

  const hasBuildingObjects = project.buildingObjects.length > 0;
  const hasSalesDepartments = project.salesDepartments.length > 0;

  return (
    <WithLoader isLoading={false} className="project-card-loader">
      <div className="project-card">
        <Row className="project-card__header" justify="space-between">
          <Col>
            <Row gutter={24} align="middle">
              <Col>
                <div className="project-card__title">
                  <Title level={3} variant={TitleVariant.normal}>
                    {project.name}
                  </Title>
                </div>
              </Col>
              <Col>
                <Button onClick={onClickEdit} variant={ButtonVariant.icon} size={ButtonSize.small}>
                  <Icon size={IconSize.md} icon={<EditIcon />} />
                </Button>
              </Col>
            </Row>
          </Col>
          <Col>
            <Button
              size={ButtonSize.small}
              variant={ButtonVariant.icon}
              onClick={onClickCollapse}
              className={clsx('project-card__collapse-button', { active: isCollapsed })}
            >
              <Icon size={IconSize.xl} icon={<ArrowDown />} />
            </Button>
          </Col>
        </Row>
        {isCollapsed && (
          <>
            <Row>
              <Col>
                <div className="project-card__info">
                  <Badge
                    color={project.isActive ? BadgeColors.main : BadgeColors.grifel}
                    text={project.isActive ? 'Активен' : 'Не активен'}
                  />
                  <span>{project.city}</span>
                </div>
              </Col>
            </Row>
            {hasSalesDepartments && (
              <Row className="project-card__body" gutter={8} align="middle">
                <Col style={{ marginBottom: 8 }}>Отделы продаж:</Col>
                {project.salesDepartments.map((department) => (
                  <Col style={{ marginBottom: 8 }} key={department.id}>
                    <Badge text={department.name} color={BadgeColors.storm12} />
                  </Col>
                ))}
              </Row>
            )}
            {hasBuildingObjects && (
              <Row className="project-card__body" gutter={16}>
                {buildingObjectSort(project.buildingObjects).map((building) => (
                  <Col span={8} style={{ marginBottom: 16 }} key={building.id}>
                    <BuildingObjectCard
                      name={building.name}
                      companyName={building.companyName}
                      onOpenBuildingDetails={() => null}
                      completionDatePlan={building.completionDatePlan}
                    />
                  </Col>
                ))}
              </Row>
            )}
          </>
        )}
      </div>
    </WithLoader>
  );
};
