import { DebtRepaymentScheduleViewProps } from './model';
import { Title, Flex, WithLoader, Label, LabelVariant } from '@/ui';

import { Divider, Row, Col } from 'antd';

import { formatMoney } from '@/utils/formatters';

import './styles.scss';
import { Sizes } from '@/theme';

import { HookField } from '@/app/widgets/HookField';

import { PaymentRow, PaymentRowVariant } from '../PaymentRow';

const formName = 'DebtRepaymentSchedule';

export const DebtRepaymentScheduleView = ({
  isLoading,
  items,
  monthOptions,
  projectsMap,
  amountValues
}: DebtRepaymentScheduleViewProps) => (
  <WithLoader isLoading={isLoading} className="debt-repayment-loader">
    <Flex direction="column" align="flex-start" fullWidth className="debt-repayment-schedule">
      <Title level={3}>График гашения обязательств</Title>

      <form id={formName} name={formName} style={{ width: '100%' }}>
        <Row>
          <Col span={6}>
            <HookField
              name="monthStartDate"
              type="datePicker"
              label="Начало периода"
              placeholder="Начало периода"
              picker="month"
              format="MMMM YYYY"
              allowClear={false}
            />
          </Col>

          <Divider style={{ marginBlock: Sizes.marginXL }} />

          <Col>
            <HookField name="currentMonth" type="chip" options={monthOptions} />
          </Col>
        </Row>
      </form>

      <Flex direction="row" align="flex-start" fullWidth className="debt-info">
        <Title level={4}>{formatMoney(amountValues.amount)} ₽</Title>

        <Divider type="vertical" style={{ height: Sizes.lineHeightH4px }} />

        <Flex align="baseline" className="debt-info-col">
          <Label value="Погашено" variant={LabelVariant.black48} level={4} />
          <Label value={`${formatMoney(amountValues.repaidAmount)} ₽`} variant={LabelVariant.black84} level={3} />
        </Flex>

        <Divider type="vertical" style={{ height: Sizes.lineHeightH4px }} />

        <Flex align="baseline" className="debt-info-col">
          <Label value="Остаток" variant={LabelVariant.black48} level={4} />
          <Label
            value={`${formatMoney(amountValues.repaymentBalanceAmount)} ₽`}
            variant={LabelVariant.black84}
            level={3}
          />
        </Flex>
      </Flex>

      {items?.map((item) => (
        <Flex
          direction="column"
          key={`${item.projectId}-${item.house}`}
          fullWidth
          className="debt-repayment-schedule-list"
        >
          <PaymentRow
            title={`${projectsMap[item.projectId]}  |  Дом ${item.house}`}
            amount={item.amount}
            variant={PaymentRowVariant.header}
          />

          <PaymentRow title="Погашено" amount={item.repaidAmount} />
          <PaymentRow title="Остаток" amount={item.repaymentBalanceAmount} />
          <PaymentRow title="Просрочено" amount={item.overdueAmount} />
        </Flex>
      ))}
    </Flex>
  </WithLoader>
);
