import { FC } from 'react';
import { HeaderTimezoneWidgetView } from './view';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useUsersControllerSetTimezoneMutation } from '@/api/web.client/webClientGeneratedApi';

export const HeaderTimezoneWidget: FC = () => {
  const { currentUser, timeZone } = useCurrentUser();

  const [setTimezone, { isLoading }] = useUsersControllerSetTimezoneMutation();

  const value = timeZone;
  const isSuggestionActive = typeof currentUser?.timeZone !== 'number';

  return HeaderTimezoneWidgetView({
    value,
    isLoading,
    isSuggestionActive,
    onChange: async (val) => {
      try {
        await setTimezone({
          userTimezoneRequest: {
            timeZone: val
          }
        }).unwrap();
      } catch {
        // do nothing
      }
    }
  });
};
