import { useState, useLayoutEffect, useEffect, useRef, RefObject } from 'react';
import { throttle } from 'lodash';

export enum DropdownPosition {
  top = 'top',
  bottom = 'bottom'
}

interface UseDropdownPositionProps {
  wrapperRef: RefObject<HTMLDivElement>;
  dropDownClass: string;
}

export const useDropdownPosition = ({
  wrapperRef,
  dropDownClass
}: UseDropdownPositionProps): [DropdownPosition | null, (open: boolean) => void] => {
  const [dropdownVisibility, setDropdownVisibility] = useState(false);

  const observerRef = useRef<MutationObserver | null>(null);

  const [position, setPosition] = useState<DropdownPosition | null>(null);

  useLayoutEffect(() => {
    if (observerRef.current !== null || !dropdownVisibility) {
      return;
    }

    const callback = (mutationList: any) => {
      for (const mutation of mutationList) {
        if (mutation.target.style.pointerEvents === 'none') {
          setPosition(null);

          return;
        }

        const dropdownRect: DOMRect = mutation.target.getBoundingClientRect();

        if (dropdownRect.y < 0 || !wrapperRef.current) {
          return;
        }

        setPosition(
          dropdownRect.y < wrapperRef.current.getBoundingClientRect().y ? DropdownPosition.top : DropdownPosition.bottom
        );
      }
    };

    const dropdownNode = document.getElementsByClassName(dropDownClass)[0];

    if (!dropdownNode) {
      return;
    }

    observerRef.current = new MutationObserver(throttle(callback, 100));

    observerRef.current.observe(dropdownNode, {
      attributes: true,
      attributeFilter: ['style'],
      attributeOldValue: true
    });
  }, [dropDownClass, dropdownVisibility, wrapperRef]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      observerRef.current?.disconnect();
    };
  }, []);

  return [dropdownVisibility ? position : null, setDropdownVisibility];
};
