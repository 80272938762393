import { DateFormats, formatDateInTimeZone } from './dateFormatters';

const formatDateKey = (value: string, format: string, userTimeZone?: number): string => {
  if (typeof userTimeZone !== 'number') {
    return value;
  }
  return formatDateInTimeZone(value, userTimeZone, format);
};

export const applyStringParams = (string: string, params: any, userTimeZone = 300) => {
  if (!params) {
    return string;
  }
  let formattedString = string;
  for (const key in params) {
    let formattedValue = params[key];
    if (key.endsWith('Date')) {
      formattedValue = formatDateKey(params[key], DateFormats.dateTime, userTimeZone);
    }
    if (key.endsWith('Time')) {
      formattedValue = formatDateKey(params[key], DateFormats.time, userTimeZone);
    }
    formattedString = formattedString.replace(new RegExp(`{${key}}`, 'gi'), formattedValue);
  }
  return formattedString;
};
