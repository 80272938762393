import { Modal } from '@/ui';

import { HookField } from '@/app/widgets/HookField';

import { ScheduleMeetingModalViewProps } from './model';

import { CalendarIcon } from '@/assets/icons';
import { MeetingPlaceLabel } from './components/MeetingPlaceLabel';
import { Space } from 'antd';

const formName = 'ScheduleMeeting';

export const ScheduleMeetingModalView = ({
  onSubmit,
  onClickDate,
  isLoading,
  offices,
  ...props
}: ScheduleMeetingModalViewProps) => (
  <Modal
    {...props}
    title="Встреча трёх сторон"
    titleLevel={3}
    okText="Сохранить"
    cancelText="Отмена"
    submitButtonAttrs={{ form: formName, type: 'submit', loading: isLoading }}
  >
    <form id={formName} name={formName} onSubmit={onSubmit}>
      <Space direction="vertical" size={40} style={{ alignItems: 'stretch', width: '100%' }}>
        <div>
          <HookField
            name="meetingDate"
            type="text"
            label="Дата и время встречи"
            placeholder="Дата и время встречи"
            suffix={<CalendarIcon onClick={onClickDate} />}
            onClick={onClickDate}
            onInput={onClickDate}
          />
        </div>
        <div>
          <HookField
            showSearch
            type="select"
            name="salesOfficeId"
            label="Место встречи"
            options={[
              {
                value: 0,
                label: 'Онлайн встреча'
              },
              ...offices.map((item) => ({
                value: item.id,
                label: <MeetingPlaceLabel name={item.name} address={item.address} />
              }))
            ]}
            filterOption={(input, option) => {
              return (
                ((option?.children as any).props.name.toString() ?? '')?.toLowerCase()?.includes(input.toLowerCase()) ||
                ((option?.children as any).props.address.toString() ?? '')?.toLowerCase()?.includes(input.toLowerCase())
              );
            }}
          />
        </div>
      </Space>
    </form>
  </Modal>
);
