import { useForm } from 'react-hook-form';
import { StatisticsView } from './view';
import { StatsData, StatsProps } from './model';
import { useCurrentUser } from '@/app/hooks/useCurrentUser';
import { getZonedStartOfDay, getZonedEndOfDay } from '@/utils/formatters';
import {
  useCorporateGroupControllerGetMyQuery,
  useReportsControllerGetPaymentReportQuery
} from '@/api/web.client/webClientGeneratedApi';
import dayjs from 'dayjs';
import { keyBy } from 'lodash';

export const Statistics = () => {
  const { timeZone } = useCurrentUser();

  const form = useForm<StatsData>({
    defaultValues: {
      dates: {}
    }
  });

  const startDateValidation = (current: dayjs.Dayjs) => {
    const currentDate = getZonedStartOfDay(new Date(), timeZone);
    const dayAfter = currentDate.setDate(currentDate.getDate() + 1);
    return current.isAfter(dayAfter);
  };

  const endDateValidation = (current: dayjs.Dayjs) => {
    const date = currentDates.start ? new Date(currentDates.start) : new Date();
    const currentDate = getZonedEndOfDay(date, timeZone);

    const dayBefore = currentDate.setDate(currentDate.getDate() - 1);
    return current.isBefore(dayBefore);
  };

  const { watch } = form;

  const currentDates = watch('dates');

  const dateFrom = currentDates.start ? getZonedStartOfDay(currentDates.start, timeZone).toISOString() : '';
  const dateTo = currentDates.end ? getZonedEndOfDay(currentDates.end, timeZone).toISOString() : '';

  const filtersNotActive = !dateFrom || !dateTo;

  const { data: reportsResponse, isFetching: reportIsLoading } = useReportsControllerGetPaymentReportQuery(
    { dateFrom, dateTo },
    { skip: filtersNotActive, refetchOnMountOrArgChange: true }
  );

  const items = filtersNotActive ? undefined : reportsResponse?.items;

  const { data: corporateGroup, isFetching: projectsIsLoading } = useCorporateGroupControllerGetMyQuery(undefined, {
    skip: filtersNotActive,
    refetchOnMountOrArgChange: true
  });

  const projects = corporateGroup?.projects ?? [];

  const projectById = keyBy(projects, (project) => project.id);

  const props: StatsProps = {
    form,
    data: items,
    projectById,
    endDateValidation,
    startDateValidation,

    projectsIsLoading,
    reportIsLoading
  };
  return <StatisticsView {...props} />;
};
