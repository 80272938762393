import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  SalesOfficeControllerGetApiArg,
  useSalesOfficeControllerGetQuery
} from '@/api/web.client/webClientGeneratedApi';
import { SalesOfficesView } from './view';
import { DefaultOptionType } from 'antd/es/select';

const defaultValues: SalesOfficeControllerGetApiArg = {
  cities: [],
  search: ''
};

export const SalesOffices: FC = () => {
  const [selectedOfficeId, setSelectedOfficeId] = useState<number | undefined>(undefined);
  const [isNewOfficeOpen, setIsNewOfficeOpen] = useState<boolean>(false);

  const form = useForm<SalesOfficeControllerGetApiArg>({
    defaultValues
  });

  const formData = form.watch();

  const { data: salesOffices } = useSalesOfficeControllerGetQuery(formData);

  // TODO: заменить на ручку получения всез городов из бд
  // В таком виде не работает при инвалидации
  const [dataLoaded, setDataLoaded] = useState(false);
  const [citiesOptions, setCitiesOptions] = useState<DefaultOptionType[]>([]);

  useEffect(() => {
    if (dataLoaded) {
      return;
    }

    if (salesOffices) {
      setDataLoaded(true);

      const citiesOptions = salesOffices
        .filter((office, i, self) => i === self.findIndex((found) => found.city === office.city))
        .map((office) => ({
          value: office.city,
          label: office.city
        }));

      setCitiesOptions(citiesOptions);
    }
  }, [salesOffices, dataLoaded]);

  return SalesOfficesView({
    form,
    citiesOptions,
    selectedOfficeId,
    isNewOfficeOpen,
    salesOffices: salesOffices ?? [],
    onReset(): void {
      form.reset(defaultValues);
    },
    onNewOffice(): void {
      setIsNewOfficeOpen(true);
    },
    onCloseNewOffice(): void {
      setIsNewOfficeOpen(false);
    },
    onDetailsOffice(officeId): void {
      setSelectedOfficeId(officeId);
    }
  });
};
