import { ReactNode } from 'react';
import { Typography } from 'antd';

import './Description.scss';
import clsx from 'clsx';

export interface DescriptionProps {
  children: ReactNode;
  status?: 'warning' | 'error' | '';
}

export const Description = ({ status = '', children }: DescriptionProps) => {
  const className = clsx('description', status && `${status}`);

  return <Typography.Text className={className}>{children}</Typography.Text>;
};
