import { MeResponse, useUsersControllerMeQuery } from '@/api/web.client/webClientGeneratedApi';

export type UserRole = MeResponse['roles'][number];

export const useCurrentUser = () => {
  const {
    data: currentUser,
    isLoading: isCurrentUserLoading,
    error: currentUserError
  } = useUsersControllerMeQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  });

  return {
    currentUser,
    currentUserError,
    isCurrentUserLoading,
    timeZone: currentUser?.timeZone ?? -new Date().getTimezoneOffset(),
    hasRole: (role: UserRole) => {
      return currentUser?.roles?.includes(role);
    }
  };
};
