import clsx from 'clsx';
import './Badge.scss';

export enum BadgeColors {
  main = 'main',
  yellow = 'yellow',
  grifel = 'grifel',
  storm12 = 'storm12'
}

export interface BadgeProps {
  text: string;
  color?: BadgeColors;
}

export const Badge = ({ text, color = BadgeColors.main }: BadgeProps) => {
  const badgeClassName = clsx('badge', color || '');

  return <div className={badgeClassName}>{text}</div>;
};
