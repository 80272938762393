export enum PaymentRowVariant {
  default = 'default',
  header = 'header'
}

export interface PaymentRowProps {
  title: string;
  amount: number;
  variant?: PaymentRowVariant;
}

export interface PaymentRowViewProps {
  title: string;
  amount: string;
  variant?: PaymentRowVariant;
}
