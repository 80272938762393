import { SalesView } from './view';
import { SalesProps, SalesData } from './model';
import { useForm } from 'react-hook-form';
import { useUserCorporateGroupProjects } from '@/app/hooks/useUserCorporateGroupProjects';
import { useFunnelReport } from '@/app/hooks/useFunnelReport';
import { useCurrentUser } from '@/app/hooks/useCurrentUser';
import { getZonedEndOfDay, getZonedStartOfDay } from '@/utils/formatters';
import { useFunnelRejections } from '@/app/hooks/useFunnelRejections';
import { toProjectOptions } from './utils/toProjectOptions';
import { DefaultOptionType } from 'antd/es/select';
import { useRef } from 'react';

export const Sales = () => {
  const optionsDefaultSet = useRef(false);
  const { projects, isLoading: projectsIsLoading } = useUserCorporateGroupProjects();
  const projectsOptions: DefaultOptionType[] = toProjectOptions(projects);
  const { timeZone } = useCurrentUser();

  const form = useForm<SalesData>({
    defaultValues: {
      dates: {},
      projects: []
    }
  });

  const { setValue, reset, watch } = form;

  if (!projectsIsLoading && projectsOptions.length !== 0 && !optionsDefaultSet.current) {
    optionsDefaultSet.current = true;
    setValue(
      'projects',
      projectsOptions.map((option) => Number(option.value))
    );
  }

  const handleReset = () => {
    reset({
      projects: projectsOptions?.map((option) => Number(option.value)),
      dates: {}
    });
  };

  const currentProjects = watch('projects');
  const currentDates = watch('dates');

  const dateFrom = currentDates.start ? getZonedStartOfDay(currentDates.start, timeZone).toISOString() : undefined;
  const dateTo = currentDates.end ? getZonedEndOfDay(currentDates.end, timeZone).toISOString() : undefined;

  const defaultQuery = {
    dateFrom,
    dateTo,
    projectIds: currentProjects
  };

  const { sales, isLoading: salesIsLoading } = useFunnelReport(defaultQuery);
  const { rejections, isLoading: rejectionsIsLoading } = useFunnelRejections(defaultQuery);

  const props: SalesProps = {
    form,
    projectsOptions,
    sales,
    rejections,
    salesIsLoading,
    rejectionsIsLoading,
    projectsIsLoading,

    onReset: handleReset
  };

  return <SalesView {...props} />;
};
