import { forwardRef } from 'react';
import { Input } from 'antd';
import type { TextAreaProps as AntdTextAreaProps } from 'antd/es/input';

import { InputWrapper, InputWrapperProps } from '@/ui/Inputs/InputWrapper/InputWrapper';

import './TextArea.scss';

export interface TextAreaProps extends AntdTextAreaProps, InputWrapperProps {}

export const TextArea = forwardRef<HTMLInputElement, TextAreaProps>(
  ({ label, description, readOnly, ...props }, ref) => (
    <InputWrapper label={label} description={description} status={props.status} readOnly={readOnly}>
      <Input.TextArea {...props} ref={ref} className="textarea" />
    </InputWrapper>
  )
);
