import { Row, Col } from 'antd';
import { Title, Icon, IconSize } from '@/ui';

import { Colors } from '@/theme/themeVariables';

import { CheckboxCircleFilled } from '@/assets/icons';
import { HookField } from '@/app/widgets/HookField';

import { OrderFormMode } from '../../../../model';

import { ApartmentsForBuyProps } from './model';

export const ApartmentsForBuyView = ({ mode, projectsOptions }: ApartmentsForBuyProps) => {
  return (
    <>
      <Title level={3}>
        <Icon
          icon={<CheckboxCircleFilled />}
          size={IconSize.xl}
          color={mode === OrderFormMode.readonly ? Colors.lime : Colors.storm6}
        />
        <>Приобретаемая квартира</>
      </Title>

      {/* "todo: сделать поиск с автокомплитом для селекта проектов" */}
      <Row style={{ marginBottom: 32 }} gutter={16}>
        <Col span={12}>
          <HookField
            name="projectId"
            type="select"
            label="Проект"
            placeholder="Не выбран"
            options={projectsOptions}
            readOnly={mode === OrderFormMode.readonly}
            disabled={projectsOptions.length === 0}
            showSearch
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) => {
              return (option?.children?.toString() ?? '')?.toLowerCase()?.includes(input.toLowerCase());
            }}
          />
        </Col>

        <Col span={12}>
          <HookField
            name="house"
            type="text"
            label="Дом"
            placeholder="Дом"
            readOnly={mode === OrderFormMode.readonly}
          />
        </Col>
      </Row>

      <Row style={{ marginBottom: 32 }} gutter={16}>
        <Col span={12}>
          <HookField
            name="totalArea"
            type="number"
            label="Общая площадь квартиры"
            placeholder="0"
            readOnly={mode === OrderFormMode.readonly}
            min={0}
            decimalScale={1}
            valueSuffix={`м\u00B2`}
          />
        </Col>

        <Col span={12}>
          <HookField
            name="price"
            type="number"
            label="Стоимость квартиры"
            placeholder="0"
            decimalScale={1}
            min={0}
            valueSuffix="₽"
            readOnly={mode === OrderFormMode.readonly}
          />
        </Col>
      </Row>

      {mode === OrderFormMode.readonly && (
        <Row style={{ marginBottom: 32 }} gutter={16}>
          <Col span={12}>
            <HookField
              name="manager.name"
              type="text"
              label="ФИО менеджера"
              placeholder="ФИО"
              readOnly={mode === OrderFormMode.readonly}
            />
          </Col>

          <Col span={12}>
            <HookField name="manager.phone" type="phone" label="Телефон" readOnly={mode === OrderFormMode.readonly} />
          </Col>
        </Row>
      )}
    </>
  );
};
