import { useOrderStatusesEnum } from '@/app/hooks/useOrderStatusEnum';
import { useCurrentUser } from '@/app/hooks/useCurrentUser';

import { formatDateInTimeZone, DateFormats } from '@/utils/formatters';

import { ThreePartiesMeetingCardProps } from './model';

import { ThreePartiesMeetingCardView } from './view';
import { useMeetingResultsEnum } from '../../hooks/useMeetingResultsEnum';

export const ThreePartiesMeetingCard = ({
  status,
  meeting,
  isScheduleMeetingAvailable,
  onClickSchedule,
  onClickReschedule,
  onShowMeetingResults
}: ThreePartiesMeetingCardProps) => {
  const orderStatusesEnum = useOrderStatusesEnum();
  const meetingResultEnum = useMeetingResultsEnum();
  const { timeZone } = useCurrentUser();

  const showStatuses: Array<number | undefined> = [
    orderStatusesEnum?.MeetingOfThreeParties,
    orderStatusesEnum?.WaitingForADecision,
    orderStatusesEnum?.Reassessment,
    orderStatusesEnum?.Grade
  ];

  const showResultButtonStatuses: Array<number | undefined> = [
    orderStatusesEnum?.MeetingOfThreeParties,
    orderStatusesEnum?.WaitingForADecision
  ];

  const showResultButton = showResultButtonStatuses.includes(status);

  if (
    (meeting?.result &&
      [meetingResultEnum.ClientAgrees, meetingResultEnum.Reassessment, meetingResultEnum.Refusal].includes(
        meeting.result
      )) ||
    !showStatuses.includes(status) ||
    (!isScheduleMeetingAvailable &&
      !showResultButton &&
      !meeting?.dateTimeStart &&
      !(meeting?.waiting && meeting?.waitingDate))
  ) {
    return null;
  }

  return (
    <ThreePartiesMeetingCardView
      showScheduleMeetingButton={isScheduleMeetingAvailable}
      showResultButton={showResultButton}
      showRescheduleMeetingButton={status === orderStatusesEnum?.MeetingOfThreeParties}
      meetingDateTime={
        status === orderStatusesEnum?.MeetingOfThreeParties && meeting?.dateTimeStart
          ? `Дата встречи: ${formatDateInTimeZone(meeting.dateTimeStart, timeZone, DateFormats.dateTime)}`
          : null
      }
      meetingWaitingDate={
        meeting?.result === meetingResultEnum.WaitingDecision && meeting?.waitingDate && meeting.waiting
          ? `Ожидание решения до  ${formatDateInTimeZone(meeting.waitingDate, timeZone, DateFormats.dateTime)}`
          : null
      }
      onClickSchedule={onClickSchedule}
      onClickReschedule={onClickReschedule}
      onShowMeetingResults={onShowMeetingResults}
    />
  );
};
