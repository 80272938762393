import { Col, Row } from 'antd';
import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { AddIcon, CloseIcon, SearchIcon } from '@/assets/icons';
import { Button, ButtonSize, ButtonVariant, Icon, Title } from '@/ui';
import { HookField } from '@/app/widgets/HookField';
import { PartnerCard } from '@/app/widgets/PartnerCard';
import { CorporateGroupDetails } from './components/CorporateGroupDetails';
import { CorporateGroupForm } from './components/CorporateGroupForm';
import { PartnersProps } from './model';

export const PartnersView: FC<PartnersProps> = (props) => {
  const {
    form,
    onReset,
    onEditCorporateGroup,
    onNewCorporateGroup,
    selectedCorporateGroupId,
    onSelectCorporateGroup,
    onCloseCorporateGroupForm,
    editCorporateGroupId,
    corporateGroups,
    isCreateFormOpen,
    onCloseCreateForm,
    isLoading
  } = props;

  return (
    <>
      <Row justify="center" align="top">
        <Col span={24} className="content-centered-container">
          <Row align="top" justify="space-between" gutter={16}>
            <Col span={12}>
              <Title>Партнеры</Title>
            </Col>

            <Col span={5}>
              <Row justify="end" align="middle">
                <Col span={24}>
                  <Button onClick={onNewCorporateGroup} isWide size={ButtonSize.medium} prefixIcon={<AddIcon />}>
                    Добавить партнера
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <FormProvider {...form}>
            <form name="PartnersFilters">
              <Row align="bottom" gutter={16} style={{ marginBottom: 24 }}>
                <Col span={11}>
                  <HookField
                    isWhite
                    type="text"
                    name="search"
                    label="Поиск"
                    placeholder="Название партнера или юр. лица"
                    suffix={<Icon icon={<SearchIcon />} />}
                  />
                </Col>

                <Col span={5}>
                  <Button
                    isWide
                    isWhite
                    type="button"
                    onClick={onReset}
                    prefixIcon={<CloseIcon />}
                    variant={ButtonVariant.secondary}
                  >
                    Сбросить фильтры
                  </Button>
                </Col>
              </Row>
            </form>
          </FormProvider>

          <Row>
            {corporateGroups.map((corporateGroup) => (
              <Col span={24} style={{ marginBlock: 8 }} key={corporateGroup.id}>
                <PartnerCard
                  isLoading={isLoading}
                  corporateGroup={corporateGroup}
                  onClickEdit={() => onEditCorporateGroup(corporateGroup.id)}
                  isCollapsed={selectedCorporateGroupId === corporateGroup.id}
                  onClickCollapse={() => onSelectCorporateGroup(corporateGroup.id)}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      {editCorporateGroupId && (
        <CorporateGroupDetails corporateGroupId={editCorporateGroupId} onClose={onCloseCorporateGroupForm} />
      )}
      {isCreateFormOpen && <CorporateGroupForm onClose={onCloseCreateForm} />}
    </>
  );
};
