import { FC, useEffect } from 'react';
import { ProjectDetailsProps } from './model';
import { ProjectForm } from '../ProjectForm';
import { SubmitHandler } from 'react-hook-form';
import {
  ProjectRequest,
  useProjectsControllerGetProjectQuery,
  useProjectsControllerUpdateProjectMutation
} from '@/api/web.client/webClientGeneratedApi';
import { useCurrentUser } from '@/app/hooks/useCurrentUser';
import { skipToken } from '@reduxjs/toolkit/query';
import toast from 'react-hot-toast';
import { WithLoader } from '@/ui';
import { useNavigate } from 'react-router-dom';
import { Routes } from '@/app/routing';
import { buildingObjectSort } from '@/utils/formatters';

export const ProjectDetails: FC<ProjectDetailsProps> = (props) => {
  const { projectId, onClose, openAddBuildingObject, setCurrentBuildingObject } = props;

  const navigate = useNavigate();

  const { currentUser } = useCurrentUser();

  const corporateGroupId = currentUser?.corporateGroupId;

  const { data: project, isLoading } = useProjectsControllerGetProjectQuery(
    corporateGroupId
      ? {
          corporateGroupId,
          projectId
        }
      : skipToken
  );

  const [updateProject] = useProjectsControllerUpdateProjectMutation();

  const buildingObjects = buildingObjectSort(project?.buildingObjects ?? []);

  useEffect(() => {
    if (!project && !isLoading) {
      toast.error('Проект не найден');
      navigate(Routes.projects);
    }
  }, [project, isLoading, navigate]);

  const onSubmit: SubmitHandler<ProjectRequest> = async (projectRequest) => {
    if (!corporateGroupId) {
      return;
    }
    try {
      await updateProject({
        corporateGroupId,
        projectId,
        projectRequest
      }).unwrap();
      onClose();
      toast.success('Проект успешно обновлен');
    } catch {
      // do nothing
    }
  };

  return (
    <WithLoader isLoading={!project || isLoading} className="order-form-loader">
      <ProjectForm
        openAddBuildingObject={openAddBuildingObject}
        onSubmit={onSubmit}
        onClose={onClose}
        project={project}
        buildingObjects={buildingObjects}
        setCurrentBuildingObject={setCurrentBuildingObject}
      />
    </WithLoader>
  );
};
