import { FC } from 'react';
import { useSalesOfficeControllerUpdateSalesOfficeMutation } from '@/api/web.client/webClientGeneratedApi';
import { mapSalesOfficeFormDataToUpdateRequest } from '../officeForm/mapper';
import toast from 'react-hot-toast';
import { SalesOfficeDetailsProps } from './model';
import { useCurrentUser } from '@/app/hooks/useCurrentUser';
import { SalesOfficeFormData } from '../officeForm/model';
import { OfficeForm } from '../officeForm';

export const SalesOfficeDetails: FC<SalesOfficeDetailsProps> = ({ salesOfficeId, onClose, salesOffice }) => {
  const [updateSalesOffice] = useSalesOfficeControllerUpdateSalesOfficeMutation();

  const { currentUser } = useCurrentUser();

  const corporateGroupId = currentUser?.corporateGroupId;
  const onSubmit = async (data: SalesOfficeFormData) => {
    if (!corporateGroupId) {
      return;
    }
    try {
      const salesOfficeUpdateRequest = mapSalesOfficeFormDataToUpdateRequest(data);
      await updateSalesOffice({
        salesOfficeUpdateRequest,
        corporateGroupId,
        salesOfficeId
      }).unwrap();
      onClose();
      toast.success('Офис продаж успешно изменен');
    } catch {
      // do nothing
    }
  };

  return <OfficeForm salesOffice={salesOffice} onClose={onClose} onSubmit={onSubmit} />;
};
