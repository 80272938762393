import { AddEmployeeBlockViewProps } from './model';

import { Button, ButtonSize, ButtonVariant, Select } from '@/ui';
import { Col } from 'antd';

import { AddIcon } from '@/assets/icons';
import { Sizes } from '@/theme';

export const AddEmployeeBlockView = ({
  isShowAddSelect,
  employeesOptions,
  employeeSelectValue,

  onAddUser,
  onClickAddButton
}: AddEmployeeBlockViewProps) => {
  return isShowAddSelect ? (
    <Col span={24} style={{ marginBlock: Sizes.marginSM }}>
      <Select
        label="Сотрудник"
        placeholder="Выберите сотрудника"
        options={employeesOptions}
        value={employeeSelectValue}
        onChange={onAddUser}
      />
    </Col>
  ) : (
    <Col style={{ marginBlock: Sizes.marginSM }}>
      <Button
        variant={ButtonVariant.secondary}
        size={ButtonSize.small}
        prefixIcon={<AddIcon />}
        type="button"
        onClick={onClickAddButton}
      >
        Добавить участника
      </Button>
    </Col>
  );
};
