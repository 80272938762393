import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useOrdersControllerGetOrdersQuery } from '@/api/web.client/webClientGeneratedApi';

import { useOrderStatuses } from '@/app/hooks/useOrderStatuses';
import { useNecessaryActions } from '@/app/hooks/useNecessaryActions';

import { Routes } from '@/app/routing';
import { useSalesDepartmentOptions } from '@/app/hooks/useSalesDepartmentOptions';

import { OrdersSalesView } from './view';

import type { OrdersSalesListProps, OrdersSalesListData } from './model';

import { getOrderCounts } from './helpers';

import { getZonedStartOfDay, getZonedEndOfDay } from '@/utils/formatters';
import { useCurrentUser } from '../../hooks/useCurrentUser';

const initialData: OrdersSalesListData = {
  dates: {},
  statuses: [],
  necessaryActions: [],
  salesDepartments: []
};

export const OrdersSalesList = () => {
  const navigate = useNavigate();

  const { timeZone } = useCurrentUser();

  const form = useForm<OrdersSalesListData>({
    defaultValues: initialData
  });

  const { reset, watch, setValue } = form;

  const currentDates = watch('dates');
  const currentStatuses = watch('statuses');
  const currentActions = watch('necessaryActions');
  const currentSalesDepartments = watch('salesDepartments');

  const statusOptions = useOrderStatuses();

  useEffect(() => {
    if (statusOptions.length === 0) {
      return;
    }

    setValue(
      'statuses',
      statusOptions.map((option) => Number(option.value))
    );
  }, [setValue, statusOptions]);

  const necessaryActionsOptions = useNecessaryActions();

  useEffect(() => {
    if (necessaryActionsOptions.length === 0) {
      return;
    }

    setValue(
      'necessaryActions',
      necessaryActionsOptions.map((option) => String(option.value))
    );
  }, [setValue, necessaryActionsOptions]);

  const salesDepartmentsOptions = useSalesDepartmentOptions();

  useEffect(() => {
    if (salesDepartmentsOptions.length === 0) {
      return;
    }

    setValue(
      'salesDepartments',
      salesDepartmentsOptions.map((option) => Number(option.value))
    );
  }, [salesDepartmentsOptions, setValue]);

  const ordersQuery = useOrdersControllerGetOrdersQuery({
    dateFrom: currentDates.start ? getZonedStartOfDay(currentDates.start, timeZone).toISOString() : undefined,
    dateTo: currentDates.end ? getZonedEndOfDay(currentDates.end, timeZone).toISOString() : undefined,
    statuses: currentStatuses.length === statusOptions.length ? [] : currentStatuses,
    necessaryActions: currentActions.length === necessaryActionsOptions.length ? [] : currentActions,
    salesDepartmentIds: currentSalesDepartments
  });

  const orders = ordersQuery.data ?? [];

  const handleReset = () => {
    reset({
      dates: {},
      statuses: statusOptions.map((option) => Number(option.value)),
      necessaryActions: necessaryActionsOptions.map((option) => String(option.value)),
      salesDepartments: salesDepartmentsOptions.map((option) => Number(option.value))
    });
  };

  const handleCardClick = (id: string) => () => {
    navigate(`${Routes.orders}/${id}`);
  };

  const count = getOrderCounts(orders);

  const props: OrdersSalesListProps = {
    form,
    orders,
    statusOptions,
    necessaryActionsOptions,
    salesDepartmentsOptions,
    count,
    isLoading: ordersQuery.isLoading,

    onReset: handleReset,
    onCardClick: handleCardClick
  };

  return <OrdersSalesView {...props} />;
};
