import { Col, Row } from 'antd';
import { SalesGraphRowType } from '../../model';
import './styles.scss';
import classNames from 'classnames';

export const SalesGraphRowView = ({ percentage, length, title, opacity, count, highlighted }: SalesGraphRowType) => {
  return (
    <Row className="container">
      <Col span={7}>
        <Row justify="space-between" className="info" align="middle">
          <Col className="title">{title}</Col>
          <Col className="amount">{count}</Col>
        </Row>
      </Col>
      <Col style={{ height: '100%' }} span={17}>
        <Row className="line-container" style={{ width: `${length}%` }} justify="start" align="middle">
          <Col
            className={classNames('line', { highlighted: highlighted })}
            style={{ opacity: opacity }}
            span={21}
          ></Col>
          {percentage !== undefined && <Col className="percentage">{percentage}%</Col>}
        </Row>
      </Col>
    </Row>
  );
};
