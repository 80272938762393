import { ChangeEvent, forwardRef, useEffect, useId, useState, useRef } from 'react';

import { ArrowDropDownFilled } from '@/assets/icons';

import { Icon, IconSize } from '@/ui/Icon/Icon';
import { InfoCircleFilled } from '@ant-design/icons';

import { InputWrapper, InputWrapperProps } from '@/ui/Inputs/InputWrapper/InputWrapper';
import { Label, LabelVariant } from '@/ui/Typography/Label';
import { Flex } from '@/ui/Utility/Flex/Flex';

import { Select } from 'antd';
import clsx from 'clsx';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Option } from 'rc-select';

import { PatternFormat, PatternFormatProps } from 'react-number-format';
import { useCountries } from '@/app/hooks/useCountries';
import { phoneMask } from '@/utils/formatters/phoneMask';
import { useDropdownPosition } from '@/app/hooks/useDropdownPosition';

import './InputPhone.scss';

export interface InputPhoneProps extends Omit<PatternFormatProps, 'type' | 'format' | 'onChange'>, InputWrapperProps {
  open?: boolean;
  status?: 'error' | 'warning' | '';
  onChange?: (value: string) => void;
}

export const InputPhone = forwardRef<HTMLInputElement, InputPhoneProps>(
  ({ open, label, description, readOnly, ...props }, ref) => {
    const { countryKeys, countryByKey, baseCountryKey } = useCountries();
    const [country, setCountry] = useState<string>(baseCountryKey);
    const [menuOpen, setMenuOpen] = useState(false);
    const languageOptions = countryKeys;
    const countryConfig = countryByKey?.[country];

    const wrapperRef = useRef<HTMLDivElement>(null);
    const dropdownMenuClassId = useId();
    const [position, onVisibilityChange] = useDropdownPosition({
      wrapperRef,
      dropDownClass: dropdownMenuClassId
    });

    const maskedValue = (() => {
      if (props.value && country !== undefined && countryByKey?.[country]) {
        return phoneMask(props.value?.toString(), countryByKey[country].mask);
      }
      return props.value;
    })();

    const handleChangeCountry = (country: string) => {
      props.onChange?.('');
      setCountry(country);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const phone = parsePhoneNumberFromString(String(event.target.value));
      props.onChange?.(phone?.number ?? '');
    };

    useEffect(() => {
      const phone = parsePhoneNumberFromString(String(maskedValue));
      if (phone && phone.country !== undefined && phone.country !== country) {
        setCountry(phone.country as string);
      }
    }, [country, maskedValue]);

    const handleDropdownVisibleChange = (visible: boolean) => {
      setMenuOpen(visible);
      onVisibilityChange(visible);
    };

    const inputContainerClassName = clsx(
      'phone-input-container',
      props.status ?? '',
      readOnly && 'read-only',
      menuOpen && 'menu-open'
    );

    const selectClassName = clsx(
      'ant-select-customize-input ',
      'phone-select',
      props.status ?? '',
      readOnly && 'read-only'
    );

    const menuTopDivClassName = clsx('select-menu-div', position ?? '');

    const popupClassNme = clsx('select-country-popup', dropdownMenuClassId, position ?? '');

    if (!countryKeys.length) {
      return null;
    }

    return (
      <InputWrapper label={label} description={description} status={props.status} readOnly={readOnly}>
        <div className="phone-input-group" ref={wrapperRef}>
          <Select<string>
            className={selectClassName}
            popupClassName={popupClassNme}
            suffixIcon={<Icon icon={<ArrowDropDownFilled />} size={IconSize.md} />}
            onChange={handleChangeCountry}
            value={country}
            onDropdownVisibleChange={handleDropdownVisibleChange}
            open={open}
            optionLabelProp="labelProp"
          >
            {languageOptions.map((countryCode) => (
              <Option
                value={countryCode}
                key={countryCode}
                labelProp={<span className={clsx('country-icon', countryCode)} />}
              >
                <Flex justify="start" gap={10}>
                  <span className={clsx('country-icon', countryCode)} />

                  <Label value={countryByKey[countryCode].name} variant={LabelVariant.black} inline />
                  <Label value={countryByKey[countryCode].prefix} variant={LabelVariant.black48} inline />
                </Flex>
              </Option>
            ))}
          </Select>

          <div className={menuTopDivClassName} />

          <div className={inputContainerClassName}>
            <PatternFormat
              {...props}
              value={maskedValue}
              onChange={handleChange}
              format={countryConfig?.mask}
              getInputRef={ref}
              allowEmptyFormatting
              mask="_"
              type="tel"
              className="phone-input"
            />

            {props.status === 'error' ? (
              <div className="phone-error-icon">
                <InfoCircleFilled style={{ fontSize: '20px' }} />
              </div>
            ) : null}
          </div>
        </div>
      </InputWrapper>
    );
  }
);
