import { combineReducers } from '@reduxjs/toolkit';
import { webClientApi } from '../../api/web.client/webClientApi';
import { CLEAR_STATE_ACTION_TYPE } from '../actions/actionTypes';

export const createRootReducer = () => {
  const appReducer = combineReducers({
    [webClientApi.reducerPath]: webClientApi.reducer
  });

  const clearableReducer: typeof appReducer = (state, action) => {
    if (action.type === CLEAR_STATE_ACTION_TYPE) {
      // eslint-disable-next-line no-param-reassign
      state = undefined;
    }

    return appReducer(state, action);
  };

  return clearableReducer;
};
