import { Col, Row } from 'antd';
import clsx from 'clsx';
import { FC } from 'react';
import { ArrowDown, EditIcon } from '@/assets/icons';
import { Button, ButtonSize, ButtonVariant, Icon, IconSize, Title, TitleVariant, WithLoader } from '@/ui';
import { CompanyCard } from './components/CompanyCard';
import { PartnerCardViewProps } from './model';
import './styles.scss';

export const PartnerCardView: FC<PartnerCardViewProps> = (props) => {
  const { corporateGroup, onClickEdit, isCollapsed, isLoading, onClickCollapse, onChangeCompanyActive } = props;
  const { companies = [] } = corporateGroup;

  return (
    <WithLoader isLoading={isLoading} className="partner-card-loader">
      <div className="partner-card">
        <Row className="partner-card__header" justify="space-between">
          <Col>
            <Row gutter={24} align="middle">
              <Col>
                <div className="partner-card__title">
                  <Title level={3} variant={TitleVariant.normal}>
                    {corporateGroup.name}
                  </Title>
                </div>
              </Col>
              <Col>
                <Button onClick={onClickEdit} variant={ButtonVariant.icon} size={ButtonSize.small}>
                  <Icon size={IconSize.md} icon={<EditIcon />} />
                </Button>
              </Col>
            </Row>
          </Col>
          <Col>
            <Button
              size={ButtonSize.small}
              variant={ButtonVariant.icon}
              onClick={onClickCollapse}
              className={clsx('partner-card__collapse-button', { active: isCollapsed })}
            >
              <Icon size={IconSize.xl} icon={<ArrowDown />} />
            </Button>
          </Col>
        </Row>
        {isCollapsed && (
          <Row className="partner-card__body" gutter={16}>
            {companies.map((company) => (
              <Col span={8} style={{ marginBottom: 16 }} key={company.id}>
                <CompanyCard
                  name={company.name}
                  isActive={company.isActive}
                  onChangeActive={() => onChangeCompanyActive(company)}
                />
              </Col>
            ))}
          </Row>
        )}
      </div>
    </WithLoader>
  );
};
