import { useState } from 'react';

import { Label, LabelWeight, ExpandableCard, Flex, LabelVariant } from '@/ui';

import { formatMoney, declOfMonth } from '@/utils/formatters';

import { FileLink } from '../FileLink';

import { formatDateInTimeZone, DateFormats } from '@/utils/formatters/dateFormatters';

import { CommercialOfferCardViewProps } from './model';

export const CommercialOfferCardView = ({ offers, offerTypes, timeZone }: CommercialOfferCardViewProps) => {
  const [cardExpanded, setCardExpanded] = useState(false);

  return (
    <ExpandableCard expandable onExpand={setCardExpanded} title="Ком. предложение">
      {cardExpanded && (
        <Flex direction="column" align="flex-start" gap={24}>
          {offers.map((offer) => (
            <Flex direction="column" align="flex-start" key={offer.id} fullWidth>
              {offerTypes && (
                <Flex justify="space-between" align="baseline" fullWidth>
                  <div>
                    <Label
                      value={offerTypes[offer.offerType]}
                      level={3}
                      weight={LabelWeight.bold}
                      variant={LabelVariant.black72}
                    />
                  </div>

                  <div>
                    <Label
                      value={formatDateInTimeZone(offer.createdAt, timeZone, DateFormats.date)}
                      level={5}
                      weight={LabelWeight.semibold}
                      variant={LabelVariant.black36}
                    />
                  </div>
                </Flex>
              )}

              <Label value={`Цена выкупа: ${formatMoney(offer.buyoutPrice)} ₽`} variant={LabelVariant.black72} />

              {offer.paymentForEncumbrance && (
                <Label value={`ДС на погашение ипотеки: ${formatMoney(offer.paymentForEncumbrance)} ₽`} />
              )}

              {offer.paymentToClientAccount && (
                <Label value={`ДС на р/с клиента: ${formatMoney(offer.paymentToClientAccount)} ₽`} />
              )}

              {offer.paymentForDDU && <Label value={`ДС в счет оплаты ДДУ: ${formatMoney(offer.paymentForDDU)} ₽`} />}

              {offer.paymentForParking && (
                <Label value={`ДС в счет оплаты ДДУ паркинга: ${formatMoney(offer.paymentForParking)} ₽`} />
              )}

              {offer.paymentForStorage && (
                <Label value={`ДС в счет оплаты ДДУ кладовки: ${formatMoney(offer.paymentForStorage)} ₽`} />
              )}

              {offer.isResidence && offer.residenceDuration && (
                <Label
                  value={`Проживание: ${
                    Number.isNaN(+offer.residenceDuration)
                      ? offer.residenceDuration
                      : `${offer.residenceDuration} ${declOfMonth(+offer.residenceDuration)}`
                  }`}
                />
              )}

              {offer.fileId && (
                <FileLink key={offer.fileId} orderId={offer.orderId} offerId={offer.id} fileId={offer.fileId} />
              )}
            </Flex>
          ))}
        </Flex>
      )}
    </ExpandableCard>
  );
};
