import { FC, useState } from 'react';
import {
  useCompanyControllerCompanySetIsActiveMutation,
  useCompanyControllerCreateCompanyMutation,
  useCorporateGroupControllerGetQuery,
  useCorporateGroupControllerSetIsActiveMutation,
  useCorporateGroupControllerSetOrderLiteOptionMutation
} from '@/api/web.client/webClientGeneratedApi';
import { CorporateGroupDetailsProps } from './model';
import { CorporateGroupDetailsView } from './view';
import toast from 'react-hot-toast';

export const CorporateGroupDetails: FC<CorporateGroupDetailsProps> = (props) => {
  const { corporateGroupId } = props;
  const { data: corporateGroup } = useCorporateGroupControllerGetQuery({
    id: corporateGroupId
  });
  const [corporateGroupSetIsActive] = useCorporateGroupControllerSetIsActiveMutation();
  const [companySetIsActive] = useCompanyControllerCompanySetIsActiveMutation();
  const [companyCreate] = useCompanyControllerCreateCompanyMutation();
  const [corporateGroupSetOrderLiteOption] = useCorporateGroupControllerSetOrderLiteOptionMutation();

  const [isCompanyFormOpen, setIsCompanyFormOpen] = useState(false);
  const [isCorporateGroupActive, setIsCorporateGroupActive] = useState<boolean | undefined>(undefined);

  const [orderLiteOption, setOrderLiteOption] = useState<boolean | undefined>(undefined);

  if (!corporateGroup) {
    return null;
  }

  const isPartnerActive = isCorporateGroupActive !== undefined && corporateGroup.isActive !== isCorporateGroupActive;
  const isOrderLiteOptionActive = orderLiteOption !== undefined && corporateGroup.orderLiteOption !== orderLiteOption;
  const isActiveChanged = isPartnerActive || isOrderLiteOptionActive;

  return CorporateGroupDetailsView({
    ...props,
    corporateGroup,
    isActiveChanged,
    isCompanyFormOpen,
    isCorporateGroupActive: isCorporateGroupActive ?? corporateGroup.isActive,
    orderLiteOption: orderLiteOption ?? corporateGroup.orderLiteOption,
    onChangeCorporateGroupActive: (val: boolean) => {
      setIsCorporateGroupActive(val);
    },
    onChangeOrderLiteOption: (val: boolean) => {
      setOrderLiteOption(val);
    },
    onClickAddCompany: () => {
      setIsCompanyFormOpen(true);
    },
    onCancelCompanyForm: () => {
      setIsCompanyFormOpen(false);
    },
    onChangeCompanyActive: async (id: number, val: boolean) => {
      try {
        await companySetIsActive({
          corporateGroupId: corporateGroup.id,
          companyId: id,
          companySetIsActiveRequest: {
            isActive: val
          }
        }).unwrap();
        setIsCompanyFormOpen(false);
        toast.success(`Юридическое лицо успешно ${val ? 'активировано' : 'деактивировано'}`);
      } catch {
        // do nothing
      }
    },
    onSaveCompanyForm: async (companyCreator) => {
      try {
        await companyCreate({
          corporateGroupId: corporateGroup.id,
          corporateGroupCompanyCreateRequest: companyCreator
        }).unwrap();
        setIsCompanyFormOpen(false);
        toast.success('Юридическое лицо успешно создано');
      } catch {
        // do nothing
      }
    },
    onSave: async () => {
      if (isPartnerActive) {
        await corporateGroupSetIsActive({
          id: corporateGroup.id,
          corporateGroupSetIsActiveRequest: {
            isActive: !!isCorporateGroupActive
          }
        });
        toast.success(`Группа компаний успешно ${isCorporateGroupActive ? 'активирована' : 'деактивирована'}`);
      }

      if (isOrderLiteOptionActive) {
        await corporateGroupSetOrderLiteOption({
          id: corporateGroup.id,
          corporateGroupSetOrderLiteOptionRequest: {
            orderLiteOption: !!orderLiteOption
          }
        });
        toast.success(`Сокращенный формат заявки успешно ${orderLiteOption ? 'разрешен' : 'запрещен'}`);
      }
    }
  });
};
