import { useState } from 'react';
import { useModal } from '@/app/hooks/useModal';

import { Row, Col } from 'antd';
import { Flex, Tabs, WithLoader, TextLoader } from '@/ui';

import { Sizes } from '@/theme/themeVariables';

import { OrderFormMode, OrderFormProps } from './model';

import { OrderFormHeader, OrderDataForm, DealConditionForm } from './components';
import { ManagerCard, OrderHistoryCard, CommercialOfferCard, ThreePartiesMeetingCard, FilesCard } from '@/app/widgets';

import { ScheduleMeetingModalMode } from '@/app/widgets/Modals/ScheduleMeetingModal/model';

import { MeetingDateRange } from '@/app/widgets/Modals/ScheduleMeetingModal/model';

import { MeetingResultModal, OrderRejectModal, ScheduleMeetingModal, CalendarModal } from '@/app/widgets/Modals';

import './styles.scss';

const tabItems = [
  {
    key: '1',
    label: 'ДАННЫЕ ЗАЯВКИ'
  },
  {
    key: '2',
    label: 'УСЛОВИЯ СДЕЛКИ'
  }
];

export const OrderFormView = ({
  onSubmitDataForm,
  mode,
  order,
  offers,

  isScheduleMeetingAvailable,
  isCancelAvailable,
  isManagerCardAvailable,
  isDealConditionActive,

  onClickBack
}: OrderFormProps) => {
  const [activeTabKey, setActiveTabKey] = useState(tabItems[0].key);

  const [meetingDate, setMeetingDate] = useState<MeetingDateRange | undefined>(undefined);
  const [scheduleMeetingModalMode, setScheduleModalMode] = useState<ScheduleMeetingModalMode>(
    ScheduleMeetingModalMode.create
  );

  const [scheduleMeetingModalOpened, openScheduleMeetingModal, closeScheduleMeetingModal] = useModal();
  const [rejectModalOpened, openRejectModal, closeRejectModal] = useModal();
  const [meetingResultsModalOpened, openMeetingResultsModal, closeMeetingResultsModal] = useModal();
  const [calendarModalOpened, openCalendarModal, closeCalendarModal] = useModal();

  const handleScheduleMeetingModal = () => {
    if (meetingResultsModalOpened) {
      closeMeetingResultsModal();
    }

    setScheduleModalMode(ScheduleMeetingModalMode.create);

    openScheduleMeetingModal();
  };

  const handleRescheduleMeetingModal = () => {
    if (meetingResultsModalOpened) {
      closeMeetingResultsModal();
    }

    setScheduleModalMode(ScheduleMeetingModalMode.update);

    openScheduleMeetingModal();
  };

  const handleShowRejectModal = () => {
    closeMeetingResultsModal();

    openRejectModal();
  };

  const handleScheduleMeeting = () => {
    closeScheduleMeetingModal();
  };

  const handleApplyMeetingResults = () => {
    closeMeetingResultsModal();
  };

  const handleClickDate = () => {
    closeScheduleMeetingModal();

    openCalendarModal();
  };

  const handleAddDate = (range: MeetingDateRange) => {
    closeCalendarModal();

    setMeetingDate(range);

    openScheduleMeetingModal();
  };

  return (
    <Row justify="center" align="top">
      <Col className="content-centered-container" style={{ width: '100%' }}>
        <Row gutter={16}>
          <Col span={24}>
            <OrderFormHeader
              order={order}
              mode={mode}
              isScheduleMeetingAvailable={isScheduleMeetingAvailable}
              isCancelAvailable={isCancelAvailable}
              onClickBack={onClickBack}
              onClickScheduleMeeting={handleScheduleMeetingModal}
              onClickCancel={openRejectModal}
            />
          </Col>

          {mode !== OrderFormMode.new && order === undefined && (
            <Col span={24} style={{ marginBottom: Sizes.marginXL }}>
              <TextLoader />
            </Col>
          )}

          {isDealConditionActive && (
            <Col span={24}>
              <Tabs activeKey={activeTabKey} items={tabItems} onTabClick={setActiveTabKey} />
            </Col>
          )}

          <Col span={18}>
            <WithLoader isLoading={mode !== OrderFormMode.new && order === undefined} className="order-form-loader">
              <>
                {activeTabKey === tabItems[0].key ? (
                  <OrderDataForm mode={mode} onSubmit={onSubmitDataForm} order={order} />
                ) : (
                  <>{order && <DealConditionForm order={order} />}</>
                )}
              </>
            </WithLoader>
          </Col>

          <Col span={6}>
            <WithLoader
              isLoading={mode !== OrderFormMode.new && order === undefined}
              className="order-form-side-loader"
            >
              {mode !== OrderFormMode.new && order && (
                <>
                  <Flex direction="column" gap={16}>
                    {isManagerCardAvailable && (
                      <ManagerCard
                        name={order.exchangeManagerName}
                        phone={order.exchangeManagerPhone}
                        email={order.exchangeManagerEmail}
                      />
                    )}

                    {offers && <CommercialOfferCard offers={offers} />}

                    <ThreePartiesMeetingCard
                      isScheduleMeetingAvailable={isScheduleMeetingAvailable}
                      meeting={order.meeting}
                      status={order.statusId}
                      onClickSchedule={handleScheduleMeetingModal}
                      onClickReschedule={handleRescheduleMeetingModal}
                      onShowMeetingResults={openMeetingResultsModal}
                    />

                    {order?.events && <OrderHistoryCard orderHistory={order?.events} />}

                    {offers && <FilesCard offers={offers} />}
                  </Flex>

                  <ScheduleMeetingModal
                    onOk={handleScheduleMeeting}
                    onDismiss={closeScheduleMeetingModal}
                    onCancel={closeScheduleMeetingModal}
                    onClickDate={handleClickDate}
                    mode={scheduleMeetingModalMode}
                    open={scheduleMeetingModalOpened}
                    orderId={order.id}
                    meetingDate={meetingDate}
                  />

                  <CalendarModal
                    orderId={order.id}
                    open={calendarModalOpened}
                    onClickAdd={handleAddDate}
                    onOk={closeCalendarModal}
                    onDismiss={closeCalendarModal}
                    onCancel={closeCalendarModal}
                  />

                  {offers && (
                    <MeetingResultModal
                      onShowRejectModal={handleShowRejectModal}
                      onShowRescheduleMeting={handleRescheduleMeetingModal}
                      onOk={handleApplyMeetingResults}
                      onDismiss={closeMeetingResultsModal}
                      onCancel={closeMeetingResultsModal}
                      open={meetingResultsModalOpened}
                      orderId={order.id}
                    />
                  )}

                  <OrderRejectModal
                    onDismiss={closeRejectModal}
                    onCancel={closeRejectModal}
                    open={rejectModalOpened}
                    orderId={order.id}
                  />
                </>
              )}
            </WithLoader>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
