import { FC } from 'react';
import { SalesOfficeFormViewProps } from './model';
import { Button, ButtonVariant, Label, LabelVariant } from '@/ui';
import { HookField } from '@/app/widgets/HookField';
import { Col, Row, Space } from 'antd';
import { Drawer } from '@/ui/Drawer';
import { FormProvider } from 'react-hook-form';
import { DrawerForm } from '@/ui/Drawer/DrawerForm/DrawerForm';
import { useDadata } from '@/app/hooks/useDadata';

export const SalesOfficeFormView: FC<SalesOfficeFormViewProps> = (props) => {
  const { form, onClose, onSubmit, isNewOffice } = props;

  const { data: cityOptions, onSearch: onSearchCity } = useDadata({
    from_bound: { value: 'city' },
    to_bound: { value: 'city' }
  });

  const city = form.watch('city');

  const { data: addressResults, onSearch: onSearchAddress } = useDadata({
    locations: [
      {
        city
      }
    ]
  });

  const addressOptions =
    addressResults
      ?.map((item) => item.value.replace(`${item.data.city_with_type}, `, ''))
      ?.map((value) => ({ value, label: value })) ?? [];

  const drawerFormBody = (
    <Space direction="vertical" size={36} style={{ width: '100%' }}>
      <Space direction="vertical" size={20} style={{ width: '100%' }}>
        <div>
          <Label variant={LabelVariant.black24} value="Название" />
          <HookField placeholder="Название офиса продаж" name="name" type="text" />
        </div>
        <div>
          <Label variant={LabelVariant.black24} value="Город" />
          <HookField
            name="city"
            type="autocomplete"
            placeholder="Начните вводить название"
            onSearch={onSearchCity}
            allowClear={false}
            options={cityOptions?.map((item) => ({ value: item.data.city, label: item.data.city })) ?? []}
          />
        </div>
        <div>
          <Label variant={LabelVariant.black24} value="Адрес" />
          <HookField
            type="autocomplete"
            placeholder="Начните вводить адрес"
            onSearch={onSearchAddress}
            allowClear={false}
            options={addressOptions}
            name="address"
            disabled={!city}
          />
        </div>
      </Space>
    </Space>
  );

  const drawerFormFooter = (
    <Row gutter={16}>
      <Col span={12}>
        <Button type="submit" role="button" isWide variant={ButtonVariant.primary}>
          Сохранить
        </Button>
      </Col>
      <Col span={12}>
        <Button type="button" role="button" onClick={onClose} isWide variant={ButtonVariant.secondary}>
          Отмена
        </Button>
      </Col>
    </Row>
  );

  return (
    <Drawer open onClose={onClose} size="large">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} style={{ display: 'contents' }}>
          <DrawerForm title={isNewOffice ? 'Новый офис продаж' : 'Данные офиса продаж'} footer={drawerFormFooter}>
            {drawerFormBody}
          </DrawerForm>
        </form>
      </FormProvider>
    </Drawer>
  );
};
