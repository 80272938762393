import { useForm, FormProvider } from 'react-hook-form';

import { getZonedEndOfDay, getZonedStartOfDay } from '@/utils/formatters';

import { FiltersData } from './model';

import { useCurrentUser } from '@/app/hooks/useCurrentUser';
import { useUserProjectsOptions } from '@/app/hooks/useUserProjectsOptions';

import { useReportsControllerGetBillsReportQuery } from '@/api/web.client/webClientGeneratedApi';

import { BillsView } from './view';

export const Bills = () => {
  const projectsOptions = useUserProjectsOptions({});
  const { timeZone } = useCurrentUser();

  const form = useForm<FiltersData>({
    defaultValues: {
      dates: {},
      projectIds: []
    }
  });

  const { reset, watch } = form;

  const handleReset = () => {
    reset({
      projectIds: projectsOptions?.map((option) => Number(option.value)),
      dates: {}
    });
  };

  const projectIds = watch('projectIds');
  const currentDates = watch('dates');

  const { currentData, isLoading } = useReportsControllerGetBillsReportQuery({
    dateFrom: currentDates.start ? getZonedStartOfDay(currentDates.start, timeZone).toISOString() : undefined,
    dateTo: currentDates.end ? getZonedEndOfDay(currentDates.end, timeZone).toISOString() : undefined,
    projectIds
  });

  return (
    <FormProvider {...form}>
      <BillsView
        bills={currentData?.items}
        projectsOptions={projectsOptions}
        isLoading={isLoading}
        onReset={handleReset}
      />
    </FormProvider>
  );
};
