import { get } from 'lodash';
import React, { ChangeEvent } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import {
  Checkbox,
  DatePickerRange,
  Input,
  InputNumber,
  InputPattern,
  InputPassword,
  InputPhone,
  RadioGroup,
  Select,
  TextArea,
  Autocomplete,
  DatePicker,
  Switch,
  ChipGroup,
  TimePickerRange
} from '@/ui';
import { HookFieldTransformProps, InputHookFieldProps } from './model';

export const HookField = (hookFieldProps: InputHookFieldProps) => {
  const { name, type, transform, htmlType, ...props } = hookFieldProps as InputHookFieldProps & HookFieldTransformProps;
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const error = get(errors, name);

  const errorMessage = Boolean(error) ? error?.message : undefined;
  const description = errorMessage;
  const status = Boolean(error) ? 'error' : undefined;

  const commonProps = {
    ...props,
    description,
    status
  };

  const getField = (fieldProps: any) => {
    delete fieldProps.ref;
    const transformerProps = transform
      ? {
          value: transform?.rhfValueToInputValue ? transform.rhfValueToInputValue(fieldProps.value) : fieldProps.value,
          onChange: transform?.inputEventToRhfValue
            ? (v: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                fieldProps.onChange(transform.inputEventToRhfValue?.(v))
            : fieldProps.onChange
        }
      : {};

    switch (type) {
      case 'password': {
        return <InputPassword error={error} {...commonProps} {...fieldProps} {...transformerProps} />;
      }
      case 'number': {
        return <InputNumber error={error} {...commonProps} {...fieldProps} {...transformerProps} />;
      }
      case 'pattern': {
        return <InputPattern error={error} {...commonProps} {...fieldProps} {...transformerProps} />;
      }
      case 'phone': {
        return <InputPhone error={error} {...commonProps} {...fieldProps} {...transformerProps} />;
      }
      case 'select': {
        return <Select error={error} {...commonProps} {...fieldProps} {...transformerProps} />;
      }
      case 'radio': {
        return <RadioGroup error={error} {...commonProps} {...fieldProps} {...transformerProps} />;
      }
      case 'textArea': {
        return <TextArea error={error} {...commonProps} {...fieldProps} {...transformerProps} />;
      }
      case 'datePickerRange': {
        return <DatePickerRange error={error} {...commonProps} {...fieldProps} {...transformerProps} />;
      }
      case 'timePickerRange': {
        return <TimePickerRange error={error} {...commonProps} {...fieldProps} {...transformerProps} />;
      }
      case 'checkbox': {
        return (
          <Checkbox error={error} {...commonProps} {...fieldProps} {...transformerProps} checked={fieldProps.value} />
        );
      }
      case 'autocomplete': {
        return <Autocomplete error={error} {...commonProps} {...fieldProps} {...transformerProps} />;
      }
      case 'datePicker': {
        return <DatePicker error={error} {...commonProps} {...fieldProps} {...transformerProps} />;
      }
      case 'switch': {
        return <Switch error={error} {...commonProps} {...fieldProps} {...transformerProps} />;
      }
      case 'chip': {
        return <ChipGroup {...commonProps} {...fieldProps} {...transformerProps} />;
      }
      default: {
        return <Input error={error} {...commonProps} {...fieldProps} {...transformerProps} type={htmlType} />;
      }
    }
  };

  return (
    <>
      <Controller control={control} name={name} render={({ field }) => getField({ ...field, error: errorMessage })} />
    </>
  );
};
