import clsx from 'clsx';
import { ButtonVariant } from './Button';

import './ButtonLoader.scss';

export interface ButtonLoaderProps {
  variant?: ButtonVariant;
}

export const ButtonLoader = ({ variant = ButtonVariant.primary }: ButtonLoaderProps) => {
  const loaderClassName = clsx('button-loader', `${variant}`);

  return (
    <div className={loaderClassName}>
      <span className="dot" />
      <span className="dot" />
      <span className="dot" />
    </div>
  );
};
