import { useState } from 'react';

export const useModal = (): [boolean, () => void, () => void] => {
  const [isOpen, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  return [isOpen, openModal, closeModal];
};
