import { Row, Col, Space, Layout, Typography } from 'antd';
import { Title, Button, Label } from '@/ui';

import { FormProvider } from 'react-hook-form';
import { HookField } from '@/app/widgets/HookField';

const { Content } = Layout;

import { ChangePasswordFormProps } from './model';

export const LoginFormView = ({ onSubmit, form, isLoading }: ChangePasswordFormProps) => {
  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout>
        <Content>
          <Row justify="space-around" align="middle" style={{ minHeight: '100vh' }}>
            <Col span={6}>
              <FormProvider {...form}>
                <form name="login" onSubmit={form.handleSubmit(onSubmit)}>
                  <Row justify="space-around" style={{ marginBottom: 32 }}>
                    <Col>
                      <Title level={2}>Добро пожаловать</Title>
                      <Label level={3} value="Для окончания регистрации задайте пароль для входа" />
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: 24 }}>
                    <Col span={24}>
                      <HookField type="password" name="password" label="Новый пароль" disabled={isLoading} />
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: 24 }}>
                    <Col span={24}>
                      <HookField type="password" name="repeatPassword" label="Повторите пароль" disabled={isLoading} />
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: 24 }}>
                    <Col span={24}>
                      <HookField
                        type="checkbox"
                        name="accept"
                        label={
                          <Typography.Paragraph
                            ellipsis={{
                              rows: 2,
                              expandable: true,
                              symbol: 'развернуть'
                            }}
                          >
                            Подтверждаю, что предоставляю Согласие на обработку моих персональных данных и прием -
                            передачу ПД Клиентов застройщика в рамках Сделок по продаже недвижимости со способом оплаты
                            Обмен. Обмен данными будет производиться между застройщиком и компанией обмен.рф
                          </Typography.Paragraph>
                        }
                        disabled={isLoading}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginTop: 24 }}>
                    <Button type="submit" isWide disabled={isLoading}>
                      Сохранить
                    </Button>
                  </Row>
                </form>
              </FormProvider>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Space>
  );
};
