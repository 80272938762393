import { ArrowDown, CheckboxCircleFilled } from '@/assets/icons';

import { Colors } from '@/theme/themeVariables';

import { ExpandableCard, Flex, Icon, IconSize, Label, LabelVariant, LabelWeight, Link } from '@/ui';
import { useState } from 'react';
import { EventResponse } from '@/api/web.client/webClientGeneratedApi';
import { DateFormats, formatDateInTimeZone } from '@/utils/formatters';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { applyStringParams } from '../../../utils/formatters/applyStringParams';

export interface OrderHistoryCardProps {
  orderHistory: EventResponse[];
}

const maxEventsCount = 5;

export const OrderHistoryCard = ({ orderHistory }: OrderHistoryCardProps) => {
  const [collapsed, setCollapsed] = useState(orderHistory.length > maxEventsCount);
  const { timeZone } = useCurrentUser();

  const handleExpand = () => {
    setCollapsed(false);
  };

  return (
    <ExpandableCard title="История заявки">
      <Flex direction="column" align="flex-start" gap={24}>
        {(collapsed ? orderHistory.slice(0, maxEventsCount - 1) : orderHistory).map((item) => (
          <Flex key={item.id} direction="column" align="flex-start" gap={4}>
            <Label
              value={formatDateInTimeZone(item.eventDate, timeZone, DateFormats.dateTime)}
              variant={LabelVariant.black48}
            />

            <Flex direction="row">
              <Icon icon={<CheckboxCircleFilled />} size={IconSize.md} color={Colors.lime} />
              <Label value={item.title} weight={LabelWeight.bold} level={3} />
            </Flex>

            {item.description && (
              <Label
                value={applyStringParams(item.description, item.params, timeZone)}
                variant={LabelVariant.black72}
              />
            )}
          </Flex>
        ))}

        {collapsed && orderHistory.length > maxEventsCount && (
          <Flex onClick={handleExpand} className="show-more-button" gap={0}>
            <Link>Показать больше</Link>
            <Icon icon={<ArrowDown />} size={IconSize.sm} />
          </Flex>
        )}
      </Flex>
    </ExpandableCard>
  );
};
