import { Flex } from '../Utility/Flex/Flex';
import { Title } from '../Typography';
import { Icon } from '../Icon';

import { CalendarViewProps } from './model';

import { addDays, addHours } from 'date-fns';

import { ArrowLeftSIcon, ArrowRightSIcon } from '@/assets/icons';

import { weekDays, weekEnd, getActiveHoursArray, monthFormatter } from './helpers';

import { Sizes } from '@/theme';
import './styles.scss';
import clsx from 'clsx';

export const CalendarView = ({
  calendar,
  calendarEventRanges,
  startDay,
  onClickAdd,
  onClickPrev,
  onClickToday,
  onClickNext
}: CalendarViewProps) => {
  const hoursArray = getActiveHoursArray(calendar?.startHour ?? 9, calendar?.endHour ?? 20);

  const endDate = addDays(startDay, 7);

  const startMonth = monthFormatter.format(startDay);
  const endMonth = monthFormatter.format(endDate);

  return (
    <Flex direction="column" align="flex-start" className="calendar-container" gap={Sizes.marginXL}>
      <Flex gap={Sizes.marginXL}>
        <Flex gap={0} className="calendar-actions">
          <button onClick={onClickPrev}>
            <Icon icon={<ArrowLeftSIcon />} />
          </button>

          <button className="toDay" onClick={onClickToday}>
            Сегодня
          </button>

          <button onClick={onClickNext}>
            <Icon icon={<ArrowRightSIcon />} />
          </button>
        </Flex>

        <Title level={4}>
          {startMonth}, {startDay.getDate()} - {endMonth === startMonth ? '' : `${endMonth},`} {endDate.getDate()}
        </Title>
      </Flex>

      <Flex gap={0} direction="row">
        <Flex gap={0} direction="column">
          <div className={clsx('field', 'head')}></div>

          {hoursArray.map((hour) => (
            <div className={clsx('field', 'head')} key={hour}>
              {hour}:00
            </div>
          ))}
        </Flex>

        {weekDays.map((day, index) => (
          <Flex gap={0} direction="column" key={day}>
            <div className={clsx('field', 'head')}>
              {day},{addDays(startDay, index).getDate()}
            </div>

            <Flex gap={0} direction="column" key={day} className="day-container">
              {hoursArray.map((hour) => (
                <div
                  key={`${day}-${hour}`}
                  className={clsx('field', weekEnd.includes(day) ? 'dayOff' : 'empty')}
                  onClick={weekEnd.includes(day) ? undefined : onClickAdd(addHours(addDays(startDay, index), hour))}
                />
              ))}

              {calendarEventRanges[day]?.map((event) => (
                <div
                  key={`${event.start}-${event.end}`}
                  className={typeof event.value === 'string' ? 'event' : 'busy'}
                  style={{
                    top: `${event.start}%`,
                    height: `${event.end - event.start}%`
                  }}
                >
                  {typeof event.value === 'string' ? event.value : null}
                </div>
              ))}
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
