import { useMemo } from 'react';

import { FilesCardProps, OfferFile } from './model';

import { FilesCardView } from './view';

export const FilesCard = ({ offers }: FilesCardProps) => {
  const offersFiles = useMemo(() => {
    const result: OfferFile[] = [];

    if (offers.length === 0) {
      return result;
    }

    const orderId = offers[0].orderId;

    for (const offer of offers) {
      if (!offer.fileId) {
        continue;
      }

      result.push({
        orderId,
        fileId: offer.fileId,
        offerId: offer.id
      });
    }

    return result;
  }, [offers]);

  if (offersFiles.length === 0) {
    return null;
  }

  return <FilesCardView offersFiles={offersFiles} />;
};
