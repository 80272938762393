import '@/utils/helpers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useAuthControllerLoginMutation } from '@/api/web.client/webClientGeneratedApi';
import { LocalStorageKeys } from '@/utils/localstorage/constants';
import { setLocalStorageObject } from '@/utils/localstorage/localStorage';
import { useAuthToken } from '@/app/hooks/useAuthToken';
import { Routes } from '@/app/routing';

import { LoginFormView } from './view';

import { LoginFormData, LoginFormProps } from './model';

import * as Yup from 'yup';
import { getErrorMessage } from '@/utils/formatters/error';

const emailValidator = Yup.string().required('Введите email').email('Введите валидный email').label('Email');

const passwordValidator = Yup.string().required('Введите пароль').label('Пароль');

export const loginFormSchema = Yup.object<LoginFormData>().shape({
  email: emailValidator,
  password: passwordValidator
});

export const Login = () => {
  const [onLogin, { isLoading }] = useAuthControllerLoginMutation();
  const navigate = useNavigate();
  const token = useAuthToken();

  const form = useForm<LoginFormData>({
    resolver: yupResolver<LoginFormData>(loginFormSchema)
  });

  const { handleSubmit } = form;

  useEffect(() => {
    if (token) {
      navigate(Routes.main);
    }
  }, [token, navigate]);

  const onSubmit = handleSubmit(async (data: LoginFormData) => {
    try {
      const res = await onLogin({
        loginDto: {
          email: data.email,
          password: data.password
        }
      }).unwrap();
      setLocalStorageObject(LocalStorageKeys.AuthToken, res);
      navigate(Routes.main);
    } catch (error: unknown) {
      toast.error(getErrorMessage(error));
    }
  });

  const props: LoginFormProps = {
    form,
    onSubmit,
    isLoading
  };

  return <LoginFormView {...props} />;
};
