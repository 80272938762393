import { forwardRef } from 'react';

import { TimePicker as AntdTimePicker, TimePickerProps as AntdTimePickerProps } from 'antd';

import { InputWrapper, InputWrapperProps } from '@/ui/Inputs/InputWrapper/InputWrapper';

import { DateTimeRangeType } from '../DatePicker';

import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/ru_RU';

import './TimePicker.scss';

export interface TimePickerSoloProps extends Omit<AntdTimePickerProps, 'value' | 'onChange'> {
  timeRange?: DateTimeRangeType;
  value?: string;
  isWhite?: boolean;
  onChange?: (value?: string) => void;
}

export const TimePickerSolo = forwardRef<HTMLInputElement, TimePickerSoloProps>(
  ({ value, onChange, timeRange, ...props }, ref) => (
    <AntdTimePicker
      {...props}
      ref={ref}
      value={value ? dayjs(value, 'HH:mm') : null}
      onChange={(v) => {
        onChange?.(v?.format('HH:mm'));
      }}
      changeOnBlur
      hideDisabledOptions
      showNow={false}
      popupClassName="time-picker-popup"
      className="time-picker"
      suffixIcon={null}
      locale={locale}
      format="HH:mm"
      disabledTime={() => ({
        disabledHours: () => {
          const disabledHours: number[] = [];
          const start = dayjs(timeRange?.start, 'HH:mm').hour();
          const end = dayjs(timeRange?.end, 'HH:mm').hour();

          for (let hour = 0; hour < 24; hour++) {
            if (hour < start || hour > end) {
              disabledHours.push(hour);
            }
          }

          return disabledHours;
        }
      })}
    />
  )
);

export interface TimePickerProps extends TimePickerSoloProps, InputWrapperProps {
  status?: 'error' | '';
}

export const TimePicker = forwardRef<HTMLInputElement, TimePickerProps>(
  ({ label, description, readOnly, ...props }) => {
    return (
      <InputWrapper label={label} description={description} status={props.status} readOnly={readOnly}>
        <TimePickerSolo {...props} />
      </InputWrapper>
    );
  }
);
