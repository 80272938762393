import { normalizeMessage } from '../../api/normalizeMessage';

const defaultErrorMessage = 'Произошла ошибка';

export const getErrorMessage = (payload: any) => {
  let toastErrorMessage = defaultErrorMessage;

  if (payload?.status?.toString()?.[0] === '4') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    toastErrorMessage = normalizeMessage(payload?.data);
  }

  if (payload?.status?.toString()?.[0] === '5') {
    toastErrorMessage = 'Сервис не доступен, попробуйте позже';
  }

  if (payload instanceof Error) {
    return payload.message;
  }
  if (payload?.data?.message) {
    return payload.data.message;
  }

  return toastErrorMessage;
};
