import { CssProperty } from '../types';

export const Colors: CssProperty = {
  black100: '#000000',
  black84: '#292929',
  black72: '#474747',
  black64: '#5c5c5c',
  black48: '#858585',
  black36: '#a3a3a3',
  black24: '#c2c2c2',
  black12: '#e0e0e0',
  black6: '#f0f0f0',

  storm100: '#616b7a',
  storm84: '#7a838f',
  storm72: '#8d949f',
  storm64: '#9aa0aa',
  storm48: '#b4b8c0',
  storm36: '#c6c9cf',
  storm24: '#d9dcdf',
  storm12: '#ecedef',
  storm6: '#f6f6f7',

  black100gradient: 'gradient(49.89deg, #616b7a 24.22%, #d6d9dd 85.83%)',

  grifel: '#878f9a',

  fog: '#878f9a',
  lightFog: '#D6D9DD',

  white100: '#ffffff',
  white84: '#d6d6d6',
  white72: '#b8b8b8',
  white64: '#a3a3a3',
  white48: '#7a7a7a',
  white36: '#5c5c5c',
  white24: '#3d3d3d',
  white12: '#1f1f1f',
  white6: '#0f0f0f',

  lime: '#99c93c',
  darkLime: '#8aba2e',

  pistachio: '#c0db8a',

  mainError: '#ef3d24',
  bgError: '#fff7f7',
  warning: '#fff2d5',

  modalMask: 'rgba(0,0,0, 0.6399999856948853)',

  transparent: 'transparent'
} as const;
