import { BillsTableView } from './view';
import { BillsTableProps, BillsTableItem } from './model';

import { ColumnsType } from 'antd/es/table';

import { useUserProjectsMap } from '@/app/hooks/useUserProjectsMap';
import { useCurrentUser } from '@/app/hooks/useCurrentUser';

import { formatDateInTimeZone, formatMoney, DateFormats } from '@/utils/formatters';

export const BillsTable = ({ bills }: BillsTableProps) => {
  const projectsMap = useUserProjectsMap({});
  const { timeZone } = useCurrentUser();

  const billsTableData: BillsTableItem[] =
    bills?.map((bill) => ({
      key: bill.id,
      ...bill
    })) || [];

  const columns: ColumnsType<Omit<BillsTableItem, 'id'>> = [
    {
      title: 'Проект',
      dataIndex: 'projectId',
      key: 'projectId',
      render: (projectId: number) => <>{projectsMap[projectId] ?? ''}</>
    },
    {
      title: 'Сумма, ₽',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: number) => <>{formatMoney(amount)}</>
    },
    {
      title: 'ФИО клиента',
      dataIndex: 'client',
      key: 'client'
    },
    {
      title: 'Дата составления',
      dataIndex: 'creationDate',
      key: 'creationDate',
      render: (creationDate: string) => formatDateInTimeZone(creationDate, timeZone, DateFormats.date)
    },
    {
      title: 'Плановая дата погашения',
      dataIndex: 'plannedRedemptionDate',
      key: 'plannedRedemptionDate',
      render: (plannedRedemptionDate: string) => formatDateInTimeZone(plannedRedemptionDate, timeZone, DateFormats.date)
    },
    {
      title: 'Остаток ДЗ, ₽',
      dataIndex: 'remain',
      key: 'remain',
      render: (remain: number) => <>{formatMoney(remain)}</>
    },
    {
      title: 'Фактическая дата погашения',
      dataIndex: 'factRedemptionDate',
      key: 'factRedemptionDate',
      render: (factRedemptionDate?: string) =>
        factRedemptionDate ? formatDateInTimeZone(factRedemptionDate, timeZone, DateFormats.date) : '-'
    }
  ];

  return <BillsTableView billsTableData={billsTableData} columns={columns} />;
};
