import { LoaderIcon } from '@/assets/icons';

import './Loader.scss';
import clsx from 'clsx';

export interface LoaderProps {
  className?: string;
}

export const Loader = ({ className = '' }: LoaderProps) => {
  const loaderClassName = clsx('loader', className);

  return (
    <div className={loaderClassName}>
      <LoaderIcon />
    </div>
  );
};
