import { useResourcesControllerGetEnumsQuery } from '@/api/web.client/webClientGeneratedApi';

export const useOfferType = () => {
  const enumsQuery = useResourcesControllerGetEnumsQuery();

  const offerTypes = enumsQuery?.currentData?.offerType?.reduce((acc, curr) => {
    acc[curr.value as number] = curr.label;

    return acc;
  }, {} as Record<number, string>);

  return offerTypes;
};
