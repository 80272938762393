import { ApartmentsForBuyView } from './view';

import { useUserProjectsOptions } from '@/app/hooks/useUserProjectsOptions';

import { ApartmentsForBuyProps } from './model';

export const ApartmentsForBuy = ({ ...props }: Omit<ApartmentsForBuyProps, 'projectsOptions'>) => {
  const projectsOptions = useUserProjectsOptions({ isActive: true });

  return <ApartmentsForBuyView {...props} projectsOptions={projectsOptions} />;
};
