import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { HttpStatusCode } from 'axios';
import queryString from 'query-string';
import toast from 'react-hot-toast';
import { Routes } from '../../app/routing';
import { getErrorMessage } from '../../utils/formatters/error';
import { LocalStorageKeys } from '../../utils/localstorage/constants';
import {
  getLocalStorageObject,
  removeLocalStorageObject,
  setLocalStorageObject
} from '../../utils/localstorage/localStorage';
import { normalizeMessage, getMessagesFromValidationError } from '../normalizeMessage';
import { ApiTags } from './tags';

const mutex = new Mutex();

export const baseQuery = fetchBaseQuery({
  baseUrl: '/',
  paramsSerializer: (params) => {
    return queryString.stringify(params, {
      arrayFormat: 'none'
    });
  },
  prepareHeaders: (headers) => {
    const token = getLocalStorageObject(LocalStorageKeys.AuthToken);
    if (token) {
      headers.set('Authorization', `Bearer ${token.accessToken}`);
    }
    return headers;
  }
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === HttpStatusCode.Unauthorized) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      const token = getLocalStorageObject(LocalStorageKeys.AuthToken);
      try {
        const refreshResult = await baseQuery(
          {
            url: '/api/auth/refresh',
            method: 'POST',
            body: { refreshToken: token?.refreshToken }
          },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          setLocalStorageObject(LocalStorageKeys.AuthToken, refreshResult.data);
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          removeLocalStorageObject(LocalStorageKeys.AuthToken);
          const data = result.error?.data as any;
          let message: string = '';
          if (typeof data === 'object' && data?.errors?.length) {
            message = getMessagesFromValidationError(data.errors).join(', ');
          } else if (typeof data === 'object' && data?.message) {
            message = normalizeMessage(data.message);
          }
          if (confirm(message)) {
            window.location.href = Routes.login;
          }
        }
      } catch (err) {
        console.log(err);
        // eslint-disable-next-line no-console
        toast.error(getErrorMessage(err));
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  if (result.error?.data) {
    const data = result.error?.data as any;
    if (typeof data === 'object' && data?.errors?.length) {
      data.messages = getMessagesFromValidationError(data.errors);
    } else if (typeof data === 'object' && data?.message) {
      data.message = normalizeMessage(data.message);
    }
  }

  return result;
};

export const webClientBaseApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  reducerPath: 'webClientApi',
  tagTypes: [
    ApiTags.GetMe,
    ApiTags.GetMyOrders,
    ApiTags.GetOrders,
    ApiTags.GetOrder,
    ApiTags.GetOrderManagers,
    ApiTags.GetCorporateGroup,
    ApiTags.GetCorporateGroups,
    ApiTags.GetEmployees,
    ApiTags.GetEmployee,
    ApiTags.GetStructure,
    ApiTags.GetCorporateGroupStructure,
    ApiTags.GetSalesDepartment,
    ApiTags.GetProjects,
    ApiTags.GetProject,
    ApiTags.GetSalesOffices
  ]
});
