import { TimeRangeModalViewProps } from './model';

import { HookField } from '@/app/widgets/HookField';

import { Modal as AntdModal } from 'antd';
import { Button, ButtonSize } from '@/ui';

import { Colors } from '@/theme';

import './styles.scss';

const formName = 'TimeRange';

export const TimeRangeModalView = ({ timeRange, isSubmitDisabled, onSubmit, ...props }: TimeRangeModalViewProps) => (
  <AntdModal
    {...props}
    maskStyle={{ backgroundColor: Colors.transparent }}
    className="time-range-modal"
    closable={false}
    width={280}
    footer={
      <Button form={formName} type="submit" size={ButtonSize.medium} isWide disabled={isSubmitDisabled}>
        Ок
      </Button>
    }
  >
    <form id={formName} name={formName} onSubmit={onSubmit}>
      <HookField name="meetingRangeTime" type="timePickerRange" timeRange={timeRange} />
    </form>
  </AntdModal>
);
