import { useOrderStatuses } from './useOrderStatuses';

export const useOrderStatusesMap = () => {
  const orderStatuses = useOrderStatuses();

  return orderStatuses?.reduce((acc, curr) => {
    acc[Number(curr.value)] = curr.label;

    return acc;
  }, {} as Record<number, string>);
};
