import clsx from 'clsx';
import { forwardRef } from 'react';

import { DatePicker as BaseDatePicker, DatePickerProps as BaseDatePickerProps } from 'antd';

import { InputWrapper, InputWrapperProps } from '@/ui/Inputs/InputWrapper/InputWrapper';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/ru_RU';

import './DatePicker.scss';
import { CalendarIcon } from '@/assets/icons';
import { Icon } from '../Icon';

export type SoloDatePickerProps = Omit<BaseDatePickerProps, 'value' | 'onChange'> & {
  value?: string;
  isWhite?: boolean;
  onChange?: (value?: string) => void;
  showTime?: boolean;
};

export interface DatePickerProps extends SoloDatePickerProps, InputWrapperProps {
  status?: 'error' | '';
}

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  ({ label, description, readOnly, ...props }) => {
    return (
      <InputWrapper label={label} description={description} status={props.status} readOnly={readOnly}>
        <DatePickerSolo {...props} />
      </InputWrapper>
    );
  }
);

export const DatePickerSolo = forwardRef<HTMLInputElement, SoloDatePickerProps>(
  ({ value, onChange, picker, isWhite, format = 'DD/MM/YYYY', ...props }) => {
    const inputClassName = clsx('date-picker', props.status && `${props.status}`, isWhite && 'white');
    return (
      <>
        <BaseDatePicker
          className={inputClassName}
          {...props}
          suffixIcon={<Icon icon={<CalendarIcon />} />}
          locale={locale}
          value={value ? dayjs(value) : null}
          onChange={(v) => onChange?.(v?.toISOString())}
          format={format}
          picker={picker as any}
          popupClassName="date-picker-popup"
        />
      </>
    );
  }
);
