import { Button, Col, Layout, Row, Space } from 'antd';
import { Content } from 'antd/es/layout/layout';
import classNames from 'classnames';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { LogoIcon, LogoutCircleRIcon } from '../../assets/icons';
import { Icon } from '../Icon';
import './Header.scss';
import { Label } from '../Typography';
import { HeaderTimezoneWidget } from '@/app/widgets/HeaderTimezoneWidget';

export interface HeaderMenuItem {
  url: string;
  label: string;
  isActive?: boolean;
}

export interface HeaderProps {
  menu: HeaderMenuItem[];
  userName: string;
  onClickExit: () => void;
}

export const Header: FC<HeaderProps> = (props) => {
  const { menu, userName, onClickExit } = props;
  return (
    <Space className="Header" direction="vertical" style={{ width: '100%', height: '100px' }}>
      <Layout className="Header__layout">
        <Content className="Header__content content-centered-container">
          <Row align="middle" gutter={60}>
            <Col flex={0}>
              <LogoIcon />
            </Col>
            <Col flex={1}>
              <Row className="Header-menu" gutter={32}>
                {menu.map((menuItem) => (
                  <Col key={menuItem.label}>
                    <NavLink
                      end={true}
                      className={({ isActive }) =>
                        classNames('Header-menu__link', { 'Header-menu__link_active': isActive })
                      }
                      to={menuItem.url}
                    >
                      {menuItem.label}
                    </NavLink>
                  </Col>
                ))}
              </Row>
            </Col>

            <Col flex={0}>
              <Row style={{ alignItems: 'center' }} gutter={12}>
                <Col>
                  <div className="Header-timezone">
                    <HeaderTimezoneWidget />
                  </div>
                </Col>
                {userName && (
                  <Col>
                    <div className="Header-user">
                      <Label level={1}>{userName}</Label>
                    </div>
                  </Col>
                )}
                <Col>
                  <Button type="ghost" size="small" onClick={onClickExit}>
                    <Icon icon={<LogoutCircleRIcon />} />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Space>
  );
};
