import { ReactNode, PointerEvent } from 'react';

import { Typography } from 'antd';

import './Link.scss';

export interface LinkProps {
  onClick?: (event: PointerEvent<HTMLElement>) => void;
  children: ReactNode;
}

export const Link = ({ children, onClick }: LinkProps) => (
  <Typography.Text className="link" onClick={onClick} ellipsis={{ tooltip: children }}>
    {children}
  </Typography.Text>
);
