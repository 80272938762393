import { Row, Col, Space, Layout } from 'antd';
import { Title, Button } from '@/ui';

import { FormProvider } from 'react-hook-form';
import { HookField } from '@/app/widgets/HookField';

const { Content } = Layout;

import { LoginFormProps } from './model';

export const LoginFormView = ({ onSubmit, form, isLoading }: LoginFormProps) => (
  <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
    <Layout>
      <Content>
        <Row justify="space-around" align="middle" style={{ minHeight: '100vh' }}>
          <Col span={6}>
            <FormProvider {...form}>
              <form name="login" onSubmit={onSubmit}>
                <Row justify="space-around" style={{ marginBottom: 32 }}>
                  <Col>
                    <Title level={2}>Вход</Title>
                  </Col>
                </Row>

                <Row style={{ marginBottom: 24 }}>
                  <Col span={24}>
                    <HookField type="text" name="email" label="Email" disabled={isLoading} />
                  </Col>
                </Row>

                <Row style={{ marginBottom: 24 }}>
                  <Col span={24}>
                    <HookField type="password" name="password" label="Пароль" disabled={isLoading} />
                  </Col>
                </Row>

                <Row style={{ marginTop: 24 }}>
                  <Button type="submit" isWide disabled={isLoading}>
                    войти
                  </Button>
                </Row>
              </form>
            </FormProvider>
          </Col>
        </Row>
      </Content>
    </Layout>
  </Space>
);
