import { ApiTags } from './tags';
import { webClientGeneratedApi } from './webClientGeneratedApi';

export const webClientApi = webClientGeneratedApi.enhanceEndpoints({
  endpoints: {
    usersControllerMe: {
      providesTags: [ApiTags.GetMe]
    },
    ordersControllerGetMyOrders: {
      providesTags: [ApiTags.GetMyOrders]
    },
    ordersControllerGetOrders: {
      providesTags: [ApiTags.GetOrders]
    },
    ordersControllerGetOrder: {
      providesTags: [ApiTags.GetOrder]
    },
    ordersControllerGetOrderManagers: {
      providesTags: [ApiTags.GetOrderManagers]
    },
    corporateGroupControllerFilter: {
      providesTags: [ApiTags.GetCorporateGroups]
    },
    corporateGroupControllerGet: {
      providesTags: [ApiTags.GetCorporateGroup]
    },
    employeeControllerGet: {
      providesTags: [ApiTags.GetEmployees]
    },
    employeeControllerGetById: {
      providesTags: [ApiTags.GetEmployee]
    },
    structureControllerStructure: {
      providesTags: [ApiTags.GetStructure]
    },
    structureControllerStructureCompany: {
      providesTags: [ApiTags.GetCorporateGroupStructure]
    },
    salesDepartmentControllerGetSalesDepartment: {
      providesTags: [ApiTags.GetSalesDepartment]
    },
    projectsControllerGetProject: {
      providesTags: [ApiTags.GetProject]
    },
    projectsControllerGetProjects: {
      providesTags: [ApiTags.GetProjects]
    },
    salesOfficeControllerGet: {
      providesTags: [ApiTags.GetSalesOffices]
    },
    ordersControllerCreateOrder: {
      invalidatesTags: [ApiTags.GetMyOrders, ApiTags.GetOrder]
    },
    ordersControllerOrderTransfer: {
      invalidatesTags: [ApiTags.GetMyOrders, ApiTags.GetOrders, ApiTags.GetOrder, ApiTags.GetOrderManagers]
    },
    ordersControllerUpdateDealCondition: {
      invalidatesTags: [ApiTags.GetMyOrders, ApiTags.GetOrder]
    },
    ordersControllerOrderReject: {
      invalidatesTags: [ApiTags.GetMyOrders, ApiTags.GetOrders]
    },
    ordersControllerOrderCrmt: {
      invalidatesTags: [ApiTags.GetMyOrders, ApiTags.GetOrder]
    },
    ordersControllerOrderUmt: {
      invalidatesTags: [ApiTags.GetMyOrders, ApiTags.GetOrder]
    },
    ordersControllerOrderApply: {
      invalidatesTags: [ApiTags.GetMyOrders, ApiTags.GetOrder]
    },
    ordersControllerOrderReassessment: {
      invalidatesTags: [ApiTags.GetMyOrders, ApiTags.GetOrder]
    },
    ordersControllerOrderWaitingDecision: {
      invalidatesTags: [ApiTags.GetMyOrders, ApiTags.GetOrder]
    },
    corporateGroupControllerSetIsActive: {
      invalidatesTags: [ApiTags.GetCorporateGroup, ApiTags.GetCorporateGroups]
    },
    corporateGroupControllerSetOrderLiteOption: {
      invalidatesTags: [ApiTags.GetCorporateGroup, ApiTags.GetCorporateGroups]
    },
    corporateGroupControllerCreate: {
      invalidatesTags: [ApiTags.GetCorporateGroup, ApiTags.GetCorporateGroups]
    },
    companyControllerCompanySetIsActive: {
      invalidatesTags: [ApiTags.GetCorporateGroup, ApiTags.GetCorporateGroups]
    },
    companyControllerCreateCompany: {
      invalidatesTags: [ApiTags.GetCorporateGroup, ApiTags.GetCorporateGroups]
    },
    employeeControllerCreate: {
      invalidatesTags: [ApiTags.GetEmployees]
    },
    employeeControllerResetPassword: {
      invalidatesTags: [ApiTags.GetEmployees, ApiTags.GetEmployee]
    },
    employeeControllerUpdate: {
      invalidatesTags: [ApiTags.GetEmployees, ApiTags.GetEmployee, ApiTags.GetMe]
    },
    salesDepartmentControllerUpdateSalesDepartment: {
      invalidatesTags: [ApiTags.GetStructure, ApiTags.GetSalesDepartment]
    },
    salesDepartmentControllerCreateSalesDepartment: {
      invalidatesTags: [ApiTags.GetStructure, ApiTags.GetSalesDepartment]
    },
    structureControllerAssignUserSalesDepartment: {
      invalidatesTags: [ApiTags.GetStructure, ApiTags.GetEmployees, ApiTags.GetSalesDepartment]
    },
    structureControllerUnAssignUserSalesDepartment: {
      invalidatesTags: [ApiTags.GetStructure, ApiTags.GetEmployees, ApiTags.GetSalesDepartment]
    },
    projectsControllerUpdateProject: {
      invalidatesTags: [ApiTags.GetProjects, ApiTags.GetProject]
    },
    projectsControllerCreateProject: {
      invalidatesTags: [ApiTags.GetProjects]
    },
    usersControllerSetTimezone: {
      invalidatesTags: [ApiTags.GetMe]
    },
    salesOfficeControllerCreateSalesOffice: {
      invalidatesTags: [ApiTags.GetSalesOffices]
    },
    salesOfficeControllerUpdateSalesOffice: {
      invalidatesTags: [ApiTags.GetSalesOffices]
    },
    buildingObjectControllerCreateBuildingObject: {
      invalidatesTags: [ApiTags.GetProject, ApiTags.GetProjects]
    },
    buildingObjectControllerUpdateBuildingObject: {
      invalidatesTags: [ApiTags.GetProject, ApiTags.GetProjects]
    },
    buildingObjectControllerDeleteBuildingObject: {
      invalidatesTags: [ApiTags.GetProject, ApiTags.GetProjects]
    }
  }
});

export const {} = webClientApi;
