import { CssProperty, CssPropertyNumber } from '../types';

export const Fonts: CssProperty = {
  primaryFont: 'GrtskExa',
  secondaryFont: 'GrtskTera',

  normal: '400',
  medium: '500',
  semibold: '600',
  bold: '700'
} as const;

export const FontSizes: CssPropertyNumber = {
  lineHeight: 18,

  fontSize: 18,
  fontSizeSM: 16,
  fontSizeXS: 14,

  fontSizeH1: 48,
  fontSizeH2: 32,
  fontSizeH3: 24,
  fontSizeH4: 16,

  lineHeightH1px: 60,
  lineHeightH2px: 44,
  lineHeightH3px: 36,
  lineHeightH4px: 20,

  lineHeightH1: 1.25,
  lineHeightH2: 1.375,
  lineHeightH3: 1.5,
  lineHeightH4: 1.25,

  inputFontSize: 16,
  inputLineHeight: 24,
  normalNumber: 400,
  mediumNumber: 500,
  semiboldNumber: 600,
  boldNumber: 700,

  textFontSizeXL: 24,
  textFontSizeLG: 18,
  textFontSizeMD: 16,
  textFontSizeSM: 14,
  textFontSizeXS: 12,

  textLineHeightXL: 34,
  textLineHeightLG: 28,
  textLineHeightMD: 24,
  textLineHeightSM: 24,
  textLineHeightXS: 18,
  textLineHeightXXS: 14
};
