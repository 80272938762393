import { Modal, Flex } from '@/ui';

import { HookField } from '@/app/widgets/HookField';

import { Sizes } from '@/theme/themeVariables';

import { useRejectionReasonsOptions } from '@/app/hooks/useRejectionReasonsOptions';

import { yesNoRadioOptions } from '@/constants';

import { OrderRejectModalViewProps } from './model';

const formName = 'OrderReject';

export const OrderRejectModalView = ({ onSubmit, ...props }: OrderRejectModalViewProps) => {
  const rejectionReasonsOptions = useRejectionReasonsOptions();

  return (
    <Modal
      {...props}
      title="Отмена заявки"
      titleLevel={3}
      okText="Сохранить"
      cancelText="Отмена"
      submitButtonAttrs={{ form: formName, type: 'submit' }}
    >
      <form id={formName} name={formName} onSubmit={onSubmit}>
        <Flex direction="column" align="flex-start" gap={Sizes.marginBlockModal}>
          <HookField
            name="rejectionReasonId"
            type="select"
            label="Причина отказа"
            placeholder="Выберите причину"
            options={rejectionReasonsOptions}
          />

          <HookField
            name="readyForSecondaryMarket"
            type="radio"
            label="Готовность выкупа вторичного жилья"
            options={yesNoRadioOptions}
          />
        </Flex>
      </form>
    </Modal>
  );
};
