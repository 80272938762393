export const yesNoRadioOptions = [
  {
    label: 'Да',
    value: true
  },
  {
    label: 'Нет',
    value: false
  }
];

export const apartmentRadioOptions = [
  {
    label: 'Готовая',
    value: true
  },
  {
    label: 'Строящаяся',
    value: false
  }
];

export const roomsCountOptions = [
  {
    label: 'Студия',
    value: 'Студия'
  },
  {
    label: '1',
    value: '1'
  },
  {
    label: '2',
    value: '2'
  },
  {
    label: '3',
    value: '3'
  },
  {
    label: '4',
    value: '4'
  },
  {
    label: '5',
    value: '5'
  },
  {
    label: '6',
    value: '6'
  }
];
