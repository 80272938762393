import { OrderResponse } from '@/api/web.client/webClientGeneratedApi';

export const getOrderCounts = (orders?: OrderResponse[]) => {
  let active = 0;
  let necessaryAction = 0;

  if (!orders?.length) {
    return {
      active,
      necessaryAction
    };
  }

  for (const order of orders) {
    if (order.statusId !== 10) {
      active++;
    }

    if (order.necessaryAction) {
      necessaryAction++;
    }
  }

  return {
    active,
    necessaryAction
  };
};
