import { Modal, Flex, Label, Radio } from '@/ui';

import { useFormContext } from 'react-hook-form';

import { HookField } from '@/app/widgets/HookField';

import { Sizes } from '@/theme/themeVariables';

import { MeetingResultModalViewProps } from './model';

import { useMeetingResultsEnum } from '@/app/hooks/useMeetingResultsEnum';

import './styles.scss';

const formName = 'MeetingResult';

export const MeetingResultModalView = ({ onSubmit, ...props }: MeetingResultModalViewProps) => {
  const meetingResultEnum = useMeetingResultsEnum();

  const { watch } = useFormContext<any>();

  const meetingResult = watch('meetingResult');

  return (
    <Modal
      {...props}
      title="Результат встречи"
      titleLevel={3}
      okText="Сохранить"
      cancelText="Отмена"
      submitButtonAttrs={{ form: formName, type: 'submit' }}
    >
      <form id={formName} name={formName} onSubmit={onSubmit}>
        <Flex direction="column" align="flex-start" gap={Sizes.marginBlockModal}>
          <HookField name="meetingResult" type="radio" label="Готовность выкупа вторичного жилья" vertical>
            <Radio value={meetingResultEnum.ClientAgrees}>
              <Label level={3} value="Клиент согласился" />
            </Radio>

            <Radio value={meetingResultEnum.WaitingDecision}>
              <Label level={3} value="Клиенту нужно подумать" />
            </Radio>

            {meetingResult === meetingResultEnum.WaitingDecision && (
              <div className="meeting-result-field">
                <HookField
                  name="waitingDate"
                  type="datePicker"
                  label="Ожидание решения до"
                  placeholder="Ожидание решения до"
                />
              </div>
            )}

            <Radio value={meetingResultEnum.ReMeetingScheduled}>
              <Label level={3} value="Назначена новая встреча" />
            </Radio>

            <Radio value={meetingResultEnum.Reassessment}>
              <Label level={3} value="Повторная оценка" />
            </Radio>
          </HookField>
        </Flex>
      </form>
    </Modal>
  );
};
