import { ApartmentsForSellView } from './view';
import { ApartmentsForSellProps } from './model';

import { useResourcesControllerGetResourcesQuery } from '@/api/web.client/webClientGeneratedApi';

import { apartmentRadioOptions } from '@/constants';

export const ApartmentsForSell = ({
  ...props
}: Omit<ApartmentsForSellProps, 'banksOptions' | 'apartmentRadioOptions'>) => {
  const resourceQuery = useResourcesControllerGetResourcesQuery();

  const banksOptions =
    resourceQuery.currentData?.dictionaries?.banks.map((bank) => ({
      label: bank.name,
      value: bank.id
    })) || [];

  return <ApartmentsForSellView {...props} banksOptions={banksOptions} apartmentRadioOptions={apartmentRadioOptions} />;
};
