import { PaymentRowProps, PaymentRowVariant } from './model';

import { PaymentViewRow } from './view';
import { formatMoney } from '@/utils/formatters';

export * from './model';

export const PaymentRow = ({ title, amount, variant = PaymentRowVariant.default }: PaymentRowProps) => (
  <PaymentViewRow title={title} amount={`${formatMoney(amount)} ₽`} variant={variant} />
);
