import { FC } from 'react';
import { ProjectNewProps } from './model';
import { ProjectForm } from '../ProjectForm';
import { SubmitHandler } from 'react-hook-form';
import { ProjectRequest, useProjectsControllerCreateProjectMutation } from '@/api/web.client/webClientGeneratedApi';
import { useCurrentUser } from '@/app/hooks/useCurrentUser';
import toast from 'react-hot-toast';

export const ProjectNew: FC<ProjectNewProps> = (props) => {
  const { onClose, openAddBuildingObject, buildingObjects } = props;

  const { currentUser } = useCurrentUser();

  const corporateGroupId = currentUser?.corporateGroupId;

  const [createProject] = useProjectsControllerCreateProjectMutation();

  const onSubmit: SubmitHandler<ProjectRequest> = async (projectRequest) => {
    if (!corporateGroupId) {
      return;
    }
    try {
      await createProject({
        corporateGroupId,
        projectRequest
      }).unwrap();
      onClose();
      toast.success('Проект успешно создан');
    } catch {
      // do nothing
    }
  };

  return (
    <ProjectForm
      buildingObjects={buildingObjects}
      openAddBuildingObject={openAddBuildingObject}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};
