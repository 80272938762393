import { SalesDepartmentFormViewProps } from './model';

import { Row, Col } from 'antd';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { useDadata } from '@/app/hooks/useDadata';

import { CloseIcon } from '@/assets/icons';
import { Button, ButtonSize, ButtonVariant, Label, LabelVariant, Drawer, DrawerForm, LabelWeight, Icon } from '@/ui';

import { AddEmployeeBlock } from './components/AddEmployeeBlock';

import { HookField } from '@/app/widgets/HookField';
import { Sizes } from '@/theme';

const formName = 'SalesDepartmentForm';

export const SalesDepartmentFormView = ({
  open,
  salesDepartmentId,
  headOfDepartmentOptions,
  managersOptions,
  managersMap,
  onClose,
  onSubmit,
  onAddUser,
  onRemoveUser
}: SalesDepartmentFormViewProps) => {
  const { control, watch } = useFormContext<any>();

  const { append, remove } = useFieldArray({ control, name: 'managerIds' });
  const users: number[] = watch('managerIds');

  const { data: cityOptions, onSearch: onSearchCity } = useDadata({
    from_bound: { value: 'city' },
    to_bound: { value: 'city' }
  });

  const handleAddUser = (userId: number) => {
    if (salesDepartmentId) {
      onAddUser(userId);

      return;
    }

    append(userId);
  };

  const handleRemoveUser = (id: number) => () => {
    if (salesDepartmentId) {
      onRemoveUser(id);

      return;
    }

    remove(id);
  };

  const handleClose = () => {
    // setEmployeeSelectValue(undefined);
    // setIsShowAddSelect(false);

    onClose();
  };

  return (
    <Drawer open={open} onClose={handleClose}>
      <DrawerForm
        title={salesDepartmentId ? 'Данные отдела продаж' : 'Новый отдел продаж'}
        footer={
          <Row gutter={16}>
            <Col span={12}>
              <Button type="submit" role="button" isWide variant={ButtonVariant.primary} form={formName}>
                Сохранить
              </Button>
            </Col>
            <Col span={12}>
              <Button type="button" role="button" onClick={onClose} isWide variant={ButtonVariant.secondary}>
                Отмена
              </Button>
            </Col>
          </Row>
        }
      >
        <form id={formName} name={formName} onSubmit={onSubmit}>
          <Row>
            <Col span={24} style={{ marginBlock: Sizes.marginSM }}>
              <HookField name="name" type="text" label="Название" placeholder="Название отдела продаж" />
            </Col>

            <Col span={24} style={{ marginBlock: Sizes.marginSM }}>
              <HookField
                name="city"
                type="autocomplete"
                placeholder="Выберите город"
                label="Город"
                onSearch={onSearchCity}
                allowClear={false}
                options={cityOptions?.map((item) => ({ value: item.data.city, label: item.data.city })) ?? []}
                readOnly={Boolean(salesDepartmentId)}
              />
            </Col>

            <Col span={24} style={{ marginBlock: Sizes.marginSM }}>
              <HookField
                name="headOfDepartmentUserId"
                type="select"
                options={headOfDepartmentOptions}
                allowClear
                placeholder="Выберите руководителя"
                label={
                  <span>
                    Руководитель <small>Не обязательно</small>
                  </span>
                }
              />
            </Col>

            <Col span={24} style={{ marginBlock: Sizes.marginSM }}>
              <Label level={2} weight={LabelWeight.bold} value="Менеджеры" />
            </Col>

            <Col span={24}>
              {users?.map((user) => (
                <Row key={user} justify="space-between" align="middle" style={{ marginBlock: Sizes.marginSM }}>
                  <Col>
                    <Label variant={LabelVariant.black72} level={3}>
                      {managersMap?.[user]}
                    </Label>
                  </Col>

                  <Col>
                    <Button
                      size={ButtonSize.small}
                      variant={ButtonVariant.icon}
                      type="button"
                      onClick={handleRemoveUser(user)}
                    >
                      <Icon icon={<CloseIcon />} />
                    </Button>
                  </Col>
                </Row>
              ))}
            </Col>

            {open && <AddEmployeeBlock employeesOptions={managersOptions} onAddUser={handleAddUser} />}
          </Row>
        </form>
      </DrawerForm>
    </Drawer>
  );
};
