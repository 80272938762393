import { useMemo } from 'react';

import { CalendarProps, CalendarEventRanges } from './model';

import { CalendarView } from './view';

import { weekDays } from './helpers';
import { startOfDay, addHours } from 'date-fns';

export const Calendar = ({ calendar, onClickAdd, ...props }: CalendarProps) => {
  const calendarEventRanges = useMemo(() => {
    if (!calendar) {
      return {};
    }

    const result: CalendarEventRanges = {};

    const dayLengthMs = (calendar.endHour - calendar.startHour) * 60 * 60 * 1000;

    for (const event of [...calendar.events, ...calendar.busy]) {
      const dayStart = addHours(startOfDay(new Date(event.startDate)), calendar.startHour);
      const start = new Date(event.startDate).valueOf() - dayStart.valueOf();
      const end = new Date(event.endDate).valueOf() - dayStart.valueOf();

      const weekDay = weekDays[dayStart.getDay() - 1];

      if (!result[weekDay]) {
        result[weekDay] = [];
      }

      result[weekDay].push({
        start: (100 * start) / dayLengthMs,
        end: (100 * end) / dayLengthMs,
        value: 'name' in event ? (event.name as string) : true
      });
    }

    return result;
  }, [calendar]);

  const handleClickAdd = (date: Date) => () => {
    onClickAdd(date);
  };

  return (
    <CalendarView
      calendar={calendar}
      onClickAdd={handleClickAdd}
      calendarEventRanges={calendarEventRanges}
      {...props}
    />
  );
};
