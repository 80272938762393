import { useNavigate } from 'react-router-dom';
import { Routes } from '@/app/routing';

import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as Yup from 'yup';

import { OrderRejectModalView } from './view';

import { OrderRejectModalProps } from './model';

import { OrderRejectionRequest, useOrdersControllerOrderRejectMutation } from '@/api/web.client/webClientGeneratedApi';
import { useEffect } from 'react';

export const orderRejectSchema = Yup.object<OrderRejectionRequest>().shape({
  rejectionReasonId: Yup.string().required('').label('причина отказа'),
  readyForSecondaryMarket: Yup.boolean().required('').oneOf([true, false]).label('')
});

export const OrderRejectModal = ({ orderId, ...props }: OrderRejectModalProps) => {
  const navigate = useNavigate();

  const formMethods = useForm<OrderRejectionRequest>({
    resolver: yupResolver<OrderRejectionRequest>(orderRejectSchema),
    mode: 'onSubmit',
    defaultValues: {
      readyForSecondaryMarket: false
    }
  });

  const { handleSubmit, reset } = formMethods;

  const [rejectOrder, { isSuccess, reset: resetReject }] = useOrdersControllerOrderRejectMutation();

  useEffect(() => {
    if (isSuccess) {
      resetReject();
      reset();
      navigate(Routes.ordersList);
    }
  }, [isSuccess, navigate, reset, resetReject]);

  const onSubmit = handleSubmit((data: OrderRejectionRequest) => {
    rejectOrder({
      id: orderId,
      orderRejectionRequest: data
    });
  });

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    reset();
    props.onCancel?.(event);
  };

  const handleDismiss = () => {
    reset();
    props.onDismiss?.();
  };

  return (
    <FormProvider {...formMethods}>
      <OrderRejectModalView {...props} onSubmit={onSubmit} onCancel={handleCancel} onDismiss={handleDismiss} />
    </FormProvider>
  );
};
