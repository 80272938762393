import { EmployeeRequest, EmployeeResponse } from '@/api/web.client/webClientGeneratedApi';
import { EmployeeFormData } from '@/app/content/employees/components/employeeForm/model';

export const mapEmployeeResponseToFormData = (employee: EmployeeResponse): EmployeeFormData => {
  return {
    email: employee.email,
    name: employee.name,
    phone: employee.phone ?? undefined,
    isActive: employee.isActive,
    role: employee.roles?.[0] ?? ''
  };
};

export const mapEmployeeFormDataToResponse = (employeeFormData: EmployeeFormData): EmployeeRequest => {
  return {
    email: employeeFormData.email,
    name: employeeFormData.name,
    phone: employeeFormData.phone,
    isActive: employeeFormData.isActive,
    roles: [employeeFormData.role]
  };
};
