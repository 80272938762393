import { Switch } from 'antd';
import { FC } from 'react';
import { Title } from '@/ui';
import { CompanyCardProps } from './model';
import './styles.scss';

export const CompanyCardView: FC<CompanyCardProps> = (props) => {
  const { isActive, name, onChangeActive } = props;
  return (
    <div className="company-card">
      <div className="company-card__name">
        <Title level={4}>{name}</Title>
      </div>
      <div className="company-card__checkbox">
        <Switch size="small" onChange={onChangeActive} checked={isActive} /> {isActive ? 'Активен' : 'Заблокирован'}
      </div>
    </div>
  );
};
