import { useEffect } from 'react';

export const useUnload = (prevent: boolean) => {
  useEffect(() => {
    const defaultOnBeforeOnload = window.onbeforeunload;
    window.onbeforeunload = function (event) {
      if (prevent) {
        event.preventDefault();
      }
    };

    return () => {
      window.onbeforeunload = defaultOnBeforeOnload;
    };
  }, [prevent]);
};
