import { Flex, Icon, IconSize, Link } from '@/ui';
import { AttachmentIcon } from '@/assets/icons';

import { FileLinkViewProps } from './model';

export const FileLinkView = ({ name, onFileClick }: FileLinkViewProps) => (
  <Flex onClick={onFileClick} fullWidth>
    <Icon icon={<AttachmentIcon />} size={IconSize.sm} />
    <Link>{name}</Link>
  </Flex>
);
