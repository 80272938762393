import { useEffect, useCallback } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as Yup from 'yup';

import {
  useOrdersControllerOrderApplyMutation,
  useOrdersControllerOrderWaitingDecisionMutation,
  useOrdersControllerOrderReassessmentMutation
} from '@/api/web.client/webClientGeneratedApi';

import { useMeetingResultsEnum } from '@/app/hooks/useMeetingResultsEnum';

import { MeetingResultModalProps, MeetingResultForm } from './model';

import { MeetingResultModalView } from './view';

export const meetingResultSchema = Yup.object<MeetingResultForm>().shape({
  meetingResult: Yup.number().required(''),
  waitingDate: Yup.string()
    .when('meetingResult', (meetingResult, field) => (meetingResult[0] === 5 ? field.required() : field.optional()))
    .label('Ожидание решения до')
});

export const MeetingResultModal = ({
  orderId,
  onShowRejectModal,
  onShowRescheduleMeting,
  onOk,
  ...props
}: MeetingResultModalProps) => {
  const meetingResultEnum = useMeetingResultsEnum();

  const formMethods = useForm<MeetingResultForm>({
    resolver: yupResolver<MeetingResultForm>(meetingResultSchema),
    mode: 'onSubmit',
    defaultValues: {
      meetingResult: meetingResultEnum.ClientAgrees
    }
  });

  const { handleSubmit, setValue, reset } = formMethods;

  useEffect(() => {
    if (Object.keys(meetingResultEnum).length === 0) {
      return;
    }

    setValue('meetingResult', meetingResultEnum.ClientAgrees);
  }, [meetingResultEnum, setValue]);

  const [applyOrder, { isSuccess: isSuccessApply, reset: resetApply }] = useOrdersControllerOrderApplyMutation();
  const [applyWaitDecision, { isSuccess: isSuccessWaitDecision, reset: resetWaitDecision }] =
    useOrdersControllerOrderWaitingDecisionMutation();
  const [reassessOrder, { isSuccess: isSuccessReassess, reset: resetReassess }] =
    useOrdersControllerOrderReassessmentMutation();

  const handleReset = useCallback(() => {
    reset({
      meetingResult: meetingResultEnum.ClientAgrees
    });

    resetApply();

    resetReassess();

    resetWaitDecision();
  }, [meetingResultEnum.ClientAgrees, reset, resetApply, resetReassess, resetWaitDecision]);

  useEffect(() => {
    if (isSuccessApply || isSuccessWaitDecision || isSuccessReassess) {
      handleReset();
      onOk();
    }
  }, [isSuccessApply, isSuccessWaitDecision, isSuccessReassess, handleReset, onOk]);

  const onSubmit = handleSubmit((data: MeetingResultForm) => {
    switch (data.meetingResult) {
      case meetingResultEnum.ClientAgrees:
        applyOrder({
          id: orderId
        });
        break;

      case meetingResultEnum.WaitingDecision:
        applyWaitDecision({
          id: orderId,
          orderWaitingDecisionRequest: {
            waitingDate: data.waitingDate ?? ''
          }
        });

        break;

      case meetingResultEnum.Refusal:
        handleReset();
        onShowRejectModal();
        break;

      case meetingResultEnum.ReMeetingScheduled:
        handleReset();
        onShowRescheduleMeting();
        break;
      case meetingResultEnum.Reassessment:
        reassessOrder({
          id: orderId
        });
        break;
    }
  });

  const handleDismiss = () => {
    handleReset();
    props.onDismiss();
  };

  const handleCancel = () => {
    handleReset();
    props.onCancel?.();
  };

  return (
    <FormProvider {...formMethods}>
      <MeetingResultModalView {...props} onDismiss={handleDismiss} onCancel={handleCancel} onSubmit={onSubmit} />
    </FormProvider>
  );
};
