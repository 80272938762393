import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { CorporateGroupCompanyCreateRequest } from '@/api/web.client/webClientGeneratedApi';
import { CompanyFormProps } from './model';
import { CompanyFormView } from './CompanyFormView';

export const companyCreateSchema = Yup.object<CorporateGroupCompanyCreateRequest>().shape({
  name: Yup.string().required('Введите название Юр. Лица')
});

export const CompanyForm: FC<CompanyFormProps> = (props) => {
  const form = useForm<CorporateGroupCompanyCreateRequest>({
    resolver: yupResolver<CorporateGroupCompanyCreateRequest>(companyCreateSchema),
    defaultValues: { name: '' }
  });

  return CompanyFormView({
    ...props,
    form
  });
};
