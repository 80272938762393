import { formatMoney } from '@/utils/formatters';

export const getApartmentForBuyText = ({
  project,
  totalArea,
  price
}: {
  project: string;
  totalArea: number;
  price: number;
}) => `${project}, квартира ${totalArea} кв.м. ${formatMoney(price)} ₽`;

export const canOpenClosedOrders = (userRoles: string[]): boolean => {
  if (!userRoles || userRoles.length === 0) {
    return false;
  }

  return userRoles.some((role) => role === 'PartnerCommercialDirector' || role === 'PartnerHeadOfDepartment');
};
