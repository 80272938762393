import { useRef } from 'react';
import {
  DadataSuggestAddressRequest,
  useDadataControllerAddressSuggestionsMutation
} from '../../api/web.client/webClientGeneratedApi';

export const useDadata = (baseRequest: Omit<DadataSuggestAddressRequest, 'query'>, fetchDebounce = 300) => {
  const [fetchAddressSuggestions, { data, isLoading }] = useDadataControllerAddressSuggestionsMutation();
  const timeoutRef = useRef<any>();
  return {
    data,
    isLoading,
    onSearch: (value: string) => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        const payload: DadataSuggestAddressRequest = {
          ...baseRequest,
          query: value
        };
        fetchAddressSuggestions({
          dadataSuggestAddressRequest: payload
        });
      }, fetchDebounce);
    }
  };
};
