import { ApartmentsForSellView } from './view';

import { ApartmentsForSellProps } from './model';

import { useBanksOptions } from '@/app/hooks/useBanksOptions';

export const ApartmentsForSell = ({ readOnly, isResidence }: ApartmentsForSellProps) => {
  const banksOptions = useBanksOptions();

  return <ApartmentsForSellView readOnly={readOnly} isResidence={isResidence} banksOptions={banksOptions} />;
};
