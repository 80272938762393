import { FC } from 'react';
import { useCompanyControllerCompanySetIsActiveMutation } from '../../../api/web.client/webClientGeneratedApi';
import { PartnerCardProps } from './model';
import { PartnerCardView } from './PartnerCardView';

export const PartnerCard: FC<PartnerCardProps> = (props) => {
  const [companySetIsActive] = useCompanyControllerCompanySetIsActiveMutation();

  return PartnerCardView({
    ...props,
    onChangeCompanyActive: async (company) => {
      await companySetIsActive({
        companyId: company.id,
        corporateGroupId: company.corporateGroupId,
        companySetIsActiveRequest: {
          isActive: !company.isActive
        }
      });
    }
  });
};
