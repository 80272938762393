import { Row, Col, Divider } from 'antd';
import { Button } from '@/ui';

import { Colors } from '@/theme/themeVariables';

import { DealConditionFormViewProps } from './model';
import { ApartmentsForSell, ApartmentsForBuy, DealParticipants, AgreementDataForm } from './components';
import { isDealParticipantsVisible } from '@/app/content/orderForm/components/DealConditionForm/config';

export const DealConditionFormView = ({ readOnly, onSubmit, isLoading, isResidence }: DealConditionFormViewProps) => (
  <form name="DealCondition" onSubmit={onSubmit} style={{ backgroundColor: Colors.white100, padding: 64 }}>
    <ApartmentsForSell isResidence={isResidence} readOnly={readOnly} />

    <Divider style={{ marginBlock: 64 }} />

    <ApartmentsForBuy readOnly={false} />

    <Divider style={{ marginBlock: 64 }} />

    {isDealParticipantsVisible && <DealParticipants readOnly={readOnly} />}

    <AgreementDataForm />

    <Row style={{ marginTop: 24 }}>
      <Col span={12}>
        <Button type="submit" isWide loading={isLoading}>
          Сохранить
        </Button>
      </Col>
    </Row>
  </form>
);
