import { useState } from 'react';

import { Label, Icon, IconSize, ExpandableCard, Flex } from '@/ui';

import { UserIcon, PhoneIcon, MailOpenIcon } from '@/assets/icons';
import { usePhoneFormat } from '../../hooks/usePhoneFormat';

export interface ManagerCardProps {
  name?: string;
  phone?: string;
  email?: string;
}

export const ManagerCard = ({ name, phone, email }: ManagerCardProps) => {
  const [cardExpanded, setCardExpanded] = useState(false);
  const formatPhone = usePhoneFormat();
  return (
    <ExpandableCard expandable onExpand={setCardExpanded} title="Ваш менеджер">
      <Flex>
        <Icon icon={<UserIcon />} size={IconSize.sm} />
        <Label value={name} level={4} />
      </Flex>

      {cardExpanded && (
        <>
          <Flex>
            <Icon icon={<PhoneIcon />} size={IconSize.sm} />
            <Label value={formatPhone(phone)} level={4} />
          </Flex>

          <Flex>
            <Icon icon={<MailOpenIcon />} size={IconSize.sm} />
            <Label value={email} level={4} ellipsis />
          </Flex>
        </>
      )}
    </ExpandableCard>
  );
};
