import { useState } from 'react';

import { Row, Col } from 'antd';

import { Title, TextLoader, Button, ButtonSize } from '@/ui';

import { Sizes } from '@/theme/themeVariables';

import { SalesDepartmentCard } from '@/app/widgets';

import { useModal } from '@/app/hooks/useModal';

import { StructureViewProps } from './model';

import { SalesDepartmentStructureResponse } from '@/api/web.client/webClientGeneratedApi';

import { SalesDepartmentForm } from './components';
import { AddIcon } from '@/assets/icons';

export const SalesDepartmentsView = ({ structure }: StructureViewProps) => {
  const [salesDepartmentId, setSalesDepartmentId] = useState<number | null>(null);

  const [isOpenAddSalesDepartmentForm, openAddSalesDepartmentForm, closeAddSalesDepartmentForm] = useModal();

  const handleClickEdit = (salesDepartment: SalesDepartmentStructureResponse) => {
    setSalesDepartmentId(salesDepartment.id);

    openAddSalesDepartmentForm();
  };

  const handleClickAdd = () => {
    openAddSalesDepartmentForm();
  };

  const handleCloseAddSalesDepartmentForm = () => {
    closeAddSalesDepartmentForm();
    setSalesDepartmentId(null);
  };

  return (
    <Row justify="center" align="top">
      <Col span={24} className="content-centered-container">
        <Row align="top" justify="space-between" gutter={16}>
          <Col span={12}>
            <Title>Отделы продаж</Title>
          </Col>

          <Col span={5}>
            <Row justify="end" align="middle">
              <Col span={24}>
                <Button
                  onClick={handleClickAdd}
                  isWide
                  size={ButtonSize.medium}
                  prefixIcon={<AddIcon />}
                  loading={false}
                >
                  Добавить
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row align="top" justify="start" gutter={Sizes.marginLG} style={{ marginBottom: Sizes.marginXL }}>
          {structure === undefined && (
            <Row style={{ marginBottom: Sizes.marginXL }}>
              <Col span={24}>
                <TextLoader />
              </Col>
            </Row>
          )}
        </Row>

        <Row align="top" justify="start" gutter={[Sizes.marginXL, Sizes.marginXL]}>
          {structure?.salesDepartments.map((salesDepartment, index) => (
            <Col span={24} key={salesDepartment.id}>
              <SalesDepartmentCard
                salesDepartment={salesDepartment}
                expanded={index === 0}
                onClickEdit={() => handleClickEdit(salesDepartment)}
              />
            </Col>
          ))}

          <SalesDepartmentForm
            open={isOpenAddSalesDepartmentForm}
            onClose={handleCloseAddSalesDepartmentForm}
            salesDepartmentId={salesDepartmentId}
            corporateGroupId={structure?.id ?? null}
          />
        </Row>
      </Col>
    </Row>
  );
};
