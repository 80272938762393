import { dateToCurrentQuarter } from '@/utils/formatters';
import { BuildingCardProps } from './model';
import styles from './styles.module.scss';

export const BuildingObjectCard = (props: BuildingCardProps) => {
  const { name, completionDatePlan, companyName, onOpenBuildingDetails } = props;
  return (
    <div onClick={onOpenBuildingDetails} className={styles.card}>
      <div className={styles.name}>{name}</div>
      <div className={styles.info}>Сдача: {dateToCurrentQuarter(completionDatePlan)}</div>
      <div className={styles.info}>{companyName}</div>
    </div>
  );
};
