import { ReactNode } from 'react';
import { Typography } from 'antd';

import clsx from 'clsx';

import './Title.scss';

export enum TitleVariant {
  gray = 'gray',
  normal = 'normal'
}

export interface TitleProps {
  level?: 1 | 2 | 3 | 4;
  children: ReactNode;
  variant?: TitleVariant;
}

export const Title = ({ level = 1, children, variant = TitleVariant.normal }: TitleProps) => {
  const titleClassName = clsx('title', variant);

  return (
    <Typography.Title level={level} className={titleClassName}>
      {children}
    </Typography.Title>
  );
};
