import { useEffect, useState } from 'react';

import type { DefaultOptionType } from 'rc-select/es/Select';

import { useUserCorporateGroup } from './useUserCorporateGroup';

export const useSalesDepartmentOptions = () => {
  const corporateGroup = useUserCorporateGroup();

  const [salesDepartmentsOptions, setSalesDepartmentsOptions] = useState<DefaultOptionType[]>([]);

  useEffect(() => {
    if (salesDepartmentsOptions.length !== 0 || !corporateGroup?.salesDepartments) {
      return;
    }

    setSalesDepartmentsOptions(
      corporateGroup.salesDepartments.map((item) => ({
        value: item.id,
        label: item.name
      }))
    );
  }, [corporateGroup?.salesDepartments, salesDepartmentsOptions]);

  return salesDepartmentsOptions;
};
