const defaultErrorMessage = 'An error has occurred';

export const normalizeMessageArray = (data: string | string[]) => {
  try {
    if (Array.isArray(data)) {
      return data.join('\n');
    }
    return data;
  } catch (error) {
    console.trace(error);
    return data;
  }
};

export const normalizeMessage = (data: string) => {
  try {
    const dataObj: Record<string | number, any> = JSON.parse(data as string);
    const dataFields = Object.entries(dataObj);
    const message =
      dataFields.find(
        ([key, value]) => typeof key === 'string' && key.includes('description') && typeof value === 'string',
      )?.[1] ||
      dataFields.find(
        ([key, value]) => typeof key === 'string' && key.includes('message') && typeof value === 'string',
      )?.[1] ||
      dataFields.find(
        ([key, value]) => typeof key === 'string' && key.includes('error') && typeof value === 'string',
      )?.[1] ||
      defaultErrorMessage;

    return message;
  } catch (error) {
    return normalizeMessageArray(data);
  }
};

export const getMessagesFromValidationError = (errors: ValidationError[]) => {
  const messages: string[] = [];
  errors.forEach((item: ValidationError) => {
    const propertyErrors = Object.values(item?.constraints);
    propertyErrors.forEach((propertyError) => messages.push(propertyError));
    if (item.children.length) {
      messages.concat(getMessagesFromValidationError(item.children))
    }
  })
  return messages;
}

interface ValidationError {
  children: ValidationError[];
  property: string;
  constraints: Record<string, string>;
}

