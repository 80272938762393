import { Calendar, Modal } from '@/ui';

import { CalendarModalViewProps } from './model';

import { TimeRangeModal } from '@/app/widgets/Modals';

import './styles.scss';

export const CalendarModalView = ({
  startDay,
  calendar,
  timeRangeModalOpened,
  timeRange,
  calendarDate,

  onAddDate,
  closeTimeRangeModal,
  onClickAdd,
  onClickPrev,
  onClickNext,
  onClickToday,
  ...props
}: CalendarModalViewProps) => (
  <>
    <Modal {...props} footer={<></>} width={'auto'} className="calendar-modal">
      <Calendar
        startDay={startDay}
        calendar={calendar}
        onClickAdd={onClickAdd}
        onClickPrev={onClickPrev}
        onClickNext={onClickNext}
        onClickToday={onClickToday}
      />
    </Modal>

    <TimeRangeModal
      calendarDate={calendarDate}
      calendar={calendar}
      timeRange={timeRange}
      open={timeRangeModalOpened}
      onCancel={closeTimeRangeModal}
      onAddDate={onAddDate}
    />
  </>
);
