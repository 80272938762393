import { useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';

import '@/utils/helpers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';

import { SalesDepartmentFormView } from './view';

import { SalesDepartmentFormProps } from './model';

import {
  useSalesDepartmentControllerCreateSalesDepartmentMutation,
  useSalesDepartmentControllerUpdateSalesDepartmentMutation,
  useSalesDepartmentControllerGetSalesDepartmentQuery,
  useEmployeeControllerGetQuery,
  useStructureControllerAssignUserSalesDepartmentMutation,
  useStructureControllerUnAssignUserSalesDepartmentMutation,
  SalesDepartmentCreateRequest,
  UserStructureResponse
} from '@/api/web.client/webClientGeneratedApi';

import { useFreeEmployeesOptions } from '@/app/hooks/useFreeEmployeesOptions';
import { useFreeEmployeesMap } from '@/app/hooks/useFreeEmployeesMap';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEmployeesOptions } from '@/app/hooks/useEmployeesOptions';
interface SalesDepartmentCreateRequestWithUserId extends SalesDepartmentCreateRequest {
  userId?: number;
}

const salesDepartmentSchema = Yup.object<SalesDepartmentCreateRequestWithUserId>().shape({
  name: Yup.string().required().label('Имя'),
  city: Yup.string().required().label('Город'),
  managerIds: Yup.array().of(Yup.number().required()).optional().label('Менеджер'),
  headOfDepartmentUserId: Yup.number().optional().label('Руководитель'),
  userId: Yup.number().optional()
});

const defaultValues: SalesDepartmentCreateRequestWithUserId = {
  name: '',
  city: '',
  managerIds: [],
  headOfDepartmentUserId: undefined,
  userId: undefined
};

const managerRoles = ['PartnerManager'];
const headOfDepartmentRoles = ['PartnerHeadOfDepartment'];

export const SalesDepartmentForm = ({
  salesDepartmentId,
  corporateGroupId,
  onClose,
  ...props
}: SalesDepartmentFormProps) => {
  const formMethods = useForm<SalesDepartmentCreateRequestWithUserId>({
    resolver: yupResolver<SalesDepartmentCreateRequestWithUserId>(salesDepartmentSchema),
    mode: 'onSubmit'
  });

  const { handleSubmit, reset } = formMethods;

  const [createSalesDepartment, { isSuccess: isCreateSuccess, reset: resetCreateController }] =
    useSalesDepartmentControllerCreateSalesDepartmentMutation();

  const [updateSalesDepartment, { isSuccess: isUpdateSuccess, reset: resetUpdateController }] =
    useSalesDepartmentControllerUpdateSalesDepartmentMutation();

  const onSubmit = handleSubmit(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ userId, ...data }: SalesDepartmentCreateRequestWithUserId) => {
      if (!corporateGroupId) {
        return;
      }

      if (salesDepartmentId) {
        updateSalesDepartment({
          corporateGroupId,
          salesDepartmentId,
          salesDepartmentUpdateRequest: {
            name: data.name,
            headOfDepartmentUserId: data.headOfDepartmentUserId
          }
        });
        return;
      }

      createSalesDepartment({
        corporateGroupId,
        salesDepartmentCreateRequest: data
      });
    }
  );

  const salesDepartmentQuery = useSalesDepartmentControllerGetSalesDepartmentQuery(
    corporateGroupId && salesDepartmentId
      ? {
          corporateGroupId,
          salesDepartmentId
        }
      : skipToken
  );

  useEffect(() => {
    if (!salesDepartmentQuery.isSuccess || !salesDepartmentQuery.currentData) {
      return;
    }

    const { name, city, users } = salesDepartmentQuery.currentData;
    reset({
      name,
      city,
      managerIds: users.filter((item) => item.roles.includes('PartnerManager')).map((user) => user.id),
      headOfDepartmentUserId: users.find((user) => user.roles.includes('PartnerHeadOfDepartment'))?.id
    });
  }, [reset, salesDepartmentQuery.currentData, salesDepartmentQuery.isSuccess]);

  const managerOptions = useFreeEmployeesOptions({
    roles: managerRoles
  });

  const headOfDepartmentOptions = useEmployeesOptions({
    roles: headOfDepartmentRoles
  });

  const employeeQuery = useEmployeeControllerGetQuery(corporateGroupId ? { roles: managerRoles } : skipToken);

  const freeManagerMap = useFreeEmployeesMap({
    roles: managerRoles
  });

  const employeesMap = useMemo(() => {
    if (!salesDepartmentId) {
      return freeManagerMap;
    }

    const users: UserStructureResponse[] = [
      ...(salesDepartmentQuery.currentData?.users ?? []),
      ...(employeeQuery.currentData?.map<UserStructureResponse>((item) => ({
        id: item.id,
        name: item.name,
        roles: item.roles as UserStructureResponse['roles']
      })) ?? [])
    ];

    return users.reduce((acc, current) => {
      acc[current.id] = current.name;

      return acc;
    }, {} as Record<number, string>);
  }, [salesDepartmentId, salesDepartmentQuery.currentData?.users, employeeQuery.currentData, freeManagerMap]);

  useEffect(() => {
    if (!isCreateSuccess) {
      return;
    }

    reset(defaultValues);
    resetCreateController();
    onClose();
  }, [isCreateSuccess, onClose, reset, resetCreateController]);

  useEffect(() => {
    if (!isUpdateSuccess) {
      return;
    }

    reset(defaultValues);
    resetUpdateController();
    onClose();
  }, [isUpdateSuccess, onClose, reset, resetUpdateController]);

  const [assignUser] = useStructureControllerAssignUserSalesDepartmentMutation();

  const handleAddUser = (id: number) => {
    if (!corporateGroupId || !salesDepartmentId) {
      return;
    }

    assignUser({
      corporateGroupId,
      salesDepartmentId,
      salesDepartmentUserRequest: {
        userId: id
      }
    });
  };

  const [unAssignUser] = useStructureControllerUnAssignUserSalesDepartmentMutation();

  const handleRemoveUser = (id: number) => {
    if (!corporateGroupId || !salesDepartmentId) {
      return;
    }

    unAssignUser({
      corporateGroupId,
      salesDepartmentId,
      userId: id
    });
  };

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  return (
    <FormProvider {...formMethods}>
      <SalesDepartmentFormView
        {...props}
        salesDepartmentId={salesDepartmentId}
        managersOptions={managerOptions}
        headOfDepartmentOptions={headOfDepartmentOptions}
        managersMap={employeesMap}
        onClose={handleClose}
        onSubmit={onSubmit}
        onAddUser={handleAddUser}
        onRemoveUser={handleRemoveUser}
      />
    </FormProvider>
  );
};
