import { endOfDay, startOfDay } from 'date-fns';
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { timeZoneMinutesToTimezoneString } from '../helpers/timezone';

export enum DateFormats {
  date = 'dd.MM.yyy',
  time = 'HH:mm',
  dateTime = 'dd.MM.yyy HH:mm'
}

export const calcZonedDate = (date: Date, tz: string, fn: (date: Date, options?: any) => Date, options?: any): Date => {
  const inputZoned = utcToZonedTime(date, tz);
  const fnZoned = options ? fn(inputZoned, options) : fn(inputZoned);
  return zonedTimeToUtc(fnZoned, tz);
};

export const getZonedStartOfDay = (date: Date | string, timesZoneMinutes: number): Date => {
  return calcZonedDate(new Date(date), timeZoneMinutesToTimezoneString(timesZoneMinutes), startOfDay);
};

export const getZonedEndOfDay = (date: Date | string, timesZoneMinutes: number): Date => {
  return calcZonedDate(new Date(date), timeZoneMinutesToTimezoneString(timesZoneMinutes), endOfDay);
};

export const formatDateInTimeZone = (
  date: Date | string,
  timesZoneMinutes: number,
  format: string = DateFormats.date
): string => {
  try {
    return formatInTimeZone(date, timeZoneMinutesToTimezoneString(timesZoneMinutes), format);
  } catch (e) {
    console.error(e);
    return date?.toString();
  }
};

export const formatDateToMonthYear = (date: string) => {
  const values = date.split('.');

  if (values.length !== 2) {
    return date;
  }
  const [month, year] = values;

  const formatter = new Intl.DateTimeFormat('ru', { month: 'long' });
  const formattedMonth = formatter.format(new Date(Number(year), Number(month) - 1));

  return `${formattedMonth} ${year}`;
};

export const dateToCurrentQuarter = (date: Date | string) => {
  const currentDate = new Date(date);

  const currentYear = currentDate.getFullYear();
  const currentQuarter = Math.floor((currentDate.getMonth() + 3) / 3);

  return `${currentQuarter}кв. ${currentYear}`;
};
