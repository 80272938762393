import { useMemo } from 'react';

import { useNecessaryActions } from './useNecessaryActions';

export const useNecessaryActionsEnum = () => {
  const necessaryActions = useNecessaryActions();

  const statusEnum = useMemo(() => {
    return necessaryActions?.reduce((acc, current) => {
      acc[current.key] = String(current.label);

      return acc;
    }, {} as Record<string, string>);
  }, [necessaryActions]);

  return statusEnum;
};
