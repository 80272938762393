import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EmployeeControllerGetApiArg, useEmployeeControllerGetQuery } from '@/api/web.client/webClientGeneratedApi';
import { EmployeesView } from './view';
import { useUserRoles } from '@/app/hooks/useUserRoles';

const defaultValues: EmployeeControllerGetApiArg = {
  roles: [],
  search: ''
};

export const Employees: FC = () => {
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number | undefined>(undefined);
  const [isNewEmployeeOpen, setIsNewEmployeeOpen] = useState<boolean>(false);

  const form = useForm<EmployeeControllerGetApiArg>({
    defaultValues
  });

  const formData = form.watch();

  const { data: employees } = useEmployeeControllerGetQuery(formData);

  const { roleByName, rolesOptions } = useUserRoles();

  return EmployeesView({
    form,
    roleByName,
    rolesOptions,
    selectedEmployeeId,
    isNewEmployeeOpen,
    employees: employees ?? [],
    onReset(): void {
      form.reset(defaultValues);
    },
    onNewEmployee(): void {
      setIsNewEmployeeOpen(true);
    },
    onCloseNewEmployee(): void {
      setIsNewEmployeeOpen(false);
    },
    onDetailsEmployee(employeeId): void {
      setSelectedEmployeeId(employeeId);
    }
  });
};
