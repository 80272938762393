/* eslint-disable indent */
import { isRejectedWithValue, Middleware, AnyAction } from '@reduxjs/toolkit';
import { HttpStatusCode } from 'axios';
import toast from 'react-hot-toast';
import { getErrorMessage } from '../utils/formatters/error';

const skippedEndpointNames = ['authControllerLogin'];

export const rtkQueryErrorLogger: Middleware =
  ({}) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action)) {
      const endpointName = action?.meta?.arg?.endpointName || '';
      const isSkipToast = skippedEndpointNames.includes(endpointName as string);

      if (!isSkipToast && action?.payload?.originalStatus !== HttpStatusCode.Unauthorized) {
        if (action.payload?.data?.messages?.length) {
          action.payload.data.messages.forEach((item: string) => {
            toast.error(getErrorMessage(item));
          });
        } else {
          const toastErrorMessage = getErrorMessage(action?.payload);
          toast.error(toastErrorMessage);
        }
      }
    }

    return next(action as AnyAction);
  };
