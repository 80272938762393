import { Provider as StoreProvider } from 'react-redux';

import { ConfigProvider } from 'antd';
import { Toaster } from 'react-hot-toast';

import { theme } from '@/theme';

import { Content } from '@/app/routing';

import './App.scss';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store';

function App() {
  return (
    <StoreProvider store={store}>
      <BrowserRouter>
        <ConfigProvider theme={theme}>
          <Content />
          <Toaster />
        </ConfigProvider>
      </BrowserRouter>
    </StoreProvider>
  );
}

export default App;
