import { useOrderStatuses } from './useOrderStatuses';

export const useOrderStatusesEnum = () => {
  const orderStatuses = useOrderStatuses();

  const statusEnum = orderStatuses?.reduce((acc, current) => {
    acc[current.key] = Number(current.value);

    return acc;
  }, {} as Record<string, number>);

  return statusEnum;
};
