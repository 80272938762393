import { useEffect, useState, PointerEvent } from 'react';

import { skipToken } from '@reduxjs/toolkit/dist/query';

import { openFile, useOffersCustomControllerGetFileQuery } from '@/api/web.client/webClientCustomApi';

import { FileLinkView } from './view';
import { FileLinkProps } from './model';

export const FileLink = ({ offerId, orderId, fileId }: FileLinkProps) => {
  const [isLinkClicked, setLinkClicked] = useState(false);
  const filesQuery = useOffersCustomControllerGetFileQuery(
    isLinkClicked
      ? {
          orderId,
          offerId
        }
      : skipToken
  );
  const handleFileClick = (event: PointerEvent<HTMLDivElement>) => {
    event.stopPropagation();

    setLinkClicked(true);
  };

  useEffect(() => {
    if (!filesQuery.isSuccess || !filesQuery.data || !filesQuery.data.body) {
      return;
    }

    openFile(filesQuery.data.body, filesQuery.data.fileName);

    setLinkClicked(false);
  }, [filesQuery]);

  return <FileLinkView name={fileId} onFileClick={handleFileClick} />;
};
