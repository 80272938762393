import { Typography } from 'antd';
import { MeetingPlaceLabelProps } from './model';
import { FontSizes } from '@/theme';

export const MeetingPlaceLabel = ({ name, address }: MeetingPlaceLabelProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography.Text style={{ fontSize: FontSizes.textFontSizeMD }}>{name}</Typography.Text>
      <Typography.Text style={{ fontSize: FontSizes.textFontSizeXS }} type="secondary">
        {address}
      </Typography.Text>
    </div>
  );
};
