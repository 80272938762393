import { EmployeeResponse } from '@/api/web.client/webClientGeneratedApi';
import { HookField } from '@/app/widgets/HookField';
import { AddIcon, ArrowRightSIcon, CloseIcon, SearchIcon } from '@/assets/icons';
import { Badge, BadgeColors, Button, ButtonSize, ButtonVariant, Icon, Title } from '@/ui';
import { Col, Row, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { EmployeesViewProps } from './model';
import './styles.scss';
import { EmployeeDetails } from './components/employeeDetails';
import { EmployeeNew } from './components/employeeNew';
import { usePhoneFormat } from '../../hooks/usePhoneFormat';

export const EmployeesView: FC<EmployeesViewProps> = (props) => {
  const {
    onNewEmployee,
    onDetailsEmployee,
    onReset,
    form,
    employees,
    rolesOptions,
    roleByName,
    selectedEmployeeId,
    isNewEmployeeOpen,
    onCloseNewEmployee
  } = props;

  const formatPhone = usePhoneFormat();

  const columns: ColumnsType<EmployeeResponse> = [
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      render: (name: string) => (
        <Tooltip placement="bottom" title={name}>
          <Typography.Text>{name}</Typography.Text>
        </Tooltip>
      )
    },
    {
      title: 'Статус',
      dataIndex: 'isActive',
      key: 'isActive',
      width: 200,
      render: (isActive: boolean) => (
        <Badge text={isActive ? 'Активен' : 'Не активен'} color={isActive ? BadgeColors.main : BadgeColors.storm12} />
      )
    },
    {
      title: 'Роль',
      dataIndex: 'roles',
      key: 'roles',
      width: 220,
      ellipsis: true,
      render: (roles: string[]) => {
        const rolesText = roles.map((role) => roleByName[role]).join(', ');
        return (
          <Tooltip placement="bottom" title={rolesText}>
            <Typography.Text>{rolesText}</Typography.Text>
          </Tooltip>
        );
      }
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
      width: 220,
      ellipsis: true,
      render: function (phone: string) {
        const formattedPhone = formatPhone(phone);
        return (
          <Tooltip placement="bottom" title={formattedPhone}>
            <Typography.Text>{formattedPhone}</Typography.Text>
          </Tooltip>
        );
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 220,
      ellipsis: true,
      render: (email: string) => (
        <Tooltip placement="bottom" title={email}>
          <Typography.Text>{email}</Typography.Text>
        </Tooltip>
      )
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 72,
      render: (_, row) => (
        <Button onClick={() => onDetailsEmployee(row.id)} variant={ButtonVariant.iconText} size={ButtonSize.small}>
          <Icon icon={<ArrowRightSIcon />} />
        </Button>
      )
    }
  ];

  return (
    <>
      <Row justify="center" align="top">
        <Col span={24} className="content-centered-container">
          <Row align="top" justify="space-between" gutter={16}>
            <Col span={12}>
              <Title>Сотрудники</Title>
            </Col>

            <Col span={6}>
              <Row justify="end" align="middle">
                <Col span={24}>
                  <Button onClick={onNewEmployee} isWide size={ButtonSize.medium} prefixIcon={<AddIcon />}>
                    Добавить сотрудника
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <FormProvider {...form}>
            <form name="PartnersFilters">
              <Row align="bottom" gutter={16} style={{ marginBottom: 24 }}>
                <Col span={9}>
                  <HookField
                    isWhite
                    type="text"
                    name="search"
                    label="Поиск"
                    placeholder="ФИО, email или телефон"
                    suffix={<Icon icon={<SearchIcon />} />}
                  />
                </Col>

                <Col span={9}>
                  <HookField
                    name="roles"
                    type="select"
                    label="Роль"
                    placeholder="Не выбрана"
                    options={rolesOptions}
                    mode="multiple"
                    isWhite
                    canSelectAll
                  />
                </Col>

                <Col span={6}>
                  <Button
                    isWide
                    type="button"
                    onClick={onReset}
                    prefixIcon={<CloseIcon />}
                    variant={ButtonVariant.secondary}
                  >
                    Сбросить фильтры
                  </Button>
                </Col>
              </Row>
            </form>
          </FormProvider>

          <Table
            className="employees-table"
            pagination={false}
            showHeader={false}
            columns={columns}
            dataSource={employees}
            locale={{
              emptyText: 'Нет данных'
            }}
            rowKey="id"
          />
        </Col>
      </Row>
      {selectedEmployeeId && <EmployeeDetails employeeId={selectedEmployeeId} onClose={() => onDetailsEmployee()} />}
      {isNewEmployeeOpen && <EmployeeNew onClose={onCloseNewEmployee} />}
    </>
  );
};
